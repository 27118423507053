import React, { useEffect, useMemo, useState } from "react";
import PageHeader from "../PageHeader";
import { PaymentAndBillingIcon } from "../Icons";
import { Button, Card, Form, Modal, Table } from "react-bootstrap";
import PaystackPop from "@paystack/inline-js";
import Select from "react-select";

import * as yup from "yup";

import {
  Form as FormikForm,
  ErrorMessage,
  useFormik,
  FieldArray,
  Formik,
  Field,
  FormikProvider,
} from "formik";
import { waitFor } from "../../utils/helpers";
import currency from "currency.js";
import ConfirmDialog, { ConfirmBillingDialog } from "../ConfirmDialogue";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/useAuth";
import { useQuery } from "react-query";
import NumberCustomInput from "../utils/NumberCustomInput";
import { useLocation, useNavigate } from "react-router-dom";
import { values } from "lodash";
import CreateUserAccount from "../modals/CreateUserAccount";
import { backendApis } from "../../config";
const allDepartment = [
  "",
  "Admin",
  "Accountant",
  // "Cashier",
  "Content Management",
  "Contracts",
  "HR",
  "Sales",
  // "Shout Out",
  "Store",
  "Warehouse",
  "Production",
  "Sales Manager",
  "Operations",
  "Business Development",
  "Procurement",
  "QHSE",
  "Maintenance",
  "Document Control",
  "Government InvexERP",
  "Supply and Logistics",
  "Hospital Management Board",
];

const initialValues = {
  addUsers: 1,
  totalUsers: 0,
  totalAmount: 0,
  currentBalance: 0,
  billing: 0,
  currentPlan: "",
  currentUsers: null,
};

export default function RenewBusinessPlan() {
  const { backendUrl, token } = useAuth();

  const [show, setShow] = useState(false);

  const navigate = useNavigate();

  const [companyUrl, setCompanyUrl] = useState("");

  const handleShow = () => {
    setShow(true);
  };

  const handleHide = () => {
    setShow(false);
  };
  const paystackConfig = {
    //  reference: new Date().getTime().toString(),
    //  email: "user@example.com",
    // amount: 20000, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    key: process.env.REACT_APP_BILLING_AND_PAYMENT_PAYSTACK_PUBLIC_KEY,
  };

  const pay = async ({
    amount,
    reference,
    email,
    users,
    userDetails = "",
    addedUsers,
    removedUsers,
    userActionType,
    currentUsers,
  }) => {
    if (!Boolean(users)) return;
    try {
      await waitFor(500);
      const paystack = new PaystackPop();
      paystack.newTransaction({
        ...paystackConfig,

        reference,
        email,
        amount: currency(amount).multiply(100).value,
        metadata: {
          totalUsers: users + currentUsers,
          type: "billing",
          userDetails: userDetails,
          addedUsers: addedUsers,
          removedUsers: removedUsers,
          userActionType: userActionType,
          currentUsers: currentUsers,
          email: email,
          reference,
          companyUrl: companyUrl,
        },
        // other params

        onSuccess: async (transaction) => {
          if (process.env.REACT_APP_ENV !== "production") {
            await fetch(`${backendUrl}/api/company/billing-hook`, {
              method: "POST",
              credentials: "include",
              body: JSON.stringify({
                metadata: {
                  totalUsers: users + currentUsers,
                  type: "billing",
                  userDetails: userDetails,
                  addedUsers: addedUsers,
                  removedUsers: removedUsers,
                  userActionType: userActionType,
                  currentUsers: currentUsers,
                  email,
                  reference,
                  companyUrl: companyUrl,
                },
              }),
              headers: {
                Accept: "Application/json",
                "Content-Type": "Application/json",
                ...(token ? { Authorization: `Bearer ${token}` } : {}),
              },
            });
          }
          navigate(
            `/process-billing-payment-transaction?reference=${
              transaction.reference
            }&q=${Number(formik.values.currentUsers) +
              Number(formik.values.addUsers)}`
          );
        },
        onCancel: () => {
          // user closed popup
          console.log("closed");
        },
      });
    } catch (err) {
      console.log(err);
      toast.error(JSON.stringify(err));
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async () => {
      if (process.env.REACT_APP_ENV === "development") {
        await pay({
          email: formik.values.email,
          amount: formik.values.billing,
          reference: new Date().getTime().toString(),
          users: formik.values.addUsers,
          addedUsers: formik.values.addUsers,
          removedUsers: 0,
          userActionType: "activeAddedUsers",
          currentUsers: formik.values.currentUsers,
        });
      }
    },
  });

  function isLeapYear(year) {
    const daysInNonLeapYear = 365;
    const daysInLeapYear = 366;
    if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
      return daysInLeapYear;
    } else {
      return daysInNonLeapYear;
    }
  }

  function calculateRemainingBillFromExpirationDate(
    expirationDate,
    billPerUserPeryear
  ) {
    const today = new Date();
    const expDate = new Date(expirationDate);
    const year = expDate.getFullYear();

    const diffTime = expDate.getTime() - today.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const yearToDays = isLeapYear(year);
    const billPerUserPerDay = billPerUserPeryear / yearToDays;
    const bill = Math.round(diffDays * billPerUserPerDay);
    return bill;
  }

  async function fetchData(url) {
    const res = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
    });

    const data = await res.json();

    const companyData = data?.company;
    return companyData;
  }

  const { data } = useQuery(
    ["GET_COMPANY_DETAILS"],
    () => fetchData(`${backendUrl}/api/company/details`),
    {
      onSuccess: (data) => {
        // formik.setFieldValue("currentUsers", parseInt(Number(data?.Num_Users)));
        const billingPerUser = calculateRemainingBillFromExpirationDate(
          data?.ExpiryDate,
          process.env?.REACT_APP_SITE_TITLE === "InvexERP" &&
            data?.planDurationType === "yearly"
            ? 250000
            : process.env?.REACT_APP_SITE_TITLE !== "InvexERP" &&
              data?.planDurationType === "yearly"
            ? 125000
            : process.env?.REACT_APP_SITE_TITLE === "InvexERP" &&
              data?.planDurationType === "monthly"
            ? 25000
            : process.env?.REACT_APP_SITE_TITLE !== "InvexERP" &&
              data?.planDurationType === "monthly"
            ? 12500
            : 0
        );

        formik.setFieldValue("email", data?.Email);
        formik.setFieldValue("currentPlan", "Business plan");
        formik.setFieldValue("currentUsers", Number(data?.Num_Users));
        formik.setFieldValue("billing", 1 * billingPerUser);
        formik.setFieldValue("billingPerUser", 1 * billingPerUser);

        const companyHqUrl = backendApis
          .map((url) => {
            if (url.isHq) {
              return url.url;
            }
          })

          .join("");

        setCompanyUrl(companyHqUrl);
      },
      enabled: true,
    }
  );

  return (
    <>
      <CreateUserAccount
        onHide={handleHide}
        show={show}
        refetch={() => console.log("success")}
      />

      {/* <SkipModal handleShow={handleShow} show={show} /> */}
      <main className="inner-page-outlet">
        <PageHeader
          name="Payment & Billing"
          description="Renew your business plan"
          icon={<PaymentAndBillingIcon />}
        />

        <Form noValidate onSubmit={formik.handleSubmit}>
          <main className=" container mx-auto" style={{ maxWidth: "600px" }}>
            <div>
              <div className=" w-100 p-5 px-3">
                <Card>
                  <Card.Body className="p-4 ">
                    <h6 className="card-header-title  my-3">Billing</h6>
                    <div className=" mb-3">
                      <b>
                        Current Plan:
                        <span className=" fw-normal  mx-2">Business plan</span>
                      </b>
                    </div>

                    <p className=" fw-bold border-bottom my-3 pb-2">
                      Billing Details:
                    </p>

                    <div className="mb-3   col-md-6">
                      <div className=" mb-2">Add users:</div>
                      <NumberCustomInput
                        placeholder="0"
                        name="addUsers"
                        value={formik.values.addUsers}
                        onValueChange={(value, name) => {
                          formik.setFieldValue(name, value);
                          formik.setFieldValue(
                            "billing",
                            Boolean(value)
                              ? value * formik.values.billingPerUser
                              : 0 * formik.values.billingPerUser
                          );
                        }}
                        onBlur={() => formik.setFieldTouched("addUsers", true)}
                      />
                    </div>

                    <div>Billed Yearly</div>
                    <div className="mb-3 d-flex align-items-center gap-2">
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicCheckbox"
                      >
                        <Form.Check
                          type="radio"
                          label={`${currency(
                            process.env?.REACT_APP_SITE_TITLE === "InvexERP" &&
                              data?.planDurationType === "yearly"
                              ? 250000
                              : process.env?.REACT_APP_SITE_TITLE !==
                                  "InvexERP" &&
                                data?.planDurationType === "yearly"
                              ? 125000
                              : process.env?.REACT_APP_SITE_TITLE ===
                                  "InvexERP" &&
                                data?.planDurationType === "monthly"
                              ? 25000
                              : process.env?.REACT_APP_SITE_TITLE !==
                                  "InvexERP" &&
                                data?.planDurationType === "monthly"
                              ? 12500
                              : 0
                          ).format({
                            symbol: "₦",
                          })}  / ${
                            data?.planDurationType === "yearly"
                              ? "yearly"
                              : "monthly"
                          } ( your current plan is billed at ${
                            data?.planDurationType === "yearly"
                              ? "yearly"
                              : "monthly"
                          })`}
                          name="yearly"
                          checked
                        />
                      </Form.Group>
                    </div>

                    <div className=" mb-3">
                      Total Users:
                      <span span className=" mx-2">
                        {formik.values.currentUsers + formik.values.addUsers}
                      </span>
                    </div>
                    <div className=" mb-3">
                      Prorated Amount:{" "}
                      <span className=" mx-2">
                        {" "}
                        {currency(formik.values.billing).format({
                          symbol: "₦",
                        })}
                      </span>
                    </div>

                    <div className=" row">
                      <div className=" col-md-8 mb-3">
                        <Button
                          variant="primary"
                          className="  py-3 mt-2 col-md-5"
                          type="submit"
                        >
                          Make Payment
                        </Button>
                        <Button
                          variant=""
                          className="btn-lg mt-2 col-md-5 mx-2 py-3    btn-outline-primary"
                          onClick={() => navigate("/payment-and-billing")}
                        >
                          Cancel
                        </Button>
                      </div>
                      <div className=" col-md-12">
                        <div style={{ maxWidth: "8rem" }}>
                          <img src="/card.png" className=" img-fluid" />
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </main>
        </Form>
      </main>
    </>
  );
}
