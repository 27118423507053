import { Table, Dropdown, Form, Button, InputGroup } from "react-bootstrap";
import { Popover } from "react-tiny-popover";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import Select from "react-select";
import { useQuery } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import queryString from "query-string";
import { Link } from "react-router-dom";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";

import {
  FilterTwoIcon,
  ExchangeFunds,
  SummaryChartIconUp,
  SummaryCloseIcon,
  FilterCollapseIcon,
  ChartPieSliceTitleIcon,
  FilePdfIcon,
} from "./Icons";
import "../assets/scss/reports/cashbook.scss";
import useDebounce, {
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "../utils/hooks";
// import { services } from "../../config";
import { reportActions } from "../utils/reactQueryActions";

import {
  customerFullName,
  formatDate,
  paginationOptions,
  reactSelectTheme,
} from "../utils/helpers";
import RsDateRangePicker from "./utils/RsDateRangePicker";
import { useAuth } from "../hooks/useAuth";
import CustomerSelector from "./utils/CustomerSelector";
import NoTableItem from "./utils/NoTableItem";
import PageHeader from "./PageHeader";
import { IsPrivileged } from "./DisplayChildElement";

const memos = [
  { value: "Credit Memo", label: "Credit Memo" },
  { value: "Debit Memo", label: "Debit Memo" },
  { value: "Refund", label: "Refund" },
  { value: "All", label: "All" },
];

export default function CreditDebitMemo() {
  useScrollTop();
  const initialFilterParams = {
    customerName: "",
    customerId: "",
    startDate: format(new Date(), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
    enableDateRange: true,
    memoType: "All",
  };

  const { backendUrl } = useAuth();
  //   const [activeRow, setActiveRow] = useState(null);
  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  const [btnSummryActive, setBtnSummaryActive] = useState(false);
  const [showMoreSummary, setShowMoreSummary] = useState(true);
  const [active, setActive] = useState("");
  const [acount, setAcount] = useState(false);

  const close = () => {
    setShowMoreSummary(true);
    setBtnSummaryActive(false);
  };

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const debouncedFilterParams = useDebounce(filterParams, 250);
  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  const fetchCustomerLedgers = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/reports/customer-ledger?&${queryString.stringify(
        queryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    console.log(data);
    data.invoiceCat = data.invoiceCat.map((el) => ({
      value: el,
      label: el,
    }));

    data.creditMemoAmout = data.customerLedgers.reduce(
      (p, c) => p + c.Credit,
      0
    );

    data.debitMemoAmout = data.customerLedgers.reduce((p, c) => p + c.Debit, 0);

    // console.log("data.debitMemoAmout => ", data.debitMemoAmout);
    // console.log("data.creditMemoAmout => ", data.creditMemoAmout);
    return data;
  };

  const {
    data = {
      count: 0,
      customerLedgers: [],
      debitMemoAmout: 0,
      creditMemoAmout: 0,
    },
    refetch,
    isFetching,
    isSuccess,
  } = useQuery(
    [reportActions.SALE_BY_INVOICE, queryParams],
    () => fetchCustomerLedgers(queryParams),
    {
      keepPreviousData: true,
    }
  );

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
      enableDateRange: true,
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams(initialFilterParams);
    setQueryParams({
      ...queryParams,
      ...initialFilterParams,
    });
  };

  const handleSelectedCustomer = (customer) => {
    setFilterParams({
      ...filterParams,
      customerId: customer.Cust_ID,
      customerName: customerFullName(customer),
    });
    setShowItemsPopover(false);
  };

  return (
    <IsPrivileged roleName="Credit/Debit Memo">
      <main className="inner-page-outlet">
        <PageHeader
          name="Credit/Debit Memo"
          description="Your Credit and Debit Memo"
          icon={<ChartPieSliceTitleIcon />}
        />
        <main className="cash-book">
          <div className="content">
            <div className="content-main">
              <div className={`filter-area ${showFilter ? "open" : "close"}`}>
                <div className="filter-header">
                  <h2>
                    <FilterTwoIcon /> Filters
                  </h2>

                  <Button
                    variant="white"
                    onClick={() => setShowFilter(!showFilter)}
                  >
                    <FilterCollapseIcon />
                  </Button>
                </div>
                <div className="filter-body">
                  <Form>
                    <div className="body">
                      <Form.Group className="mb-2-5">
                        <Form.Label>Customer ID</Form.Label>
                        <InputGroup>
                          <Form.Control
                            name="customerId"
                            placeholder="Enter Customer ID"
                            value={filterParams?.customerId}
                            onChange={(e) => handleFilterParamsChange(e)}
                          />
                          <Popover
                            isOpen={showItemsPopover}
                            onClickOutside={() => setShowItemsPopover(false)}
                            content={() => (
                              <CustomerSelector
                                handleSelectedCustomer={handleSelectedCustomer}
                                setShowCustomerSelector={setShowItemsPopover}
                              />
                            )}
                            position="bottom"
                          >
                            <InputGroup.Text
                              onClick={() =>
                                setShowItemsPopover(!showItemsPopover)
                              }
                            >
                              <MagnifyIcon />
                            </InputGroup.Text>
                          </Popover>
                        </InputGroup>
                      </Form.Group>

                      <Form.Group className="mb-2-5">
                        <Form.Label>Business Name</Form.Label>
                        <Form.Control
                          name="customerName"
                          value={filterParams?.customerName}
                          placeholder="Enter Business Name"
                          onChange={(e) => handleFilterParamsChange(e)}
                        />
                      </Form.Group>

                      <Form.Group className="mb-2-5">
                        <Form.Label>Invoice Cat</Form.Label>
                        <Select
                          classNamePrefix="form-select"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          placeholder="All"
                          theme={reactSelectTheme}
                          isSearchable={false}
                          key={data?.invoiceCat}
                          value={data?.invoiceCat?.find(
                            (el) => el.value === queryParams?.invoiceCat
                          )}
                          options={data?.invoiceCat || []}
                          onChange={(selected) => {
                            setFilterParams({
                              ...filterParams,
                              invoiceCat: selected.value,
                            });
                          }}
                        />
                      </Form.Group>

                      <Form.Group className="mb-2-5">
                        <Form.Label>Choose Memo Type</Form.Label>
                        <Select
                          classNamePrefix="form-select"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          placeholder="All"
                          theme={reactSelectTheme}
                          isSearchable={false}
                          value={memos?.find(
                            (el) => el.value === queryParams?.invoiceCat
                          )}
                          options={memos}
                          onChange={(selected) => {
                            setFilterParams({
                              ...filterParams,
                              memoType: selected.value,
                            });
                          }}
                        />
                      </Form.Group>

                      <hr className="mt-3 mb-4" />

                      <div className="mb-2-5">
                        <Form.Check
                          inline
                          label="Enable Date Range"
                          name="enableDateRange"
                          type={"checkbox"}
                          id={`enableDateRange`}
                          checked={filterParams?.enableDateRange}
                          onChange={(e) => handleFilterParamsChange(e)}
                        />
                      </div>

                      <Form.Group className="">
                        <Form.Label>Date Range</Form.Label>
                        <div className="position-relative">
                          <RsDateRangePicker
                            placement="topStart"
                            value={
                              filterParams.startDate && filterParams.endDate
                                ? [
                                    parse(
                                      filterParams.startDate,
                                      "yyyy-MM-dd",
                                      new Date()
                                    ),
                                    parse(
                                      filterParams.endDate,
                                      "yyyy-MM-dd",
                                      new Date()
                                    ),
                                  ]
                                : []
                            }
                            onClean={() => clearDateRange()}
                            onOk={(date) => filterByDateRange(date)}
                          />
                        </div>
                      </Form.Group>
                    </div>

                    <div className="buttons rounded">
                      <Button onClick={() => reset()} variant="white">
                        Reset
                      </Button>
                      <Button onClick={() => search()} variant="primary">
                        Search
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="content-body">
                <header>
                  <h1>
                    {!showFilter && (
                      <button
                        onClick={() => setShowFilter(!showFilter)}
                        className="btn filter"
                      >
                        <FilterTwoIcon />
                      </button>
                    )}
                    Customer Credit/Debit Memo
                    <button
                      title="Refresh"
                      onClick={() => refetch()}
                      className="btn text-primary"
                    >
                      <CachedIcon />
                    </button>
                  </h1>
                  <div className="actions">
                    {/* <button className="btn print">
              Filters <FilterIcon />
            </button> */}

                    <Link
                      to="/create-credit-debit-memo"
                      className="btn bg-primary text-white"
                    >
                      Create New <ExchangeFunds color="white" />
                    </Link>

                    <button
                      onClick={() => setBtnSummaryActive(true)}
                      className="btn summaryBtn "
                    >
                      Show Summary
                    </button>
                  </div>
                </header>

                <div className="px-md-4">
                  <Table
                    responsive
                    borderless
                    // striped
                    className="product-table text-nowrap"
                  >
                    <thead>
                      <tr>
                        <th />
                        <th>Business Name</th>
                        <th>Trans Date</th>
                        <th>Doc Type</th>
                        <th>Trans ID</th>
                        <th>Payment Type</th>
                        <th>Credit Amount</th>
                        <th>Debit Amount</th>
                        <th>Balance</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.customerLedgers.map((el, index) => (
                        <tr
                          key={index}
                          className={`p-cursor tran-table ${active === index &&
                            "active-table"} ${index % 2 === 0 && "dark-row"}`}
                          onClick={() => setActive(index)}
                        >
                          <td>
                            {
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant=""
                                  className="bg-white border-0"
                                  bsPrefix="print more"
                                >
                                  <DotsVerticalIcon />
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                  popperConfig={{
                                    strategy: "fixed",
                                  }}
                                  renderOnMount
                                  className="dropdown-with-icons"
                                >
                                  <Dropdown.Item as="div">
                                    <a
                                      href={`${backendUrl}/api/invoice/pdf/memo/${
                                        el.Trans_ID
                                      }`}
                                      target="blank"
                                    >
                                      <FilePdfIcon color="#2463AE" />
                                      <span className="text-primary">
                                        View PDF - A4
                                      </span>
                                    </a>
                                  </Dropdown.Item>
                                  <Dropdown.Item as="div">
                                    <a
                                      href={`${backendUrl}/api/customers/view-memo/pdf/${
                                        el.Trans_ID
                                      }/${el.Cust_ID}`}
                                      target="blank"
                                    >
                                      <FilePdfIcon color="#2463AE" />
                                      <span className="text-primary">
                                        View PDF - Thermal Printer
                                      </span>
                                    </a>
                                  </Dropdown.Item>
                                </Dropdown.Menu>{" "}
                              </Dropdown>
                            }
                          </td>
                          <td>{customerFullName(el.customer)}</td>
                          <td>
                            {el.Post_Time
                              ? formatDate(el.Post_Time, "dd MMM, yyyy")
                              : ""}
                          </td>
                          <td>{el.DocType}</td>
                          <td>{el.Trans_ID}</td>
                          <td>{el.PaymentType}</td>
                          <td>
                            {currency(el.Credit, {
                              symbol: "",
                            }).format()}
                          </td>
                          <td>
                            {currency(el.Debit, {
                              symbol: "",
                            }).format()}
                          </td>
                          <td>
                            {currency(el.Balance, {
                              symbol: "",
                            }).format()}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  {!isFetching &&
                  isSuccess &&
                  isEmpty(data?.customerLedgers) ? (
                    <NoTableItem queryParams={queryParams} />
                  ) : null}
                </div>

                <div className="d-flex justify-content-between px-3 align-items-center pagination">
                  {/*<p className="m-0">
                Showing {data?.startIndex + 1} to{" "}
                {data?.endIndex <= data?.count ? data?.endIndex : data?.count}{" "}
                of {data.count} entries
                  </p> */}
                  <div className="pagination_left">
                    <p className="m-0 p-0">Show</p>
                    <select
                      value={queryParams.limit}
                      name="limit"
                      className="form-select "
                      onChange={(e) => handleSearchQueryChange(e)}
                    >
                      <option value="10">10 rows</option>
                      <option value="20">20 rows</option>
                      <option value="30">30 rows</option>
                      <option value="40">40 rows</option>
                      <option value="50">50 rows</option>
                      <option value="100">100 rows</option>
                    </select>
                  </div>

                  <ReactPaginate
                    {...paginationOptions}
                    pageCount={Math.ceil(data.count / queryParams.limit)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={0}
                    onPageChange={({ selected }) => {
                      document.body.scrollTop = document.documentElement.scrollTop = 0;
                      setQueryParams({
                        ...queryParams,
                        page: selected + 1,
                      });
                    }}
                    forcePage={queryParams.page - 1}
                  />
                </div>

                <div
                  className={
                    btnSummryActive
                      ? `summaryNew border-top open`
                      : `summaryNew border-top`
                  }
                >
                  <div className="summaryHeader">
                    <h2>Summary</h2>
                    {btnSummryActive && (
                      <button onClick={() => close()} className="btn p-0">
                        <SummaryCloseIcon />
                      </button>
                    )}
                  </div>

                  <div className="gridCont">
                    <div className="gridChild gridChildBorderLeftBlue">
                      <div className="gridChildLeft">
                        <p className="gridChld1">
                          {currency(data?.creditMemoAmout, {
                            symbol: "",
                          }).format()}
                        </p>
                        <p className="gridChld2">Total Credit Memo</p>
                      </div>

                      <div className="gridChildRight">
                        <SummaryChartIconUp />
                        <p>5%</p>
                      </div>
                    </div>

                    <div className="gridChild gridChildBorderLeftOrange">
                      <div className="gridChildLeft">
                        <p className="gridChld1">
                          {currency(data?.debitMemoAmout, {
                            symbol: "",
                          }).format()}
                        </p>
                        <p className="gridChld2">Total Debit Memo</p>
                      </div>

                      <div className="gridChildRight">
                        <SummaryChartIconUp />
                        <p>5%</p>
                      </div>
                    </div>

                    {/*  <AddAccounts show={acount} onHide={() => setAcount(false)} /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </main>
    </IsPrivileged>
  );
}
