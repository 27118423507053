import { Table, InputGroup, Button, Dropdown, Form } from "react-bootstrap";
import { CSVLink } from "react-csv";
import printJS from "print-js";
import {
  CalendarIcon,
  CubeIcon,
  DeleteIcon,
  EditIcon,
  ExportIcon,
  FIleUploadLineIcon,
  FilterCollapseIcon,
  MoneyDollarIcon,
  PrintIcon,
  RecieptIcon,
} from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useRef, useState } from "react";
import DateRangePicker from "../utils/DateRangePicker";
import CachedIcon from "mdi-react/CachedIcon";
import { FilterTwoIcon, PDFIcon, ExcelIcon } from "../Icons";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import DownloadIcon from "mdi-react/DownloadOutlineIcon";
import { queryActions, reportActions } from "../../utils/reactQueryActions";
import useDebounce, {
  useIsAdmin,
  useIsCashier,
  useIsProcurement,
  useIsStore,
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
import queryString from "query-string";
import { appSettings, services } from "../../config";
import { useMutation, useQuery, useQueryClient } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  defaultSelectValue,
  paginationOptions,
  qtyFormat,
  qtyFormatToString,
  scrollToTop,
  sortOptions,
} from "../../utils/helpers";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import { useAuth } from "../../hooks/useAuth";
import UpdateItemModal from "../UpdateItemModal";
// import NewItemModal from "../NewItemModal";
// import PurchaseDialog from "../PurchaseDialog";
// import NewItemServiceModal from "../NewItemServiceModal";
import NewItemModalWrapper from "../NewItemModalWrapper";
// import NewVendorModal from "../NewVendorModal";
import BatchesModal from "./BatchesModal";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import LoginDialog from "../LoginDialog";
import ConfirmDialog from "../ConfirmDialogue";
import { toast } from "react-toastify";
import CubeOutline from "mdi-react/CubeOutlineIcon";
import { Link } from "react-router-dom";
import { useStoreState } from "easy-peasy";
import Select from "react-select";
import { Popover } from "react-tiny-popover";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import ItemsTable from "../utils/ItemsTable";
import TableComponent from "../TableComponent";
import ConvertQuantity from "../utils/ConvertQuantity";
import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import ModalLoader from "../utils/ModalLoader";
import UpdateItemPriceModal from "../UpdateItemPriceModal";
// import QuantityConversionModal from "../QuantityConversionModal";
import GRNDialog from "../GRNDialog";
import AddItemSizeDialog from "../modals/AddItemSizeModal";
// import ItemService from "../../hooks/ItemService";
import { lowerCase, truncate } from "lodash";
import BarcodeIcon from "mdi-react/BarcodeIcon";
import UpdateImageModal from "../DisplayItemImages";
import { fetchActionsUtil } from "../../utils/helpers";
import { read, utils } from "xlsx";
import * as yup from "yup";
import { uniqBy } from "lodash";
import ItemExchangeRateModal from "../modals/ItemExchangeRateModal";
import { IsPrivileged } from "../DisplayChildElement";

export default function HMBInventory({ ItemStatus }) {
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();
  const { backendUrl, isIronRod, isCement } = useAuth();
  const isAdmin = useIsAdmin();
  const isStore = useIsStore();
  const isCashier = useIsCashier();
  const isProcurement = useIsProcurement();
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showUpdatePriceModal, setShowUpdatePriceModal] = useState(false);
  const [showUpdateImageModal, setShowUpdateImageModal] = useState(false);
  const [showItemExchangeRateModal, setShowItemExchangeRateModal] = useState(
    false
  );
  // const [itemBarcodeToEdit, setItemBarcodeToEdit] = useState();

  const generalSettings = useStoreState((state) => state.generalSettings);

  useScrollTop();

  const [showUpdateInventoryModal, setShowUpdateInventoryModal] = useState(
    false
  );
  const [showBatchesModal, setShowBatchesModal] = useState(false);
  const [
    showCreateNewInventoryModal,
    setShowCreateNewInventoryModal,
  ] = useState(false);

  const initialFilterParams = {
    page: 1,
    limit: 40,
    barcode: "",
    itemName: "",
    product: "",
    category: "",
    branch: "",
    withProduct: true,
    withCategory: true,
    sort: "name-asc",
    ItemStatus,
  };
  const [queryParams, setQueryParams] = useQueryParams({
    ...initialFilterParams,
  });
  const [filterParams, setFilterParams] = useState({
    ...queryParams,
    ...initialFilterParams,
  });

  const [excelData, setExcelData] = useState([]);

  const CSVLinkRef = useRef(null);

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  useEffect(() => {
    setFilterParams((filter) => ({
      ...filter,
      ItemStatus:
        generalSettings?.itemStatusSettings && ItemStatus !== "Unprocessed"
          ? "Processed"
          : ItemStatus,
    }));
  }, [generalSettings?.itemStatusSettings, ItemStatus]);

  const fetchItems = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/items?&${queryString.stringify(queryParams)}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    data.product = [
      {
        label: "All",
        value: "",
      },
      ...data.product.map((el) => ({
        label: el.Product_Name,
        value: el.Product_Name,
      })),
    ];

    data.category = [
      {
        label: "All",
        value: "",
      },
      ...data.category.map((el) => ({
        label: el.Cat_Name,
        value: el.Cat_Name,
      })),
    ];

    data.branch = [
      {
        label: "All",
        value: "",
      },
      ...data.branch.map((el) => ({
        label: el.Branch,
        value: el.Branch,
      })),
    ];
    return data;
  };

  const { data = { count: 0, items: [] }, refetch } = useQuery(
    [queryActions.ITEMS, queryParams],
    () => fetchItems(queryParams),
    {
      keepPreviousData: true,
    }
  );

  const handleShowBatches = (item) => {
    setSelectedItem(item);
    setShowBatchesModal(true);
  };

  const deleteItem = async (payload) => {
    let response = await fetch(`${backendUrl}/api/items/delete`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const deleteMutation = useMutation((payload) => deleteItem(payload), {
    onSuccess: ({ message, data: { item } }) => {
      toast.success(message);

      queryClient.setQueryData([queryActions.ITEMS, queryParams], (data) => {
        data.items = data.items.filter((el) => el.Bar_Code !== item.Bar_Code);
        return data;
      });
    },
    onError: ({ message = "" }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });

  const addMultipleItems = async (payload) => {
    let response = await fetch(`${backendUrl}/api/items/add-stock-multiple`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const addMultipleItemsMutation = useMutation(
    (payload) => addMultipleItems(payload),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
        refetch();
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  const handleDeleteItem = async (item) => {
    if (await LoginDialog()) {
      if (
        await ConfirmDialog({
          title: "Delete Item",
          description:
            "Are you sure you want to delete this Item from your inventory",
        })
      ) {
        deleteMutation.mutate(item);
      }
    }
  };

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams({
      ...initialFilterParams,
    });
  };

  const handleSelectedItem = (item) => {
    setFilterParams({
      ...filterParams,
      barcode: item.Bar_Code,
      itemName: item.Item_Name,
    });
    setShowItemsPopover(false);
  };

  const editPrice = async (el) => {
    if (await LoginDialog()) {
      setSelectedItem(el);
      setShowUpdatePriceModal(true);
    }
  };

  const printGRN = async (InvoiceNo) => {
    await GRNDialog({
      title: "Transaction posted successfully",
      // description: "...",
      InvoiceNo,
    });
  };

  /*  const getItem = async (itemBarcodeToEdit) => {
    let response = await fetch(
      `${backendUrl}/api/items/get-item/${itemBarcodeToEdit}`,
      {
        method: "GET",
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };
 */
  const handleEditSizes = async (item) => {
    const sizes = await getItemSizes(item);

    if (sizes) {
      const data = await AddItemSizeDialog({
        item,
        maxQuantity: item.Quantity,
        previousSizes: sizes,
      });
      if (data === false) return;
      if (data?.sizesAndColours) {
        saveItemSizesMutation.mutate({ sizes: data?.sizesAndColours, item });
      }
    }
  };

  const getItemSizes = (item) => {
    return new Promise(async (resolve, reject) => {
      try {
        setIsLoading(true);
        let response = await fetch(
          `${backendUrl}/api/items/get-item/${item.Bar_Code}`,
          {
            headers: {
              Accept: "Application/json",
              "Content-Type": "Application/json",
            },
            credentials: "include",
          }
        );

        if (!response.ok) {
          response = await response.json();
          toast.error(response.message);
        } else {
          const {
            data: { sizes },
          } = await response.json();

          resolve(sizes);
        }
      } catch (err) {
        console.log(err);
        toast.error("Unable to perform action");
        reject();
      } finally {
        setIsLoading(false);
      }
    });
  };

  const saveItemSizes = async (payload) => {
    let response = await fetch(`${backendUrl}/api/items/sizes/save`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const saveItemSizesMutation = useMutation(
    (payload) => saveItemSizes(payload),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
        // setItemBarcodeToEdit(null);
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  const turnOFFandON = async (Bar_Code) => {
    if (!Bar_Code) return toast.error(`Add Bar Code`);
    await fetchActionsUtil(
      `${backendUrl}/api/items/update-showInStore`,
      "POST",
      "",
      { Bar_Code }
    );
    refetch();
  };

  const updateShowInStore = async (Bar_Code, status) => {
    if (status) {
      turnOFFandON(Bar_Code);
    } else {
      if (generalSettings.store) {
        turnOFFandON(Bar_Code);
      } else {
        toast.error("Not subscribed");
      }
    }
  };

  // The main table data
  const tableBodyData = (el, index) => {
    return (
      <>
        <td>{data?.startIndex + index + 1}</td>
        <td>{el?.Bar_Code}</td>
        <td>{el?.Item_Name}</td>
        <td style={{ whiteSpace: "pre" }} title={el?.Item_Desc}>
          {appSettings.isMedbury ? truncate(el?.Item_Desc) : el?.Item_Desc}
        </td>
        <td>{el?.Product_Model}</td>
        <td
          className="p-cursor text-nowrap"
          onClick={() => handleShowBatches(el)}
          title={el.Quantity}
        >
          <ConvertQuantity quantity={el.Quantity} desc={el.Item_Desc} />
        </td>
        <td>
          {currency(el?.UnitPrice, {
            symbol: "",
          }).format()}
        </td>
        <td>{el?.Pack_Size}</td>
      </>
    );
  };

  const tableHead = () => {
    return (
      <thead>
        <tr>
          <th>S/N</th>
          <th>Item Code</th>
          <th>Item Name</th>
          <th>Item Desc</th>
          <th>UOM</th>
          <th>QTY</th>
          <th>Unit Price</th>
          <th>Pack Size</th>
        </tr>
      </thead>
    );
  };

  const { limit, page, ...rest } = queryParams;
  const onDownloadExcelData = async () => {
    const { limit, page, ...rest } = queryParams;
    let exData = await fetchExcelData(
      `${backendUrl}/api/items?${queryString.stringify(rest)}`,
      "GET"
    );

    const company = exData.data?.company?.CompName;

    // console.log(exData.data);
    exData = exData.data.items.map((row) => ({
      ...row,
      Date_Log: format(new Date(row.Date_Log), "dd-MMM-yyyy"),
    }));

    exData = exData.map((d, i) => [
      data?.startIndex + i + 1,
      d.Bar_Code,
      d.Item_Name,
      d.Item_Desc,
      isIronRod
        ? qtyFormatToString(
            qtyFormat(d.Quantity, d.Item_Desc, itemMeasurements)
          )
        : d.Quantity,
      currency(d?.UnitPrice, {
        symbol: "",
      }).format(),
      d.Pack_Size,
    ]);
    const date =
      rest.startDate && rest.endDate
        ? `Date Prepared: Between ${format(
            new Date(rest.startDate),
            "E MMM d yyyy k:mm:ss z"
          )} to ${format(new Date(rest.endDate), "E MMM d yyyy k:mm:ss z")}`
        : "";
    exData = [
      [company],
      ["Inventory Entry Report"],
      [date],
      [""],
      [
        "S/N",
        "Item Code",
        "Item Name",
        "Item Desc",
        "UOM",
        "QTY",
        "Unit Price",
        "Pack Side",
      ],
      ...exData,
      [""],
    ];
    // console.log(exData);
    setExcelData(exData);
  };

  function handleFile({ eventData: e, type }) {
    /*  toast.info("Service not Available");
    return; */
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async function(e) {
        const workbook = read(e.target.result, {
          cellDates: true,
          dateNF: "yyyy-MM-dd HH:mm:ss.SSS",
        });
        let rows = utils.sheet_to_json(
          workbook.Sheets[workbook.SheetNames[0]],
          {
            defval: "",
            dateNF: "yyyy-MM-dd HH:mm:ss.SSS",
          }
        );
        //  console.log("excel", rows);

        //--
        if (
          !(await ConfirmDialog({
            title: "Are you sure?",
            description: "You want to import",
          }))
        ) {
          return;
        }
        // TODO - validate excel
        let schema = yup.object().shape({
          items: yup.array().of(
            yup.object({
              Vendor_ID: yup.string(),
              vendorName: yup.string(),
              Item_Type: yup.string(),
              Item_Code: yup.string().required(),
              New_Item_Code: yup.string(),
              Batch_Name: yup.string(),
              Item_Name: yup.string().required(),
              InvoiceNo: yup.string(),
              Item_Desc: yup.string(),
              Unit: yup.string(),
              Usage: yup.string(),
              UnitCost: yup.string().required(),
              Freight: yup.string(),
              LandingUnitCost: yup.string(),
              Quantity: yup.string().default("0"),
              ManDate: yup.string().default(new Date().toISOString()),
              ExpireDate: yup.string().default(new Date().toISOString()),
              Date_Log: yup.string().default(new Date().toISOString()),
              Manufacturer: yup.string(),
              Category: yup.string(),
              Branch: yup.string(),
              Minimum_Level: yup.string().default("0"),
              Tax: yup.string(),
              Vat_5: yup.string(),
              UnitPrice: yup.string().required(),
              MaximumLevel: yup.string().default("0"),
              DriverName: yup.string(),
              DriverPhoneNumber: yup.string(),
              TruckNumber: yup.string(),
              DeliveryDate: yup.string(),
              showInStore: yup.string(),
              addDiscount: yup.string().default("0"),
              Profit: yup.string().default("0"),
              Incentive: yup.string().default("0"),
              pcsPriceDistributor: yup.string(),
              marginType: yup.string().default("Fixed"),
              marginPercentage: yup.string().default("0"),
              incentiveType: yup.string().default("Fixed"),
              incentivePercentage: yup.string().default("0"),
              age: yup.string(),
              ageDurationValue: yup.string(),
              parentBatchBarCode: yup.string(),
              parentBatchItemName: yup.string(),
              ItemStatus: yup.string(),
              InvoiceAmount: yup.string().default("0"),
              Total_Freight: yup.string().default("0"),
              totalLandingCost: yup.string().default("0"),
              totalDiscount: yup.string(),
              freightVendor_ID: yup.string(),
              Pack_Size: yup.string(),
              Generic_Configuration: yup.string(),
              Tier: yup.string(),
              Branded_Generic: yup.string(),
              Batch_Number: yup.string(),
              Serial_Number: yup.string(),
              currency: yup.string().default("NGN"),
            })
          ),
        });
        try {
          await schema.validate({ items: rows });

          // General Clean up
          rows = rows.map((el) => ({
            ...el,
            Bar_Code: el.Item_Code,
            ManDate: el?.ManDate ? el?.ManDate : el?.Date_Log,
            ExpireDate: el?.ExpireDate ? el?.ExpireDate : el?.Date_Log,
            Reorder_Level: el.Minimum_Level,
          }));

          if (generalSettings?.itemsRequiresSerialNumber) {
            // Group by and clean up
            const uniqueByBar_Code = uniqBy(
              rows.map((el) => {
                el.LandingUnitCost = el?.LandingUnitCost
                  ? el?.LandingUnitCost
                  : el.UnitCost;
                el.totalLandingCost = el?.totalLandingCost
                  ? el?.totalLandingCost
                  : el.UnitCost;
                el.InvoiceAmount = el?.InvoiceAmount
                  ? el?.InvoiceAmount
                  : el.UnitCost;
                return {
                  ...el,
                  Bar_Code: el.Item_Code,
                  //  uniqueRow: `${el.Bar_Code}-${el.Batch_Number}`,
                };
              }),
              "Bar_Code"
            );

            rows = uniqueByBar_Code.map((el) => {
              const foundItems = rows.filter(
                (rowItem) => String(rowItem.Bar_Code) == String(el.Bar_Code)
              );
              return {
                ...el,
                ...(type === "RESTOCK"
                  ? {
                      InvoiceAmount: foundItems
                        .map((el) => el?.InvoiceAmount || 0)
                        .reduce((a, b) => currency(a).add(b).value),
                      Total_Freight: foundItems
                        .map((el) => el?.Total_Freight || 0)
                        .reduce((a, b) => currency(a).add(b).value),
                      totalLandingCost: foundItems
                        .map((el) => el?.totalLandingCost || 0)
                        .reduce((a, b) => currency(a).add(b).value),
                    }
                  : {}),
                Quantity: foundItems?.length,
                serialNumbers: foundItems.map((el) => ({
                  serialNum: el.Serial_Number,
                })),
              };
            });

            // TODO - Do not mix vendors

            // TODO - Ensure all dates are valid
          }

          // console.log(rows);

          if (type === "RESTOCK") {
            addMultipleItemsMutation.mutate({
              items: rows.map((el) => ({
                ...el,
                New_Bar_Code: el.New_Item_Code,
                Bar_Code: el.Item_Code,
                Margin: el.Profit,
                Cat_Name: el.Category,
                PurchasePrice: el.UnitCost,
                UnitCost: el?.LandingUnitCost,
                Batch_Name: el.Batch_Number,
                InvoiceNo: el?.InvoiceNo ? el?.InvoiceNo : `IVN${Date.now()}`,
              })),
              type, // "RESTOCK"
            });
            return;
          }

          addMultipleItemsMutation.mutate({
            items: rows.map((el) => ({
              ...el,
              New_Bar_Code: el.New_Item_Code,
              Bar_Code: el.Item_Code,
              Margin: el.Profit,
              Cat_Name: el.Category,
              PurchasePrice: el.UnitCost,
              UnitCost: el?.UnitCost,
              Batch_Name: el.Batch_Number,
              InvoiceNo: el?.InvoiceNo ? el?.InvoiceNo : `IVN${Date.now()}`,
            })),
            type, // "INSERT_ITEMS"  "CHANGE_PRICES" "RESTOCK"
          });

          //   resolve(validData);
        } catch (err) {
          console.log(err);
          toast.error(err.name);
          toast.error(JSON.stringify(err.errors));
        }
      };
      reader.readAsArrayBuffer(file);
    }
    e.target.value = "";
  }
  // isPrivilegesd

  return (
    <IsPrivileged roleName={["Sales Inventory", "Inventory Entry"]}>
      <section className="cashbook-wrapped">
        <main className="cash-book">
          <div className="content">
            <div className="content-main">
              <div className={`filter-area ${showFilter ? "open" : "close"}`}>
                <div className="filter-header">
                  <h2>
                    <FilterTwoIcon /> Filters
                  </h2>

                  <Button
                    variant="white"
                    onClick={() => setShowFilter(!showFilter)}
                  >
                    <FilterCollapseIcon />
                  </Button>
                </div>
                <div className="filter-body">
                  <Form>
                    <div className="body">
                      <Form.Group className="mb-2-5">
                        <Form.Label>Item Code</Form.Label>

                        <InputGroup>
                          <Form.Control
                            name="barcode"
                            placeholder="Enter Item Code"
                            value={filterParams?.barcode}
                            onChange={(e) => handleFilterParamsChange(e)}
                          />

                          <Popover
                            isOpen={showItemsPopover}
                            onClickOutside={() => setShowItemsPopover(false)}
                            content={() => (
                              <ItemsTable
                                handleSelectedItem={handleSelectedItem}
                                defaultSort={"name-asc"}
                              />
                            )}
                            position="bottom"
                          >
                            <InputGroup.Text
                              onClick={() =>
                                setShowItemsPopover(!showItemsPopover)
                              }
                            >
                              <MagnifyIcon />
                            </InputGroup.Text>
                          </Popover>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group className="mb-2-5">
                        <Form.Label>Item Name</Form.Label>
                        <Form.Control
                          name="itemName"
                          value={filterParams?.itemName}
                          placeholder="Enter Item Name"
                          onChange={(e) => handleFilterParamsChange(e)}
                        />
                      </Form.Group>
                    </div>

                    <div className="buttons rounded">
                      <Button onClick={() => reset()} variant="white">
                        Reset
                      </Button>
                      <Button onClick={() => search()} variant="primary">
                        Search
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="content-body">
                <header>
                  <h1>
                    {!showFilter && (
                      <button
                        onClick={() => setShowFilter(!showFilter)}
                        className="btn filter"
                      >
                        <FilterTwoIcon />
                      </button>
                    )}
                    Inventory
                    <button
                      onClick={() => refetch()}
                      className="btn text-primary"
                      title="Refresh"
                    >
                      <CachedIcon />
                    </button>
                  </h1>
                  <div className="actions mr-5" style={{ zIndex: "3" }}>
                    <Select
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          width: "12rem",
                        }),
                      }}
                      classNamePrefix={`form-select-store`}
                      isSearchable={false}
                      placeholder="Sort By"
                      options={sortOptions.filter((el) =>
                        el?.value?.includes("name")
                      )}
                      value={sortOptions.find(
                        (el) => el.value === filterParams.sort
                      )}
                      onChange={({ value }) =>
                        setFilterParams({
                          ...filterParams,
                          page: 1,
                          sort: value,
                        })
                      }
                    />{" "}
                    <CSVLink
                      className="btn print d-none"
                      filename={`inventory Entry Report(${format(
                        new Date(),
                        "dd-MMM-yyyy hh:mm:ss a"
                      )}).csv`}
                      data={excelData}
                      ref={CSVLinkRef}
                    />
                    <Dropdown>
                      <Dropdown.Toggle
                        variant=""
                        className="btn print"
                        disabled={isfetchingExcel}
                        bsPrefix=""
                      >
                        Export
                        <ExportIcon color="#008000" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        popperConfig={{
                          strategy: "fixed",
                        }}
                        renderOnMount
                        className="text-center"
                      >
                        <Dropdown.Item
                          as="div"
                          onClick={onDownloadExcelData}
                          className="p-cursor"
                        >
                          Excel <ExcelIcon color="#008000" />
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </header>

                <div className="px-md-4">
                  <TableComponent
                    responsive
                    borderless
                    striped
                    hover
                    tableHeadsFunction={tableHead}
                    mainDataArray={data?.items}
                    tableDataRowFunction={tableBodyData}
                    className="product-table"
                  />
                </div>

                <div className="d-flex justify-content-between px-3 align-items-center pagination">
                  {/*<p className="m-0">
                Showing {data?.startIndex + 1} to{" "}
                {data?.endIndex <= data?.count ? data?.endIndex : data?.count}{" "}
                of {data.count} entries
                  </p> */}
                  <div className="pagination_left">
                    <p className="m-0 p-0">Show</p>
                    <select
                      value={queryParams.limit}
                      name="limit"
                      className="form-select "
                      onChange={(e) => handleSearchQueryChange(e)}
                    >
                      <option value="10">10 rows</option>
                      <option value="20">20 rows</option>
                      <option value="30">30 rows</option>
                      <option value="40">40 rows</option>
                      <option value="50">50 rows</option>
                      <option value="100">100 rows</option>
                    </select>
                  </div>

                  <ReactPaginate
                    {...paginationOptions}
                    pageCount={Math.ceil(data.count / queryParams.limit)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={0}
                    onPageChange={({ selected }) => {
                      scrollToTop();
                      setQueryParams({
                        ...queryParams,
                        page: selected + 1,
                      });
                    }}
                    forcePage={queryParams.page - 1}
                  />
                </div>
              </div>
            </div>
          </div>

          {showUpdateInventoryModal && (
            <UpdateItemModal
              showUpdateInventoryModal={showUpdateInventoryModal}
              setShowUpdateInventoryModal={setShowUpdateInventoryModal}
              refetch={refetch}
              printGRN={printGRN}
              requiresApproval={generalSettings.restockRequiresApproval}
            />
          )}

          {showCreateNewInventoryModal && (
            <NewItemModalWrapper
              setShowCreateNewInventoryModal={setShowCreateNewInventoryModal}
              showCreateNewInventoryModal={showCreateNewInventoryModal}
              refetch={refetch}
            />
          )}

          {showBatchesModal && selectedItem && (
            <BatchesModal
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              showBatchesModal={showBatchesModal}
              setShowBatchesModal={setShowBatchesModal}
            />
          )}

          {showUpdatePriceModal && selectedItem ? (
            <UpdateItemPriceModal
              showUpdatePriceModal={showUpdatePriceModal}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              setShowUpdatePriceModal={setShowUpdatePriceModal}
              refetch={refetch}
            />
          ) : null}

          {showUpdateImageModal && selectedItem ? (
            <UpdateImageModal
              showUpdatePriceModal={showUpdateImageModal}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              setShowUpdatePriceModal={setShowUpdateImageModal}
              refetch={refetch}
            />
          ) : null}

          {showItemExchangeRateModal && (
            <ItemExchangeRateModal
              showItemExchangeRateModal={showItemExchangeRateModal}
              setShowItemExchangeRateModal={setShowItemExchangeRateModal}
              refetch={refetch}
            />
          )}

          <ModalLoader
            show={
              isfetchingExcel ||
              isLoading ||
              saveItemSizesMutation.isLoading ||
              addMultipleItemsMutation.isLoading
            }
          />

          {/*  <QuantityConversionModal /> */}
        </main>
      </section>
    </IsPrivileged>
  );
}
