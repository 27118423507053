import { useStoreState } from "easy-peasy";
import AddRodItemModal from "./AddRodItemModal";
import AddRodItemMultipleModal from "./AddRodItemMultipleModal";

export default function AddItemModal(props) {
  const generalSettings = useStoreState((state) => state.generalSettings);
  return generalSettings.inventorySelector === "Single" ? (
    <AddRodItemModal {...props} />
  ) : (
    <AddRodItemMultipleModal {...props} />
  );
}
