import { Table, Dropdown, Form, Button } from "react-bootstrap";
import { useQuery, useMutation } from "react-query";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { toast } from "react-toastify";
import { useState } from "react";
import CachedIcon from "mdi-react/CachedIcon";

import {
  customerFullName,
  employeeFullName,
  fetchActionsUtil,
  formatDate,
} from "../../utils/helpers";
import { useAuth } from "../../hooks/useAuth";
import ConfirmDialog from "../ConfirmDialogue";
import PasswordModal from "../modals/ChangePassword";
import EditUserAccountModal from "../modals/EditUserAccount";
import "../../assets/scss/reports/cashbook.scss";
import { DeleteIcon, EditIcon, ExchangeFunds } from "../Icons";
import CreateUserAccount from "../modals/CreateUserAccount";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import NewEmployeeModal from "./NewEmployeeModal";
import EditEmployeeModal from "./EditEmployeeModal";
import ModalLoader from "../utils/ModalLoader";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import useDebounce, { useIsAdmin, useQueryParams } from "../../utils/hooks";
import queryString from "query-string";
import EyeOutlineIcon from "mdi-react/EyeOutlineIcon";
import SalesRepModal from "../SalesRepModal";
import { isEmpty } from "lodash";
import Select from "react-select";
import { IsPrivileged } from "../DisplayChildElement";

const ViewEmployees = ({ usage }) => {
  const navigate = useNavigate();
  const { backendUrl } = useAuth();
  const isAdmin = useIsAdmin();
  const [passwordModal, setPasswordModal] = useState({
    modal: false,
    name: "",
    userId: "",
  });

  const [selectedEmployee, setSelectedEmployee] = useState();
  const [showCreateNewEmployeeModal, showCreateNewCustomerModal] = useState(
    false
  );
  const [showEditEditEmployeeModal, setShowEditEmployeeModal] = useState(false);
  const [showSalesRepModal, setShowSalesRepModal] = useState(false);

  const initialFilterParams = { q: "", page: 1, limit: 40, usage };
  const [queryParams, setQueryParams] = useQueryParams({
    ...initialFilterParams,
  });
  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });
  const debouncedFilterParams = useDebounce(filterParams, 500);

  const { data, refetch } = useQuery(
    ["FETCH EMPLOYEES", debouncedFilterParams],
    () =>
      fetchActionsUtil(
        `${backendUrl}/api/employees?${queryString.stringify(
          debouncedFilterParams
        )}`,
        "GET"
      ),
    {
      keepPreviousData: true,
    }
  );

  const deleteAccount = useMutation(
    (payload) =>
      fetchActionsUtil(`${backendUrl}/api/employees/delete/${payload}`, "POST"),
    {
      onSuccess: () => {
        refetch();
        toast.success("Employee deleted Successfully");
      },
      onError: () => {
        toast.error("Unable to delete");
      },
    }
  );

  const onDeleteAccount = async (employeesID, name) => {
    if (
      await ConfirmDialog({
        title: "Delete Employee",
        description: `Are you sure you want to DELETE?`,
      })
    ) {
      deleteAccount.mutate(employeesID);
    }
  };

  return (
    <IsPrivileged roleName={["Sales Rep"]}>
      <div className="dashboard-content">
        <div className=" pt-2 bg-white">
          <main className="table-sticky-header">
            <div className="">
              <header className="px-4 mt-3 d-flex justify-content-between">
                <div className="d-flex gap-3 align-items-center">
                  {isEmpty(usage) && <h6>Employees</h6>}
                  <div className="d-flex flex-wrap gap-3">
                    <div className="global-search-area">
                      <MagnifyIcon />
                      <Form.Control
                        id="queryParams-q"
                        className=""
                        name="q"
                        value={filterParams.q}
                        onChange={(e) =>
                          setFilterParams({
                            ...filterParams,
                            q: e.target.value,
                          })
                        }
                        placeholder="Search..."
                      />
                    </div>
                    <Button
                      onClick={() => refetch()}
                      variant=""
                      className="bg-light-blue border-0 text-primary"
                    >
                      <CachedIcon />
                    </Button>
                  </div>
                </div>
                <div className="actions mr-5 d-flex">
                  {isAdmin && (
                    <button
                      onClick={() => showCreateNewCustomerModal(true)}
                      className="btn bg print text-black mx-4"
                    >
                      Add +
                    </button>
                  )}
                </div>
              </header>

              <div className="px-md-4 my-4">
                <Table striped responsive className="product-table">
                  <thead>
                    <tr>
                      <th />
                      <th>Employee ID</th>
                      <th>Title</th>
                      <th>Name</th>
                      <th>Department</th>
                      <th>Gender</th>
                      <th>Phone</th>
                      <th>Email</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.employees.map((el, i) => (
                      <tr key={i}>
                        <td>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant=""
                              className="bg-white border-0"
                              bsPrefix="print more"
                            >
                              <DotsVerticalIcon />
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                              popperConfig={{
                                strategy: "fixed",
                              }}
                              renderOnMount
                              className="dropdown-with-icons"
                            >
                              <Dropdown.Item
                                as="div"
                                onClick={() => {
                                  setSelectedEmployee(el);
                                  setShowSalesRepModal(true);
                                }}
                                className="p-cursor"
                              >
                                <EyeOutlineIcon /> Customers
                              </Dropdown.Item>

                              {isAdmin && (
                                <>
                                  <Dropdown.Item
                                    as="div"
                                    onClick={() => {
                                      setSelectedEmployee(el);
                                      setShowEditEmployeeModal(true);
                                    }}
                                    className="p-cursor"
                                  >
                                    <EditIcon /> Edit
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    as="div"
                                    onClick={() =>
                                      onDeleteAccount(el.EmployeeID, el.Name)
                                    }
                                    className="p-cursor"
                                  >
                                    <DeleteIcon /> Delete
                                  </Dropdown.Item>
                                </>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                        <td>{el.EmployeeID}</td>
                        <td>{el.Title}</td>
                        <td>{employeeFullName(el)}</td>
                        <td>{el.Department}</td>
                        <td>{el.Gender}</td>
                        <td>{el.PhoneNumber}</td>
                        <td>{el.Email}</td>
                        <td>
                          {el?.ModifiedDate
                            ? formatDate(el?.ModifiedDate)
                            : null}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </main>
        </div>

        {showEditEditEmployeeModal && selectedEmployee ? (
          <EditEmployeeModal
            showEditEditEmployeeModal={showEditEditEmployeeModal}
            setShowEditEmployeeModal={setShowEditEmployeeModal}
            refetch={refetch}
            selectedEmployee={selectedEmployee}
            setSelectedEmployee={setSelectedEmployee}
          />
        ) : null}

        {showCreateNewEmployeeModal && (
          <NewEmployeeModal
            showCreateNewEmployeeModal={showCreateNewCustomerModal}
            setShowCreateNewEmployeeModal={showCreateNewCustomerModal}
            refetch={refetch}
          />
        )}

        {/* Customers  */}
        {showSalesRepModal && selectedEmployee ? (
          <SalesRepModal
            showSalesRepModal={showSalesRepModal}
            setShowSalesRepModal={setShowSalesRepModal}
            refetch={refetch}
            selectedEmployee={selectedEmployee}
            setSelectedEmployee={setSelectedEmployee}
          />
        ) : null}

        <ModalLoader isLoading={deleteAccount.isLoading} />
      </div>
    </IsPrivileged>
  );
};

export default ViewEmployees;
