import { Button, Dropdown, Form } from "react-bootstrap";
import { CSVLink } from "react-csv";
import {
  DeleteIcon,
  DropdownCheckIcon,
  DropdownCloseIcon,
  EditIcon,
  ExportIcon,
  FilterCollapseIcon,
  PDFIcon,
} from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useRef, useState } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import { FilterTwoIcon, ExcelIcon } from "../Icons";
import useDebounce, {
  useIsAdmin,
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
import queryString from "query-string";
import { useMutation, useQuery } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  formatDate,
  paginationOptions,
  reactSelectTheme,
  scrollToTop,
} from "../../utils/helpers";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import { useAuth } from "../../hooks/useAuth";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import TableComponent from "../TableComponent";
import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import ModalLoader from "../utils/ModalLoader";
import { isEmpty } from "lodash";
import NoTableItem from "../utils/NoTableItem";
import { fetchActionsUtil } from "../../utils/helpers";
import ConfirmDialog from "../ConfirmDialogue";
import { toast } from "react-toastify";
import Select from "react-select";
import LoginDialog from "../LoginDialog";
import Modal from "react-bootstrap/Modal";
import { IsPrivileged } from "../DisplayChildElement";
import { FieldArray, FormikProvider, useFormik } from "formik";

export default function SerialNumbers() {
  const { backendUrl } = useAuth();
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);

  useScrollTop();
  const [showAddSerialNumber, setShowAddSerialNumber] = useState(false);
  const [showEditSerialNumber, setShowEditSerialNumber] = useState(false);
  const [showApproveSerialNumber, setShowApproveSerialNumber] = useState(false);
  const [selected, setSelected] = useState({});
  const isAdmin = useIsAdmin();

  const [allStates, setAllStates] = useState();

  const initialFilterParams = {
    page: 1,
    limit: 40,
    Name: "",
  };
  const [queryParams, setQueryParams] = useQueryParams({
    ...initialFilterParams,
  });
  const [filterParams, setFilterParams] = useState({
    ...queryParams,
    ...initialFilterParams,
  });

  const [excelData, setExcelData] = useState([]);
  const CSVLinkRef = useRef(null);

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams((q) => ({ ...q, ...debouncedFilterParams }));
  }, [debouncedFilterParams, setQueryParams]);

  const fetchItems = async (queryParams) => {
    let { data } = await fetchActionsUtil(
      `${backendUrl}/api/items/serial-number?&${queryString.stringify(
        queryParams
      )}`
    );

    data.staffs = data?.staffs.map((el) => ({
      value: el.UserName,
      label: el.UserName,
    }));
    return data;
  };

  const {
    data = { count: 0, serialNumbers: [], staffs: [] },
    refetch,
    isFetching,
  } = useQuery(
    ["STOCK_COUNTS", queryParams],
    () => fetchItems(queryParams),
    {}
  );

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams(initialFilterParams);
    setQueryParams({
      ...queryParams,
      ...initialFilterParams,
    });
  };

  const deleteApi = async (payload) => {
    let response = await fetch(`${backendUrl}/api/items/serial-number/delete`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const deleteSerialNumberMutation = useMutation(
    (payload) => deleteApi(payload),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
        refetch();
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );
  const handleDelete = async (serialNumber) => {
    if (
      await ConfirmDialog({
        title: "Delete Serial Number",
        description: "Are you sure you want to delete this Serial Number",
      })
    ) {
      deleteSerialNumberMutation.mutate({ ...serialNumber });
    }
  };

  // The main table data
  const tableBodyData = (el, index) => {
    return (
      <>
        <td>
          <Dropdown>
            <Dropdown.Toggle
              variant=""
              className="bg-white border-0"
              bsPrefix="print more"
            >
              <DotsHorizontalIcon />
            </Dropdown.Toggle>
            <Dropdown.Menu
              popperConfig={{
                strategy: "fixed",
              }}
              renderOnMount
              className="dropdown-with-icons"
            >
              <Dropdown.Item
                as="div"
                className="p-cursor"
                onClick={() => handleDelete(el)}
              >
                <DeleteIcon />
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
        <td>{data?.startIndex + index + 1}</td>
        <td>{el?.serialNumber || "..."}</td>
        <td>{el?.UserName}</td>
        <td>{el?.Reg_date ? formatDate(el?.Reg_date, "dd MMM, yyyy") : ""}</td>
      </>
    );
  };

  const tableHead = () => {
    return (
      <thead>
        <tr>
          <th />
          <th>S/N</th>
          <th>Serial Number</th>
          <th>Staff</th>
          <th>Date</th>
        </tr>
      </thead>
    );
  };

  const { limit, page, ...rest } = queryParams;

  const onDownloadExcelData = async () => {
    let exData = await fetchExcelData(
      `${backendUrl}/api/items/serial-number?${queryString.stringify(rest)}`,
      "GET"
    );

    // console.log(exData);

    exData = exData?.data.serialNumbers.map((row) => ({
      ...row,
    }));

    exData = exData.map((d, i) => [String(d.serialNumber)]);

    exData = [["Serial Number"], ...exData];

    // console.log(exData);

    setExcelData(exData);
  };

  return (
    <IsPrivileged roleName="Stock Count">
      <section className="cashbook-wrapped">
        <main className="cash-book">
          <div className="content">
            <div className="content-main">
              <div className={`filter-area ${showFilter ? "open" : "close"}`}>
                <div className="filter-header">
                  <h2>
                    <FilterTwoIcon /> Filters
                  </h2>

                  <Button
                    variant="white"
                    onClick={() => setShowFilter(!showFilter)}
                  >
                    <FilterCollapseIcon />
                  </Button>
                </div>
                <div className="filter-body">
                  <Form>
                    <div className="body">
                      <Form.Group className="mb-2-5">
                        <Form.Label>Staff</Form.Label>
                        <Select
                          classNamePrefix="form-select"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          name="UserName"
                          placeholder="All"
                          theme={reactSelectTheme}
                          isSearchable={true}
                          key={data?.UserName}
                          onChange={(selected) => {
                            setFilterParams({
                              ...filterParams,
                              UserName: selected.value,
                            });
                          }}
                          value={
                            filterParams?.UserName && data?.staffs
                              ? data?.staffs.find(
                                  (el) => el.value === filterParams?.UserName
                                )
                              : {
                                  value: "",
                                  label: "All",
                                }
                          }
                          options={data?.staffs}
                          isClearable
                        />
                      </Form.Group>

                      <hr className="mt-3 mb-4" />
                      <Form.Group className="">
                        <Form.Label>Date Range</Form.Label>
                        <div className="position-relative">
                          <RsDateRangePicker
                            placement="topStart"
                            value={
                              filterParams.startDate && filterParams.endDate
                                ? [
                                    parse(
                                      filterParams.startDate,
                                      "yyyy-MM-dd",
                                      new Date()
                                    ),
                                    parse(
                                      filterParams.endDate,
                                      "yyyy-MM-dd",
                                      new Date()
                                    ),
                                  ]
                                : []
                            }
                            onClean={() => clearDateRange()}
                            onOk={(date) => filterByDateRange(date)}
                          />
                        </div>
                      </Form.Group>
                    </div>

                    <div className="buttons rounded">
                      <Button onClick={() => reset()} variant="white">
                        Reset
                      </Button>
                      <Button onClick={() => search()} variant="primary">
                        Search
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="content-body">
                <header>
                  <h1>
                    {!showFilter && (
                      <button
                        onClick={() => setShowFilter(!showFilter)}
                        className="btn filter"
                      >
                        <FilterTwoIcon />
                      </button>
                    )}
                    Serial Numbers
                    <button
                      onClick={() => refetch()}
                      className="btn text-primary"
                      title="Refresh"
                    >
                      <CachedIcon />
                    </button>
                  </h1>
                  <div className="actions mr-5">
                    <CSVLink
                      className="btn print d-none"
                      filename={`Serial_Numbers_(${formatDate(
                        new Date(),
                        "dd-MMM-yyyy hh:mm:ss a"
                      )}).csv`}
                      data={excelData}
                      ref={CSVLinkRef}
                    />

                    <Button
                      onClick={onDownloadExcelData}
                      variant=""
                      className="btn print"
                    >
                      Export Excel <ExcelIcon color="#008000" />
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => setShowAddSerialNumber(true)}
                    >
                      + Add
                    </Button>
                  </div>
                </header>

                <div className="px-md-4">
                  <TableComponent
                    responsive
                    borderless
                    striped
                    tableHeadsFunction={tableHead}
                    mainDataArray={data?.serialNumbers}
                    tableDataRowFunction={tableBodyData}
                    className="product-table text-nowrap"
                  />
                  {isEmpty(data.serialNumbers) && !isFetching ? (
                    <div className="d-flex justify-content-center text-center w-100 my-4">
                      <NoTableItem queryParams={queryParams} />
                    </div>
                  ) : null}
                </div>

                <div className="d-flex justify-content-between px-3 align-items-center pagination">
                  <div className="pagination_left">
                    <p className="m-0 p-0">Show</p>
                    <select
                      value={queryParams.limit}
                      name="limit"
                      className="form-select "
                      onChange={(e) => handleSearchQueryChange(e)}
                    >
                      <option value="10">10 rows</option>
                      <option value="20">20 rows</option>
                      <option value="30">30 rows</option>
                      <option value="40">40 rows</option>
                      <option value="50">50 rows</option>
                      <option value="100">100 rows</option>
                    </select>
                  </div>

                  <ReactPaginate
                    {...paginationOptions}
                    pageCount={Math.ceil(data.count / queryParams.limit)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={0}
                    onPageChange={({ selected }) => {
                      scrollToTop();
                      setQueryParams({
                        ...queryParams,
                        page: selected + 1,
                      });
                    }}
                    forcePage={queryParams.page - 1}
                  />
                </div>
              </div>
            </div>
          </div>

          {showAddSerialNumber ? (
            <AddSerialNumber
              showAddSerialNumber={showAddSerialNumber}
              setShowAddSerialNumber={setShowAddSerialNumber}
              refetch={refetch}
            />
          ) : null}

          <ModalLoader
            show={isfetchingExcel || deleteSerialNumberMutation.isLoading}
          />
        </main>
      </section>
    </IsPrivileged>
  );
}

function AddSerialNumber({
  showAddSerialNumber,
  setShowAddSerialNumber,
  refetch,
}) {
  const { backendUrl } = useAuth();
  const addSerialNumberApi = async (payload) => {
    let response = await fetch(`${backendUrl}/api/items/serial-number/add`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const addMutation = useMutation((payload) => addSerialNumberApi(payload), {
    onSuccess: ({ message }) => {
      toast.success(message);
      formik.resetForm();
      if (refetch) refetch();
      setShowAddSerialNumber(false);
    },
    onError: ({ message = "" }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });

  const formik = useFormik({
    initialValues: { serialNumbers: [] },
    onSubmit: async (values) => {
      addMutation.mutate(values);
    },
  });

  const addSerialNumbers = (e) => {
    const { value } = e.target;

    if (e.keyCode === 13 || e.which === 13) {
      if (formik.values.serialNumbers.find((el) => el.serialNumber === value)) {
        toast.info("Already added");
        return;
      }
      const array = [...formik.values.serialNumbers, { serialNumber: value }];
      formik.setFieldValue("serialNumbers", array);
      e.target.value = "";
    }
  };

  return (
    <>
      <Modal
        show={showAddSerialNumber}
        onHide={setShowAddSerialNumber}
        animation={false}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="h6">Add Serial Numbers</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormikProvider value={formik}>
            <Form
              noValidate
              onSubmit={formik.handleSubmit}
              className="row new-item-form w-100 m-0"
              autoComplete="off"
            >
              {" "}
              <div>
                <Form.Group className="mb-3">
                  <Form.Label className="d-flex justify-content-center">
                    <span>Serial Numbers</span>
                  </Form.Label>

                  <Form.Control
                    className="mb-2"
                    type="text"
                    placeholder="Enter Serial Numbers"
                    name="Item_Name"
                    onKeyDown={addSerialNumbers}
                  />

                  <FieldArray
                    name="serialNumbers"
                    render={(arrayHelpers) => (
                      <>
                        <div className="row">
                          {formik.values.serialNumbers.map((el, index) => (
                            <div className="col-4">
                              <div className="d-flex gap-3 mb-2">
                                <Form.Control
                                  name={`serialNumbers[${index}].serialNumber`}
                                  placeholder="Serial Number"
                                  value={
                                    formik.values.serialNumbers[index]
                                      .serialNumber
                                  }
                                  onChange={formik.handleChange}
                                />
                                <Button
                                  title="Remove"
                                  variant=""
                                  type="button"
                                  size="xs"
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  ✖
                                </Button>
                              </div>
                            </div>
                          ))}
                        </div>

                        <div>
                          <span className="custom-invalid-feedback">
                            {formik.errors.serialNumbers}
                          </span>
                        </div>
                      </>
                    )}
                  />
                </Form.Group>
              </div>
            </Form>
          </FormikProvider>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowAddSerialNumber(false)}
          >
            Close
          </Button>
          <Button
            variant="primary"
            className="px-4"
            onClick={() => formik.submitForm()}
            disabled={addMutation.isLoading}
          >
            Post
          </Button>
        </Modal.Footer>
      </Modal>
      <ModalLoader show={addMutation.isLoading} />
    </>
  );
}
