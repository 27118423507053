import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import CloseIcon from "mdi-react/CloseIcon";

const RejectModal = (props) => {
  const [inputText, setInputText] = useState("");

  return (
    <Modal
      onHide={props.onHide}
      show={props.show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div>
          <div>
            <div className="d-flex justify-content-between">
              <h4>Reason For Rejection</h4>
              <div role="button" className="text-lg" onClick={props.onHide}>
                <CloseIcon />
              </div>
            </div>
            <p className="text-muted">Reason for the rejection</p>
          </div>

          <div className="mt-3">
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              onChange={(e) => setInputText(() => e.target.value)}
              value={inputText}
            />
          </div>
        </div>
      </Modal.Body>
      <div className="d-inline-flex justify-content-end m-4">
        <button className="btn bg print mx-4" onClick={props.onHide}>
          Cancel
        </button>
        <div
          className="btn btn-primary"
          onClick={() => {
            props.disapproveAll(inputText);
            setInputText("");
          }}
        >
          Send Reject
        </div>
      </div>
    </Modal>
  );
};

export default RejectModal;
