import { useState } from "react";
import { NavLink } from "react-router-dom";

export default function ReconcilationNav(props) {
  const paymentTypes = [
    {
      name: "Cash",
      to: "customer-ledger/Cash",
    },

    {
      name: "Credit/Debit Card (POS)",
      to: `customer-ledger/${encodeURIComponent("Credit/Debit Card")}`,
    },
    {
      name: "Direct Bank Transfer",
      to: `customer-ledger/${encodeURIComponent("Direct Bank Transfer")}`,
    },
    {
      name: "Cheque",
      to: "customer-ledger/Cheque",
    },
  ];

  const others = [
    {
      name: "Bank",
      to: "bank",
    },
    {
      name: "Expenses",
      to: "expenses",
    },
  ];

  return (
    <div className="dashboard-tabs innerpage-tabs tier-1 px-4">
      <ul className="nav nav-tabs">
        {paymentTypes.map((item, index) => (
          <li key={index} className="nav-item">
            <NavLink className={`nav-link ga-nav-tab`} to={item.to}>
              {item.name}
            </NavLink>
          </li>
        ))}
        {others.map((item, index) => (
          <li key={index} className="nav-item">
            <NavLink className={`nav-link ga-nav-tab`} to={item.to}>
              {item.name}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
}
