import { useEffect, useRef } from "react";
import { useState } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Dropdown,
  Overlay,
  Table,
} from "react-bootstrap";
import { Popover } from "react-tiny-popover";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import { useLocation } from "react-router-dom";
import {
  convertToBaseCurrency,
  copyText,
  customerFullName,
  formatDate,
  maxTopPopperConfig,
  pcsToTons,
  qtyFormat,
  qtyFormatToString,
  reactSelectTheme,
  tonsToPcs,
  toTonsOrPcs,
} from "../../utils/helpers";
import CustomerSelectModal from "../CustomerSelectModal";
import {
  AddCircleIcon,
  CreateInvoiceIcon,
  NoSelectedItemIcon,
  UserSolidIcon,
  CashSelectIcon,
  CreditSelectIcon,
  DirectTransferSelectIcon,
  ChequeSelectIcon,
  CustomerAccountSelectIcon,
  CreditMemoSelectIcon,
  EditIcon,
  DeleteIcon,
  RecieptIcon,
} from "../Icons";
import NewCustomerModal from "../NewCustomerModal";
import NewItemModal from "../NewItemModal";
import PageHeader from "../PageHeader";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import DatePickerCustomInput from "../utils/DatePickerCustomInput";
import ItemsTable from "../utils/ItemsTable";
import "./../../assets/scss/create-invoice.scss";
import AddItemModal from "./AddItemModal";
import currency from "currency.js";
import DotsVeritcalIcon from "mdi-react/DotsVerticalIcon";
import { isEmpty, lowerCase } from "lodash";
import EditItemModal from "./EditItemModal";
import { useMemo } from "react";
import NumberCustomInput from "../utils/NumberCustomInput";
import { appSettings, services } from "../../config";
import queryString from "query-string";
import { useQuery } from "react-query";
import { queryActions, reportActions } from "../../utils/reactQueryActions";
import { useFormik, FieldArray, FormikProvider } from "formik";
import * as yup from "yup";
import Datetime from "react-datetime";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import SelectBankDialog from "../SelectBankDialog";
import useDebounce, {
  useUpdateEffect,
  useEffectOnce,
  useTaxOptions,
  useUnits,
} from "../../utils/hooks";
import AddRodItemModal from "./AddRodItemModal";
import ConfirmDialog from "../ConfirmDialogue";
import EditIronRodItemModal from "./EditIronRodItemModal";
import PermitModal from "./PermitModal";
import { useAuth } from "../../hooks/useAuth";
import { useStoreActions, useStoreState } from "easy-peasy";
import ModalLoader from "../utils/ModalLoader";
import printJS from "print-js";
import { first } from "lodash";
import ProformaDialog from "../ProformaDialog";
import moment from "moment";
import { currenciesOptions } from "../../utils/currencies";
import { truncate } from "lodash";
import ConvertQuantity from "../utils/ConvertQuantity";
import { IsPrivileged } from "../DisplayChildElement";
import ProformaChoiceDialog from "../ProformaChoiceDialog";

export default function CreateProformaInvoice({ withoutApproval = false }) {
  const paymentMethod = [
    {
      icon: <CreditMemoSelectIcon />,
      label: "Proforma",
      value: "Proforma",
    },
  ];

  const taxOptions = useTaxOptions();

  const { Units: saleTypes } = useUnits();
  const generalSettings = useStoreState((state) => state.generalSettings);
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const defaultCustomer = useStoreState((state) => state.defaultCustomer);
  const { backendUrl } = useAuth();
  const [loadingPrint, setLoadingPrint] = useState(false);
  const [showCustomerSelectorModal, setShowCustomerSelectorModal] = useState(
    false
  );
  const [selectedCustomer, setSelectedCustomer] = useState(defaultCustomer);
  const [showItemSelectorModal, setShowItemSelectorModal] = useState(false);
  const [showCreateNewCustomerModal, setShowCreateNewCustomerModal] = useState(
    false
  );
  const [showPermitModal, setShowPermitModal] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [lockedTableData, setLockedTableData] = useState([]);
  const [editedItemIndex, setEditedItemIndex] = useState(null);
  const location = useLocation();

  const convertQuantity = (Quantity, Item_Desc, type) => {
    return lowerCase(type) === "tons"
      ? pcsToTons(Quantity, Item_Desc, itemMeasurements)
      : Quantity;
  };

  useEffectOnce(() => {
    if (location.state && location.state?.customer) {
      setSelectedCustomer(location.state?.customer);
    }
  });

  const createRodInvoice = async (payload) => {
    let response = await fetch(`${backendUrl}/api/invoice/create`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  const createRodInvoiceMutation = useMutation(
    (payload) => createRodInvoice(payload),
    {
      onSuccess: ({ data, message }) => {
        toast.success(message);
        /*  if (formik.values.printReciept) {
          window.open(
            `${backendUrl}/api/invoice/pdf/invoice/${data.TransactionID}`,
            "_blank",
            "noopener,noreferrer"
          );
        } */

        formik.resetForm();
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  // permit
  const createRodPermit = async (payload) => {
    //  Conversions Before  Send -\
    // console.log(payload);

    if (payload.currency && payload.currency !== "NGN") {
      payload = convertToBaseCurrency({
        data: payload,
        conversionAmount: payload.conversionAmount,
      });
    }

    let response = await fetch(
      `${backendUrl}/api/invoice/create-pending-permit`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  const openInvoiceModal = async (data) => {
    await ProformaDialog({
      title: "Transaction posted successfully",
      TransactionID: data.TransactionID,
      pendingInvoiceType: generalSettings.pendingInvoiceType,
    });
  };

  const createRodPermitMutation = useMutation(
    (payload) => createRodPermit(payload),
    {
      onSuccess: ({ message, data }) => {
        toast.success(message);
        copyText(
          `${services.frontendUrl}/approval/details/${data.TransactionID}`
        );
        /*  printJS({
          printable: `${backendUrl}/api/invoice/pdf/proforma-invoice/${
            data.TransactionID
          }`,
          onLoadingStart: () => setLoadingPrint(true),
          onLoadingEnd: () => setLoadingPrint(false),
        }); */
        formik.resetForm();
        setTableData([]);
        /*  window.open(
          `${backendUrl}/api/invoice/pdf/proforma-invoice/${
            data.TransactionID
          }`,
          "_blank",
          "noopener,noreferrer"
        ); */
        openInvoiceModal(data);
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );
  // ----------------------------------------------------------
  const formik = useFormik({
    initialValues: {
      //  tax: "",
      taxType: "None",
      PaymentType: "Proforma",
      proformaNumber: "",
      splitPaymentType: "",
      printWaybill: true,
      salesDate: new Date(),
      invoiceCat: "",
      OverwriteOfficer: "Retail",
      chequeNumber: "",
      BankName: "",
      cashAmount: "",
      sendEmail: false,
      printReciept: true,
      dueIn: 1,
      pendingTransaction: "",
      supplyNow: true,
      ShipTo: "",
      customerBalance: "",
      amountToPayFromCustomerCredit: "",
      shippingCost: 0,
      terms: [{ text: "" }],
      remark: generalSettings.pendingInvoiceType === "Quotation" ? "" : "",
      otherCharges: 0,
      currency: "NGN",
      linkedPaymentID: "",
      conversionAmount: generalSettings.dollarInBaseCurrency,
      poNumber: "",
    },
    validationSchema: yup.object().shape({
      PaymentType: yup.string().required(),
      // invoiceCat: yup.string().required("required"),
    }),
    onSubmit: async (values) => {
      try {
        if (isEmpty(tableData)) return toast.error(`Please add an Item`);
        if (!selectedCustomer) return toast.error(`Please select a customer`);

        let isWithoutApproval = withoutApproval;
        let choice = "";

        if (
          appSettings.isMedbury &&
          generalSettings.pendingInvoiceType === "Proforma Invoice"
        ) {
          choice = await ProformaChoiceDialog({
            title: "Select",
          });

          if (choice) {
            if (choice === "Send for Payment") {
              isWithoutApproval = true;
            } else if (choice === "Send for Approval") {
              isWithoutApproval = false;
            }
          } else {
            return;
          }
        }

        // send to pending
        if (
          await ConfirmDialog({
            title: `Post Transaction: ${choice}`,
            description: "Are you sure, you want to make this transaction ?",
          })
        ) {
          createRodPermitMutation.mutate({
            items: tableData.map((el) => ({
              ...el,
              Quantity: el.Quantity,
            })),
            ...values,
            subTotal,
            discount,
            amountDue,
            profit,
            balance,
            amount,
            customer: selectedCustomer,
            taxValue,
            costOFSales,
            ...(generalSettings.pendingInvoiceType === "Quotation"
              ? { Status: "Quotation" }
              : isWithoutApproval
              ? { Status: "Approved" }
              : {}),
            terms: JSON.stringify(values.terms),
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    onReset: () => {
      setTableData([]);
    },
  });

  const showSelectBankDialog = async (props = {}) => {
    const bank = await SelectBankDialog({
      ...props,
      selectedBank: formik.values.BankName,
    });
    if (bank) {
      formik.setFieldValue("BankName", bank.bank);
      if (props.hasChequeNumber) {
        formik.setFieldValue("chequeNumber", bank.chequeNumber);
      }
    }
  };

  useEffect(() => {
    if (
      ["Credit/Debit Card", "Direct Bank Transfer"].includes(
        formik.values.PaymentType
      )
    ) {
      showSelectBankDialog();
    } else if (formik.values.PaymentType === "Cheque") {
      showSelectBankDialog({
        hasChequeNumber: true,
      });
    }
    if (formik.values.PaymentType === "Split Payment") {
      formik.setFieldValue("splitPaymentType", "card");
    } else {
      formik.setFieldValue("splitPaymentType", "");
    }
  }, [formik.values.PaymentType]);

  /* Split Payment  */
  useEffect(() => {
    if (["cheque"].includes(formik.values.splitPaymentType)) {
      showSelectBankDialog({
        hasChequeNumber: true,
      });
    } else if (
      ["card", "directTransfer"].includes(formik.values.splitPaymentType)
    ) {
      showSelectBankDialog({
        hasChequeNumber: false,
      });
    }
  }, [formik.values.splitPaymentType]);

  const fetchSetUpData = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/invoice/set-up`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    data.invoiceCat = data.invoiceCat.map((el) => ({
      value: el.Product_Name,
      label: el.Product_Name,
    }));
    return data;
  };

  const { data = { invoiceCat: [] } } = useQuery(
    [queryActions.INVOICE_SETUP],
    () => fetchSetUpData(),
    {
      keepPreviousData: true,
    }
  );

  const populateTableFromItems = (items) => {
    // convert qtys and clean up

    items = items.map((el) => {
      // check if sales type
      const value = qtyFormat(el.QTY, el.Serial_Number, itemMeasurements).split(
        "-"
      );
      const tons = value[0];

      return {
        ...el,
        saleType: el.Serial_Number
          ? Number(tons) > 0
            ? "Tons"
            : "Pieces"
          : "",
        Item_Desc: el.Serial_Number,
        PriceSold: currency(el.PriceSold, {
          symbol: "",
          separator: "",
        }).format(),
        Quantity: currency(el.QTY, {
          symbol: "",
          separator: "",
        }).format(),
        UnitCost: currency(el.UnitCost, {
          symbol: "",
          separator: "",
        }).format(),
        Discount: currency(el.Discount, {
          symbol: "",
          separator: "",
        }).format(),
        SubTotal: currency(el.SubTotal, {
          symbol: "",
          separator: "",
        }).format(),
        Profit: currency(el.Profit, {
          symbol: "",
          separator: "",
        }).format(),
        UnitPrice: currency(el.Unit_Price, {
          symbol: "",
          separator: "",
        }).format(),
      };
    });

    console.log(items);

    if (items) {
      formik.resetForm();

      const {
        PayType,
        VAT,
        OverwriteOfficer,
        Date_Log,
        TransactionID,
        customer,
        ProductName,
        terms,
        shippingCost,
        otherCharges,
        remark,
        taxType,
      } = items[0];

      formik.setValues({
        ...formik.values,
        taxValue: VAT,
        PaymentType: PayType,
        OverwriteOfficer,
        salesDate: Date_Log,
        pendingTransaction: TransactionID,
        invoiceCat: ProductName,
        ...(terms ? { terms: JSON.parse(terms) } : {}),
        shippingCost,
        otherCharges,
        remark,
        taxType,
      });
      setTableData(items);
      setSelectedCustomer(customer);

      // locked
      setLockedTableData(items);
    }
  };

  useEffect(() => {
    formik.setFieldValue(
      "ShipTo",
      selectedCustomer?.LastName
        ? selectedCustomer?.LastName
        : defaultCustomer.LastName
    );
  }, [selectedCustomer]);

  const handleSelectedPermit = (permit) => {
    setShowPermitModal(false);
    populateTableFromItems(permit.items);
  };

  const handleAddItem = (item) => {
    if (Array.isArray(item)) {
      setTableData(item);
      setShowItemSelectorModal(false);
      return;
    }

    // console.log(item);
    if (lockedTableData.find((el) => el.Bar_Code === item.Bar_Code)) {
      return toast.error(`${item.Item_Name} can't be modified`);
    }

    // if sale rep version, ensure all other items have same product_name
    if (!isEmpty(tableData) && appSettings.requireSalesRep) {
      const firstItem = first(tableData);
      console.log(firstItem);
      if (firstItem.Product_Name !== item.Product_Name) {
        return toast.error(
          `Select Items with the same manufacturer (${
            firstItem.Product_Name
          }), `
        );
      }
    }
    const foundIndex = tableData.findIndex(
      (el) => el.Bar_Code === item.Bar_Code
    );
    if (foundIndex === -1) {
      setTableData([...tableData, item]);
    } else {
      const oldItem = tableData[foundIndex];
      if (
        (oldItem.saleType !== item.saleType && !appSettings.isBatchStandard) ||
        oldItem.Serial_Number !== item.Serial_Number
      ) {
        setTableData([...tableData, item]);
      } else {
        tableData[foundIndex] = item;
        setTableData([...tableData]);
      }
    }

    formik.setFieldValue("invoiceCat", item.Product_Name);
    setShowItemSelectorModal(false);
  };

  const handleEditItem = (item) => {
    if (lockedTableData.find((el) => el.Bar_Code === item.Bar_Code)) {
      return toast.error(`${item.Item_Name} can't be modified`);
    }
    const foundIndex = tableData.findIndex(
      (el) =>
        el.Bar_Code === item.Bar_Code &&
        el.saleType === item.saleType &&
        el.Serial_Number === item.Serial_Number
    );

    tableData[foundIndex] = item;
    setTableData([...tableData]);
    setEditedItemIndex(null);
  };

  const handleRemoveItem = (index) => {
    setTableData([...tableData.filter((el, i) => i !== index)]);
  };

  const discard = () => {
    formik.resetForm();
    setTableData([]);
    setSelectedCustomer(defaultCustomer);
  };

  const handleRowClick = (e, index) => {
    if (e.target.tagName === "TD") setEditedItemIndex(index);
  };

  // discount sum
  const discount = useMemo(() => {
    const sum = tableData
      ? tableData
          .map(
            (el) => el.Discount
            /*  currency(el.Discount, { symbol: "", separator: "" })
              .multiply(
                convertQuantity(el.Quantity, el.Serial_Number, el.saleType)
              )
              .format() */
          )
          .reduce(
            (a, b) =>
              currency(a, {
                precision: 2,
              }).add(b),
            0
          )
      : 0.0;
    return sum
      ? currency(sum, {
          symbol: "",
          separator: "",
        }).format()
      : "0.00";
  }, [tableData]);

  const subTotal = useMemo(() => {
    const sum = tableData
      ? tableData
          .map((el) => el.SubTotal)
          .reduce(
            (a, b) =>
              currency(a, {
                symbol: "",
                precision: 2,
              }).add(b),
            0
          )
      : 0.0;
    return sum
      ? currency(sum, {
          symbol: "",
          separator: "",
        })
          .add(discount)
          .format()
      : "0.00";
  }, [tableData, discount]);

  const chargesAfterTax = useMemo(() => {
    return currency(formik.values.loadingCharge, {
      symbol: "",
      separator: "",
    })
      .add(formik.values.offloadingCharge)
      .add(formik.values.posCharge)
      .add(formik.values.transportCharge)
      .add(formik.values.shippingCost)
      .add(formik.values.otherCharges);
  }, [
    formik.values.offloadingCharge,
    formik.values.loadingCharge,
    formik.values.posCharge,
    formik.values.transportCharge,
    formik.values.shippingCost,
    formik.values.otherCharges,
  ]);

  const taxValue = useMemo(() => {
    const taxType = taxOptions.find((el) => el.value === formik.values.taxType);
    return currency(subTotal, {
      symbol: "",
      separator: "",
    })
      .multiply(taxType?.percentage)
      .format();
  }, [subTotal, formik.values.taxType]);

  const amountDue = useMemo(() => {
    const sum = tableData
      ? tableData
          .map((el) => el.SubTotal)
          .reduce(
            (a, b) =>
              currency(a, {
                precision: 2,
              }).add(b),
            0
          )
      : 0.0;

    const total = sum
      ? currency(sum, {
          symbol: "",
          separator: "",
        })
          .multiply(100)
          .divide(100)
          .add(taxValue)
          .add(chargesAfterTax)
          .format()
      : "0.00";

    const value = formik.values.PaymentType;
    if (value === "Credit" || value === "Customer Account") {
      formik.setFieldValue("amountPaid", 0);
    } else {
      formik.setFieldValue("amountPaid", total);
    }

    formik.setFieldValue("amountToPayFromCustomerCredit", total);
    formik.setFieldValue("cashAmount", total);
    return total;
  }, [tableData, taxValue, formik.values.PaymentType, chargesAfterTax]);

  const amount = useMemo(() => {
    return currency(amountDue, {
      symbol: "",
      separator: "",
    })
      .subtract(formik.values.cashAmount)
      .format();
  }, [amountDue, formik.values.cashAmount]);

  const balance = useMemo(() => {
    // is Balance Zero for split Payment
    const totalCashForSplit = currency(formik.values.cashAmount, {
      symbol: "",
      separator: "",
    })
      .add(amount)
      .format();

    return currency(amountDue, {
      symbol: "",
      separator: "",
    })
      .subtract(
        formik.values.PaymentType === "Split Payment"
          ? totalCashForSplit
          : formik.values.amountPaid
      )
      .format();
  }, [
    amount,
    amountDue,
    formik.values.amountPaid,
    formik.values.PaymentType,
    formik.values.cashAmount,
  ]);

  const profit = useMemo(() => {
    const sum = tableData
      ? tableData
          .map((el) => el.Profit)
          .reduce(
            (a, b) =>
              currency(a, {
                precision: 2,
              }).add(b),
            0
          )
      : 0.0;
    return sum
      ? currency(sum, {
          symbol: "",
          separator: "",
        }).format()
      : "0.00";
  }, [tableData]);

  const grandTotal = useMemo(() => {
    return amountDue;
  }, [amountDue]);

  const costOFSales = useMemo(() => {
    return tableData
      .map(
        (el) =>
          currency(el.UnitCost, { symbol: "", separator: "" }).multiply(
            convertQuantity(el.Quantity, el.Serial_Number, el.saleType)
          ).value
      )
      .reduce(
        (a, b) =>
          currency(a, {
            precision: 2,
          }).add(b),
        0
      );
  }, [tableData]);

  const handlePaymentMethod = (value) => {
    formik.setFieldValue("PaymentType", value);
    if (value === "Credit" || value === "Customer Account") {
      formik.setFieldValue("amountPaid", 0);
    }
  };

  const currencySymbol = useMemo(() => {
    const foundCurrency = currenciesOptions.find(
      (el) => el.cc === formik.values.currency
    );
    return foundCurrency ? foundCurrency.symbol : "";
  }, [formik.values.currency]);

  const paymentFilter = useMemo(() => {
    /*   return selectedCustomer?.Cust_ID && selectedCustomer?.Cust_ID !== "000101"
      ? { Cust_ID: selectedCustomer?.Cust_ID, Remark: "" }
      : { Remark: formik.values.ShipTo, Cust_ID: "" }; */
    return { Cust_ID: selectedCustomer?.Cust_ID, Remark: "" };
  }, [selectedCustomer?.Cust_ID /* , formik.values.ShipTo */]);

  const customerPaymentsFilter = useDebounce(paymentFilter, 800);

  const fetchPayments = async ({ Cust_ID, Remark }) => {
    let response = await fetch(
      `${backendUrl}/api/customers/get-payments?${queryString.stringify({
        Cust_ID,
        /*     Remark, */
      })}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    data.payments = [
      {
        value: "",
        label: "None",
      },
      ...data.payments.map((el) => {
        const requiresApproval = false;
        return {
          ...el,
          value: el.Trans_ID,
          label: `${el.Remark} -  ${currency(el.Credit, {
            symbol: currencySymbol,
          }).format()} - ${el?.Post_Time ? formatDate(el?.Post_Time) : ""} ${
            requiresApproval ? "- Requires Approval" : ""
          }`,
          requiresApproval,
        };
      }),
    ];

    return data;
  };

  const { data: paymentsData, ...paymentsDataQuery } = useQuery(
    ["CUSTOMER_PAYMENTS", customerPaymentsFilter],
    () => fetchPayments(customerPaymentsFilter),
    {
      keepPreviousData: false,
      enabled:
        generalSettings?.linkPaymentToInvoice &&
        !!customerPaymentsFilter.Cust_ID /*  || !!customerPaymentsFilter.Remark */,
    }
  );

  const handleLinkPayment = async (selected) => {
    const setShipTo = () => {
      //walk-In
      if (selectedCustomer?.Cust_ID === "000101") {
        formik.setFieldValue("ShipTo", selected.Remark);
      } else {
        formik.setFieldValue("ShipTo", customerFullName(selectedCustomer));
      }
      formik.setFieldValue("linkedPaymentID", selected?.value);
    };
    setShipTo();
  };

  return (
    <IsPrivileged roleName="Quotation/Proforma Invoice">
      <main className="create-invoice">
        <PageHeader
          name={`Create ${generalSettings.pendingInvoiceType} ${
            withoutApproval ? "without Approval" : ""
          }`}
          description={`Create a ${
            generalSettings.pendingInvoiceType
          } For Approval `}
          icon={<RecieptIcon />}
        />
        <div className="p-3 content">
          <div className="d-md-flex content-holder rounded">
            <section
              /*  style={
              formik.values.pendingTransaction
                ? {
                    pointerEvents: "none",
                  }
                : {}
            } */
              className="item-details"
            >
              <div>
                <header>
                  <h1>{generalSettings.pendingInvoiceType}</h1>
                </header>
                <div className="actions">
                  <div>
                    <h2>Item Details</h2>
                    <p>Add items in to the invoice table. </p>
                  </div>
                  <div className="d-flex gap-2">
                    <Form.Group>
                      <Select
                        classNamePrefix="form-select"
                        placeholder="Select Currency"
                        isSearchable={false}
                        options={currenciesOptions}
                        value={currenciesOptions.find(
                          (el) => el.value === formik.values.currency
                        )}
                        onChange={({ value }) => {
                          formik.setFieldValue("currency", value);
                        }}
                      />
                    </Form.Group>
                    {formik.values.currency &&
                    formik.values.currency !== "NGN" ? (
                      <Form.Group>
                        <CurrencyCustomInput
                          currencySymbol={"Ex. Rate"}
                          name="conversionAmount"
                          value={formik.values.conversionAmount}
                          onValueChange={(value, name) => {
                            formik.setFieldValue(name, value);
                          }}
                          placeholder="0.00"
                        />
                      </Form.Group>
                    ) : null}
                    <Button
                      onClick={() => setShowItemSelectorModal(true)}
                      variant="outline-primary"
                      className="text-nowrap"
                    >
                      + Add Item
                    </Button>
                  </div>
                </div>

                <div className="selected-data-area">
                  <div className="table-holder">
                    <Table
                      responsive
                      borderless
                      hover
                      striped
                      className="product-table  text-nowrap"
                    >
                      <thead>
                        <tr>
                          <th />
                          <th>Size/Desc</th>
                          <th>Item Name</th>
                          <th>Price Sold</th>
                          <th>Quantity</th>
                          <th>Subtotal</th>
                          <th>Discount</th>
                          <th>Item Code</th>
                          <th>Product name</th>
                          <th>Unit Price</th>
                          <th>Profit</th>
                          <th>Quantity in Stock</th>
                          <th>Warranty</th>
                          <th>Warranty Duration</th>
                          <th>Overwrite officer</th>
                          <th>Cost</th>
                          <th>Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((el, index) => (
                          <tr
                            key={index}
                            onClick={(e) => handleRowClick(e, index)}
                            className="p-cursor"
                          >
                            <td>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant=""
                                  className="bg-white border-0"
                                  bsPrefix="print more"
                                >
                                  <DotsVeritcalIcon />
                                </Dropdown.Toggle>
                                <Dropdown.Menu
                                  popperConfig={{
                                    strategy: "fixed",
                                  }}
                                  renderOnMount
                                  className="dropdown-with-icons"
                                >
                                  <Dropdown.Item
                                    as="button"
                                    onClick={() => setEditedItemIndex(index)}
                                  >
                                    <EditIcon />
                                    Edit Items
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    as="button"
                                    onClick={() => handleRemoveItem(index)}
                                  >
                                    <DeleteIcon />
                                    Remove Item
                                  </Dropdown.Item>
                                </Dropdown.Menu>{" "}
                              </Dropdown>
                            </td>
                            <td title={el.Serial_Number}>
                              {truncate(el.Serial_Number)}
                            </td>
                            <td>{el.Item_Name}</td>
                            <td>
                              {currency(el.PriceSold, { symbol: "" }).format()}
                            </td>
                            <td title={el.Quantity}>
                              <ConvertQuantity
                                quantity={el.Quantity}
                                desc={el?.Item_Desc}
                                convertNow={true}
                              />

                              {/* {qtyFormatToString(
                              qtyFormat(
                                el?.Quantity,
                                el?.Item_Desc,
                                itemMeasurements
                              )
                            )} */}
                            </td>
                            <td>
                              {currency(el.SubTotal, { symbol: "" }).format()}
                            </td>
                            <td>
                              {currency(el.Discount, { symbol: "" }).format()}
                            </td>
                            <td>{el.Bar_Code}</td>
                            <td>{el.Product_Name || el.ProductName}</td>
                            <td>
                              {currency(el.UnitPrice, { symbol: "" }).format()}
                            </td>
                            <td>
                              {currency(el.Profit, { symbol: "" }).format()}
                            </td>
                            <td title={el?.quantityInStock}>
                              <ConvertQuantity
                                quantity={el?.quantityInStock}
                                desc={el?.Item_Desc}
                                convertNow={true}
                              />
                            </td>
                            <td>{el.Warranty}</td>
                            <td>{el.Warrant_Duration}</td>
                            <td>{"..."}</td>
                            <td>
                              {currency(Number(el.UnitCost), { symbol: "" })
                                .multiply(
                                  convertQuantity(
                                    el.Quantity,
                                    el.Serial_Number,
                                    el.saleType
                                  )
                                )
                                .format()}
                            </td>
                            <td>{el.Item_Type || "..."}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  {/*  */}

                  {/* <Popover
                  isOpen={showItemsPopover}
                  reposition={true}
                  onClickOutside={() => setShowItemsPopover(false)}
                  content={() => (
                    <ItemsTable
                      hideItemsNotInStock={true}
                      handleSelectedItem={handleSelectedItem}
                    />
                  )}
                >
                  <button
                    onClick={() => setShowItemsPopover(!showItemsPopover)}
                    className="btn text-primary my-3 d-flex align-items-center gap-2"
                  >
                    <AddCircleIcon />
                    Add an Item
                  </button>
                </Popover> */}

                  {/*  No item  */}
                  {isEmpty(tableData) ? (
                    <div className="no-item my-4">
                      <div className="info">
                        <NoSelectedItemIcon />
                        <h2 className="mb-2">Haven't selected an item yet</h2>
                        <p>
                          You can click +Add Item Button to add an item to the
                          table.
                        </p>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="d-flex justify-content-end total-info">
                <table className="table table-borderless balance">
                  <tbody>
                    <tr>
                      <td>Subtotal</td>
                      <td>
                        {currency(subTotal, {
                          symbol: currencySymbol,
                        })
                          .add(chargesAfterTax)
                          .format()}
                      </td>
                    </tr>

                    <tr>
                      <td>Discount</td>
                      <td>
                        {/*  <button className="btn text-primary p-0">
                        +Add Discount
                      </button> */}

                        {currency(discount, {
                          symbol: currencySymbol,
                        }).format()}
                      </td>
                    </tr>

                    <tr>
                      <td>Amount Due</td>
                      <td>
                        {currency(amountDue, {
                          symbol: currencySymbol,
                        }).format()}
                      </td>
                    </tr>

                    <tr>
                      <td>Balance</td>
                      <td>
                        {currency(balance, {
                          symbol: currencySymbol,
                        }).format()}
                      </td>
                    </tr>

                    <tr>
                      <td>Total</td>
                      <td>
                        {currency(grandTotal, {
                          symbol: currencySymbol,
                        }).format()}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
            <section className="customer">
              <h2>Business Name</h2>

              <div className="d-flex justify-content-between">
                <div className="avatar">
                  <UserSolidIcon />
                </div>
                {selectedCustomer ? (
                  <div className="customer-actions d-flex justify-content-between flex-grow-1">
                    <div>
                      <h3>{selectedCustomer?.LastName}</h3>
                      <p>{selectedCustomer.Cust_ID}</p>
                    </div>

                    <div>
                      {!formik.values.pendingTransaction && (
                        <Dropdown style={{ margin: 0 }}>
                          <Dropdown.Toggle
                            variant=""
                            className="bg-light-blue text-primary"
                            bsPrefix="change"
                          >
                            Change
                          </Dropdown.Toggle>

                          <Dropdown.Menu
                            popperConfig={{
                              strategy: "fixed",
                            }}
                            renderOnMount
                            className=""
                          >
                            <Dropdown.Item
                              as="button"
                              onClick={() => setShowCustomerSelectorModal(true)}
                            >
                              Select Customer
                            </Dropdown.Item>
                            <Dropdown.Item
                              as="button"
                              onClick={() =>
                                setShowCreateNewCustomerModal(true)
                              }
                            >
                              Create New Customer
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="customer-actions flex-grow-1">
                    <h3>No customer selected</h3>
                    <p>Select customer or create new customer.</p>

                    <div className="d-grid mt-4">
                      <Button
                        onClick={() => setShowCustomerSelectorModal(true)}
                        variant="outline-primary"
                      >
                        Select Customer
                      </Button>
                      <Button
                        onClick={() => setShowCreateNewCustomerModal(true)}
                        variant="outline-primary"
                      >
                        + Create New Customer
                      </Button>
                    </div>
                  </div>
                )}
              </div>
              <FormikProvider value={formik}>
                <Form
                  noValidate
                  onSubmit={formik.handleSubmit}
                  autoComplete="off"
                >
                  <Form.Group className="form-mb align-items-center">
                    <Form.Label className="mb-1 text-nowrap fw-bold">
                      Ship To.
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Ship To"
                      name="ShipTo"
                      value={formik.values.ShipTo}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>

                  {generalSettings?.linkPaymentToInvoice && (
                    <Form.Group className="form-mb align-items-center">
                      <Form.Label className="mb-1 text-nowrap fw-bold">
                        Link Payment to Invoice
                      </Form.Label>
                      <Select
                        classNamePrefix={"form-select"}
                        options={paymentsData?.payments}
                        value={paymentsData?.payments.find(
                          (el) => el.value === formik.values.linkedPaymentID
                        )}
                        onChange={(selected) => {
                          handleLinkPayment(selected);
                        }}
                        isClearable={true}
                        isLoading={paymentsDataQuery.isFetching}
                      />
                    </Form.Group>
                  )}
                  <hr />
                  <section className="date">
                    <h2>Invoice Details</h2>

                    <Row className="form-mb">
                      <Form.Group as={Col}>
                        <Form.Label>Sales Date</Form.Label>
                        <Datetime
                          timeFormat={false}
                          closeOnSelect={true}
                          closeOnClickOutside={true}
                          dateFormat="MMM DD, YYYY"
                          name="salesDate"
                          inputProps={{
                            className: `date-input form-control ${
                              formik.touched.salesDate &&
                              !!formik.errors.salesDate
                                ? "is-invalid"
                                : ""
                            }`,
                            placeholder: "Select date",
                            readOnly: true,
                          }}
                          value={formik.values.salesDate}
                          onChange={(date) => {
                            formik.setFieldValue("salesDate", date, true);
                          }}
                          onBlur={() =>
                            formik.setFieldTouched("salesDate", true)
                          }
                        />
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>Invoice Cat </Form.Label>
                        <Select
                          classNamePrefix="form-select"
                          placeholder="Select"
                          isSearchable={false}
                          options={data.invoiceCat}
                          value={data.invoiceCat.find(
                            (el) => el.value === formik.values.invoiceCat
                          )}
                          onChange={({ value }) =>
                            formik.setFieldValue("invoiceCat", value)
                          }
                          onBlur={() =>
                            formik.setFieldTouched("invoiceCat", true)
                          }
                          className={
                            formik.touched.invoiceCat &&
                            !!formik.errors.invoiceCat
                              ? "is-invalid"
                              : ""
                          }
                        />
                        {formik.touched.invoiceCat &&
                        formik.errors.invoiceCat ? (
                          <span className="custom-invalid-feedback">
                            {formik.errors.invoiceCat}
                          </span>
                        ) : null}
                      </Form.Group>
                    </Row>
                  </section>

                  <section className="date">
                    <h2>Payment Details</h2>

                    <div className="row mb-3">
                      <Form.Group className="col-12">
                        <Form.Label>Payment Type</Form.Label>
                        <Select
                          classNamePrefix="form-select"
                          menuPlacement="top"
                          placeholder="Choose method"
                          isSearchable={false}
                          value={paymentMethod.find(
                            (el) => el.value === formik.values.PaymentType
                          )}
                          options={paymentMethod}
                          onChange={({ value }) => handlePaymentMethod(value)}
                          getOptionLabel={(el) => (
                            <div className="label-with-icon d-flex gap-2 align-items-center">
                              <span>{el.icon}</span>{" "}
                              <span className="fw-5">{el.label}</span>
                            </div>
                          )}
                          //  menuIsOpen={true}
                        />
                      </Form.Group>
                    </div>

                    <section>
                      <div className="row form-mb">
                        <Form.Group
                          className="col-6"
                          controlId="formGridPassword"
                        >
                          <Form.Label>Tax </Form.Label>
                          <Select
                            classNamePrefix="form-select"
                            placeholder="Select"
                            isSearchable={false}
                            value={taxOptions.find(
                              (el) => el.value === formik.values.taxType
                            )}
                            options={taxOptions}
                            onChange={({ value }) =>
                              formik.setFieldValue("taxType", value)
                            }
                          />
                        </Form.Group>

                        <Form.Group className="col-6">
                          <Form.Label>
                            {formik.values.taxType &&
                            formik.values.taxType !== "None"
                              ? formik.values.taxType
                              : "VAT"}
                          </Form.Label>
                          <CurrencyCustomInput
                            currencySymbol={currencySymbol}
                            name="tax"
                            value={taxValue}
                            onValueChange={(value, name) => {
                              //   formik.setFieldValue(name, value);
                            }}
                            placeholder="0.00"
                          />
                        </Form.Group>
                      </div>
                      {generalSettings?.poNumber && (
                        <Form.Group className="form-mb">
                          <Form.Label className="mb-1">Po No.</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Po No."
                            name="poNumber"
                            value={formik.values.poNumber}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      )}
                    </section>

                    <section>
                      <div className="row">
                        <Form.Group className="col-6 mt-2 form-mb">
                          <Form.Label>Shipping Cost</Form.Label>
                          <CurrencyCustomInput
                            currencySymbol={currencySymbol}
                            name="shippingCost"
                            value={formik.values.shippingCost}
                            onValueChange={(value, name) => {
                              formik.setFieldValue(name, value);
                            }}
                            placeholder="0.00"
                          />
                        </Form.Group>

                        <Form.Group className="col-6 mt-2 form-mb">
                          <Form.Label>Due In (days)</Form.Label>
                          <NumberCustomInput
                            name="dueIn"
                            value={formik.values.dueIn}
                            onValueChange={(value, name) => {
                              formik.setFieldValue(name, value);
                            }}
                          />
                        </Form.Group>
                      </div>
                    </section>

                    <section>
                      <Form.Group className=" mt-2 form-mb">
                        <Form.Label>
                          Clearing charges, Custom duty and Other Charges
                        </Form.Label>
                        <CurrencyCustomInput
                          currencySymbol={currencySymbol}
                          name="otherCharges"
                          value={formik.values.otherCharges}
                          onValueChange={(value, name) => {
                            formik.setFieldValue(name, value);
                          }}
                          placeholder="0.00"
                        />
                      </Form.Group>
                    </section>

                    <section>
                      <Form.Group className=" mt-2 form-mb">
                        <Form.Label>Terms</Form.Label>
                        <FieldArray
                          name="terms"
                          render={(arrayHelpers) => (
                            <>
                              {formik.values.terms.map((el, index) => (
                                <div key={index} className="d-flex gap-2  mb-3">
                                  <Form.Control
                                    name={`terms[${index}].text`}
                                    placeholder="Enter Terms"
                                    value={formik.values.terms[index].text}
                                    onChange={formik.handleChange}
                                    as={"textarea"}
                                  />
                                  <button
                                    type="button"
                                    title="Remove"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    ✖
                                  </button>
                                </div>
                              ))}
                              <div className="d-flex justify-content-end px-5 mt-3">
                                <button
                                  type="button"
                                  className="btn btn-sm btn-primary text-nowrap"
                                  onClick={() =>
                                    arrayHelpers.push({ text: "" })
                                  }
                                >
                                  + Add
                                </button>
                              </div>
                            </>
                          )}
                        />
                      </Form.Group>

                      <Form.Group className=" mt-2 form-mb">
                        <Form.Label>Remark</Form.Label>
                        <Form.Control
                          name={`remark`}
                          placeholder="Enter Remark"
                          value={formik.values.remark}
                          onChange={formik.handleChange}
                          as={"textarea"}
                        />
                      </Form.Group>
                    </section>
                  </section>

                  <section className="buttons">
                    <Button
                      type="button"
                      variant="outline-primary"
                      // className="border-0"
                      onClick={() => discard()}
                    >
                      Discard
                    </Button>
                    <Button type="submit" variant="primary">
                      Post {generalSettings?.pendingInvoiceType}
                    </Button>
                  </section>
                </Form>
              </FormikProvider>
            </section>
          </div>
        </div>

        {/*   Modals */}

        {showCustomerSelectorModal && (
          <CustomerSelectModal
            setShowCustomerSelectorModal={setShowCustomerSelectorModal}
            setSelectedCustomer={setSelectedCustomer}
            selectedCustomer={selectedCustomer}
            withCredit={true}
          />
        )}

        {showItemSelectorModal && (
          <AddItemModal
            showItemSelectorModal={showItemSelectorModal}
            setShowItemSelectorModal={setShowItemSelectorModal}
            handleAddItem={handleAddItem}
            saleTypes={saleTypes}
            lockedTableData={lockedTableData}
            selectedCustomer={selectedCustomer}
            conversionAmount={formik.values.conversionAmount}
            currencySymbol={currencySymbol}
            currencyText={formik.values.currency}
            previouslySelectedItems={tableData}
          />
        )}

        {showCreateNewCustomerModal && (
          <NewCustomerModal
            showCreateNewCustomerModal={showCreateNewCustomerModal}
            setShowCreateNewCustomerModal={setShowCreateNewCustomerModal}
            setSelectedCustomer={setSelectedCustomer}
          />
        )}

        {editedItemIndex !== null && (
          <EditIronRodItemModal
            setEditedItemIndex={setEditedItemIndex}
            handleEditItem={handleEditItem}
            selectedItemToEdit={{
              ...tableData[editedItemIndex],
              //  quantityInStock: tableData[editedItemIndex].Quantity,
            }}
            saleTypes={saleTypes}
            conversionAmount={formik.values.conversionAmount}
            currencySymbol={currencySymbol}
            currencyText={formik.values.currency}
          />
        )}

        {showPermitModal && (
          <PermitModal
            setShowPermitModal={setShowPermitModal}
            handleSelectedPermit={handleSelectedPermit}
            batchData={true}
          />
        )}

        <ModalLoader show={createRodPermitMutation.isLoading || loadingPrint} />
      </main>
    </IsPrivileged>
  );
}
