import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./../assets/scss/requisition/index.scss";

import { confirmable, createConfirmation } from "react-confirm";
import { ApproveModalIcon, BookIcon, RecieptIcon } from "./Icons";
import { Dropdown, DropdownButton, Table } from "react-bootstrap";
import { map, isEmpty } from "lodash";
import { isNumber } from "lodash";
import currency from "currency.js";
import { useBackendUrl } from "../utils/hooks";

function Dialog({
  proceed,
  show,
  title = "",
  description = "",
  TransactionID,
  thermalPrinter,
}) {
  const backendUrl = useBackendUrl();

  const openPreorderAndReciept = () => {
    window.open(
      `${backendUrl}/api/invoice/pdf/pre-order/${TransactionID}`,
      "_blank",
      "noopener,noreferrer"
    );

    window.open(
      `${backendUrl}/api/invoice/pdf/receipt/${TransactionID}`,
      "_blank",
      "noopener,noreferrer"
    );
  };
  return (
    <Modal
      show={show}
      onHide={() => proceed(false)}
      dialogClassName="requisition-details-modal"
      centered={true}
      animation={false}
      enforceFocus={false}
    >
      <Modal.Header closeButton />
      <Modal.Body className="approve-action">
        <div className="text-info-h">
          <ApproveModalIcon className="mb-4" />
          <h2>{title}</h2>
          <p
            className="fs-6"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
        <section className="row gap-4 px-3 mt-4">
          <a
            href={`${backendUrl}/api/invoice/pdf/pre-order/${TransactionID}`}
            className="btn btn-outline-primary p-3 col"
            target="blank"
          >
            <BookIcon /> Open Pre-order
          </a>
          <a
            href={`${backendUrl}/api/invoice/pdf/receipt/${TransactionID}`}
            className="btn btn-outline-primary p-3 col"
            target="blank"
          >
            <RecieptIcon /> Open Reciept
          </a>
        </section>
        <section className="row gap-4 px-3 mt-4">
          <Button
            className="btn btn-primary p-3 col-12"
            onClick={() => openPreorderAndReciept()}
          >
            Open Pre-order and Reciept
          </Button>
        </section>
      </Modal.Body>
    </Modal>
  );
}

export default function PreorderDialog(props) {
  return createConfirmation(confirmable(Dialog))(props);
}
