import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { ApproveBlueModalIcon } from "./Icons";
import eventBus from "../utils/EventBus";
import { toast } from "react-toastify";
import { useIsFetching, useMutation } from "react-query";
import { useAuth } from "../hooks/useAuth";

export default function AlreadyLoggedIn({
  title = "User already logged In",
  description = "End previous session",
}) {
  const isFetching = useIsFetching();
  const { backendUrl, user, setUser } = useAuth();
  const [show, setShow] = useState(false);

  const okay = () => {
    mutate();
    // setShow(false);
  };

  const setUserToken = async (values) => {
    let response = await fetch(`${backendUrl}/api/users/token/set-user-token`, {
      method: "POST",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      body: JSON.stringify({}),
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    return await response.json();
  };

  const { status, error, mutate, isLoading } = useMutation(
    ["SET_TOKEN_"],
    (values) => setUserToken(values),
    {
      enabled: false,
      onError: () => {
        toast.error("Unable to perform action:");
      },
      onSuccess: (data) => {
        toast.success(data?.message);
        setShow(false);
        // Rerender
        setUser({ ...user, timestamp: String(Date.now()) });
      },
    }
  );

  eventBus.useCustomEventListener("ALREADY_LOGGED_IN", (alreadyLoggedIn) => {
    if (alreadyLoggedIn) setShow(true);
  });

  const logoutUser = () => {
    setShow(false);
    eventBus.dispatch("LOGOUT", { clearToken: false });
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      dialogClassName="requisition-details-modal"
      centered={true}
      animation={false}
      enforceFocus={false}
      backdrop="static"
    >
      <Modal.Header />
      <Modal.Body className="approve-action">
        <div className="text-info-h">
          <div className="mb-4">
            <ApproveBlueModalIcon />
          </div>

          <h2>{title}</h2>
          <p
            className="fs-6"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <span />
        <div className="proceed-actions justify-content-between">
          <Button
            onClick={() => logoutUser()}
            variant="white"
            className="border bg-white"
          >
            No
          </Button>
          <Button
            disabled={isLoading || isFetching}
            onClick={() => okay()}
            variant="primary"
            autoFocus={true}
          >
            {isLoading ? "Please wait..." : "Yes"}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
