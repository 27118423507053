import Cashes from "../Reports/Cashes";

export default function Accounts() {
  return (
    <>
      {/*   <div className="dashboard-content">
        <div className="p-3 vh-100 bg-white rounded">
          <p> </p>
        </div>
      </div> */}

      <Cashes />
    </>
  );
}
