import Cookies from "universal-cookie";

import { HRBackEndUrl } from "../config";

export const loginHRSite = (logged) => {
  let domain = process.env.REACT_APP_FRONTEND_URL.includes("localhost")
    ? null
    : process.env.REACT_APP_INVEX_DOMAIN;

  // console.log("logged", logged);
  const {
    user: { email, password },
    backendApi,
  } = logged;
  fetch(HRBackEndUrl + "/users/signIn", {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ username: email, password: password, backendApi }),
  })
    .then((response) => {
      if (response) {
        response.json().then((responseJson) => {
          // console.log("responseJson", responseJson);
          if (responseJson.success && responseJson.user) {
            const expiryDate = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);
            const cookies = new Cookies();
            cookies.set("isLoggedIn", true, {
              path: "/",
              expires: expiryDate,
              ...(domain ? { domain } : {}),
            });
            cookies.set("userId", responseJson.user._id, {
              path: "/",
              expires: expiryDate,
              ...(domain ? { domain } : {}),
            });
            cookies.set("userId", responseJson.user._id, {
              path: "/",
              expires: expiryDate,
              ...(domain ? { domain } : {}),
            });
            cookies.set("token", responseJson.user.token, {
              path: "/",
              expires: expiryDate,
              ...(domain ? { domain } : {}),
            });
            cookies.set("isFirstTime", responseJson.user.isFirstTime, {
              path: "/",
              expires: expiryDate,
              ...(domain ? { domain } : {}),
            });
            cookies.set("branch_id", responseJson.branch, {
              path: "/",
              expires: expiryDate,
              ...(domain ? { domain } : {}),
            });
            cookies.set(
              "company_id",
              responseJson.user.company_id
                ? responseJson.user.company_id
                : process.env?.REACT_APP_HR_COMPANY,
              {
                path: "/",
                expires: expiryDate,
                ...(domain ? { domain } : {}),
              }
            );

            if (responseJson.user.isAdmin || responseJson.user.isSuperAdmin) {
              if (responseJson.user.isSuperAdmin) {
                cookies.set("isSuperAdmin", true, {
                  path: "/",
                  expires: expiryDate,
                  ...(domain ? { domain } : {}),
                });
              } else if (responseJson.user.isAdmin) {
                cookies.set("isAdmin", true, {
                  path: "/",
                  expires: expiryDate,
                  ...(domain ? { domain } : {}),
                });
              }
            }
          }
        });
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
