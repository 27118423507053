import { Dropdown, Table, Form, Button, InputGroup } from "react-bootstrap";
import DetailedIcon, {
  ExportIcon,
  FilterIcon,
  FilterTwoIcon,
  BusinessBillIcon,
  PrintIcon,
  SummaryChartIconUp,
  SummaryChartIconDown,
  SummaryCloseIcon,
  FilterCollapseIcon,
  RecieptIcon,
  PageHeaderListIcon,
  BookIcon,
  NoSelectedItemIcon,
  MoneyDollarIcon,
  EditIcon,
  DropdownEyeIcon,
} from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useRef, useState } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import Select from "react-select";
import useDebounce, {
  useIsAdmin,
  useLocalStorage,
  useQueryParams,
  useScrollTop,
  useSingleAndDoubleClick,
} from "../../utils/hooks";
import { services } from "../../config";
import { queryActions, reportActions } from "../../utils/reactQueryActions";
import { useMutation, useQuery } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  customerFullName,
  defaultSelectValue,
  paginationOptions,
  reactSelectTheme,
} from "../../utils/helpers";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import queryString from "query-string";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { useAuth } from "../../hooks/useAuth";
import { Link } from "react-router-dom";
import CustomerSelector from "../utils/CustomerSelector";
import { Popover } from "react-tiny-popover";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { lowerCase } from "lodash";
import { isEmpty } from "lodash";
import NoTableItem from "../utils/NoTableItem";
import ExpensesSelector from "../utils/ExpensesSelector";
import PageHeader from "../PageHeader";
import { useLocation, useNavigate } from "react-router-dom";
import { currenciesOptions } from "../../utils/currencies";
import ModalLoader from "../utils/ModalLoader";
import { toast } from "react-toastify";
import { IsPrivileged } from "../DisplayChildElement";

export default function JobIncomeStatement() {
  useScrollTop();
  const navigate = useNavigate();
  const initialFilterParams = {
    Description: "",
    AccountID: "",
    /*startDate: format(new Date(), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
    enableDateRange: true,*/
  };

  const { backendUrl } = useAuth();
  const [activeRow, setActiveRow] = useState(null);
  const [showExpensePopover, setShowExpensePopover] = useState(false);
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  const [btnSummryActive, setBtnSummaryActive] = useState(false);
  const [showMoreSummary, setShowMoreSummary] = useState(true);
  const isAdmin = useIsAdmin();

  const close = () => {
    setShowMoreSummary(true);
    setBtnSummaryActive(false);
  };

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 100,
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  const fetchJournalDetailsByAccount = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/journal/job-income-statement?&${queryString.stringify(
        queryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    return data;
  };

  const {
    data = { count: 0, journal: [] },
    refetch,
    isFetching,
    isSuccess,
  } = useQuery(
    [queryActions.JOURNAL_BY_ACCOUNT, queryParams],
    () => fetchJournalDetailsByAccount(queryParams),
    {
      keepPreviousData: true,
      enabled: Boolean(queryParams?.AccountID),
    }
  );

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
      enableDateRange: true,
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams(initialFilterParams);
    setQueryParams({
      ...queryParams,
      ...initialFilterParams,
    });
  };

  const handleSelectedExpense = ({ AccountID, Description }) => {
    setFilterParams({
      ...filterParams,
      AccountID,
      Description,
    });
    setShowExpensePopover(false);
  };

  const getWarehouse = async ({ warehouseId }) => {
    let response = await fetch(
      `${backendUrl}/api/warehouse/single/${warehouseId}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const getWarehouseMutation = useMutation((payload) => getWarehouse(payload), {
    onError: ({ message = "" }) => {
      toast.error(`Unable to get warehouse data, Try again.`);
    },
  });
  const openIssuedItem = (data) => {
    const warehouseId = data.Ref.split(" ")[0].trim();
    getWarehouseMutation.mutate(
      {
        warehouseId,
      },
      {
        onSuccess: ({ message, warehouses }) => {
          window.localStorage.setItem(
            "warehouseName",
            JSON.stringify({
              namewh: warehouses.W_name,
              cater: warehouses.W_Category,
              whID: warehouses.W_ID,
            })
          );
          navigate(`/warehouse-inventory/item-issuer?Trans_ID=${data.TransID}`);
        },
      }
    );
  };
  // isprivileged
  return (
    <IsPrivileged roleName="Job/Contract Report">
      <main className="inner-page-outlet">
        <main className="cash-book">
          <div className="content">
            <div className="content-main">
              <div className={`filter-area ${showFilter ? "open" : "close"}`}>
                <div className="filter-header">
                  <h2>
                    <FilterTwoIcon /> Filters
                  </h2>

                  <Button
                    variant="white"
                    onClick={() => setShowFilter(!showFilter)}
                  >
                    <FilterCollapseIcon />
                  </Button>
                </div>
                <div className="filter-body">
                  <Form>
                    <div className="body">
                      <Form.Group className="mb-2-5">
                        <Form.Label>Job/Contract Number</Form.Label>
                        <InputGroup>
                          <Form.Control
                            name="AccountID"
                            placeholder="Enter Job/Contract Number"
                            value={filterParams?.AccountID}
                            onChange={(e) => handleFilterParamsChange(e)}
                          />
                          <Popover
                            isOpen={showExpensePopover}
                            onClickOutside={() => setShowExpensePopover(false)}
                            content={() => (
                              <ExpensesSelector
                                handleSelectedExpense={handleSelectedExpense}
                                usage="chart of accounts"
                                DetailType="JOB"
                              />
                            )}
                            position="bottom"
                          >
                            <InputGroup.Text
                              onClick={() =>
                                setShowExpensePopover(!showExpensePopover)
                              }
                            >
                              <MagnifyIcon />
                            </InputGroup.Text>
                          </Popover>
                        </InputGroup>
                      </Form.Group>

                      <Form.Group className="mb-2-5">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                          name="Description"
                          value={filterParams?.Description}
                          placeholder="Enter Title"
                          onChange={(e) => handleFilterParamsChange(e)}
                        />
                      </Form.Group>

                      <hr className="mt-3 mb-4" />

                      <div className="mb-2-5">
                        <Form.Check
                          inline
                          label="Enable Date Range"
                          name="enableDateRange"
                          type={"checkbox"}
                          id={`enableDateRange`}
                          checked={filterParams?.enableDateRange}
                          onChange={(e) => handleFilterParamsChange(e)}
                        />
                      </div>

                      <Form.Group className="">
                        <Form.Label>Date Range</Form.Label>
                        <div className="position-relative">
                          <RsDateRangePicker
                            placement="topStart"
                            value={
                              filterParams.startDate && filterParams.endDate
                                ? [
                                    parse(
                                      filterParams.startDate,
                                      "yyyy-MM-dd",
                                      new Date()
                                    ),
                                    parse(
                                      filterParams.endDate,
                                      "yyyy-MM-dd",
                                      new Date()
                                    ),
                                  ]
                                : []
                            }
                            onClean={() => clearDateRange()}
                            onOk={(date) => filterByDateRange(date)}
                          />
                        </div>
                      </Form.Group>
                    </div>

                    <div className="buttons rounded">
                      <Button onClick={() => reset()} variant="white">
                        Reset
                      </Button>
                      <Button onClick={() => search()} variant="primary">
                        Search
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="content-body">
                <header>
                  <h1>
                    {!showFilter && (
                      <button
                        onClick={() => setShowFilter(!showFilter)}
                        className="btn filter"
                      >
                        <FilterTwoIcon />
                      </button>
                    )}
                    Job/Contract Report
                    <button
                      title="Refresh"
                      onClick={() => refetch()}
                      className="btn text-primary"
                    >
                      <CachedIcon />
                    </button>
                  </h1>
                  <h2 className="m-0">{queryParams?.Description}</h2>
                  <div className="actions">
                    {/* <button className="btn print">
              Filters <FilterIcon />
            </button> */}
                    {/*  <Link
                    to="/sales-and-invoicing/create-invoice"
                    className="btn bg print"
                  >
                    Make Invoice <BusinessBillIcon className="pageListIcon" />
                  </Link> */}
                    {/*  <button className="btn print">
                  Print <PrintIcon />
                </button>
                <button className="btn print">
                  Export <ExportIcon />
                </button>
 */}
                    {/*   <button className="btn print">
                  Open Receipt <BusinessBillIcon className="pageListIcon" />
                </button>

                <button className="btn print">
                  Open Invoice <BusinessBillIcon className="pageListIcon" />
                </button> */}

                    <button
                      onClick={() => setBtnSummaryActive(true)}
                      className="btn summaryBtn "
                    >
                      Show Summary
                    </button>
                  </div>
                </header>

                <div className="px-md-4">
                  <Table
                    responsive
                    borderless
                    striped
                    className="product-table"
                  >
                    <thead>
                      <tr>
                        <th />
                        <th>Trans Date</th>
                        <th>Account Type</th>
                        <th>Remark</th>
                        <th>Debit Amount</th>
                        <th>Credit Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.journal.map((el, index) => (
                        <tr key={index}>
                          <td>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant=""
                                className="bg-white border-0"
                                bsPrefix="print more"
                              >
                                <DotsVerticalIcon />
                              </Dropdown.Toggle>
                              <Dropdown.Menu
                                popperConfig={{
                                  strategy: "fixed",
                                }}
                                renderOnMount
                                className="dropdown-with-icons"
                              >
                                <>
                                  {isAdmin ? (
                                    <Dropdown.Item as="div">
                                      <Link
                                        to={`/journal-entry/edit/${el.TransID}`}
                                      >
                                        <EditIcon />
                                        Edit
                                      </Link>
                                    </Dropdown.Item>
                                  ) : null}
                                </>{" "}
                                {el.AccountID.includes("INCOME") ? (
                                  <Dropdown.Item
                                    as="button"
                                    onClick={() =>
                                      navigate(
                                        `/inventory-for-sales/manage-transaction`,
                                        {
                                          state: {
                                            TransactionID: el.TransID,
                                          },
                                        }
                                      )
                                    }
                                  >
                                    <DropdownEyeIcon className="text-light" />
                                    Open Transaction
                                  </Dropdown.Item>
                                ) : null}
                                {el.Ref.includes("ISSUE ITEM") ||
                                el.Ref.includes("RETURN ITEM") ? (
                                  <Dropdown.Item
                                    as="button"
                                    onClick={() => openIssuedItem(el)}
                                  >
                                    <DropdownEyeIcon className="text-light" />
                                    Open Issued Items
                                  </Dropdown.Item>
                                ) : el.AccountID.includes("EXPENSE") ? (
                                  <Dropdown.Item
                                    as="button"
                                    onClick={() =>
                                      navigate(
                                        `/reports/expenses?ExpID=${
                                          el?.ExpID
                                        }&startDate=&endDate=`
                                      )
                                    }
                                  >
                                    <DropdownEyeIcon className="text-light" />
                                    Open Expense
                                  </Dropdown.Item>
                                ) : null}
                              </Dropdown.Menu>{" "}
                            </Dropdown>
                          </td>
                          <td className="text-nowrap">
                            {el.DateLog
                              ? format(new Date(el.DateLog), "dd MMM yyyy")
                              : "..."}
                          </td>
                          <td>{el.AccountID}</td>
                          <td>{el.Ref}</td>
                          <td>
                            {currency(el.Debit, {
                              symbol: "",
                            }).format()}
                          </td>
                          <td>
                            {currency(el.Credit, {
                              symbol: "",
                            }).format()}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  {!isFetching && isSuccess && isEmpty(data?.journal) ? (
                    <NoTableItem queryParams={queryParams} />
                  ) : isEmpty(queryParams?.AccountID) ? (
                    <div className="d-flex flex-column align-items-center justify-content-center">
                      <NoTableItem queryParams={{}} />
                      <Button
                        onClick={() => {
                          setTimeout(() => {
                            setShowExpensePopover(true);
                          }, 20);
                        }}
                        className="text-center"
                        variant="outline-primary"
                      >
                        Select a Job
                      </Button>
                    </div>
                  ) : null}
                </div>

                <div className="d-flex justify-content-between px-3 align-items-center pagination">
                  {/*<p className="m-0">
                Showing {data?.startIndex + 1} to{" "}
                {data?.endIndex <= data?.count ? data?.endIndex : data?.count}{" "}
                of {data.count} entries
                  </p> */}
                  <div className="pagination_left">
                    <p className="m-0 p-0">Show</p>
                    <select
                      value={queryParams.limit}
                      name="limit"
                      className="form-select "
                      onChange={(e) => handleSearchQueryChange(e)}
                    >
                      <option value="10">10 rows</option>
                      <option value="20">20 rows</option>
                      <option value="30">30 rows</option>
                      <option value="40">40 rows</option>
                      <option value="50">50 rows</option>
                      <option value="100">100 rows</option>
                    </select>
                  </div>

                  <ReactPaginate
                    {...paginationOptions}
                    pageCount={Math.ceil(data.count / queryParams.limit)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={0}
                    onPageChange={({ selected }) => {
                      document.body.scrollTop = document.documentElement.scrollTop = 0;
                      setQueryParams({
                        ...queryParams,
                        page: selected + 1,
                      });
                    }}
                    forcePage={queryParams.page - 1}
                  />
                </div>

                {/* <div className="p-4 border-top">
          <h2>Summary</h2>
          <div className="summary">
            <table className="table table-borderless balance">
              <tbody>
                <tr>
                  <td>Total Sales</td>
                  <td>218,134,177.92</td>
                </tr>

                <tr>
                  <td>Total Profit</td>
                  <td>9,276,961.63</td>
                </tr>

                <tr>
                  <td>Total Losses</td>
                  <td>N/A</td>
                </tr>

                <tr>
                  <td>Qty Sold</td>
                  <td>19884</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> */}

                <div
                  className={
                    btnSummryActive
                      ? `summaryNew border-top open`
                      : `summaryNew border-top`
                  }
                >
                  <div className="summaryHeader">
                    <h2>Summary</h2>
                    {btnSummryActive && (
                      <button onClick={() => close()} className="btn p-0">
                        <SummaryCloseIcon />
                      </button>
                    )}
                  </div>

                  <div className="gridCont">
                    <div className="gridChild gridChildBorderLeftBlue">
                      <div className="gridChildLeft">
                        <p className="gridChld1">
                          {currency(data?.debit, {
                            symbol: "₦",
                          }).format()}
                        </p>
                        <p className="gridChld2">Total Debit</p>
                      </div>

                      <div className="gridChildRight">
                        <SummaryChartIconUp />
                        <p>5%</p>
                      </div>
                    </div>

                    <div className="gridChild gridChildBorderLeftOrange">
                      <div className="gridChildLeft">
                        <p className="gridChld1">
                          {currency(data?.credit, {
                            symbol: "₦",
                          }).format()}
                        </p>
                        <p className="gridChld2">Total Credit</p>
                      </div>

                      <div className="gridChildRight">
                        <SummaryChartIconUp />
                        <p>5%</p>
                      </div>
                    </div>

                    <div className="gridChild gridChildBorderLeftBrown">
                      <div className="gridChildLeft">
                        <p className="gridChld1">
                          {currency(data?.balance, {
                            symbol: "₦",
                          }).format()}
                        </p>
                        <p className="gridChld2">Balance (LCY)</p>
                      </div>

                      <div className="gridChildRight">
                        <SummaryChartIconDown />
                        <p>5%</p>
                      </div>
                    </div>

                    <div className="gridChild gridChildBorderLeftLightYellow">
                      <div className="gridChildLeft">
                        <p className="gridChld1">
                          {data?.jobBudget?.amount
                            ? currency(data?.jobBudget?.amount, {
                                symbol: currenciesOptions.find(
                                  (curr) =>
                                    curr.cc ===
                                    (data?.jobBudget?.currency
                                      ? data?.jobBudget?.currency
                                      : "NGN")
                                )?.symbol,
                              }).format()
                            : "..."}
                        </p>
                        <p className="gridChld2">Budget</p>
                      </div>

                      <div className="gridChildRight">
                        <SummaryChartIconDown />
                        <p>5%</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <ModalLoader show={getWarehouseMutation.isLoading} />
      </main>
    </IsPrivileged>
  );
}
