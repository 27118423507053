import { Table, Pagination } from "react-bootstrap";
import CachedIcon from "mdi-react/CachedIcon";
import { useQuery } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import { useEffect, useRef, useState } from "react";
import queryString from "query-string";
import { CSVLink } from "react-csv";
import { isEmpty } from "lodash";

import { ExcelIcon, PDFIcon } from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import useDebounce, { useQueryParams, useScrollTop } from "../../utils/hooks";
import { useAuth } from "../../hooks/useAuth";
import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import ModalLoader from "../utils/ModalLoader";
import NoTableItem from "../utils/NoTableItem";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import {
  calculateAccounts,
  displayAccountType,
  calculateAccountsTotal,
} from "../../utils/helpers";
import { useNavigate } from "react-router";
import { IsPrivileged } from "../DisplayChildElement";

function TrialBalance() {
  useScrollTop();
  const initialFilterParams = {
    startDate: format(new Date(), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
  };
  const navigate = useNavigate();
  const { backendUrl } = useAuth();
  const [excelData, setExcelData] = useState([]);

  const CSVLinkRef = useRef(null);

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  const [queryParams, setQueryParams] = useQueryParams({
    // page: 1,
    // limit: 40,
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({
      ...queryParams,
      ...debouncedFilterParams,
    });
  }, [debouncedFilterParams]);

  const fetchCustomerLedgers = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/reports/general-ledger?&${queryString.stringify(
        queryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    const types = [...new Set(data.journal.map((d) => d.Description))];

    // console.log(types);
    let sumall = {};

    types.forEach((el) => {
      sumall[el] = data.journal.filter((d) => d.Description === el);
      sumall[el]["Debit"] = sumall[el]
        .filter((d) => d.Description === el)
        .reduce((p, c) => p + c.Debit, 0);
      sumall[el]["Credit"] = sumall[el]
        .filter((d) => d.Description === el)
        .reduce((p, c) => p + c.Credit, 0);
      sumall[el]["key"] = [...new Set(sumall[el].map((d) => d.AccountID))][0];
      sumall[el]["totalAmount"] = calculateAccounts(
        sumall[el]["key"],
        sumall[el]["Credit"],
        sumall[el]["Debit"]
      );
    });

    // console.log(sumall);

    data.sumall = Object.entries(sumall);
    data.allCreditDebitTotal = calculateAccountsTotal(Object.entries(sumall));

    // console.log(data);

    return data;
  };

  const {
    data = {
      count: 0,
      sumall: [],
    },
    refetch,
    isFetching,
    isSuccess,
  } = useQuery(
    ["TrailBalance", queryParams],
    () => fetchCustomerLedgers(queryParams),
    {
      keepPreviousData: true,
    }
  );

  // download excel function
  // const { limit, page, ...rest } = queryParams;
  const onDownloadExcelData = async () => {
    let exData = await fetchExcelData(
      `${backendUrl}/api/reports/general-ledger?${queryString.stringify(
        queryParams
      )}`,
      "GET"
    );
    const { company, journal } = exData.data;

    const types = [...new Set(journal.map((d) => d.Description))];

    let sumall = {};

    types.forEach((el) => {
      sumall[el] = journal.filter((d) => d.Description === el);
      sumall[el]["Debit"] = sumall[el]
        .filter((d) => d.Description === el)
        .reduce((p, c) => p + c.Debit, 0);
      sumall[el]["Credit"] = sumall[el]
        .filter((d) => d.Description === el)
        .reduce((p, c) => p + c.Credit, 0);
      sumall[el]["key"] = [...new Set(sumall[el].map((d) => d.AccountID))][0];
      sumall[el]["totalAmount"] = calculateAccounts(
        sumall[el]["key"],
        sumall[el]["Credit"],
        sumall[el]["Debit"]
      );
    });

    sumall = Object.entries(sumall);

    exData = sumall.map((el) => [
      el[1][0]["AccountDescription.AccountID"],
      el[0],
      currency(
        displayAccountType(el[1]["key"], el[1]["totalAmount"], "debit"),
        {
          symbol: "",
        }
      ).format(),
      currency(
        displayAccountType(el[1]["key"], el[1]["totalAmount"], "credit"),
        {
          symbol: "",
        }
      ).format(),
    ]);

    const date =
      queryParams.startDate && queryParams.endDate
        ? `Date Prepared: Between ${format(
            new Date(queryParams.startDate),
            "E MMM d yyyy k:mm:ss z"
          )} to ${format(
            new Date(queryParams.endDate),
            "E MMM d yyyy k:mm:ss z"
          )}`
        : "";

    exData = [
      [company[0]?.CompName],
      ["Trial Balance Report"],
      [date],
      [""],
      ["AccountID", "Description", "Debit", "Credit"],
      ...exData,
      [
        "",
        "TOTAL",
        currency(data?.allCreditDebitTotal?.Debit, {
          symbol: "",
        }).format(),
        currency(data?.allCreditDebitTotal?.Credit, {
          symbol: "",
        }).format(),
      ],
    ];
    //  console.log(exData);
    setExcelData(exData);
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
      enableDateRange: true,
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };
  // isprivileged

  return (
    <IsPrivileged roleName="Financials">
      <main className="cash-book  table-sticky-header">
        <div className="content">
          <header className="container">
            <h1>
              Trial Balance
              <button
                title="Refresh"
                onClick={() => refetch()}
                className="btn text-primary"
              >
                <CachedIcon />
              </button>
            </h1>
            <div className="actions mr-5">
              <CSVLink
                className="btn print d-none"
                filename={`Trial Balance(${format(
                  new Date(),
                  "dd-MMM-yyyy hh:mm:ss a"
                )}).csv`}
                data={excelData}
                ref={CSVLinkRef}
              />

              <div className="position-relative">
                <RsDateRangePicker
                  placement="bottomStart"
                  value={
                    filterParams.startDate && filterParams.endDate
                      ? [
                          parse(
                            filterParams.startDate,
                            "yyyy-MM-dd",
                            new Date()
                          ),
                          parse(filterParams.endDate, "yyyy-MM-dd", new Date()),
                        ]
                      : []
                  }
                  onClean={() => clearDateRange()}
                  onOk={(date) => filterByDateRange(date)}
                />
              </div>
              <a
                target="_blank"
                rel="noreferrer"
                href={`${backendUrl}/api/reports/pdf/trial-balance?${queryString.stringify(
                  queryParams
                )}`}
                className="btn print mx-4"
              >
                PDF <PDFIcon color="#ff0000" />
              </a>
              <button className="btn print" onClick={onDownloadExcelData}>
                Excel <ExcelIcon color="#008000" />
              </button>
            </div>
          </header>

          <div>
            <Table
              // responsive
              borderless
              striped
              className="product-table container"
              hover
            >
              <thead className="position-sticky top-0 text-nowrap">
                <tr>
                  <th>AccountID</th>
                  <th>Description</th>
                  <th>Debit</th>
                  <th>Credit</th>
                </tr>
              </thead>
              <tbody>
                {data?.sumall?.map((el, index) => (
                  <tr
                    key={index}
                    className="p-cursor"
                    onClick={() =>
                      navigate(
                        `/account-setup/account-list?AccountID=${
                          el[1][0]["AccountDescription.AccountID"]
                        }&Description=${encodeURIComponent(el[0])}`
                      )
                    }
                  >
                    <td>{el[1][0]["AccountDescription.AccountID"]}</td>
                    <td>{el[0]}</td>
                    <td>
                      {currency(
                        displayAccountType(
                          el[1]["key"],
                          el[1]["totalAmount"],
                          "debit"
                        ),
                        {
                          symbol: "",
                        }
                      ).format()}
                    </td>
                    <td>
                      {currency(
                        displayAccountType(
                          el[1]["key"],
                          el[1]["totalAmount"],
                          "credit"
                        ),
                        {
                          symbol: "",
                        }
                      ).format()}
                    </td>
                  </tr>
                ))}
                {data?.sumall?.length > 0 && (
                  <tr>
                    <td />
                    <td className="fw-bold">TOTAL</td>
                    <td
                      className="fw-bold"
                      style={{
                        borderBottom: "4px double #333",
                        borderTop: "2px solId #333",
                      }}
                    >
                      {currency(data?.allCreditDebitTotal?.Debit, {
                        symbol: "",
                      }).format()}
                    </td>
                    <td
                      className="fw-bold"
                      style={{
                        borderBottom: "4px double #333",
                        borderTop: "2px solId #333",
                      }}
                    >
                      {currency(data?.allCreditDebitTotal?.Credit, {
                        symbol: "",
                      }).format()}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          {!isFetching && isSuccess && isEmpty(data?.sumall) ? (
            <NoTableItem queryParams={queryParams} />
          ) : null}

          <div className="pagination">
            {/* Left */}
            {/* <div className="pagination_left">
            <p className="m-0 p-0">Show</p>
            <select name="rows" className="form-select ">
              <option value="10">10 rows</option>
              <option value="20">20 rows</option>
              <option value="30">30 rows</option>
              <option value="40">40 rows</option>
            </select>
          </div> */}

            {/* right */}
            {/* <div className="pagintion_right">
            <Pagination>
              <Pagination.Item active>{1}</Pagination.Item>
              <Pagination.Item>{2}</Pagination.Item>

              <Pagination.Ellipsis className="border-none" />

              <Pagination.Item>{6}</Pagination.Item>
              <Pagination.Next className="next" />
            </Pagination>
          </div> */}
          </div>
        </div>
        <ModalLoader show={isfetchingExcel || isFetching} />
      </main>
    </IsPrivileged>
  );
}

export default TrialBalance;
