import { isEmpty } from "lodash";
import { Fragment, useState } from "react";
import { Button, Dropdown, Form, Table } from "react-bootstrap";
import Select from "react-select";
import { convertMomentDate, scrollToElement } from "../../utils/helpers";
import {
  AddCircleIcon,
  CreditMemoSelectIcon,
  CreditSelectIcon,
  DeleteIcon,
  DropdownCloseIcon,
  // MoneyDollarIcon,
  NoSelectedItemIcon,
} from "../Icons";
import Datetime from "react-datetime";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import { Popover } from "react-tiny-popover";
import { useFormik } from "formik";
import moment from "moment/moment";
import DatePickerCustomInput from "../utils/DatePickerCustomInput";
import useDebounce, { useEffectOnce } from "../../utils/hooks";
import currency from "currency.js";
import { useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/useAuth";
import ModalLoader from "../utils/ModalLoader";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import SelectBankDialog from "../SelectBankDialog";
// import { queryActions } from "../../utils/reactQueryActions";
import { last } from "lodash";
import ConfirmDialog from "../ConfirmDialogue";
import VendorSelectModal from "./VendorPostSelector";
import { IsPrivileged } from "../DisplayChildElement";

function Attachments({ files = [], setFiles }) {
  //  const [files, setFiles] = useState([]);
  const { backendUrl } = useAuth();
  return (
    <div>
      <div>
        {files?.map((el, index) => (
          <div key={index} className="d-flex align-items-center">
            {el?.fileId ? (
              <a
                href={`${backendUrl}/api/job/view-certificate/${
                  el.systemFilename
                }`}
                target="_blank"
                rel="noreferrer"
                className="text-primary"
              >
                {el?.name}
              </a>
            ) : (
              <span>{el?.name}</span>
            )}

            <Button
              variant=""
              size="sm"
              onClick={(e) => setFiles(files.filter((el, i) => i !== index))}
            >
              <DropdownCloseIcon width={16} />
            </Button>
            <hr className="m-0" />
          </div>
        ))}
      </div>

      <Form.Label className="btn btn-light-blue">
        <span>Add Files </span>
        <Form.Control
          type="file"
          className="d-none"
          multiple
          onChange={(e) => setFiles([...files, ...e.target.files])}
        />
      </Form.Label>
    </div>
  );
}

const paymentMethod = [
  {
    icon: <CreditSelectIcon />,
    label: "Debit Memo",
    value: "Debit Memo",
  },
  {
    icon: <CreditMemoSelectIcon />,
    label: "Credit Memo",
    value: "Credit Memo",
  },
];

function VendorRow({
  index,
  vendor,
  editTable,
  remove,
  vendors = [],
  addNewRow,
  backendUrl,
  getVendorBalanceAndCredit,
}) {
  const [datePopoverOpened, setDatePopoverOpened] = useState(false);
  const [duePaymentOptions, setDuePaymentOptions] = useState([]);
  const [invoiceCatOptions, setInvoiceCatOptions] = useState([]);

  const initialValues = {
    Post_Date: moment(),
    newInstallment: "",
    Remark: "",
    PaymentType: "Cash",
    duePaymentTransactionID: "",
    chequeNumber: "",
    BankName: "",
    Vendor_ID: "",
    InvoiceCat: "None",
    attachments: [],
    InvoiceNo: "",
  };
  const formik = useFormik({
    initialValues,
    // validationSchema: yup.object().shape({}),
  });

  const formValues = useDebounce(formik.values, 500);

  const setUp = async (Vendor_ID) => {
    const vendor = vendors.find((el) => el.Vendor_ID === Vendor_ID);

    // GET CUSTOMER BALANCE & CREDIT
    const duePayments = vendor?.duePayments ? vendor.duePayments : [];
    const invoiceCats = vendor?.invoiceCats ? vendor.invoiceCats : [];
    if (
      isEmpty(vendor?.balance) ||
      isEmpty(duePayments) ||
      isEmpty(invoiceCats)
    ) {
      getVendorBalanceAndCredit(Vendor_ID);
    }

    setDuePaymentOptions([
      {
        label: "None",
        value: "",
      },
      ...duePayments.map((el) => ({
        ...el,
        label: `${el.Trans_ID} ${currency(el.AmountDue, {
          symbol: "",
        }).format()} ${el?.InvoiceCat || ""}`,
        value: el.Trans_ID,
      })),
    ]);

    setInvoiceCatOptions([
      {
        label: "None",
        value: "None",
      },
      ...invoiceCats.map((el) => ({
        label: el.InvoiceCat,
        value: el.InvoiceCat,
      })),
    ]);

    // formik.setFieldValue("newInstallment", duePayments[0]?.AmountDue);
    formik.setFieldValue("Vendor_ID", Vendor_ID);
  };

  useEffect(() => {
    if (vendor?.duePayments) {
      setDuePaymentOptions([
        {
          label: "None",
          value: "",
        },
        ...vendor?.duePayments.map((el) => ({
          ...el,
          label: `${el.Trans_ID} ${currency(el.AmountDue, {
            symbol: "",
          }).format()} ${el?.InvoiceCat || ""}`,
          value: el.Trans_ID,
        })),
      ]);
    }
  }, [vendor?.duePayments]);

  useEffect(() => {
    if (vendor?.invoiceCats) {
      setInvoiceCatOptions([
        {
          label: "None",
          value: "None",
        },
        ...vendor?.invoiceCats.map((el) => ({
          label: el.InvoiceCat,
          value: el.InvoiceCat,
        })),
      ]);
    }
  }, [vendor?.invoiceCats]);

  useEffectOnce(() => {
    if (vendor?.Vendor_ID) setUp(vendor.Vendor_ID);
  });

  useEffect(() => {
    // formik values is set empty to undefined
    for (let k in initialValues) {
      if (!formValues.hasOwnProperty(k)) {
        const key = String(k);
        formValues[key] = "";
      }
    }
    editTable({
      index,
      formValues: {
        ...(formValues?.Vendor_ID
          ? vendors.find((el) => el.Vendor_ID === formValues.Vendor_ID)
          : {}),
        ...formValues,
      },
    });
  }, [formValues]);

  const showSelectBankDialog = async (props = {}) => {
    const bank = await SelectBankDialog({
      ...props,
      selectedBank: formik.values.BankName,
    });
    if (bank) {
      formik.setFieldValue("BankName", bank.bank);
      if (props.hasChequeNumber) {
        formik.setFieldValue("chequeNumber", bank.chequeNumber);
      }
    }
  };

  useEffect(() => {
    if (
      ["Credit/Debit Card", "Direct Bank Transfer"].includes(
        formik.values.PaymentType
      )
    ) {
      showSelectBankDialog();
    } else if (formik.values.PaymentType === "Cheque") {
      showSelectBankDialog({
        hasChequeNumber: true,
      });
    }
  }, [formik.values.PaymentType]);

  const handleVendorChange = (value) => {
    formik.setFieldValue("Vendor_ID", value);
    if (value) {
      console.log("value =>", value);
      setUp(value);
      addNewRow(index);
    }
  };

  return (
    <tr>
      <td>
        <Dropdown>
          <Dropdown.Toggle
            variant=""
            className="bg-white border-0"
            bsPrefix="print more"
            type="button"
          >
            <DotsVerticalIcon />
          </Dropdown.Toggle>
          <Dropdown.Menu
            popperConfig={{
              strategy: "fixed",
            }}
            renderOnMount
            className="dropdown-with-icons"
          >
            <Dropdown.Item
              as="button"
              type="button"
              onClick={() => remove(index)}
            >
              <DeleteIcon />
              Remove Vendor
            </Dropdown.Item>
          </Dropdown.Menu>{" "}
        </Dropdown>
      </td>
      {/*   <td>{vendor.Vendor_ID}</td> */}
      {/*  <td>
        <Select
          classNamePrefix="form-select"
          menuPlacement="bottom"
          menuPosition="fixed"
          placeholder="Choose method"
          isSearchable={false}
          value={paymentMethod.find(
            (el) => el.value === formik.values.PaymentType
          )}
          options={paymentMethod}
          onChange={({ value }) => formik.setFieldValue("PaymentType", value)}
          getOptionLabel={(el) => (
            <div className="label-with-icon d-flex gap-2 align-items-center">
              <span>{el.icon}</span> <span className="fw-5">{el.label}</span>
            </div>
          )}
        />
      </td> */}
      <td>
        <Select
          classNamePrefix={"form-select"}
          className="vendor-select-dropdown"
          data={vendors}
          menuPlacement="bottom"
          menuPosition="fixed"
          isSearchable={true}
          isClearable={true}
          value={vendors.find((el) => el.value === formik.values.Vendor_ID)}
          options={vendors}
          onChange={(selection) =>
            handleVendorChange(selection?.value ? selection?.value : "")
          }
          id={`newInstallment-vendor-${index}`}
        />{" "}
      </td>

      <td>
        <Form.Control
          placeholder="Enter Invoice No"
          name="InvoiceNo"
          value={formik.values.InvoiceNo}
          onChange={formik.handleChange}
          style={{ width: "15rem" }}
        />
      </td>
      <td>
        <Form.Control
          placeholder="Enter Remark"
          name="Remark"
          value={formik.values.Remark}
          onChange={formik.handleChange}
          style={{ width: "15rem" }}
        />
      </td>
      <td>
        <CurrencyCustomInput
          id={`newInstallment-${vendor.Vendor_ID}`}
          name="newInstallment"
          placeholder="0.00"
          value={formik.values.newInstallment}
          onValueChange={(value, name) => {
            formik.setFieldValue(name, value);
          }}
          // disabled={formik.values.duePaymentTransactionID}
        />
      </td>

      <td>
        <Attachments
          files={formik.values.attachments}
          setFiles={(files) => formik.setFieldValue("attachments", files)}
        />
      </td>

      <td>
        {currency(vendor.balance, {
          symbol: "",
        }).format()}
      </td>

      <td>
        <Popover
          reposition={false}
          isOpen={datePopoverOpened}
          onClickOutside={() => setDatePopoverOpened(false)}
          align="end"
          padding={10}
          content={() => (
            <Datetime
              dateFormat="MM DD, YYYY"
              className="date-picker-2"
              timeFormat={false}
              closeOnSelect={true}
              closeOnClickOutside={true}
              name="Post_Date"
              inputProps={{
                className: `date-input form-control ${
                  formik.touched.Post_Date && !!formik.errors.Post_Date
                    ? "is-invalid"
                    : ""
                }`,
                placeholder: "Select date",
                readOnly: true,
              }}
              input={false}
              value={formik.values.Post_Date}
              onChange={(date) => {
                formik.setFieldValue("Post_Date", date, true);
                setDatePopoverOpened(false);
              }}
              onBlur={() => formik.setFieldTouched("Post_Date", true)}
            />
          )}
        >
          <DatePickerCustomInput
            onClick={() => setDatePopoverOpened(!datePopoverOpened)}
            value={convertMomentDate(formik.values.Post_Date)}
          />
        </Popover>
      </td>
      <td>{vendor.TransactionID}</td>
      {/*  <td>
        <Select
          classNamePrefix={"form-select"}
          menuPlacement="bottom"
          menuPosition="fixed"
          isSearchable={false}
          options={invoiceCatOptions}
          key={invoiceCatOptions}
          onChange={({ value }) => formik.setFieldValue("InvoiceCat", value)}
        />
      </td> */}
    </tr>
  );
}

export default function CreateVendorInvoice() {
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const { backendUrl, token } = useAuth();
  const [showVendorSelectorModal, setShowVendorSelectorModal] = useState(false);

  const [tableData, setTableData] = useState([
    {
      Vendor_ID: "",
      TransactionID: `STN${Date.now()}1`,
      attachments: [],
    },
  ]);
  const setSelectedVendor = (vendor) => {
    const lastitem = last(tableData);
    vendor.TransactionID = `STN${Date.now()}`;
    console.log("vendor => ", vendor);
    if (lastitem.Vendor_ID === "") {
      setTableData([
        ...tableData.filter((el, index) => index !== tableData.length - 1),
        vendor,
        { Vendor_ID: "", TransactionID: `STN${Date.now()}2`, attachments: [] },
      ]);
    } else {
      setTableData([
        ...tableData,
        vendor,
        { Vendor_ID: "", TransactionID: `STN${Date.now()}1`, attachments: [] },
      ]);
    }
  };

  const editTable = ({ index, formValues }) => {
    tableData[index] = {
      ...tableData[index],
      ...formValues,
    };
    setTableData([...tableData]);
  };

  const addNewRow = (index) => {
    if (index === tableData.length - 1) {
      setTableData([
        ...tableData,
        { Vendor_ID: "", TransactionID: `STN${Date.now()}1` },
      ]);
    }
  };

  const remove = (index) => {
    const newTableData = tableData.filter((el, i) => index !== i);
    setTableData([...newTableData]);
  };

  const updateVendorsLedger = async (payload) => {
    const formData = new FormData();
    formData.append("payload", JSON.stringify(payload));

    // pick up files
    for (let vendor of payload.vendors) {
      if (!isEmpty(vendor.attachments)) {
        for (let file of vendor.attachments) {
          formData.append(`vendorInvoiceFile_${vendor.TransactionID}`, file);
        }
      }
    }

    let response = await fetch(`${backendUrl}/api/vendors/post-invoice`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
      body: formData,
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const createVendorInvoiceMutation = useMutation(
    (payload) => updateVendorsLedger(payload),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
        setTableData([{ Vendor_ID: "", TransactionID: `STN${Date.now()}1` }]);
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  const post = async () => {
    if (
      !(await ConfirmDialog({
        title: "Post Transaction",
        description: "Are you sure, you want to make this transaction",
      }))
    ) {
      return;
    }
    const table =
      tableData.length > 1
        ? tableData.filter((el, index) => index !== tableData.length - 1)
        : tableData;

    const foundEmptyVendorIndex = table.findIndex((el) => el.Vendor_ID === "");
    if (foundEmptyVendorIndex !== -1) {
      // console.log(foundEmptyVendorIndex);
      scrollToElement(`#newInstallment-vendor-${foundEmptyVendorIndex}`);
      return toast.error(
        `Select a Vendor in row ${foundEmptyVendorIndex + 1} or delete the row`
      );
    }

    const foundEmptyInstallment = table.find(
      (el) => Number(el.newInstallment) <= 0
    );

    const foundIndexInstallment = table.findIndex(
      (el) => Number(el.newInstallment) <= 0
    );

    if (foundEmptyInstallment) {
      scrollToElement(`#newInstallment-${foundEmptyInstallment.Vendor_ID}`);
      return toast.error(
        `Empty or Invalid amount in row ${foundIndexInstallment + 1}`
      );
    }

    // Ensure only valid data are sent
    createVendorInvoiceMutation.mutate({
      vendors: tableData.filter((el) => el.Vendor_ID && el.newInstallment),
    });

    console.log({
      vendors: tableData.filter((el) => el.Vendor_ID && el.newInstallment),
    });
  };

  const getVendor = async () => {
    let response = await fetch(
      `${backendUrl}/api/vendors?withCredit=${true}&withPaymentDue=${true}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    data.vendors = data.vendors.map((el) => ({
      ...el,
      label: el.CompanyName,
      value: el.Vendor_ID,
    }));
    return data;
  };

  const { data = { vendors: [] } } = useQuery(
    ["GET ALL VENDORS"],
    () => getVendor(),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  const getVendorBalanceAndCredit = async (Vendor_ID) => {
    // return new Promise(async (resolve, reject) => {
    try {
      setIsLoading(true);
      let response = await fetch(
        `${backendUrl}/api/vendors/balance-and-credit/${Vendor_ID}`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
            "Content-Type": "Application/json",
          },
          credentials: "include",
        }
      );

      if (!response.ok) {
        response = await response.json();
        throw new Error();
      } else {
        const {
          data: { balance, duePayments, invoiceCats },
        } = await response.json();
        // resolve({ balance, duePayments });
        queryClient.setQueryData(["GET ALL VENDORS"], (data) => {
          data.vendors = data.vendors.map((el) =>
            el.Vendor_ID === Vendor_ID
              ? { ...el, balance, duePayments, invoiceCats }
              : { ...el }
          );
          return data;
        });

        setTableData((tableData) =>
          tableData.map((el) =>
            el.Vendor_ID === Vendor_ID
              ? { ...el, balance, duePayments, invoiceCats }
              : { ...el }
          )
        );
      }
    } catch (err) {
      console.log(err);

      // reject();
    } finally {
      setIsLoading(false);
    }
    //  });
  };

  return (
    <IsPrivileged roleName="Create Invoice">
      <main className="create-invoice">
        <div className="p-3 content">
          <div className="d-flex content-holder rounded">
            <section className="item-details customers">
              <div>
                <header>
                  <h1>Post Invoice</h1>
                </header>

                <div className="selected-data-area mt-3">
                  {/*  */}
                  <div className="table-holder">
                    {!isEmpty(tableData) ? (
                      <Table
                        responsive
                        borderless
                        hover
                        striped
                        className="product-table text-nowrap post-payment"
                      >
                        <thead>
                          <tr>
                            <th />
                            {/* <th>Vendor ID</th> */}
                            {/*  <th>Memo Type</th> */}
                            <th>Vendor</th>
                            <th>Invoice No</th>
                            <th>Remark</th>

                            <th>Amount</th>
                            <th>Attach Invoice</th>
                            <th>Balance on Ledger</th>

                            <th>Transaction Date</th>
                            <th>Transaction ID</th>
                            {/*   <th>Invoice Type</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {tableData.map((el, index) => (
                            <Fragment key={el.TransactionID}>
                              <VendorRow
                                vendors={data?.vendors}
                                index={index}
                                vendor={el}
                                editTable={editTable}
                                remove={remove}
                                addNewRow={addNewRow}
                                backendUrl={backendUrl}
                                getVendorBalanceAndCredit={
                                  getVendorBalanceAndCredit
                                }
                              />
                            </Fragment>
                          ))}
                        </tbody>
                      </Table>
                    ) : null}
                  </div>

                  <button
                    onClick={() => setShowVendorSelectorModal(true)}
                    type="button"
                    className="btn text-primary my-3 d-flex align-items-center gap-2"
                  >
                    <AddCircleIcon />
                    Add Vendor
                  </button>

                  {/*  No item  */}
                  {isEmpty(tableData) ? (
                    <div className="no-item my-5">
                      <div className="info">
                        <NoSelectedItemIcon />
                        <h2 className="mb-2">Haven't selected a Vendor yet</h2>
                        <p>
                          You can click +Add Vendor Button to add a Vendor to
                          the table.
                        </p>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              {!isEmpty(tableData) && (
                <div className="d-flex justify-content-between total-info">
                  <div />
                  <div>
                    <Button
                      onClick={() => post()}
                      disabled={createVendorInvoiceMutation.isLoading}
                      type="button"
                      className="py-3 px-5"
                    >
                      Post
                    </Button>
                  </div>
                </div>
              )}
            </section>
          </div>
        </div>

        {/*   Modals */}
        {showVendorSelectorModal && (
          <VendorSelectModal
            setShowVendorSelectorModal={setShowVendorSelectorModal}
            setSelectedVendor={setSelectedVendor}
            withCredit={true}
            withPaymentDue={true}
            isMulti={false}
            alreadySelectedVendors={tableData}
            withInvoiceCat={true}
          />
        )}

        <ModalLoader show={createVendorInvoiceMutation.isLoading} />

        <ModalLoader
          show={isLoading}
          title="Please wait, getting vendor balance and due payments..."
        />
      </main>
    </IsPrivileged>
  );
}
