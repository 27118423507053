import { useFormik } from "formik";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Button from "react-bootstrap/Button";
import { Modal, Form, Nav, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import "../assets/scss/RolesPriviledgesModal.scss";
import * as yup from "yup";
import EyeOffOutline from "mdi-react/EyeOffOutlineIcon";
import EyeOutline from "mdi-react/EyeOutlineIcon";

import useDebounce, {
  useBackendUrl,
  useEffectOnce,
  useIsAdmin,
} from "../utils/hooks";
import { useFetchUsers } from "../hooks/useFetchUsers";
import Avatar from "./utils/Avatar";
import { useAuth } from "../hooks/useAuth";
import { useStoreActions, useStoreState } from "easy-peasy";
import { GearIcon } from "./Icons";
import { useMutation, useQuery } from "react-query";
import { initialGeneralSettings } from "../utils/helpers";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import CurrencyCustomInput from "./utils/CurrencyCustomInput";
import ExpensesSelector from "./utils/ExpensesSelector";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { Popover } from "react-tiny-popover";
import eventBus from "../utils/EventBus";
import roles from "./roles.json";
import Cookies from "universal-cookie";
import { cloneDeep } from "lodash";

export default function RolesPriviledgesModal({
  showRolesPriviledgesModal,
  setShowRolesPriviledgesModal,
}) {
  const { backendUrl, isCement } = useAuth();
  const isAdmin = useIsAdmin();
  //const generalSettings = useStoreState((state) => state.generalSettings);
  const setGeneralSettings = useStoreActions(
    (actions) => actions.setGeneralSettings
  );

  const [showJournalPopover, setShowJournalPopover] = useState(false);
  const [priveledges, setPriveledges] = useState([]);
  const [userId, setUserId] = useState("");

  const userRoles = useMemo(() => {
    return cloneDeep(roles.roles);
  }, [roles.roles]);

  const isChecked = (item) => {
    console.log(item);

    const found = priveledges.find((el) => el.name === item.name);
    console.log("found");

    console.log(found);

    if (!found) {
      return false;
    }

    return !found.checked;
  };

  const handleChange = (checked, role) => {
    // const found = priveledges.find(el => el.name === item.name)
    // console.log(priveledges)
    // console.log(role.priviledges)

    if (role.priviledges !== undefined) {
      role.priviledges.map((priviledge) => {
        setPriveledges((priveledges) => [
          ...priveledges.filter((el) => el.name !== priviledge.name),
          {
            name: priviledge.name,
            checked: checked,
            parent: role.name,
          },
        ]);

        // console.log('Priviledges:')
        // console.log(priviledge)
        // console.log(priviledge.actions)

        if (priviledge.actions !== undefined) {
          priviledge.actions.map((action) => {
            setPriveledges((priveledges) => [
              ...priveledges.filter((el) => el.name !== action.name),
              {
                name: action.name,
                checked: checked,
                parent: role.name,
              },
            ]);
          });
        }
      });
    }

    if (role.actions !== undefined) {
      role.actions.map((action) => {
        setPriveledges((priveledges) => [
          ...priveledges.filter((el) => el.name !== action.name),
          {
            name: action.name,
            checked: checked,
            parent: role.name,
          },
        ]);
      });
    }

    setPriveledges((priveledges) => [
      ...priveledges.filter((el) => el.name !== role.name),
      {
        name: role.name,
        checked: checked,
        parent: "",
      },
    ]);

    // console.log(priveledges)
  };

  const addSettings = async (payload) => {
    const cookies = new Cookies();
    const token = cookies.get("x-access-token");
    let response = await fetch(
      `${backendUrl}/api/users/save-roles-priviledges`,
      {
        method: "POST",
        // credentials: "include",
        body: JSON.stringify(priveledges),
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const addSettingsMutation = useMutation((payload) => addSettings(payload), {
    onError: () => {
      toast.error(`Unable to perform action`);
    },
  });

  const formik = useFormik({
    initialValues: {
      priveledges,
      Staff_ID: "",
    },
    onSubmit: (values) => {
      //  Save  to  server
      addSettingsMutation.mutate(
        { ...values },
        {
          onSuccess: ({ message, data }) => {
            // setGeneralSettings(values);
            toast.success("Roles & Priviledges saved");
            setShowRolesPriviledgesModal(false);
          },
        }
      );
    },
  });

  const setUp = async () => {
    // formik.setFieldValue("userld", '');
  };

  const { data, isFetching } = useQuery(
    ["GET_GLOBAL_SETTINGS"],
    () => setUp(),
    {
      enabled: true,
    }
  );
  const getUsersInSystem = async () => {
    let response = await fetch(`${backendUrl}/api/users`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const data = await response.json();
    data.allUsersInSystem = [
      {
        label: "Select User",
        value: "",
      },
      ...data.staff.map((el) => ({
        label: el.Name,
        value: el.Staff_ID,
      })),
    ];
    console.log(data);
    return data;
  };
  const {
    data: { allUsersInSystem } = { allUsersInSystem: [] },
    isFetching: isFetchingUsers,
  } = useQuery(["GET_USERS_IN_SYSTEM"], () => getUsersInSystem(), {});

  const animatedComponents = makeAnimated();
  const users = useFetchUsers();

  const handleSelectChange = (selected) => {
    console.log(selected.value);
    formik.setFieldValue("Staff_ID", selected.value);
  };

  return (
    <>
      <Modal
        size="xl"
        show={showRolesPriviledgesModal}
        onHide={() => setShowRolesPriviledgesModal(false)}
        enforceFocus={false}
        className={"roles-priviledges"}
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5">
            <GearIcon /> Roles & Priviledge Settings
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            onSubmit={formik.handleSubmit}
            autoComplete="off"
            style={
              isFetching || isFetchingUsers
                ? { opacity: "0.5", pointerEvents: "none" }
                : {}
            }
          >
            <h2 className="mb-3 mt-2 h5">Users</h2>
            <Form.Group className={"col-sm-12 col-md-4"}>
              <Select
                isMulti
                components={animatedComponents}
                options={users}
                name="Staff_ID"
                value={users.find((el) => el.value === formik.values.Staff_ID)}
                onChange={handleSelectChange}
              />
            </Form.Group>

            {/* <div className={'search-role'}>
              <input type={'text'} placeholder="Type your search term" className="form-control" />
            </div> */}

            {isAdmin ? (
              <div className="roles border-top my-4 pt-4">
                {userRoles.map((role, index) => (
                  <div className={"role-item"}>
                    <h6 key={index}>
                      <Form.Check
                        inline
                        type={"checkbox"}
                        checked={isChecked(role)}
                        onChange={(e) => handleChange(isChecked(role), role)}
                      />
                      &nbsp;
                      {role.name}
                    </h6>
                    <div className="priviledges">
                      {role.priviledges.map((priviledge, p) => (
                        <>
                          <p>
                            <Form.Check
                              inline
                              type={"checkbox"}
                              checked={isChecked(priviledge)}
                              onChange={(e) =>
                                handleChange(isChecked(priviledge), priviledge)
                              }
                            />
                            &nbsp;{priviledge.name}
                          </p>
                          <ul className="actions">
                            {priviledge.actions?.map((action, a) => (
                              <li key={a}>
                                <Form.Check
                                  inline
                                  type={"checkbox"}
                                  checked={isChecked(action)}
                                  onChange={(e) =>
                                    handleChange(isChecked(action), action)
                                  }
                                />
                                &nbsp;{action.name}
                              </li>
                            ))}
                          </ul>
                        </>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => formik.submitForm()}
            variant="primary"
            disabled={addSettingsMutation.isLoading}
            className="px-4"
          >
            {addSettingsMutation.isLoading ? "Please wait..." : "Save"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export function RootRolesPriviledgesModal({
  showRolesPriviledgesModal,
  setShowRolesPriviledgesModal,
}) {
  const { backendUrl, isCement } = useAuth();
  const isAdmin = useIsAdmin();
  // const generalSettings = useStoreState((state) => state.generalSettings);
  const setGeneralSettings = useStoreActions(
    (actions) => actions.setGeneralSettings
  );
  const rootRoles = useStoreState((state) => state.rootRoles);

  const [showJournalPopover, setShowJournalPopover] = useState(false);
  const [priveledges, setPriveledges] = useState(rootRoles || []);
  const [userId, setUserId] = useState("");
  const [userRoles] = useState(roles.roles);
  const setRootRoles = useStoreActions((actions) => actions.setRootRoles);

  const [displayPrivilege, setDisplayPrivilege] = useState({
    password: "yj&41",
    confim: "",
    show: false,
  });

  /* const isChecked = (item) => {
    // console.log(item.name);
    const found = priveledges.find((el) => el.name === item.name);

    if (!found) {
      return false;
    }

    console.log("found", found.name, found.checked);
    return !Boolean(found.checked);
  }; */

  const handleChange = (checked, role) => {
    let roles = [...priveledges];
    checked = !checked;

    if (role.priviledges !== undefined) {
      role.priviledges.map((priviledge) => {
        roles = [
          ...roles.filter(
            (el) =>
              !Boolean(
                el.name == priviledge.name && el.parent == priviledge.parent
              )
          ),
          {
            name: priviledge.name,
            checked: checked,
            parent: role.name,
          },
        ];

        if (priviledge.actions !== undefined) {
          priviledge.actions.map((action) => {
            roles = [
              ...roles.filter(
                (el) =>
                  !Boolean(
                    el.name === action.name && el.parent == action.parent
                  )
              ),
              {
                name: action.name,
                checked: checked,
                parent: priviledge.name,
              },
            ];

            if (action.subActions !== undefined) {
              action.subActions.map((subAction) => {
                roles = [
                  ...roles.filter(
                    (el) =>
                      !Boolean(
                        el.name === subAction.name &&
                          el.parent == subAction.parent
                      )
                  ),
                  {
                    name: subAction.name,
                    checked: checked,
                    parent: action.name,
                  },
                ];
              });
            }
          });
        }
      });
    }

    if (role.actions !== undefined) {
      role.actions.map((action) => {
        roles = [
          ...roles.filter(
            (el) =>
              !Boolean(el.name === action.name && el.parent == action.parent)
          ),
          {
            name: action.name,
            checked: checked,
            parent: role.name,
          },
        ];

        if (action.subActions !== undefined) {
          action.subActions.map((subAction) => {
            roles = [
              ...roles.filter(
                (el) =>
                  !Boolean(
                    el.name === subAction.name && el.parent == subAction.parent
                  )
              ),
              {
                name: subAction.name,
                checked: checked,
                parent: action.name,
              },
            ];
          });
        }
        // sub
      });
    }

    if (role.subActions !== undefined) {
      role.subActions.map((subAction) => {
        roles = [
          ...roles.filter(
            (el) =>
              !Boolean(
                el.name === subAction.name && el.parent == subAction.parent
              )
          ),
          {
            name: subAction.name,
            checked: checked,
            parent: role.name,
          },
        ];
      });
    }

    roles = [
      ...roles.filter(
        (el) => !Boolean(el.name == role.name && el.parent == role.parent)
      ),
      {
        name: role.name,
        checked: checked,
        parent: role.parent,
      },
    ];

    setPriveledges(roles);
  };

  const addSettings = async (payload) => {
    const cookies = new Cookies();
    const token = cookies.get("x-access-token");
    let response = await fetch(
      `${backendUrl}/api/users/save-roles-priviledges`,
      {
        method: "POST",
        // credentials: "include",
        body: JSON.stringify({
          roles: payload.priveledges,
          Staff_ID: "root",
        }),
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const addSettingsMutation = useMutation((payload) => addSettings(payload), {
    onError: () => {
      toast.error(`Unable to perform action`);
    },
    onSuccess: () => {},
  });

  const formik = useFormik({
    initialValues: {
      /* priveledges,
      Staff_ID: "", */
    },
    onSubmit: (values) => {
      //  Save  to  server
      addSettingsMutation.mutate(
        { priveledges },
        {
          onSuccess: ({ message, data }) => {
            setRootRoles(data.rootRoles);
            toast.success("Roles & Priviledges saved");
            setShowRolesPriviledgesModal(false);
          },
        }
      );
    },
  });

  const setUp = async () => {
    // formik.setFieldValue("userld", '');
  };

  const { data, isFetching } = useQuery(
    ["GET_GLOBAL_SETTINGS"],
    () => setUp(),
    {
      enabled: true,
    }
  );
  const getUsersInSystem = async () => {
    let response = await fetch(`${backendUrl}/api/users`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const data = await response.json();
    data.allUsersInSystem = [
      {
        label: "Select User",
        value: "",
      },
      ...data.staff.map((el) => ({
        label: el.Name,
        value: el.Staff_ID,
      })),
    ];

    return data;
  };
  const {
    data: { allUsersInSystem } = { allUsersInSystem: [] },
    isFetching: isFetchingUsers,
  } = useQuery(["GET_USERS_IN_SYSTEM"], () => getUsersInSystem(), {});

  const animatedComponents = makeAnimated();
  const users = useFetchUsers();

  const handleSelectChange = (selected) => {
    formik.setFieldValue("Staff_ID", selected.value);
  };

  const handlePrivilegePassword = (e) => {
    const { name, value } = e.target;
    setDisplayPrivilege({ ...displayPrivilege, [name]: value.toLowerCase() });
  };

  const showRolesAndPrivileges = (e) => {
    e.preventDefault();
    if (displayPrivilege.password === displayPrivilege.confim) {
      setDisplayPrivilege({ ...displayPrivilege, show: true });
    }
  };

  return (
    <>
      <div className="">
        <Modal
          size={displayPrivilege.show ? "xl" : ""}
          show={showRolesPriviledgesModal}
          onHide={() => setShowRolesPriviledgesModal(false)}
          enforceFocus={false}
          className={"roles-priviledges "}
        >
          <div
            style={{ maxWidth: "40rem" }}
            className={` w-100 mx-auto p-5 ${
              displayPrivilege.show ? "d-none" : ""
            }`}
          >
            <Form onSubmit={showRolesAndPrivileges}>
              <Form.Group className="mb-4">
                <Form.Label>Password</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={"password"}
                    name="confim"
                    placeholder="*****"
                    autoFocus
                    value={displayPrivilege.confim}
                    onChange={handlePrivilegePassword}
                  />
                </InputGroup>
              </Form.Group>
              <Button
                variant="primary"
                className="text-primary text-xs forgot py-1 my-2 text-center text-white"
                type="submit"
                style={{ fontSize: "0.75rem" }}
              >
                Submit
              </Button>
            </Form>
          </div>
          <div className={displayPrivilege.show ? "" : "d-none"}>
            <Modal.Header closeButton>
              <Modal.Title className="h5">
                <GearIcon /> Root Roles & Priviledge Settings
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                noValidate
                onSubmit={formik.handleSubmit}
                autoComplete="off"
                style={
                  isFetching || isFetchingUsers
                    ? { opacity: "0.5", pointerEvents: "none" }
                    : {}
                }
              >
                {isAdmin ? (
                  <div className="roles my-4 pt-4">
                    {userRoles.map((role, index) => (
                      <div key={index} className={"role-item"}>
                        <h6>
                          <Form.Check
                            inline
                            type={"checkbox"}
                            checked={Boolean(
                              priveledges.find(
                                (el) =>
                                  el.name === role.name &&
                                  el.parent == role.parent
                              )?.checked
                            )}
                            onChange={(e) =>
                              handleChange(
                                Boolean(
                                  priveledges.find(
                                    (el) =>
                                      el.name === role.name &&
                                      el.parent == role.parent
                                  )?.checked
                                ),
                                role
                              )
                            }
                          />
                          &nbsp;
                          {role.name}
                        </h6>
                        <div className="priviledges">
                          {role.priviledges
                            .filter((el) =>
                              el.hasOwnProperty("isCement")
                                ? el.isCement === isCement
                                : true
                            )
                            .map((priviledge, pIndex) => (
                              <Fragment key={pIndex}>
                                <p>
                                  <Form.Check
                                    inline
                                    type={"checkbox"}
                                    checked={Boolean(
                                      priveledges.find(
                                        (el) =>
                                          el.name === priviledge.name &&
                                          el.parent == priviledge.parent
                                      )?.checked
                                    )}
                                    onChange={(e) =>
                                      handleChange(
                                        Boolean(
                                          priveledges.find(
                                            (el) =>
                                              el.name === priviledge.name &&
                                              el.parent == priviledge.parent
                                          )?.checked
                                        ),
                                        priviledge
                                      )
                                    }
                                  />
                                  &nbsp;{priviledge.name}
                                </p>
                                <ul className="actions">
                                  {priviledge.actions?.map((action, aIndex) => (
                                    <li key={aIndex}>
                                      <Form.Check
                                        inline
                                        type={"checkbox"}
                                        checked={Boolean(
                                          priveledges.find(
                                            (el) =>
                                              el.name === action.name &&
                                              el.parent == action.parent
                                          )?.checked
                                        )}
                                        onChange={(e) =>
                                          handleChange(
                                            Boolean(
                                              priveledges.find(
                                                (el) =>
                                                  el.name === action.name &&
                                                  el.parent == action.parent
                                              )?.checked
                                            ),
                                            action
                                          )
                                        }
                                      />
                                      &nbsp;{action.name}
                                      <ul className="actions">
                                        {action?.subActions?.map(
                                          (subAction, aIndex) => (
                                            <li li key={aIndex}>
                                              <Form.Check
                                                inline
                                                type={"checkbox"}
                                                checked={Boolean(
                                                  priveledges.find(
                                                    (el) =>
                                                      el.name ===
                                                        subAction.name &&
                                                      el.parent ==
                                                        subAction.parent
                                                  )?.checked
                                                )}
                                                onChange={(e) =>
                                                  handleChange(
                                                    Boolean(
                                                      priveledges.find(
                                                        (el) =>
                                                          el.name ===
                                                            subAction.name &&
                                                          el.parent ==
                                                            subAction.parent
                                                      )?.checked
                                                    ),
                                                    subAction
                                                  )
                                                }
                                              />
                                              &nbsp;{subAction.name}
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    </li>
                                  ))}
                                </ul>
                              </Fragment>
                            ))}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => formik.submitForm()}
                variant="primary"
                disabled={addSettingsMutation.isLoading}
                className="px-4"
              >
                {addSettingsMutation.isLoading ? "Please wait..." : "Save"}
              </Button>
            </Modal.Footer>
          </div>
        </Modal>
      </div>
    </>
  );
}
