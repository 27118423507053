// import { Formik } from "formik";
import * as yup from "yup";
import { Alert, Button, Form } from "react-bootstrap";
import { useQueryClient, useMutation } from "react-query";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import "./../assets/scss/scoped/login.scoped.scss";
import {
  LoginImageOne,
  OrnamentOne,
  OrnamentTwo,
  SlideShowIndex,
  LogoMeduim,
} from "./Icons";
import { useFormik } from "formik";
import { useAuth } from "../hooks/useAuth";
import { useQueryParams } from "../utils/hooks";
import { decryptData } from "../utils/helpers";
import { backendApis } from "../config";
import { useEffectOnce } from "../utils/hooks";

export default function VendorLogin() {
  const location = useLocation();
  const { VendorLogin } = useAuth();
  const queryClient = useQueryClient();
  const [queryParams] = useQueryParams({});
  const [errorLink, setErrorsLink] = useState(false);
  const [backendApi, setBackendApi] = useState("");

  useEffect(() => {
    // const api = decryptData(queryParams.company);
    // console.log("api ==>", queryParams);

    const api = queryParams.company ? queryParams.company : backendApis[0].url;

    setBackendApi(api);
    // try {
    //   const api = decryptData(queryParams.company);
    //   console.log("api ==>", api, queryParams);
    //   if (api === "") {
    //     setErrorsLink(true);
    //   } else {
    //     setBackendApi(api);
    //   }
    // } catch (error) {
    //   console.log(error);
    //   setErrorsLink(true);
    // }
  }, [queryParams.company]);

  const initialValues = {
    username: "",
    password: "",
    rememberMe: true,
  };
  const schema = yup.object().shape({
    username: yup.string().required("required"),
    password: yup.string().required("required"),
  });

  const loginUser = async () => {
    let response = await fetch(`${backendApi}/api/vendors/login`, {
      method: "POST",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      body: JSON.stringify({
        ...formik.values,
      }),
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      if (response.errors) formik.setErrors(response.errors);
      throw new Error(response.message);
    }

    return await response.json();
  };

  const { status, error, mutate, isLoading } = useMutation(
    ["VENDOR_LOGIN"],
    () => loginUser(),
    {
      enabled: false,
      // cacheTime: 0,
      onSuccess: (data) => {
        queryClient.removeQueries("VENDOR_LOGIN");
        VendorLogin(
          { ...data.user, isExpired: data.isExpired, vendorApi: backendApi },
          location
        );
      },
    }
  );

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      mutate(values);
    },
  });

  const options = backendApis.map((el) => ({
    ...el,
    value: el.name,
    label: el.name,
  }));

  useEffectOnce(() => {
    if (options && options.length === 1) {
      formik.setFieldValue("company", options[0].value);
    }
  });

  return (
    <div className="login">
      <div className="form-area">
        <div className="logo">
          <LogoMeduim />
        </div>

        <div className="content">
          <div className="text-center">
            <img
              src={`${options[0].url}/images/company-logo.png`}
              alt="Company Logo"
              width={75}
              className="mb-3"
            />
            <h5>Sign in</h5>
            <p>Welcome! Please enter your details.</p>
          </div>
          {!isLoading &&
            (status === "error" && error?.message ? (
              <Alert variant="danger">{error?.message}</Alert>
            ) : status === "success" ? (
              <Alert variant="success">Success</Alert>
            ) : null)}

          <Form noValidate onSubmit={formik.handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label className="login-label">Email</Form.Label>
              <Form.Control
                className="ga-form-control"
                type="text"
                placeholder="Enter your username"
                name="username"
                value={formik.values.username}
                onChange={formik.handleChange}
                isInvalid={formik.touched.username && !!formik.errors.username}
                // disabled={errorLink}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.username}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="login-label">Password</Form.Label>
              <Form.Control
                className="ga-form-control"
                type="password"
                placeholder="••••••••"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                isInvalid={formik.touched.password && !!formik.errors.password}
                // disabled={errorLink}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.password}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Check
                type={"checkbox"}
                id={`default`}
                label={`Remember me`}
                // value={formik.values.remeberMe}
                checked={formik.values.rememberMe}
                onChange={formik.handleChange}
              />
            </Form.Group>

            <Button
              variant="primary"
              className="w-100 p-2"
              // disabled={isLoading || errorLink}
              disabled={isLoading}
              type="submit"
            >
              {isLoading ? "Please wait…" : "Sign in"}
            </Button>
          </Form>
        </div>

        <a
          className="owner"
          href="https://excellentbridge.com"
          target="_blank"
          rel="noreferrer"
        >
          ExcellentBridge Technologies
        </a>
      </div>
      <div className="bg-primary slide-show">
        <OrnamentTwo className="ornament-two" />
        <OrnamentOne className="ornament-one" />

        <div className="image">
          <div className="slide-image-holder">{<LoginImageOne />}</div>

          <div>
            <h2 className="text-white">Access insightful data</h2>
            <p>
              Get direct access to key information with customizable dashboards.
              analyze your invoicing by product, customer, salesperson, etc.
            </p>
          </div>

          <SlideShowIndex />
        </div>
      </div>
    </div>
  );
}
