export const requisitionActions = {
  GET_REQUISITION_DETAILS: "GET_REQUISITION_DETAILS",
  GET_REQUISITION: "GET_REQUISITION",
  ACTION: "ACTION",
};

export const reportActions = {
  GET_SALES: "GET_SALES",
  CASH_BOOK: "CASH_BOOK",
  SALE_BY_INVOICE: "SALE_BY_INVOICE",
  ACCOUNT_RECIEVABLES: "ACCOUNT_RECIEVABLES",
  ACCOUNT_PAYABLES: "ACCOUNT_PAYABLES",
  INVENTORY_ANALYSIS: "INVENTORY_ANALYSIS",
  PURCHASE_HISTORY: "PURCHASE_HISTORY",
  SALES_INVENTORY: "SALES_INVENTORY",
  EXPENSES: "EXPENSES",
  PENDING_PERMITS: "PENDING_PERMITS",
  PERMITS: "PERMITS",
  CUSTOMER_LEDGER: "CUSTOMER_LEDGER",
};

export const queryActions = {
  CUSTOMERS: "CUSTOMERS",
  GET_DEPARTMENTS: "GET_DEPARTMENTS",
  GET_USERS_IN_DEPARTMENTS: "GET_USERS_IN_DEPARTMENTS",
  GET_ITEMS: "GET_ITEMS",
  GET_SALE_ITEMS: "GET_SALE_ITEMS",
  GET_TRANSACTIONS: "GET_TRANSACTIONS",
  GET_VENDORS: "GET_VENDORS",
  INVOICE_SETUP: "INVOICE_SETUP",
  ALL_PERMITS: "ALL_PERMITS",
  DRIVER_SALARY: "DRIVER_SALARY",
  ITEMS: "ITEMS",
  ITEMS_SETUP: "ITEMS_SETUP",
  GET_SALES_BY_TRANSACTION_ID: "GET_SALES_BY_TRANSACTION_ID",
  ISSUEITEM_SETUP: "ISSUEITEM_SETUP",
  GET_DAMAGES: "GET_DAMAGES",
  JOURNAL_BY_ACCOUNT: "JOURNAL_BY_ACCOUNT",
  JOURNAL_SETUP: "JOURNAL_SETUP",
  GET_ALL_TRANSACTIONS: "GET_ALL_TRANSACTIONS",
  ITEMS_OUT_OF_STOCK: "ITEMS_OUT_OF_STOCK",
  GET_USERS_WITH_DEPARTMENTS: "GET_USERS_WITH_DEPARTMENTS",
};

export const cacheTimes = {
  GET_USERS_WITH_DEPARTMENTS: 60 * 10,
};
