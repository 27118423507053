/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Button, Table } from "react-bootstrap";
import { useState, useEffect, useMemo, useRef } from "react";
import CreatableSelect from "react-select/creatable";
import "./../assets/scss/scoped/issueItem.scss";
import { format } from "date-fns";
import currency from "currency.js";
import Select from "react-select";
import CurrencyInput from "react-currency-input-field";
import { useQuery } from "react-query";
import { useBackendUrl } from "../utils/hooks";
import { fetchActionsUtil } from "../utils/helpers";
import { DeleteIcon } from "./Icons";
import { toast } from "react-toastify";
import { useAuth } from "../hooks/useAuth";
import { backendApis } from "../config";

export default function AddWarehousePOModal({
  show,
  mainData,
  onHide,
  setAllItems,
  allItems,
  savedStaff,
  setSavedStaff,
  showedData,
  PODetails,
}) {
  const [tableData, setTableData] = useState([]);
  const [Item, setItem] = useState({});
  const [allstaff, setAllStaff] = useState({});
  const [isFetchingstaff, setIsFetchingstaff] = useState(false);

  const { user } = useAuth();

  const dragItem = useRef();
  const dragOverItem = useRef();

  const issuedToData = backendApis
    // .filter((d) => d.name !== user?.company)
    .map((el) => ({
      value: el.name,
      label: el.name,
    }));

  const newIT = {
    barcode: mainData?.barcode,
    itemName: mainData?.itemName,
    quantity: 0,
    warehouse: "",
    sendTo: "",
    receivingofficer: "",
    issueTo: "",

    Generic_Configuration: mainData?.Generic_Configuration
      ? mainData?.Generic_Configuration
      : "",
    Branded_Generic: mainData?.Branded_Generic ? mainData?.Branded_Generic : "",
    Pack_Size: mainData?.Pack_Size ? mainData?.Pack_Size : "",
    Tier: mainData?.Tier ? mainData?.Tier : "",
  };

  useEffect(() => {
    setItem(mainData);
    setAllStaff(savedStaff);
    if (allItems?.length > 0) {
      const pos = allItems?.filter((el) => el.barcode === mainData.barcode);
      pos?.length > 0 ? setTableData(pos) : setTableData([newIT]);
    } else {
      setTableData([newIT]);
    }
  }, [mainData, allItems]);

  const handleInputChange = ({ index, name, value, otherData = {} }) => {
    const oldTableData = tableData;
    oldTableData[index][name] = value;

    for (let key in otherData) {
      oldTableData[index][key] = otherData[key];
    }

    // console.log(oldTableData);
    setTableData([...oldTableData]);
  };

  // const setUp = async () => {
  //   let data = await fetchActionsUtil(`${backendUrl}/api/warehouse`, "GET");
  //   data.warehouses = data?.warehouses?.map((el) => ({
  //     value: el.W_ID,
  //     label: el.W_name,
  //   }));
  //   return data;
  // };

  // const {
  //   data = {
  //     warehouses: [],
  //   },
  // } = useQuery(["WAREHOUSE_ITEMS_PO"], () => setUp(), {
  //   enabled: true,
  // });

  const addmore = () => {
    const qty = parseInt(Item.quantity);
    const tolQty = tableData.reduce((a, p) => a + parseInt(p.quantity), 0);
    if (tolQty > qty) {
      return toast.error("Quantity exceeded");
    }
    const more = tableData.concat(newIT);
    setTableData(more);
  };

  const save = () => {
    const qty = parseInt(Item.quantity);
    const tolQty = tableData.reduce((a, p) => a + parseInt(p.quantity), 0);
    const error = tableData?.filter(
      (el) => el.issueTo === "" || el.receivingofficer === ""
    );

    if (error.length > 0) {
      return toast.error("IssueTo or Receivingofficer can not be empty");
    }

    const newItems = tableData.map((can, i) => {
      const sendTo = backendApis.find((el) => el.name === can.issueTo);

      return {
        ...can,
        sendTo: sendTo ? `${sendTo.url}/api/itemissuer/receiver` : "",
        priority: i,
        remaining: can.quantity,
        Sendercompany: user?.company,
      };
    });

    if (tolQty > qty) {
      return toast.error("Quantity exceeded");
    }

    const pos = allItems?.filter((el) => el.barcode !== mainData.barcode);
    const arr = [...pos, ...newItems];
    // console.log(arr);
    setAllItems(arr);
    onHide(false);
  };

  const removeSelection = (ind) => {
    const oldTableData = tableData.filter((el, index) => index !== ind);
    setTableData([...oldTableData]);
  };

  const getStaffs = async (name) => {
    setIsFetchingstaff(true);
    try {
      const sendTo = `${
        backendApis.find((el) => el.name === name).url
      }/api/users`;
      const staffs = await fetchActionsUtil(sendTo, "GET");

      staffs.staff = staffs.staff.map((el) => {
        //  console.log(el);
        return {
          ...el,
          value: el.Name,
          label: el.Name,
          company: staffs.company,
        };
      });

      const all = savedStaff;
      const local = { ...all, [name]: staffs.staff };
      setIsFetchingstaff(false);
      setSavedStaff(local);
      setAllStaff(local);
    } catch (error) {
      console.log(error);
      setIsFetchingstaff(false);
    }
  };

  const dragStart = (eid) => {
    dragItem.current = eid;
  };

  const dragEnter = (eid) => {
    dragOverItem.current = eid;
  };

  const drop = () => {
    const copyListItems = [...tableData];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setTableData(copyListItems);
  };

  return (
    <Modal
      onHide={() => onHide(false)}
      show={show}
      backdropClassName={`global-backdrop`}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="my-style-modal"
      animation={false}
      enforceFocus={false}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div>
            <h1>PO Distribution </h1>
          </div>

          <p>Add Items for distribution.</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="container">
            <div>
              <div>
                <div className="d-flex justify-content-between mb-3 fw-bold">
                  <div>
                    <span>Item Name : </span>
                    <span>
                      {PODetails ? PODetails?.itemName : Item?.itemName}{" "}
                    </span>
                  </div>
                  <div>
                    <span>Quantity in PO : </span>
                    <span>
                      {PODetails ? PODetails?.quantity : Item?.quantity}{" "}
                    </span>
                  </div>
                  <div>
                    <span>Item Code : </span>
                    <span>
                      {PODetails ? PODetails?.barcode : Item?.barcode}{" "}
                    </span>
                  </div>
                </div>
                {/*  */}

                {/* Table */}

                <div className="mb-4">
                  <Table
                    // responsive
                    borderless
                    striped
                    className="product-table"
                  >
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Issue To</th>
                        <th>Receiving Officer</th>
                        <th>Quantity</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {showedData ? (
                        <>
                          {showedData?.length > 0 ? (
                            showedData?.map((el, index) => (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{el?.issueTo}</td>
                                <td>{el?.receivingofficer}</td>
                                <td>{el?.quantity}</td>
                                <td> </td>
                              </tr>
                            ))
                          ) : (
                            <div> No Distribution</div>
                          )}
                        </>
                      ) : (
                        <>
                          {tableData?.length > 0 &&
                            tableData?.map((el, index) => (
                              <tr
                                onDragStart={(e) => dragStart(index)}
                                onDragEnter={(e) => dragEnter(index)}
                                onDragEnd={drop}
                                draggable
                                key={index}
                                className="p-cursor"
                              >
                                <td>{index + 1}</td>
                                <td>
                                  <Select
                                    classNamePrefix={"form-select"}
                                    isSearchable={true}
                                    value={issuedToData.find(
                                      (is) => is.value === el.issueTo
                                    )}
                                    onChange={({ value }) => {
                                      handleInputChange({
                                        index,
                                        name: "issueTo",
                                        value,
                                      });
                                      getStaffs(value);
                                    }}
                                    options={issuedToData}
                                  />
                                </td>

                                <td>
                                  <Select
                                    classNamePrefix={"form-select"}
                                    isSearchable={true}
                                    isLoading={isFetchingstaff}
                                    value={allstaff[(el?.issueTo)]?.find(
                                      (al) => al.value === el.receivingofficer
                                    )}
                                    onChange={(selected) => {
                                      const company = selected?.company;
                                      handleInputChange({
                                        index,
                                        name: "receivingofficer",
                                        value: selected.value,
                                        otherData: {
                                          issueToCompanyName: company?.CompName,
                                          issueToAddress: company?.AddressLine1,
                                          issueToPhone: company?.Phone,
                                        },
                                      });
                                    }}
                                    options={allstaff[(el?.issueTo)]}
                                  />
                                </td>

                                {/* <td>
                              {data?.warehouses?.length > 0 && (
                                <CreatableSelect
                                  classNamePrefix={`form-select`}
                                  options={data?.warehouses}
                                  value={data?.warehouses?.find(
                                    (wh) => wh.value === el?.warehouse
                                  )}
                                  onChange={({ value }) => {
                                    handleInputChange({
                                      index,
                                      name: "warehouse",
                                      value,
                                    });
                                  }}
                                />
                              )}
                            </td> */}
                                <td>
                                  <CurrencyInput
                                    className="form-control border-0 px-1 w-50"
                                    value={el.quantity}
                                    onKeyDown={(e) =>
                                      e.keyCode === 13 && e.target.blur()
                                    }
                                    name="quantity"
                                    onValueChange={(value, name) =>
                                      handleInputChange({
                                        index,
                                        name,
                                        value,
                                      })
                                    }
                                    //  disableGroupSeparators
                                    allowNegativeValue={false}
                                    allowDecimals={false}
                                    //   disabled={selectedItems}
                                    width={50}
                                  />
                                </td>
                                <td>
                                  <div onClick={() => removeSelection(index)}>
                                    <DeleteIcon />
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </>
                      )}
                    </tbody>
                  </Table>

                  {!showedData && (
                    <div className="d-flex justify-content-end mt-3">
                      <Button
                        onClick={addmore}
                        variant="primary"
                        className="border bg-primary"
                      >
                        Add more+
                      </Button>
                    </div>
                  )}
                </div>

                {/*  */}

                {/*  */}

                {!showedData && (
                  <div className="d-flex justify-content-end mt-3">
                    <Button
                      onClick={() => onHide(false)}
                      variant="white"
                      className="border bg-white mx-4"
                    >
                      Cancel
                    </Button>

                    <button className="btn btn-primary mx-3" onClick={save}>
                      Save
                    </button>
                  </div>
                )}
              </div>
            </div>

            {/*  */}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
