import { useQuery, useMutation } from "react-query";
import { toast } from "react-toastify";
import { useState, useMemo } from "react";
import CachedIcon from "mdi-react/CachedIcon";

import { fetchActionsUtil, sendFormData } from "../../utils/helpers";
import { useAuth } from "../../hooks/useAuth";
import "../../assets/scss/reports/cashbook.scss";
import UploadImage from "../utils/UploadImage";
import ConfirmDialog from "../ConfirmDialogue";
import { Form } from "react-bootstrap";
import { useStoreState } from "easy-peasy";
import ContentPage from "./ContentPage";
import ModalLoader from "../utils/ModalLoader";

const initials = {
  AddressLine1: "",
  AddressLine2: "",
  BankDetails: "",
  BranchAddressLine1: "",
  BranchAddressLine2: "",
  BranchEmail: "",
  BranchPhone: "",
  CompName: "",
  Email: "",
  Phone: "",
  Website: "",
  signature: "",
  Comp_ID: "",
  returnPolicy: "",
  termsAndConditions: "",
};

const CompanyInformationSettings = () => {
  const { backendUrl } = useAuth();
  const [inputData, setInputData] = useState(initials);
  const generalSettings = useStoreState((state) => state.generalSettings);

  const { data, refetch } = useQuery(
    ["FETCH COMPANY"],
    () => fetchActionsUtil(`${backendUrl}/api/users/company`, "GET"),
    {
      keepPreviousData: true,
    }
  );

  useMemo(async () => {
    setInputData({
      AddressLine1: data?.data?.company[0]?.AddressLine1
        ? data?.data?.company[0]?.AddressLine1
        : "",
      AddressLine2: data?.data?.company[0]?.AddressLine2
        ? data?.data?.company[0]?.AddressLine2
        : "",
      BankDetails: data?.data?.company[0]?.BankDetails
        ? data?.data?.company[0]?.BankDetails
        : "",
      BranchAddressLine1: data?.data?.company[0]?.BranchAddressLine1
        ? data?.data?.company[0]?.BranchAddressLine1
        : "",
      BranchAddressLine2: data?.data?.company[0]?.BranchAddressLine2
        ? data?.data?.company[0]?.BranchAddressLine2
        : "",
      BranchEmail: data?.data?.company[0]?.BranchEmail
        ? data?.data?.company[0]?.BranchEmail
        : "",
      BranchPhone: data?.data?.company[0]?.BranchPhone
        ? data?.data?.company[0]?.BranchPhone
        : "",
      CompName: data?.data?.company[0]?.CompName
        ? data?.data?.company[0]?.CompName
        : "",
      Email: data?.data?.company[0]?.Email ? data?.data?.company[0]?.Email : "",
      Phone: data?.data?.company[0]?.Phone ? data?.data?.company[0]?.Phone : "",
      Website: data?.data?.company[0]?.Website
        ? data?.data?.company[0]?.Website
        : "",
      Comp_ID: data?.data?.company[0]?.Comp_ID
        ? data?.data?.company[0]?.Comp_ID
        : "",
      TIN: data?.data?.company[0]?.TIN ? data?.data?.company[0]?.TIN : "",
      returnPolicy: data?.data?.company[0]?.returnPolicy
        ? data?.data?.company[0]?.returnPolicy
        : "",
    });

    try {
      const getPics = await fetch(`${backendUrl}/api/users/logo/company`);

      const signature = await getPics.blob();
      setInputData((d) => {
        return { ...d, signature };
      });
    } catch (error) {
      console.log("the error");
      //   console.log(error);
      setInputData((d) => {
        return { ...d, signature: false };
      });
    }
  }, [data?.data?.company, backendUrl]);

  const OnChangeInput = (e) => {
    const { name, value, files } = e.target;
    if (name === "signature")
      return setInputData({ ...inputData, [name]: files[0] });
    setInputData({ ...inputData, [name]: value });
  };

  const editAccount = useMutation(
    (payload) => sendFormData(`${backendUrl}/api/users/company`, payload),
    {
      onSuccess: () => {
        refetch();
        toast.success("Account Updated Successfully");
      },
      onError: ({ message = "" }) => {
        toast.error(message);
      },
    }
  );

  //   console.log(inputData);
  const saveData = async (e) => {
    e.preventDefault();

    const { signature, ...rest } = inputData;

    const formData = new FormData();
    for (const [key, value] of Object.entries(rest)) {
      formData.append(key, value);
    }

    if (signature) {
      formData.append("signature", signature, `${rest.Comp_ID}_signature`);
    }

    if (
      await ConfirmDialog({
        title: "Update Profile",
        description: `Are you sure you want to UPDATE this Account`,
      })
    ) {
      editAccount.mutate(formData);
    }
  };

  return (
    <div className="dashboard-content">
      <div className="pt-2 pb-3 bg-white">
        <main className="table-sticky-header">
          <div className="">
            <header className="container px-4 mt-3 d-flex justify-content-between">
              <h6>
                Company Information Settings
                <button
                  title="Refresh"
                  onClick={() => refetch()}
                  className="btn text-primary"
                >
                  <CachedIcon />
                </button>
              </h6>
            </header>

            <div className="px-md-4 my-4">
              <form onSubmit={saveData}>
                <div className="row">
                  <div className="col-2 p-3 mt-4">
                    <div className="text-center mb-2 fw-bold">Logo</div>
                    <UploadImage
                      onFileUpload={OnChangeInput}
                      name="signature"
                      image={inputData.signature}
                    />
                  </div>
                  <div className="col-10 p-3">
                    <div className="row">
                      <div className="col-6">
                        <div className="mb-3 row">
                          <label className="col-form-label fw-bold">
                            Company Name
                          </label>
                          <div>
                            <input
                              type="text"
                              className="form-control"
                              value={inputData.CompName}
                              name="CompName"
                              onChange={OnChangeInput}
                            />
                          </div>
                        </div>
                        {/*  */}
                        <div className="mb-3 row">
                          <label className="col-form-label fw-bold">
                            Address
                          </label>
                          <div>
                            <input
                              type="text"
                              className="form-control"
                              value={inputData.AddressLine1}
                              name="AddressLine1"
                              onChange={OnChangeInput}
                            />
                          </div>
                        </div>
                        {/*  */}
                        <div className="mb-3 row">
                          <label className="col-form-label fw-bold">
                            Website
                          </label>
                          <div>
                            <input
                              type="text"
                              className="form-control"
                              value={inputData.Website}
                              name="Website"
                              onChange={OnChangeInput}
                            />
                          </div>
                        </div>
                        {/*  */}
                      </div>
                      {/* Second side inputs */}
                      <div className="col-6">
                        <div className="mb-3 row">
                          <label className="col-sm-4 col-form-label fw-bold">
                            Address Line 2
                          </label>
                          <div>
                            <input
                              type="text"
                              className="form-control"
                              value={inputData.AddressLine2}
                              name="AddressLine2"
                              onChange={OnChangeInput}
                            />
                          </div>
                        </div>
                        {/*  */}
                        <div className="mb-3 row">
                          <label className="col-sm-4 col-form-label fw-bold">
                            Phone No(s)
                          </label>
                          <div>
                            <input
                              type="text"
                              className="form-control"
                              value={inputData.Phone}
                              name="Phone"
                              onChange={OnChangeInput}
                            />
                          </div>
                        </div>
                        {/*  */}

                        <div className="mb-3 row">
                          <label className="col-sm-4 col-form-label fw-bold">
                            Email
                          </label>
                          <div>
                            <input
                              type="email"
                              className="form-control"
                              value={inputData.Email}
                              name="Email"
                              onChange={OnChangeInput}
                            />
                          </div>
                        </div>
                        {/*  */}
                      </div>
                    </div>
                    {/*  */}
                  </div>
                </div>

                <hr />
                <div>
                  <div className="my-4">
                    <h6>Branch Information</h6>
                  </div>
                  <div className="row container px-5">
                    <div className="col-6">
                      {/*  */}
                      <div className="mb-3 row">
                        <label className="col-sm-4 col-form-label fw-bold">
                          Address
                        </label>
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            value={inputData.BranchAddressLine1}
                            name="BranchAddressLine1"
                            onChange={OnChangeInput}
                          />
                        </div>
                      </div>
                      {/*  */}
                      <div className="mb-3 row">
                        <label className="col-sm-4 col-form-label fw-bold">
                          Phone
                        </label>
                        <div>
                          <input
                            type="tel"
                            className="form-control"
                            value={inputData.BranchPhone}
                            name="BranchPhone"
                            onChange={OnChangeInput}
                          />
                        </div>
                      </div>
                      {/*  */}
                    </div>
                    <div className="col-6">
                      {/*  */}
                      <div className="mb-3 row">
                        <label className="col-sm-4 col-form-label fw-bold">
                          Address Line 2
                        </label>
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            value={inputData.BranchAddressLine2}
                            name="BranchAddressLine2"
                            onChange={OnChangeInput}
                          />
                        </div>
                      </div>
                      {/*  */}
                      <div className="mb-3 row">
                        <label className="col-sm-4 col-form-label fw-bold">
                          Email
                        </label>
                        <div>
                          <input
                            type="email"
                            className="form-control"
                            value={inputData.BranchEmail}
                            name="BranchEmail"
                            onChange={OnChangeInput}
                          />
                        </div>
                      </div>
                      {/*  */}
                    </div>
                    {/*  */}
                    <div className="mb-3 row">
                      <label className="col-sm-4 col-form-label fw-bold">
                        Tax Identification Number
                      </label>
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          value={inputData.TIN}
                          name="TIN"
                          onChange={OnChangeInput}
                        />
                      </div>
                    </div>
                    {/*  */}
                  </div>
                </div>

                <div className="mt-4 text-center">
                  <button
                    type="submit"
                    className="btn btn-primary btn-lg"
                    disabled={editAccount.isLoading}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </main>
      </div>

      <div className="pt-0 pb-3 bg-white">
        {generalSettings?.store && <ContentPage />}
      </div>

      <ModalLoader show={editAccount.isLoading} />
    </div>
  );
};

export default CompanyInformationSettings;
