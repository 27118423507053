import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useState, useRef } from "react";
import { SearchIcon } from "../Icons";
import { useLocalStorage } from "../../utils/hooks";
import { sideBarItems } from "../../utils/sideBarItems";
import CloseIcon from "mdi-react/CloseIcon";

export const QuickLookUpsModal = (props) => {
  const [data, setData] = useState(sideBarItems);
  const [sectedCount, setSectedCount] = useState(0);

  // store in local storage
  const [, setLookUps] = useLocalStorage("lookUps", null);

  // initiaze useRef
  let dragItem = useRef(null);
  let dragOverItem = useRef(null);

  // get and update the selected item and the count
  const getSelectedItem = (e) => {
    const { name } = e.target;

    const allIcons = [...data];

    const getselect = allIcons.map((d) => {
      if (d.name === name) {
        d.selected = !d.selected;
      }
      return d;
    });

    const countSelected = getselect.filter((d) => d.selected === true).length;
    setSectedCount(countSelected);
    setData(getselect);
  };

  const DragEnd = () => {
    // Duplicate the data
    let _datas = [...data];
    // remove and save the drag item content
    const draggedItemContent = _datas.splice(dragItem.current, 1)[0];
    // switch the position
    _datas.splice(dragOverItem, 0, draggedItemContent);
    // reset the position ref
    dragItem = null;
    dragOverItem = null;
    // update the actual array
    setData(_datas);
  };

  // send the selected item to the sidebar component and save in the localstorage
  const updateItem = () => {
    const selected = data.filter((d) => d.selected === true);
    const selectedText = selected.map((d) => d.name);
    setLookUps(selectedText);
    props.getselectedsidebar(selectedText);
  };

  return (
    <Modal
      // {...props}
      onHide={props.onHide}
      show={props.show}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div>
          <div>
            <div className="d-flex justify-content-between">
              <h6>Quick Lookups</h6>
              <div role="button" onClick={props.onHide}>
                <CloseIcon />
              </div>
            </div>

            <small className="text-muted smallestText">
              Select menu for Quick Lookups (up to 5 menus){" "}
              <span className="text-black">{sectedCount} Selected</span>
            </small>
            <div className="input-group mb-3">
              <span
                className="input-group-text bg-white border border-end-0"
                id="basic-addon1"
              >
                <SearchIcon />
              </span>
              <input
                type="text"
                className="form-control border border-start-0"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="basic-addon1"
              />
            </div>
          </div>
          <div>
            {data.map(({ name, icon, selected }, i) => (
              <div
                className={`mt-3 ${selected ? "selectedIcons" : ""}  p-2`}
                key={i}
                // draggable={selected ? true : false}
                draggable
                onDragStart={() => (dragItem.current = i)}
                onDragEnter={() => (dragOverItem.current = i)}
                onDragEnd={DragEnd}
                onDragOver={(e) => e.preventDefault()}
              >
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={name}
                    name={name}
                    onChange={getSelectedItem}
                    checked={selected}
                  />
                  <label className="form-check-label" htmlFor={name}>
                    <span className="px-1">{icon}</span>
                    {name}
                  </label>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal.Body>
      <div className="d-inline-flex justify-content-end m-4">
        <Button className="btn-white border mx-4" onClick={props.onHide}>
          Cancel
        </Button>
        <Button onClick={updateItem}>Done</Button>
      </div>
    </Modal>
  );
};
