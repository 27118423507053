import { Form, Button, Table, Modal } from "react-bootstrap";
import { useEffect, useState, useMemo } from "react";
// import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useQuery, useMutation } from "react-query";
import queryString from "query-string";
import { toast } from "react-toastify";
import { truncate } from "lodash";
import Select from "react-select";

import "../assets/scss/reports/cashbook.scss";
import { paginationOptions, defaultSelectValue } from "../utils/helpers";
import { useAuth } from "../hooks/useAuth";
import ReactPaginate from "react-paginate";
import { fetchActionsUtil } from "../utils/helpers";
import NoTableItem from "./utils/NoTableItem";
import useDebounce from "../utils/hooks";
import ConvertQuantity from "./utils/ConvertQuantity";
import { scrollToTop } from "../utils/helpers";
import ConfirmDialog from "./ConfirmDialogue";
import ModalLoader from "./utils/ModalLoader";
import CurrencyCustomInput from "./utils/CurrencyCustomInput";
import currency from "currency.js";

export default function CustomerProducts({
  showProductModal,
  setShowProductModal,
  selectedCustomer,
  itemRefetch,
}) {
  const { backendUrl, token } = useAuth();

  const [selectedData, setSelectedData] = useState([]);

  const [selectedAllData, setSelectedAllData] = useState([]);
  const [showSelected, setShowSeleted] = useState(false);

  const initialFilterParams = {
    page: 1,
    limit: 40,
    q: "",
    model: "Items",
    product: "",
    category: "",
    withProduct: true,
    withCategory: true,
  };
  const [queryParams, setQueryParams] = useState({
    ...initialFilterParams,
  });
  const [filterParams, setFilterParams] = useState({
    ...queryParams,
    ...initialFilterParams,
  });

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams((q) => ({ ...q, ...debouncedFilterParams }));
  }, [debouncedFilterParams]);

  const fetchItems = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/items?&${queryString.stringify(queryParams)}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();

    data.product = [
      {
        label: "All",
        value: "",
      },
      ...data.product.map((el) => ({
        label: el.Product_Name,
        value: el.Product_Name,
      })),
    ];

    data.category = [
      {
        label: "All",
        value: "",
      },
      ...data.category.map((el) => ({
        label: el.Cat_Name,
        value: el.Cat_Name,
      })),
    ];

    return data;
  };

  const { data = { count: 0, items: [] } } = useQuery(
    ["CUSTOMERS_PRODUCTS", queryParams],
    () => fetchItems(queryParams),
    {
      keepPreviousData: true,
    }
  );

  const getCustomerSaveItems = async () => {
    const { customer, items } = await fetchActionsUtil(
      `${backendUrl}/api/customers/customer-product/${
        selectedCustomer?.Cust_ID
      }`,
      "GET"
    );
    setSelectedData(customer);
    setSelectedAllData(items);
  };

  useEffect(() => {
    getCustomerSaveItems();
  }, []);

  const onSelected = (code, el) => {
    const theSelected = [...selectedData];
    const theAllSelected = [...selectedAllData];
    if (theSelected.length > 0) {
      const index = theSelected.findIndex((i) => i.Bar_Code === code);

      if (index >= 0) {
        theSelected.splice(index, 1);
        setSelectedData(theSelected);
      } else {
        setSelectedData((d) => [...d, { Bar_Code: code, cost: el.UnitPrice }]);
      }
    } else {
      setSelectedData((d) => [...d, { Bar_Code: code, cost: el.UnitPrice }]);
    }
    // All items
    if (theAllSelected.length > 0) {
      const index = theAllSelected.findIndex((i) => i.Bar_Code === el.Bar_Code);

      if (index >= 0) {
        theAllSelected.splice(index, 1);
        setSelectedAllData(theAllSelected);
      } else {
        setSelectedAllData((d) => [...d, el]);
      }
    } else {
      setSelectedAllData((d) => [...d, el]);
    }
  };

  const items = useMemo(() => {
    let arr = null;
    const selected = selectedData.map((el) => el.Bar_Code);
    if (selectedAllData.length > 0) {
      arr = Array.from(new Set(data?.items?.map((obj) => JSON.stringify(obj))))
        .map((str) => JSON.parse(str))
        .filter((el) => !selected.includes(el.Bar_Code))
        .concat([...selectedAllData]);
    } else {
      arr = Array.from(new Set(data?.items?.map((obj) => JSON.stringify(obj))))
        .map((str) => JSON.parse(str))
        .filter((el) => !selected.includes(el.Bar_Code));
    }
    // console.log(arr);
    return arr;
  }, [data]);

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const createCustomerProduct = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/customers/add-customer-product`,
        "POST",
        token,
        payload
      ),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
        setShowProductModal(false);
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const saveItems = async () => {
    const payload = {
      Cust_ID: selectedCustomer?.Cust_ID,
      selectedData,
    };

    if (
      await ConfirmDialog({
        title: `Add Product to ${selectedCustomer?.LastName}`,
        description: `Are you sure you want to add these products to ${
          selectedCustomer?.LastName
        }?`,
      })
    ) {
      // console.log(payload);
      createCustomerProduct.mutate(payload);
    }
  };

  const onChangePrice = (price, Bar_Code) => {
    setSelectedData((oldData) =>
      oldData.map((el) => {
        if (el.Bar_Code === Bar_Code) {
          return { ...el, cost: price };
        } else {
          return el;
        }
      })
    );
  };

  const onSelectedAll = (e) => {
    if (!e.target.checked) {
      setSelectedData([]);
      setSelectedAllData([]);
    } else {
      const all = items.map((el) => ({
        Bar_Code: el?.Bar_Code,
        cost: el.UnitPrice,
      }));
      setSelectedData(all);
      setSelectedAllData(items);
    }
  };

  const returnShowItems = () => {
    return showSelected ? selectedAllData : items;
  };

  return (
    <Modal
      show={showProductModal}
      onHide={() => setShowProductModal(false)}
      dialogClassName="new-entity-modal"
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1>Add Customer Product to {selectedCustomer?.LastName}</h1>
          <p>
            Add product to {selectedCustomer?.LastName} by ticking the boxes.
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <main className="cash-book p-0">
          <div className="content">
            <div className="content-main">
              <div className="content-body">
                <header className="text-nowrap px-0">
                  <div className="d-flex gap-3">
                    <Select
                      classNamePrefix="form-select"
                      menuPosition="fixed"
                      menuPlacement="auto"
                      placeholder="All"
                      name="product"
                      isSearchable={true}
                      key={data?.product}
                      onChange={(selected) => {
                        setFilterParams({
                          ...filterParams,
                          product: selected.value,
                        });
                      }}
                      defaultValue={defaultSelectValue(
                        queryParams?.product,
                        data?.product,
                        { value: "", label: "Product" }
                      )}
                      options={data?.product || []}
                    />
                    <Select
                      classNamePrefix="form-select"
                      menuPosition="fixed"
                      className="mx-3"
                      menuPlacement="auto"
                      placeholder="All"
                      name="category"
                      isSearchable={true}
                      key={data?.category}
                      onChange={(selected) => {
                        setFilterParams({
                          ...filterParams,
                          category: selected.value,
                        });
                      }}
                      defaultValue={defaultSelectValue(
                        queryParams?.category,
                        data?.category,
                        { value: "", label: "Category" }
                      )}
                      options={data?.category || []}
                    />
                  </div>
                  <div className="actions">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        onChange={onSelectedAll}
                        checked={selectedData.length === items.length}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="selectedItems"
                      >
                        Select All
                      </label>
                    </div>
                    <Form.Control
                      name="q"
                      value={filterParams?.q}
                      placeholder="Enter Item Name"
                      onChange={(e) => handleFilterParamsChange(e)}
                    />

                    <Button
                      variant="secondary"
                      onClick={() => {
                        setSelectedAllData([]);
                        setSelectedData([]);
                      }}
                    >
                      Clear
                    </Button>
                    <Button variant="primary" onClick={() => saveItems()}>
                      Add to customer
                    </Button>
                  </div>
                </header>

                <div className="pb-4">
                  {items.length > 0 ? (
                    <Table borderless striped className="product-table">
                      <thead className="sticky border-bottom">
                        <tr>
                          <th />
                          <th>S/N</th>
                          <th>Item Code</th>
                          <th>Item Name</th>
                          <th>Item Desc</th>
                          <th>QTY</th>
                          <th>Amount</th>
                        </tr>
                      </thead>

                      <tbody className="blue-hover">
                        {returnShowItems() &&
                          returnShowItems().map((el, index) => (
                            <tr key={index}>
                              <td>
                                <input
                                  type="checkbox"
                                  onChange={() => onSelected(el.Bar_Code, el)}
                                  checked={
                                    selectedData.length < 1
                                      ? false
                                      : selectedData.find(
                                          (id) => id.Bar_Code === el.Bar_Code
                                        )
                                  }
                                />
                              </td>
                              <td>{data?.startIndex + index + 1}</td>
                              <td>{el.Bar_Code}</td>
                              <td>{el?.Item_Name}</td>
                              <td title={el?.Item_Desc}>
                                {truncate(el?.Item_Desc)}
                              </td>

                              <td className="p-cursor" title={el.Quantity}>
                                <ConvertQuantity
                                  quantity={el.Quantity}
                                  desc={el.Item_Desc}
                                />
                              </td>
                              <td>
                                {selectedData.find(
                                  (id) => id.Bar_Code === el.Bar_Code
                                ) ? (
                                  <div
                                    style={{
                                      width: "11rem",
                                      backgroundColor: "#fff",
                                    }}
                                  >
                                    <CurrencyCustomInput
                                      placeholder="Enter Price"
                                      value={
                                        selectedData.find(
                                          (id) => id.Bar_Code === el.Bar_Code
                                        )?.cost
                                      }
                                      onValueChange={(value, name) =>
                                        onChangePrice(value, el.Bar_Code)
                                      }
                                    />{" "}
                                  </div>
                                ) : (
                                  currency(el?.UnitPrice, {
                                    symbol: "",
                                  }).format()
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  ) : (
                    <NoTableItem />
                  )}
                </div>

                <div className="d-flex justify-content-end mx-4">
                  <Button
                    variant="light-blue"
                    onClick={() => setShowSeleted(!showSelected)}
                  >
                    {showSelected ? "Show All" : "Show Selected"}
                  </Button>
                </div>

                <div className="d-flex justify-content-between px-3 align-items-center pagination">
                  <div className="pagination_left">
                    <p className="m-0 p-0">Show</p>
                    <select
                      value={queryParams.limit}
                      name="limit"
                      className="form-select "
                      onChange={(e) => handleSearchQueryChange(e)}
                    >
                      <option value="10">10 rows</option>
                      <option value="20">20 rows</option>
                      <option value="30">30 rows</option>
                      <option value="40">40 rows</option>
                      <option value="50">50 rows</option>
                      <option value="100">100 rows</option>
                    </select>
                  </div>

                  <ReactPaginate
                    {...paginationOptions}
                    pageCount={Math.ceil(data.count / queryParams.limit)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={0}
                    onPageChange={({ selected }) => {
                      scrollToTop();
                      setQueryParams({
                        ...queryParams,
                        page: selected + 1,
                      });
                    }}
                    forcePage={queryParams.page - 1}
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
      </Modal.Body>
      <ModalLoader show={createCustomerProduct.isLoading} />
    </Modal>
  );
}
