import { Table, Dropdown, Form } from "react-bootstrap";
import CachedIcon from "mdi-react/CachedIcon";
import { useQuery } from "react-query";
import { format, parse } from "date-fns";
import { useEffect, useState, useMemo } from "react";
import queryString from "query-string";
// import { CSVLink } from "react-csv";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import MagnifyIcon from "mdi-react/MagnifyIcon";

import { ExchangeFunds } from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import useDebounce, { useQueryParams, useScrollTop } from "../../utils/hooks";
import { useAuth } from "../../hooks/useAuth";
// import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import ModalLoader from "../utils/ModalLoader";
import NoTableItem from "../utils/NoTableItem";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import { fetchActionsUtil, paginationOptions } from "../../utils/helpers";
import CreatePlantModal from "../modals/CreatePlant";
import CreateViewStorageBinsModal from "../modals/CreateViewStorageBins";
import EditPlantModal from "../modals/EditPlant";
import PageHeader from "../PageHeader";
import { WareHouseIcon } from "../Icons";
import { IsPrivileged } from "../DisplayChildElement";

function Plants() {
  useScrollTop();
  const initialFilterParams = {
    q: "",
    startDate: "",
    endDate: "",
  };

  const { backendUrl } = useAuth();

  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openStorageBinModal, setOpenStorageBinModal] = useState(false);
  const [singlePlant, setSinglePlant] = useState({
    plantName: "",
    storageBins: [],
    plantId: "",
  });

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({
      ...queryParams,
      ...debouncedFilterParams,
    });
  }, [debouncedFilterParams]);

  const navigate = useNavigate();

  const { data, refetch, isFetching } = useQuery(
    ["FETCH_PLANTS", queryParams],
    () =>
      fetchActionsUtil(
        `${backendUrl}/api/production/plants?${queryString.stringify(
          queryParams
        )}`,
        "GET"
      ),
    {
      keepPreviousData: true,
    }
  );

  const changeData = useMemo(() => {
    return data?.allPlants?.map((d) => ({ ...d[0] }));
  }, [data]);
  //   console.log(changeData);
  const onGoToWarehouse = (namePL, PLID) => {
    window.localStorage.setItem(
      "plantDetails",
      JSON.stringify({ namePL, PLID })
    );
    navigate("/plant-inventory/inventry-entry");
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const handleSearchQueryChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <IsPrivileged roleName={["Plants"]}>
      <main className="inner-page-outlet">
        <PageHeader
          name="Plant List"
          description="List of all your plants"
          icon={<WareHouseIcon />}
        />

        <main className="cash-book">
          <div className="content">
            <div className="content-main">
              <div className="content-body">
                <header className="flex-wrap gap-3">
                  <div className="d-flex flex-grow-1">
                    <div className="global-search-area col col-md-6">
                      <MagnifyIcon />
                      <Form.Control
                        id="queryParams-q"
                        className=""
                        name="q"
                        value={filterParams.q}
                        onChange={(e) => handleSearchQueryChange(e)}
                        placeholder="Search by Plant Name and ID..."
                      />
                    </div>
                    <button
                      onClick={() => refetch()}
                      className="btn text-primary mx-3"
                    >
                      <CachedIcon />
                    </button>
                  </div>
                  <div className="actions mr-5">
                    {/* <CSVLink
              className="btn print d-none"
              filename={`General Ledger Report(${format(
                new Date(),
                "dd-MMM-yyyy hh:mm:ss a"
              )}).csv`}
              data={excelData}
              ref={CSVLinkRef}
            /> */}

                    <div className="position-relative">
                      <RsDateRangePicker
                        placement="bottomStart"
                        value={
                          filterParams.startDate && filterParams.endDate
                            ? [
                                parse(
                                  filterParams.startDate,
                                  "yyyy-MM-dd",
                                  new Date()
                                ),
                                parse(
                                  filterParams.endDate,
                                  "yyyy-MM-dd",
                                  new Date()
                                ),
                              ]
                            : []
                        }
                        onClean={() => clearDateRange()}
                        onOk={(date) => filterByDateRange(date)}
                      />
                    </div>

                    <button
                      onClick={() => setOpenCreateModal(true)}
                      className="btn bg-primary text-white"
                    >
                      Create New <ExchangeFunds color="white" />
                    </button>
                    {/* <a
              target="_blank"
              rel="noreferrer"
              href={`${backendUrl}/api/reports/pdf/general-ledger?${queryString.stringify(
                queryParams
              )}`}
              className="btn print mx-4"
            >
              PDF <PDFIcon color="#ff0000" />
            </a>
            <button className="btn print" onClick={onDownloadExcelData}>
              Excel <ExcelIcon color="#008000" />
            </button> */}
                  </div>
                </header>

                <div>
                  <Table
                    // responsive
                    borderless
                    striped
                    className="product-table container"
                  >
                    <thead className="position-sticky top-0">
                      <tr>
                        <th />
                        <th>Plant Name</th>
                        <th>Manufacturing Type</th>
                        <th>Product Line</th>
                        <th>Account</th>
                        <th>Storage Bins</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {changeData?.map((ek, i) => (
                        <tr
                          key={i}
                          onDoubleClick={() =>
                            onGoToWarehouse(ek.Plant_Name, ek.Plant_ID)
                          }
                          className="p-cursor"
                        >
                          <td>
                            <Dropdown>
                              <Dropdown.Toggle
                                variant=""
                                className="bg-white border-0"
                                bsPrefix="print more"
                              >
                                <DotsVerticalIcon />
                              </Dropdown.Toggle>
                              <Dropdown.Menu
                                popperConfig={{
                                  strategy: "fixed",
                                }}
                                renderOnMount
                                className="dropdown-with-icons"
                              >
                                <Dropdown.Item
                                  as="div"
                                  onClick={() =>
                                    onGoToWarehouse(ek.Plant_Name, ek.Plant_ID)
                                  }
                                  className="p-cursor"
                                >
                                  Open
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="div"
                                  className="p-cursor"
                                  onClick={() => {
                                    setSinglePlant({
                                      ...singlePlant,
                                      plantName: ek.Plant_Name,
                                      plantId: ek.Plant_ID,
                                    });
                                    setOpenEditModal(true);
                                  }}
                                >
                                  Edit
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                          <td>{ek.Plant_Name}</td>
                          <td>{ek?.Manu_Type}</td>
                          <td>{ek?.Prod_Line}</td>
                          <td>{ek?.Plant_Account?.Description}</td>
                          <td>
                            <div
                              className="text-white position-relative"
                              onClick={() => {
                                setSinglePlant({
                                  plantName: ek.Plant_Name,
                                  storageBins: ek?.storageBin,
                                  plantId: ek.Plant_ID,
                                });
                                setOpenStorageBinModal(true);
                              }}
                            >
                              {ek?.storageBin?.map(
                                (u, i, arr) =>
                                  i < 2 && (
                                    <span class="badge bg-primary" key={i}>
                                      {u?.Storage_Name?.split("")[0]}
                                    </span>
                                  )
                              )}
                              {ek?.storageBin?.length > 2 ? (
                                <span class="badge bg-primary" key={i}>{`+${ek
                                  ?.storageBin?.length - 2}`}</span>
                              ) : null}
                            </div>
                          </td>
                          <td>{ek?.Date}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>

                {changeData?.length < 1 && (
                  <NoTableItem queryParams={queryParams} />
                )}
                {changeData?.length > 0 && (
                  <div className="d-flex justify-content-between px-3 align-items-center pagination">
                    <div className="pagination_left">
                      <p className="m-0 p-0">Show</p>
                      <select
                        value={queryParams.limit}
                        name="limit"
                        className="form-select "
                        onChange={(e) => handleSearchQueryChange(e)}
                      >
                        <option value="10">10 rows</option>
                        <option value="20">20 rows</option>
                        <option value="30">30 rows</option>
                        <option value="40">40 rows</option>
                        <option value="50">50 rows</option>
                        <option value="100">100 rows</option>
                      </select>
                    </div>

                    <ReactPaginate
                      {...paginationOptions}
                      pageCount={Math.ceil(data?.count / queryParams?.limit)}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={0}
                      onPageChange={({ selected }) => {
                        document.body.scrollTop = document.documentElement.scrollTop = 0;
                        setQueryParams({
                          ...queryParams,
                          page: selected + 1,
                        });
                      }}
                      forcePage={parseInt(queryParams.page) - 1}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </main>
        <ModalLoader show={isFetching} />
        <CreatePlantModal
          show={openCreateModal}
          onHide={() => setOpenCreateModal(false)}
          refetch={refetch}
        />
        <CreateViewStorageBinsModal
          show={openStorageBinModal}
          onHide={() => setOpenStorageBinModal(false)}
          refetch={refetch}
          singlePlant={singlePlant}
        />
        <EditPlantModal
          show={openEditModal}
          onHide={() => setOpenEditModal(false)}
          refetch={refetch}
          singlePlant={singlePlant.plantName}
          plantId={singlePlant.plantId}
        />
      </main>
    </IsPrivileged>
  );
}

export default Plants;
