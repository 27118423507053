// import { Formik } from "formik";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import {
  QueryClient,
  useQuery,
  useQueryClient,
  QueryCache,
  useMutation,
} from "react-query";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Select from "react-select";
import "./../assets/scss/scoped/login.scoped.scss";
import {
  LoginImageOne,
  LogoMeduim,
  OrnamentOne,
  OrnamentTwo,
  SlideShowIndex,
} from "./Icons";
import { useFormik } from "formik";
import { initialGeneralSettings, waitFor } from "../utils/helpers";
import { useAuth } from "../hooks/useAuth";
import { setDefaultLocale } from "react-datepicker";
import { services, backendApis, appSettings, IS_HR } from "../config";
import { useStoreActions } from "easy-peasy";
import { useEffectOnce } from "../utils/hooks";
import { loginHRSite } from "../utils/loginHRSite";
import ConfirmDialog from "./ConfirmDialogue";
import { SendPasswordResetLink } from "./SendPasswordResetLinkModal";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";
import { useMemo } from "react";
import EyeOffOutlineIcon from "mdi-react/EyeOffOutlineIcon";
import EyeOutlineIcon from "mdi-react/EyeOutlineIcon";

const options = backendApis.map((el) => ({
  value: el.name,
  label: el.name,
}));

export default function ChangePassword() {
  const navigate = useNavigate();
  const { login } = useAuth();
  const queryClient = useQueryClient();
  const setItemMeasurements = useStoreActions(
    (actions) => actions.setItemMeasurements
  );
  const setGeneralSettings = useStoreActions(
    (actions) => actions.setGeneralSettings
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [showPassword, setShowPassword] = useState(false);
  const [
    showConfirmpasswordPassword,
    setShowConfirmpasswordPassword,
  ] = useState(false);

  const company = searchParams.get("company");
  const backendUrl = useMemo(() => {
    return backendApis.find((el) => el.name === company)?.url;
  }, [company]);

  const initialValues = {
    password: "",
    confirmpassword: "",
  };
  const schema = yup.object().shape({
    password: yup.string().required(),
    confirmpassword: yup
      .string()
      .oneOf([yup.ref("password")], "Passwords must match"),
  });

  const changePassword = async (values) => {
    // await waitFor(5000);
    const resetKey = searchParams.get("resetKey");
    let response = await fetch(`${backendUrl}/api/auth/change-password`, {
      method: "POST",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      body: JSON.stringify({
        ...formik.values,
        resetKey,
      }),
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      if (response.errors) formik.setErrors(response.errors);
      throw new Error(response.message);
    }

    return await response.json();
  };

  const { status, error, mutate, isLoading } = useMutation(
    ["LOGIN"],
    (values) => changePassword(values),
    {
      enabled: false,
      // cacheTime: 0,
      onSuccess: (data) => {
        toast.success("Password Changed");
        if (searchParams.get("st") && searchParams.get("st") == "true") {
          navigate(`${appSettings.storeBaseUrl}?action=login`, {
            replace: true,
          });
        } else {
          navigate("/", {
            replace: true,
          });
        }
      },
    }
  );

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      /* login(
        {
          ...values,
          company: formik.values.company,
          Department: "Admin",
        },
        location
      ); */
      mutate(values);
    },
  });

  useEffectOnce(() => {
    if (options && options.length === 1) {
      formik.setFieldValue("company", options[0].value);
    }
  });

  const handleAlreadyLoggedInUser = async () => {
    const proceed = await ConfirmDialog({
      title: "User already logged In",
      description: "End previous session",
      choice: true,
      backdrop: "static",
    });
    if (proceed) {
      mutate({
        ...formik.values,
        overwriteToken: true,
      });
    }
  };

  return (
    <div className="login reset-password">
      <div className="form-area">
        <Link to={"/dashboard"} className="logo">
          <LogoMeduim />
        </Link>

        <div className="content">
          <div className="text-center">
            <img
              src={`${backendUrl}/images/company-logo.png`}
              alt="Company Logo"
              width={75}
              className="mb-3"
            />
            <h1>Change Password</h1>
            <p>Welcome back! Please enter your details.</p>
          </div>

          {!isLoading &&
            (status === "error" && error?.message ? (
              <Alert variant="danger">{error?.message}</Alert>
            ) : status === "success" ? (
              <Alert variant="success">Success</Alert>
            ) : null)}

          <Form noValidate onSubmit={formik.handleSubmit}>
            <Form.Group className="mb-3 position-relative">
              <Form.Label className="login-label">Password</Form.Label>
              <Form.Control
                className="ga-form-control"
                type={showPassword ? "text" : "password"}
                placeholder="••••••••"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                isInvalid={formik.touched.password && !!formik.errors.password}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.password}
              </Form.Control.Feedback>
              <Button
                variant=""
                type="button"
                style={{
                  position: "absolute",
                  right: 0,
                  top: "2rem",
                  transform: `scale(0.8)`,
                }}
                onClick={(e) => {
                  e.target.blur();
                  setShowPassword(!showPassword);
                }}
                className="no-focus text-light"
              >
                {!showPassword ? <EyeOffOutlineIcon /> : <EyeOutlineIcon />}
              </Button>
            </Form.Group>

            <Form.Group className="mb-3 position-relative">
              <Form.Label className="login-label">Confirm Password</Form.Label>
              <Form.Control
                className="ga-form-control"
                type={showConfirmpasswordPassword ? "text" : "password"}
                placeholder="••••••••"
                name="confirmpassword"
                value={formik.values.confirmpassword}
                onChange={formik.handleChange}
                isInvalid={
                  formik.touched.confirmpassword &&
                  !!formik.errors.confirmpassword
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.confirmpassword}
              </Form.Control.Feedback>
              <Button
                variant=""
                type="button"
                style={{
                  position: "absolute",
                  right: 0,
                  top: "2rem",
                  transform: `scale(0.8)`,
                }}
                onClick={(e) => {
                  e.target.blur();
                  setShowConfirmpasswordPassword(!showConfirmpasswordPassword);
                }}
                className="no-focus text-light"
              >
                {!showConfirmpasswordPassword ? (
                  <EyeOffOutlineIcon />
                ) : (
                  <EyeOutlineIcon />
                )}
              </Button>
            </Form.Group>

            <Button
              variant="primary"
              className="w-100 p-2"
              disabled={isLoading}
              type="submit"
            >
              {isLoading ? "Please wait…" : "Change"}
            </Button>
          </Form>
        </div>

        <a
          className="owner"
          href="https://excellentbridge.com"
          target="_blank"
          rel="noreferrer"
        >
          ExcellentBridge Technologies - version{" "}
          {process.env?.REACT_APP_VERSION}
        </a>
      </div>
    </div>
  );
}
