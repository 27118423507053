import { Modal, Form, Button } from "react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import CurrencyCustomInput from "./utils/CurrencyCustomInput";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import moment from "moment";
import Select from "react-select";
import ModalLoader from "./utils/ModalLoader";
import { useAuth } from "../hooks/useAuth";
import { fetchActionsUtil } from "../utils/helpers";
import ConfirmDialog from "./ConfirmDialogue";
import CachedIcon from "mdi-react/CachedIcon";
import { isEmpty } from "lodash";

export default function CitySelector({ formik }) {
  const [cities, setcities] = useState([]);
  const [allStates, setAllStates] = useState();
  const [fetchingCities, setFetchingCities] = useState(false);
  const { backendUrl } = useAuth();

  const fetchItems = async () => {
    const { states } = await fetchActionsUtil(
      `${backendUrl}/api/customers/nigeria-states`
    );
    setAllStates([
      { label: "Select State", value: "" },
      ...states.map((el) => ({ label: el, value: el })),
    ]);
  };

  const { data = { count: 0, costs: [] }, refetch, isFetching } = useQuery(
    ["GET_ALL_SHOPPING_COST_FOR_SELECTOR"],
    () => fetchItems(),
    {
      keepPreviousData: true,
    }
  );

  const fetchLGA = async (state) => {
    if (!state) return;
    setFetchingCities(true);
    const { lga } = await fetchActionsUtil(
      `${backendUrl}/api/customers/nigeria-lga/${state}`
    );

    if (lga) {
      setcities([
        { label: "Select City", value: "" },
        ...lga?.map((el) => ({ label: el, value: el })),
      ]);
    }
    setFetchingCities(false);
  };

  useEffect(() => {
    if (formik.values.state) {
      fetchLGA(formik.values.state);
    }
  }, [formik.values.state]);

  return (
    <>
      <Form.Group className="col mb-3 pb-1">
        <Form.Label>
          State{" "}
          <Button variant="" onClick={() => refetch()}>
            <CachedIcon />
          </Button>
        </Form.Label>

        <Select
          classNamePrefix={`form-select`}
          options={allStates}
          value={allStates?.find((el) => el.value === formik.values.state)}
          onChange={({ value }) => {
            fetchLGA(value);
            formik.setFieldValue("state", value);
            formik.setFieldValue("LGA", "");
          }}
          onBlur={() => formik.setFieldTouched("state", true)}
          isLoading={isFetching}
        />
        {formik.touched.state && !!formik.errors.state ? (
          <span className="custom-invalid-feedback">{formik.errors.state}</span>
        ) : null}
      </Form.Group>

      <Form.Group className="col mb-3 pb-1">
        <Form.Label>City</Form.Label>
        <Select
          isLoading={fetchingCities}
          classNamePrefix={`form-select`}
          options={cities}
          value={cities?.find((el) => el.value === formik.values.LGA)}
          onChange={({ value }) => formik.setFieldValue("LGA", value)}
        />
      </Form.Group>
    </>
  );
}
