import { Modal, Form, Button } from "react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import { confirmable, createConfirmation } from "react-confirm";
import { useBackendUrl, useEffectOnce } from "../utils/hooks";
import NumberCustomInput from "./utils/NumberCustomInput";
import CurrencyCustomInput from "./utils/CurrencyCustomInput";
import { qtyFormat, Units } from "../utils/helpers";
import { appSettings } from "../config";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import { useStoreActions, useStoreState } from "easy-peasy";
import Select from "react-select";
import ItemSizeDialog from "./ItemSizeDialog";
import { useEffect, useState } from "react";
import currency from "currency.js";
import CreatableSelect from "react-select/creatable";
import { useQuery, useQueryClient } from "react-query";
import { queryActions } from "../utils/reactQueryActions";
import { FilesAttachments } from "../utils/Attachments";
import ConfirmDialog from "./ConfirmDialogue";

export default function UpdateImageModal({
  showUpdatePriceModal,
  selectedItem,
  setSelectedItem,
  setShowUpdatePriceModal,
  refetch,
}) {
  const backendUrl = useBackendUrl();

  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    images: [],
    Item_Name: "",
    Bar_Code: "",
  };
  const schema = yup.object().shape({
    Item_Name: yup.string().required("required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values) => {
      let isNewImage = false;
      const formData = new FormData();
      formData.append("Bar_Code", values.Bar_Code);

      for (const file of values.images) {
        if (!file.fileId) {
          isNewImage = true;
          formData.append("files", file);
        }
      }

      if (isNewImage) {
        await addNewImage(formData);
        // if (
        //   await ConfirmDialog({
        //     title: "Add Image",
        //     description: "Do you want to add more image",
        //   })
        // ) {
        //   await addNewImage(formData);
        // }
      } else {
        toast.error("Add new image to save");
      }
    },
  });

  const setUp = () => {
    formik.setValues({
      images: selectedItem?.images ? JSON.parse(selectedItem.images) : [],
      Item_Name: selectedItem?.Item_Name,
      Bar_Code: selectedItem?.Bar_Code,
    });
  };

  useEffectOnce(() => {
    setUp();
  });

  const deleteImage = async (index) => {
    const img = formik.values.images.find((el, i) => i === index);
    if (img?.fileId) {
      if (
        await ConfirmDialog({
          title: "Delete Image",
          description: "Do you want to delete this image",
        })
      ) {
        const res = await sendDeleteImage(
          { img, Bar_Code: selectedItem?.Bar_Code },
          index
        );
        refetch();
        toast.success(res?.message);
      }
    } else {
      formik.setFieldValue(
        "images",
        formik.values.images.filter((el, i) => i !== index)
      );
    }
  };

  const sendDeleteImage = async (payload, index) => {
    setIsLoading(true);
    let response = await fetch(`${backendUrl}/api/items/delete-image`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    formik.setFieldValue(
      "images",
      formik.values.images.filter((el, i) => i !== index)
    );

    setIsLoading(false);
    return res;
  };

  const addNewImage = async (payload) => {
    setIsLoading(true);
    let response = await fetch(`${backendUrl}/api/items/add-more-image`, {
      method: "POST",
      credentials: "include",
      body: payload,
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    refetch();
    toast.success(res?.message);
    setIsLoading(false);
  };

  return (
    <Modal
      show={showUpdatePriceModal}
      onHide={() => {
        setSelectedItem(null);
        setShowUpdatePriceModal(false);
      }}
      centered={true}
      animation={false}
      enforceFocus={false}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 className="h6">Add Image to {selectedItem?.Bar_Code}</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <div>
            <Form.Group className="form-mb align-items-center mb-3">
              <Form.Label>Images</Form.Label>
              <FilesAttachments
                files={formik.values.images}
                setFiles={(files) => formik.setFieldValue("images", files)}
                url={`${backendUrl}/api/items/view-file`}
                deleteImage={deleteImage}
              />
            </Form.Group>
          </div>

          <Button
            disabled={isLoading}
            variant="primary"
            className="w-100 p-3"
            type="submit"
          >
            {isLoading ? "Please wait…" : "Save Changes"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

/* export default function UpdateItemPriceModal(props) {
  return createConfirmation(confirmable(UpdatePrice))({ ...props });
}
 */
