import { Dropdown, Table, Form, Button } from "react-bootstrap";
import DetailedIcon, {
  ExportIcon,
  FilterIcon,
  FilterTwoIcon,
  BusinessBillIcon,
  PrintIcon,
  SummaryChartIconUp,
  SummaryChartIconDown,
  SummaryCloseIcon,
  ChartPieSliceTitleIcon,
  FilterCollapseIcon,
  ExchangeFunds,
  DropdownEyeIcon,
  DropdownCheckIcon,
  DropdownCloseIcon,
  LinkVariantIcon,
  EditIcon,
  DeleteIcon,
} from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useRef, useState } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import Select from "react-select";
import useDebounce, {
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
import { services } from "../../config";
import { reportActions } from "../../utils/reactQueryActions";
import { useMutation, useQuery, useQueryClient } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  copyText,
  customerFullName,
  defaultSelectValue,
  paginationOptions,
} from "../../utils/helpers";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import queryString from "query-string";
import PageHeader from "../PageHeader";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { Outlet, useNavigate } from "react-router-dom";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import { LinkWithQuery as Link } from "../utils/LinkWithQuery";
import { toast } from "react-toastify";
import eventBus from "../../utils/EventBus";
import { filter, isEmpty } from "lodash";
import { useAuth } from "../../hooks/useAuth";
import ConfirmDialog from "../ConfirmDialogue";
import ModalLoader from "../utils/ModalLoader";
import NoTableItem from "../utils/NoTableItem";
import ChangeStoreOrderStatusDialog from "../ChangeStoreOrderPaymentStatus";
import { IsPrivileged } from "../DisplayChildElement";

export default function ApprovalIndex({ sentBy = "", status = "Pending" }) {
  //  useScrollTop();
  const { backendUrl } = useAuth();
  const queryClient = useQueryClient();
  const initialFilterParams = { q: "", page: 1, limit: 40 };

  const navigate = useNavigate();
  const [queryParams, setQueryParams] = useQueryParams({});
  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });
  const debouncedFilterParams = useDebounce(filterParams, 500);

  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  const fetchPendingPermits = async (queryParams, sentBy) => {
    // console.log(queryParams);
    let response = await fetch(
      `${backendUrl}/api/permits?&${queryString.stringify({
        ...queryParams,
        sentBy,
        ...(status ? { status } : {}),
      })}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };

  const {
    data = { pendingPermits: [] },
    refetch,
    isFetching,
    isSuccess,
  } = useQuery(
    [reportActions.PENDING_PERMITS, queryParams, sentBy],
    () => fetchPendingPermits(queryParams, sentBy),
    {
      keepPreviousData: true,
      staleTime: 0,
    }
  );

  const handleSearchQueryChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]: e.target.value,
    });
  };

  const viewDetails = (el) => {
    el.TransactionID && navigate(`/approval/details/${el.TransactionID}`);
  };

  const updateAllPermit = async (payload) => {
    let response = await fetch(`${backendUrl}/api/permits/change-status-all`, {
      method: "POST",
      // credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };
  const updatePermitsMutation = useMutation(
    (payload) => updateAllPermit(payload),
    {
      onSuccess: ({ status, transactionIds }) => {
        queryClient.setQueryData(
          [reportActions.PENDING_PERMITS, queryParams, sentBy],
          (data) => {
            /* if (status === "Approved") {
              data.pendingPermits = data.pendingPermits.map((el) => {
                if (transactionIds.includes(el.TransactionID)) {
                  return {
                    ...el,
                    Status: status,
                  };
                }
              });
            } else if (status === "Disapproved") { */
            data.pendingPermits = data.pendingPermits.filter(
              (el) => !transactionIds.includes(el.TransactionID)
            );
            //  }
            return data;
          }
        );
        toast.success(`Success`);
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const deletePermitApi = async (payload) => {
    let response = await fetch(`${backendUrl}/api/permits/delete`, {
      method: "POST",
      // credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };
  const deletePermitMutation = useMutation(
    (payload) => deletePermitApi(payload),
    {
      onSuccess: ({ status, transactionIds }) => {
        queryClient.setQueryData(
          [reportActions.PENDING_PERMITS, queryParams, sentBy],
          (data) => {
            data.pendingPermits = data.pendingPermits.filter(
              (el) => !transactionIds.includes(el.TransactionID)
            );
            return data;
          }
        );
        toast.success(`Success`);
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const approveAll = async () => {
    if (
      await ConfirmDialog({
        title: "Approve all",
        description: "Are you sure, you want to approve all",
      })
    ) {
      const transactionIds = data.pendingPermits.map((el) => el.TransactionID);
      updatePermitsMutation.mutate({
        status: "Approved",
        transactionIds,
      });
    }
  };

  const disapproveAll = async () => {
    if (
      await ConfirmDialog({
        title: "Disapprove all",
        description: "Are you sure, you want to disapprove all",
      })
    ) {
      const transactionIds = data.pendingPermits.map((el) => el.TransactionID);
      updatePermitsMutation.mutate({
        status: "Disapproved",
        transactionIds,
      });
    }
  };

  const approve = async (el) => {
    if (
      await ConfirmDialog({
        title: "Approve",
        description: "Are you sure, you want to approve",
      })
    ) {
      updatePermitsMutation.mutate({
        status: "Approved",
        transactionIds: [el.TransactionID],
      });
    }
  };

  const disapprove = async (el) => {
    if (
      await ConfirmDialog({
        title: "Disapprove",
        description: "Are you sure, you want to disapprove",
      })
    ) {
      updatePermitsMutation.mutate({
        status: "Disapproved",
        transactionIds: [el.TransactionID],
      });
    }
  };

  const deletePermit = async (el) => {
    if (
      await ConfirmDialog({
        title: "Delete",
        description: "Are you sure, you want to delete",
      })
    ) {
      deletePermitMutation.mutate({
        transactionIds: [el.TransactionID],
      });
    }
  };

  const changePaymentStatus = async (el) => {
    await ChangeStoreOrderStatusDialog({
      TransactionID: el.TransactionID,
      paymentStatus: el.paymentStatus,
      refetch,
      customerName:
        el?.customer?.LastName === "Walk-In" && el?.ShipTo
          ? `${el?.ShipTo} (Walk-In)`
          : customerFullName(el.customer),
      oldPaymentStatus: el.paymentStatus,
    });
  };

  eventBus.useCustomEventListener("FETCH_PENDING_PERMITS", refetch);

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  return (
    <IsPrivileged
      roleName={[
        "Approve Pending Invoice",
        "Pending Online Orders",
        "Pending Online Quotes",
      ]}
    >
      <main className="inner-page-outlet">
        <PageHeader
          name={
            sentBy === "Store"
              ? status === "Pending"
                ? "Pending Online Orders"
                : "Pending Online Quotes"
              : "Approve Pending Invoice"
          }
          description="Your current pending"
          icon={<ChartPieSliceTitleIcon />}
        />

        <main className="cash-book">
          <div className="content">
            <div className="content-main">
              <div className="content-body">
                <header className="flex-wrap gap-3">
                  <div className="d-flex flex-grow-1  gap-3">
                    <div className="global-search-area col col-md-6">
                      <MagnifyIcon />
                      <Form.Control
                        id="queryParams-q"
                        className=""
                        name="TransactionID"
                        value={filterParams.TransactionID}
                        onChange={(e) => handleSearchQueryChange(e)}
                        placeholder="Search Transaction ID..."
                      />
                    </div>

                    <RsDateRangePicker
                      placement="bottomEnd"
                      defaultValue={
                        queryParams.startDate && queryParams.endDate
                          ? [
                              parse(
                                queryParams.startDate,
                                "yyyy-MM-dd",
                                new Date()
                              ),
                              parse(
                                queryParams.endDate,
                                "yyyy-MM-dd",
                                new Date()
                              ),
                            ]
                          : []
                      }
                      onClean={() => clearDateRange()}
                      onOk={(date) => filterByDateRange(date)}
                    />

                    <button
                      onClick={() => refetch()}
                      className="btn text-primary mx-3"
                    >
                      <CachedIcon />
                    </button>
                  </div>

                  <div className="d-flex gap-3">
                    <Button
                      disabled={isEmpty(data?.pendingPermits)}
                      onClick={() => disapproveAll()}
                      variant="danger"
                      className="text-white px-3"
                    >
                      Disapprove All
                    </Button>

                    <Button
                      disabled={isEmpty(data?.pendingPermits)}
                      onClick={() => approveAll()}
                    >
                      Approve all
                    </Button>
                  </div>
                </header>

                <div className="px-md-4">
                  <Table
                    borderless
                    responsive
                    hover
                    striped
                    className="product-table"
                  >
                    <thead>
                      <tr>
                        <th>TransID</th>
                        <th>Total Value</th>
                        <th>Business Name</th>
                        <th>Sent By</th>
                        <th>Payment Status</th>
                        <th>Date</th>
                        <th> {"  "}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.pendingPermits?.map((el, index) => (
                        <tr className="p-cursor" key={index}>
                          <td onClick={() => viewDetails(el)}>
                            {el.TransactionID}
                          </td>
                          <td onClick={() => viewDetails(el)}>
                            {currency(el.totalValue, {
                              symbol: "",
                            }).format()}
                          </td>
                          <td onClick={() => viewDetails(el)}>
                            {el?.customer?.LastName === "Walk-In" && el?.ShipTo
                              ? `${el?.ShipTo} (Walk-In)`
                              : customerFullName(el.customer)}
                          </td>

                          <td onClick={() => viewDetails(el)}>{el.UserName}</td>
                          <td
                            onClick={() => changePaymentStatus(el)}
                            title="Edit Payment Status"
                          >
                            {el.paymentStatus || "..."}
                          </td>
                          <td onClick={() => viewDetails(el)}>
                            {" "}
                            {el.Date_Log
                              ? format(new Date(el.Date_Log), "dd MMM, yyyy")
                              : "..."}{" "}
                          </td>
                          <td className="position-relative">
                            <div className="d-flex gap-3">
                              {/*  <Link
                            to={`/approval/details/${el.TransactionID}`}
                            className="btn border bg-white text-primary d-flex align-items-center"
                          >
                            View Details
                          </Link> */}

                              <Button
                                className="ga-btn"
                                onClick={() => approve(el)}
                              >
                                Approve
                              </Button>
                              <Button
                                className="ga-btn"
                                variant="outline-danger"
                                onClick={() => disapprove(el)}
                              >
                                Disapprove
                              </Button>

                              <Dropdown style={{ margin: 0 }}>
                                <Dropdown.Toggle
                                  variant=""
                                  className="bg-light-blue text-primary"
                                  bsPrefix="print more"
                                >
                                  <DotsHorizontalIcon />
                                </Dropdown.Toggle>

                                <Dropdown.Menu
                                  popperConfig={{
                                    strategy: "fixed",
                                  }}
                                  renderOnMount
                                  className="dropdown-with-icons"
                                >
                                  <Dropdown.Item
                                    as="button"
                                    onClick={() =>
                                      copyText(
                                        `${
                                          services.frontendUrl
                                        }/approval/details/${el.TransactionID}`
                                      )
                                    }
                                  >
                                    <LinkVariantIcon />
                                    Copy Link
                                  </Dropdown.Item>

                                  {/*  <Dropdown.Item as="div">
                                  <Link
                                    to={`/approval/details/${el.TransactionID}`}
                                  >
                                    <DropdownEyeIcon /> View Details
                                  </Link>
                                </Dropdown.Item> */}
                                  <Dropdown.Item as="div">
                                    <Link
                                      to={`/approval/details/${
                                        el.TransactionID
                                      }`}
                                    >
                                      <EditIcon /> Edit Items
                                    </Link>
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    as="button"
                                    onClick={() => changePaymentStatus(el)}
                                  >
                                    <EditIcon /> Edit Payment Status
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    as="button"
                                    onClick={() => deletePermit(el)}
                                  >
                                    <DeleteIcon /> Delete
                                  </Dropdown.Item>
                                  {/* 
                              <Dropdown.Item as="button" onClick={() => {}}>
                                <DropdownCheckIcon /> Appove
                              </Dropdown.Item>
                              <Dropdown.Item
                                as="button"
                                className="text-danger"
                                onClick={() => {}}
                              >
                                <DropdownCloseIcon /> Reject
                              </Dropdown.Item> */}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  {!isFetching && isSuccess && isEmpty(data?.pendingPermits) ? (
                    <NoTableItem queryParams={queryParams} />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </main>

        <Outlet />

        <ModalLoader
          show={
            deletePermitMutation.isLoading || updatePermitsMutation.isLoading
          }
        />
      </main>
    </IsPrivileged>
  );
}
