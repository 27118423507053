import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { QueryClient, useIsFetching } from "react-query";
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { LoaderIcon } from "../Icons";
import StoreNavBar from "./StoreNavBar";
import OfflineCheck from "../OfflineCheck";
import StoreFooter from "./StoreFooter";

export default function StoreLayout() {
  const location = useLocation();
  const navigate = useNavigate();
  const isFetching = useIsFetching();

  /* if (isEmpty(user)) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  } */

  return (
    <>
      <StoreNavBar />
      <Outlet />
      {isFetching ? (
        <div className="global-spinner">
          <LoaderIcon className="spin text-primary" />
        </div>
      ) : (
        <></>
      )}

      <StoreFooter />

      {/*   <OfflineCheck /> */}
    </>
  );
}
