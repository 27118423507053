import photoUrl from "../../assets/images/uploadFileIcon.png";

const UploadPDF = ({
  onFileUpload,
  name,
  image,
  noCirle,
  size,
  clearImage,
}) => {
  return (
    <div
      className={`${!noCirle && "rounded-circle"}${
        size ? size : "w-100 h-100"
      }`}
    >
      {/* <label className="text-center p-cursor"> */}
      {image ? (
        <div>
          <object data={URL.createObjectURL(image)} type="application/pdf">
            <embed src={URL.createObjectURL(image)} type="application/pdf" />
          </object>

          <div className="d-flex  my-3 align-items-baseline">
            <input
              type="file"
              onChange={onFileUpload}
              name={name}
              className="my-3 custom-file-input"
            />
            {clearImage && (
              <div onClick={clearImage} className="btn btn-light text-white">
                Clear Image
              </div>
            )}
          </div>
        </div>
      ) : (
        <div>
          <img
            src={photoUrl}
            alt="pics"
            className={`${size ? size : "w-100 h-100"} img-fluid rounded`}
            width={150}
            height={150}
          />
          <input
            type="file"
            onChange={onFileUpload}
            name={name}
            className="my-3 custom-file-input"
          />
        </div>
      )}
      {/* </label> */}
      {/* <p className="fw-bold">Upload</p> */}
    </div>
  );
};

export default UploadPDF;
