import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useEffect, useState } from "react";
import { Modal, Table, Form, Button, Dropdown } from "react-bootstrap";
// import "./../assets/scss/customer-select-modal.scss";
import useDebounce, {
  useEffectOnce,
  useSingleAndDoubleClick,
} from "../../utils/hooks";
import { appSettings, services } from "../../config";
import queryString from "query-string";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { queryActions } from "../../utils/reactQueryActions";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import {
  fetchActionsUtil,
  paginationOptions,
  qtyFormat,
  qtyFormatToString,
  scrollToTop,
  tonsToPcs,
  toTonsOrPcs,
} from "../../utils/helpers";
import currency from "currency.js";
import Datetime from "react-datetime";
import { format, startOfMonth, parse, differenceInWeeks } from "date-fns";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import { useAuth } from "../../hooks/useAuth";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import {
  CashSelectIcon,
  CheckCircleIcon,
  ChequeSelectIcon,
  DeleteIcon,
  DropdownEyeIcon,
  EditIcon,
  FileListIcon,
  RecieptIcon,
} from "../Icons";
import { toast } from "react-toastify";
import LoginDialog from "../LoginDialog";
import ConfirmDialog from "../ConfirmDialogue";
import CurrencyInput from "react-currency-input-field";
import BatchQuantityDialog from "../BatchQuantityDialog";
import { isEmpty } from "lodash";
import { useStoreActions, useStoreState } from "easy-peasy";
import ModalLoader from "../utils/ModalLoader";
import ConvertQuantity from "../utils/ConvertQuantity";
import { Link } from "react-router-dom";
import QuantityAdjustmentModal from "../QuantityAdjustmentModal";
import CachedIcon from "mdi-react/CachedIcon";
import CloseIcon from "mdi-react/CloseIcon";
import moment from "moment";
import OutsourceBatchToVendorModal from "../OutsourceBatchToVendorModal";
import BatchConsumptionModal from "./BatchConsumtionModal";
import BatchProductionReportModal from "./BatchProductionReportModal";

const ViewIssueNumberModal = ({
  show,
  selectedItem,
  setShowBatchesModal,
  serialNumbers,
  updateQuantity,
  setInputValue,
  updateSerial,
  handleInputChange,
  indexValue,
}) => {
  const [selectedData, setSelectedData] = useState([]);

  const [allselectedData, setAllSelectedData] = useState([]);

  useEffect(() => {
    setSelectedData(serialNumbers);
    setAllSelectedData(JSON.parse(selectedItem?.allserialNumbers));
  }, [serialNumbers]);

  useEffect(() => {
    // console.log(selectedData?.length, selectedItem);
    updateQuantity(selectedData?.length, selectedItem);
    setInputValue(selectedData?.length);
    updateSerial(selectedData, selectedItem);

    // handleInputChange({
    //   index: indexValue,
    //   name: "serialNumbers",
    //   value: selectedData,
    // });
  }, [selectedData]);

  const onSelectedOnly = (item) => {
    const theSelected = [...selectedData];
    if (theSelected.length > 0) {
      const index = theSelected.findIndex(
        (it) => it.serialNum === item.serialNum
      );

      if (index >= 0) {
        theSelected.splice(index, 1);
        setSelectedData(theSelected);
      } else {
        setSelectedData((d) => [...d, item]);
      }
    } else {
      setSelectedData((d) => [...d, item]);
    }
  };

  const selectAllOnly = (e) => {
    if (!e.target.checked) {
      setSelectedData([]);
    } else {
      setSelectedData(allselectedData);
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          setShowBatchesModal(false);
        }}
        dialogClassName="requisition-details-modal approve-modal"
        backdropClassName={`global-backdrop`}
        centered={true}
        animation={false}
        enforceFocus={false}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h1 className="h5">Serial Number Details</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-4 d-flex flex-column pt-0">
          <div className="search-area d-flex justify-content-between align-items-center gap-3 w-100 px-3 mb-4 h6">
            <h2>{selectedItem?.Bar_Code}</h2>
          </div>

          <div className="content px-1 pb-4 pe-2 flex-grow-1">
            <Table borderless responsive striped className="product-table">
              <thead className="sticky border-bottom">
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      onChange={selectAllOnly}
                      checked={Boolean(
                        selectedData.length === allselectedData?.length
                      )}
                    />
                  </th>
                  <th>Serial Number</th>
                  <th>Bar Code</th>
                  <th>Item Name</th>
                </tr>
              </thead>

              <tbody className="blue-hover">
                {allselectedData &&
                  allselectedData?.map((el, index) => (
                    <tr key={index} className={`p-cursor`}>
                      <td>
                        <input
                          type="checkbox"
                          onChange={() => onSelectedOnly(el)}
                          checked={Boolean(
                            selectedData?.find(
                              (ek) => ek.serialNum === el.serialNum
                            )
                          )}
                        />
                      </td>
                      <td>{el?.serialNum}</td>
                      <td>{selectedItem?.Bar_Code}</td>
                      <td>{selectedItem?.Item_Name}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewIssueNumberModal;
