import { endOfDay, startOfDay } from "date-fns";
import { compareAsc } from "date-fns/esm";
import { useMemo } from "react";
import { Form } from "react-bootstrap";

export default function ReconcilationCheckbox({
  Post_Time,
  reconciled,
  ...props
}) {
  const isOldRecord = useMemo(() => {
    return Post_Time
      ? compareAsc(new Date(startOfDay(new Date())), new Date(Post_Time)) ===
          1 && !props?.checked
      : true;
  }, [Post_Time, props?.checked]);

  return (
    <div key={props?.checked} className="d-flex gap-3  fw-bold">
      <Form.Check type={"checkbox"} {...props} isInvalid={isOldRecord} />

      {reconciled === null ? (
        <span>Pending</span>
      ) : reconciled ? (
        <span className="text-success">Cleared</span>
      ) : (
        <span>Pending</span>
      )}
    </div>
  );
}
