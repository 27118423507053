import Button from "react-bootstrap/Button";

import Modal from "react-bootstrap/Modal";
import "./../assets/scss/requisition/index.scss";

import { confirmable, createConfirmation } from "react-confirm";
import {
  ApproveBlueModalIcon,
  ApproveCheckIcon,
  RejectModalIcon,
} from "./Icons";
import { Table, Form } from "react-bootstrap";
import { map, isEmpty } from "lodash";
import { isNumber } from "lodash";
import currency from "currency.js";
import { useState } from "react";
import { toast } from "react-toastify";
import { useAuth } from "../hooks/useAuth";

function Dialog({
  proceed,
  show,
  title = "",
  description = "",
  summary = {},
  summaryTitle = "Summary",
  removeUnderscore = false,
  type = "info",
  choice = true,
  backdrop = true,
  // under score is added to summary object keys, incase of duplicates
  withComment = false,
}) {
  const [comment, setComment] = useState("");

  return (
    <Modal
      show={show}
      onHide={() => proceed(false)}
      dialogClassName="requisition-details-modal"
      centered={true}
      animation={false}
      enforceFocus={false}
      backdrop={backdrop}
    >
      <Modal.Header />
      <Modal.Body className="approve-action">
        <div className="text-info-h">
          <div className="mb-4">
            {type === "info" ? (
              <ApproveBlueModalIcon />
            ) : type === "danger" ? (
              <RejectModalIcon />
            ) : (
              <ApproveBlueModalIcon />
            )}{" "}
          </div>

          <h2>{title}</h2>
          <p
            className="fs-6"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>

        {!isEmpty(summary) ? (
          <>
            <Table striped className="mt-3">
              <thead>
                <tr>
                  <th colSpan={2}>{summaryTitle}</th>
                </tr>
              </thead>
              <tbody>
                {map(summary, (obj, key, all) => (
                  <tr key={key}>
                    <td>
                      {removeUnderscore ? String(key).replaceAll("_", "") : key}
                    </td>
                    <td>
                      {obj
                        ? isNumber(Number(obj)) && !isNaN(Number(obj))
                          ? currency(obj, {
                              symbol: "",
                            }).format()
                          : obj
                        : "-"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        ) : null}

        {withComment && (
          <Form.Group className="my-4">
            <Form.Label className="d-none">Comment :</Form.Label>
            <label className="border p-0 rounded pb-2 message-area d-block">
              <Form.Control
                name="message"
                as="textarea"
                className="w-100 border-0 p-3"
                placeholder="Enter your comment"
                rows={4}
                autoFocus
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </label>
          </Form.Group>
        )}
      </Modal.Body>
      <Modal.Footer>
        <span />
        <div className="proceed-actions justify-content-between">
          {choice ? (
            <>
              <Button
                onClick={() => proceed(false)}
                variant="white"
                className="border bg-white"
              >
                No
              </Button>
              <Button
                onClick={() =>
                  withComment
                    ? proceed({
                        proceed: true,
                        comment,
                      })
                    : proceed(true)
                }
                variant="primary"
                autoFocus={true}
              >
                Yes
              </Button>
            </>
          ) : (
            <>
              <span />
              <Button
                onClick={() =>
                  withComment
                    ? proceed({
                        proceed: true,
                        comment,
                      })
                    : proceed(true)
                }
                variant="primary"
                autoFocus={true}
              >
                Okay
              </Button>
            </>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
}
function BillingDialog({
  proceed,
  show,
  title = "",
  description = "",
  summary = {},
  summaryTitle = "Summary",
  removeUnderscore = false,
  type = "info",
  choice = true,
  backdrop = true,
  previousNumOfUsers,
  backendUrl,
  token,

  // under score is added to summary object keys, incase of duplicates
  withComment = false,
}) {
  const [comment, setComment] = useState("");

  return (
    <Modal
      show={show}
      dialogClassName="requisition-details-modal"
      centered={true}
      animation={false}
      enforceFocus={false}
      backdrop={backdrop}
      onHide={() => proceed(false)}
    >
      <Modal.Header />
      <Modal.Body className="approve-action">
        <div className="text-info-h">
          <div className="mb-4">
            {type === "info" ? (
              <ApproveBlueModalIcon />
            ) : type === "danger" ? (
              <RejectModalIcon />
            ) : (
              <ApproveBlueModalIcon />
            )}{" "}
          </div>

          <h2>{title}</h2>
          <p
            className="fs-6"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>

        {!isEmpty(summary) ? (
          <>
            <Table striped className="mt-3">
              <thead>
                <tr>
                  <th colSpan={2}>{summaryTitle}</th>
                </tr>
              </thead>
              <tbody>
                {map(summary, (obj, key, index) => (
                  <tr key={index}>
                    <td>
                      {removeUnderscore ? String(key).replaceAll("_", "") : key}
                    </td>
                    <td>
                      {obj
                        ? isNumber(Number(obj)) && !isNaN(Number(obj))
                          ? currency(obj, {
                              symbol: "",
                            }).format()
                          : obj
                        : "-"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        ) : null}

        {withComment && (
          <Form.Group className="my-4">
            <Form.Label className="d-none">Comment :</Form.Label>
            <label className="border p-0 rounded pb-2 message-area d-block">
              <Form.Control
                name="message"
                as="textarea"
                className="w-100 border-0 p-3"
                placeholder="Enter your comment"
                rows={4}
                autoFocus
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </label>
          </Form.Group>
        )}
      </Modal.Body>
      <Modal.Footer>
        <span />
        <div className="proceed-actions justify-content-between">
          {choice ? (
            <>
              <Button
                onClick={() => proceed(true)}
                variant="primary"
                autoFocus={true}
              >
                Create Now
              </Button>
              <Button
                onClick={() => proceed(false)}
                variant="white"
                className="border bg-white"
              >
                I will do this later
              </Button>
            </>
          ) : (
            <>
              <span />
              <Button
                onClick={() =>
                  withComment
                    ? proceed({
                        proceed: true,
                        comment,
                      })
                    : proceed(true)
                }
                variant="primary"
                autoFocus={true}
              >
                Continue
              </Button>
            </>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
}
function RemoveUserBillingDialog({
  proceed,
  show,
  title = "",
  description = "",
  summary = {},
  summaryTitle = "Summary",
  removeUnderscore = false,
  type = "info",
  choice = true,
  backdrop = true,
  // under score is added to summary object keys, incase of duplicates
  withComment = false,
}) {
  const [comment, setComment] = useState("");
  const [hide, setHide] = useState(show);

  return (
    <Modal
      show={show}
      dialogClassName="requisition-details-modal"
      centered={true}
      animation={false}
      enforceFocus={false}
      backdrop={backdrop}
      onHide={() => proceed(false)}
    >
      <Modal.Header closeButton />
      <Modal.Body className="approve-action">
        <div className="text-info-h">
          <div className="mb-4">
            {type === "info" ? (
              <ApproveBlueModalIcon />
            ) : type === "danger" ? (
              <RejectModalIcon />
            ) : (
              <ApproveBlueModalIcon />
            )}{" "}
          </div>

          <h2>{title}</h2>
          <p
            className="fs-6"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>

        {!isEmpty(summary) ? (
          <>
            <Table striped className="mt-3">
              <thead>
                <tr>
                  <th colSpan={2}>{summaryTitle}</th>
                </tr>
              </thead>
              <tbody>
                {map(summary, (obj, key, index) => (
                  <tr key={index}>
                    <td>
                      {removeUnderscore ? String(key).replaceAll("_", "") : key}
                    </td>
                    <td>
                      {obj
                        ? isNumber(Number(obj)) && !isNaN(Number(obj))
                          ? currency(obj, {
                              symbol: "",
                            }).format()
                          : obj
                        : "-"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        ) : null}

        {withComment && (
          <Form.Group className="my-4">
            <Form.Label className="d-none">Comment :</Form.Label>
            <label className="border p-0 rounded pb-2 message-area d-block">
              <Form.Control
                name="message"
                as="textarea"
                className="w-100 border-0 p-3"
                placeholder="Enter your comment"
                rows={4}
                autoFocus
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
            </label>
          </Form.Group>
        )}
      </Modal.Body>
      <Modal.Footer>
        <span />
        <div className="proceed-actions justify-content-between">
          {choice ? (
            <>
              <Button
                onClick={() =>
                  withComment
                    ? proceed({
                        proceed: true,
                        comment,
                      })
                    : proceed(true)
                }
                variant="primary"
                autoFocus={true}
              >
                Remove Now
              </Button>
              <Button
                onClick={() => proceed(false)}
                variant="white"
                className="border bg-white"
              >
                Cancel
              </Button>
            </>
          ) : (
            <>
              <span />
              <Button
                onClick={() =>
                  withComment
                    ? proceed({
                        proceed: true,
                        comment,
                      })
                    : proceed(true)
                }
                variant="primary"
                autoFocus={true}
              >
                Continue
              </Button>
            </>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default function ConfirmDialog(props) {
  return createConfirmation(confirmable(Dialog))(props);
}
export function ConfirmBillingDialog(props) {
  return createConfirmation(confirmable(BillingDialog))(props);
}
export function ConfirmRemoveUserBillingDialog(props) {
  return createConfirmation(confirmable(RemoveUserBillingDialog))(props);
}
