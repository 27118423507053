import { Table, Form, Button } from "react-bootstrap";
import { PageHeaderListIcon, ExportIcon } from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useState, useRef } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import useDebounce, { useQueryParams } from "../../utils/hooks";
import { useMutation, useQuery, useQueryClient } from "react-query";
import ReactPaginate from "react-paginate";
import queryString from "query-string";
import Select from "react-select";

import PageHeader from "../PageHeader";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { Outlet } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/useAuth";
import { fetchActionsUtil } from "../../utils/helpers";
import RejectModal from "../modals/RejectModal";
import { paginationOptions } from "../../utils/helpers";
import ConfirmDialog from "../ConfirmDialogue";
import ModalLoader from "../utils/ModalLoader";
import ReceivePlantTable from "./ReceivePLantTabble";

export default function ReceiveItemPlant() {
  const [selectedItems, setSelectedItems] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [openReject, setOpenReject] = useState(false);
  const [selectedAll, setSelectedAll] = useState(true);
  const [unRejectedItems, setUnRejectedItems] = useState([]);
  const [storageBins, setStorageBins] = useState("");

  const { backendUrl } = useAuth();
  const initialFilterParams = { q: "" };

  const refreshButton = useRef(null);

  const queryClient = useQueryClient();

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });
  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });
  const debouncedFilterParams = useDebounce(filterParams, 500);

  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  const plantLocal = JSON.parse(window.localStorage.getItem("plantDetails"));

  const fechData = async (plant) => {
    const pending = await fetchActionsUtil(
      `${backendUrl}/api/production/pending/${
        plant?.PLID
      }?&${queryString.stringify(queryParams)}`,
      "GET"
    );

    const { storage_bins } = await fetchActionsUtil(
      `${backendUrl}/api/production/storageBin/${plant?.PLID}`,
      "GET"
    );

    pending.storage = [
      { value: "", label: "Select Storage Bin" },
      ...storage_bins.map((d) => ({
        value: d.Storage_ID,
        label: d.Storage_Name,
      })),
    ];

    // console.log(pending);
    return pending;
  };

  const { data, refetch } = useQuery(
    ["PLANT_RECEIVE_ITEMS", { queryParams, plantLocal }],
    () => fechData(plantLocal),
    {
      keepPreviousData: false,
      staleTime: 0,
    }
  );

  useEffect(() => {
    let _data = data?.pending?.filter((p) => p.Status !== "Rejected");
    _data = _data?.map((d) => {
      const newItem = {
        ...d,
      };

      return newItem;
    });

    setSelectedItems(_data);
    setUnRejectedItems(_data);
    setAllItems((prev) => data?.pending);
  }, [data]);

  const handleSearchQueryChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]: e.target.value,
    });
  };

  const sendIssueItemMutation = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/production/recieve-items`,
        "POST",
        "",
        payload
      ),
    {
      onSuccess: ({ message, pending }) => {
        queryClient.setQueryData(
          ["PLANT_RECEIVE_ITEMS", queryParams],
          (oldData) => {
            let _data = pending?.filter((p) => p.Status !== "Rejected");

            _data = _data?.map((d) => {
              const newItem = {
                ...d,
              };

              return newItem;
            });
            setSelectedItems(_data);
            setUnRejectedItems(_data);
            setAllItems((prev) => oldData?.pending);
            return oldData;
          }
        );
        toast.success(message);
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const rejectItemMutation = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/production/reject-items`,
        "POST",
        "",
        payload
      ),
    {
      onSuccess: ({ message, pending }) => {
        queryClient.setQueryData(
          ["PLANT_RECEIVE_ITEMS", queryParams],
          (oldData) => {
            let _data = pending?.filter((p) => p.Status !== "Rejected");

            _data = _data?.map((d) => {
              const newItem = {
                ...d,
              };

              return newItem;
            });

            setSelectedItems(_data);
            setUnRejectedItems(_data);
            setAllItems((prev) => oldData?.pending);
            return oldData;
          }
        );
        toast.success(message);
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const onSelectAll = (e) => {
    if (!e.target.checked) {
      setSelectedItems([]);
      setSelectedAll(!selectedAll);
    } else {
      console.log(data);
      let _data = data?.data.filter((p) => p.Status !== "Rejected");

      _data = _data?.map((d) => {
        const newItem = {
          ...d,
        };

        return newItem;
      });

      setSelectedItems((prev) => _data);
      setUnRejectedItems(_data);
      setSelectedAll(!selectedAll);
    }
  };

  const onChangeSelected = (item) => {
    const _selectedItems = [...selectedItems];

    if (_selectedItems.length > 0) {
      const index = _selectedItems.findIndex(
        (d) => d.Bar_Code === item.Bar_Code && d.Trans_ID === item.Trans_ID
      );
      if (index >= 0) {
        _selectedItems.splice(index, 1);
        setSelectedItems(_selectedItems);
      } else {
        setSelectedItems((prev) => [...prev, item]);
      }
    } else {
      setSelectedItems((prev) => [...prev, item]);
    }
  };

  const updateQuantity = (num, item) => {
    let _datas = selectedItems.find(
      (p, i) => p.Bar_Code === item.Bar_Code && p.Trans_ID === item.Trans_ID
    );
    const index = selectedItems.findIndex(
      (a) => a.Bar_Code === item.Bar_Code && a.Trans_ID === item.Trans_ID
    );
    _datas = { ..._datas, Quantity: parseInt(num) };

    selectedItems.splice(index, 1, _datas);
  };

  const approveAll = async () => {
    if (storageBins === "") {
      return toast.error("Please choose a storage bin");
    }

    if (
      await ConfirmDialog({
        title: "Receive Item(s)",
        description:
          "Are you sure you want to receive this Item(s) to your inventry",
      })
    ) {
      const receives = {
        selectedItems,
        storageBins,
      };

      console.log(receives);

      sendIssueItemMutation.mutate(receives);
    }
  };

  const disapproveAll = async (reasonForReject) => {
    const data = { selectedItems, reasonForReject };
    if (
      await ConfirmDialog({
        title: "Reject Item(s)",
        description: "Are you sure you want to reject this Item(s)",
      })
    ) {
      console.log(data);
      rejectItemMutation.mutate(data);
      setOpenReject(false);
    }
  };

  return (
    <main className="inner-page-outlet">
      <main className="cash-book">
        <div className="content">
          <div className="content-main">
            <div className="content-body">
              <header className="flex-wrap gap-3">
                <div className="d-flex flex-grow-1">
                  <div className="global-search-area col col-md-6">
                    <MagnifyIcon />
                    <Form.Control
                      id="queryParams-q"
                      className=""
                      name="q"
                      value={filterParams.q}
                      onChange={(e) => handleSearchQueryChange(e)}
                      placeholder="Search by Code and Name..."
                    />
                  </div>
                  <button
                    onClick={() => refetch()}
                    className="btn text-primary mx-3"
                    ref={refreshButton}
                  >
                    <CachedIcon />
                  </button>
                </div>

                <div
                  className="d-flex align-items-center fw-bold mx-2"
                  style={{ zIndex: 99 }}
                >
                  {/* <label className="mx-3 fw-bold">Storage Bins</label> */}
                  <Select
                    classNamePrefix={"form-select"}
                    isSearchable={true}
                    value={data?.storage?.find(
                      (el) => el.value === storageBins
                    )}
                    onChange={({ value }) => setStorageBins(value)}
                    options={data?.storage}
                    menuPosition="fixed"
                  />
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="selectedItems"
                    onChange={onSelectAll}
                    checked={selectedItems?.length === unRejectedItems?.length}
                  />
                  <label className="form-check-label" htmlFor="selectedItems">
                    Select All
                  </label>
                </div>

                <div className="d-flex gap-3">
                  <Button
                    disabled={
                      selectedItems?.length < 1 ||
                      sendIssueItemMutation.isLoading ||
                      rejectItemMutation.isLoading
                    }
                    onClick={() => approveAll()}
                  >
                    {sendIssueItemMutation.isLoading
                      ? "Please wait..."
                      : "Receive Selected"}{" "}
                  </Button>
                  <Button
                    disabled={
                      selectedItems?.length < 1 ||
                      sendIssueItemMutation.isLoading ||
                      rejectItemMutation.isLoading
                    }
                    onClick={() => setOpenReject(true)}
                    variant="danger"
                    className="text-white px-3"
                  >
                    {rejectItemMutation.isLoading
                      ? "Please wait..."
                      : "Reject Selected"}{" "}
                  </Button>
                  <div className="btn bg print ">
                    Export to Excel <ExportIcon className="px-2" />
                  </div>
                </div>
              </header>

              <div className="px-md-4">
                <Table
                  borderless
                  responsive
                  hover
                  striped
                  className="product-table"
                >
                  <thead>
                    <tr>
                      <th className="ml-3">Item Code</th>
                      <th>Item Name</th>
                      <th>Quantity</th>
                      <th>Warehouse</th>
                      <th>Date Log</th>
                      <th className="text-center">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allItems?.map((el, index) => (
                      <ReceivePlantTable
                        key={index}
                        el={el}
                        index={index}
                        onChangeSelected={onChangeSelected}
                        updateQuantity={updateQuantity}
                        selectedItems={selectedItems}
                      />
                    ))}
                  </tbody>
                </Table>
              </div>

              <div className="d-flex justify-content-between px-3 align-items-center pagination">
                <div className="pagination_left">
                  <p className="m-0 p-0">Show</p>
                  <select
                    value={queryParams.limit}
                    name="limit"
                    className="form-select "
                    onChange={(e) => handleSearchQueryChange(e)}
                  >
                    <option value="10">10 rows</option>
                    <option value="20">20 rows</option>
                    <option value="30">30 rows</option>
                    <option value="40">40 rows</option>
                    <option value="50">50 rows</option>
                    <option value="100">100 rows</option>
                  </select>
                </div>

                <ReactPaginate
                  {...paginationOptions}
                  pageCount={Math.ceil(data?.count / queryParams?.limit)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={0}
                  onPageChange={({ selected }) => {
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                    setQueryParams({
                      ...queryParams,
                      page: selected + 1,
                    });
                  }}
                  forcePage={parseInt(queryParams.page) - 1}
                />
              </div>
            </div>
          </div>
        </div>
      </main>

      <Outlet />
      <RejectModal
        show={openReject}
        onHide={() => setOpenReject(false)}
        disapproveAll={disapproveAll}
      />
      <ModalLoader
        show={sendIssueItemMutation.isLoading || rejectItemMutation.isLoading}
      />
    </main>
  );
}
