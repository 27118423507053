import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useState } from "react";
import { Modal, Table, Form, Button, Dropdown } from "react-bootstrap";
import "./../assets/scss/customer-select-modal.scss";
import useDebounce, { useEffectOnce } from "../utils/hooks";
import { services } from "../config";
import queryString from "query-string";
import { useQuery } from "react-query";
import { queryActions } from "../utils/reactQueryActions";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import {
  customerTypeOptions,
  employeeFullName,
  paginationOptions,
  scrollToTop,
} from "../utils/helpers";
import { useAuth } from "../hooks/useAuth";
import NewCustomerModal from "./NewCustomerModal";
import { uniqBy } from "lodash";
import { format } from "date-fns";
import { ExportIcon, ExcelIcon, PDFIcon } from "./Icons";
import { useDownloadExcel } from "../hooks/useDownloadExcel";
import { useRef } from "react";
import { CSVLink } from "react-csv";
import CachedIcon from "mdi-react/CachedIcon";

export default function SalesRepModal({
  setShowSalesRepModal,
  showSalesRepModal,
  selectedEmployee,
  setSelectedEmployee,
}) {
  const [showCreateNewCustomerModal, setShowCreateNewCustomerModal] = useState(
    false
  );
  const { backendUrl, token } = useAuth();
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 10,
    LastName: "",
    PhoneNo1: "",
    Rep_ID: selectedEmployee.EmployeeID,
  });
  const debouncedqueryParams = useDebounce(queryParams, 500);
  const [excelData, setExcelData] = useState([]);

  const CSVLinkRef = useRef(null);

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  const getCustomer = async (debouncedqueryParams) => {
    let response = await fetch(
      `${backendUrl}/api/customers/sales-rep?${queryString.stringify(
        debouncedqueryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
          Authorization: `Bearer ${token}`,
        },
        credentials: "include",
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    data.productName = [
      {
        label: "All Product / Manufacturer",
        value: "",
      },
      ...data.products
        .map((el) => ({
          label: el.Product_Name,
          value: el.Product_Name,
        }))
        .filter((el) => el.value),
    ];
    return data;
  };

  const {
    data = { customerReps: [], customerTypes: [], productName: [] },
    refetch,
  } = useQuery(
    ["SALES_REP_DATA", debouncedqueryParams],
    () => getCustomer(debouncedqueryParams),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const { limit, page, ...rest } = queryParams;
  const onDownloadExcelData = async () => {
    let exData = await fetchExcelData(
      `${backendUrl}/api/customers/customers-sales-rep?${queryString.stringify({
        ...rest,
        salesRep: selectedEmployee.EmployeeID,
      })}`,
      "GET"
    );
    const company = exData?.company?.CompName;

    const date =
      rest.startDate && rest.endDate
        ? `Date Prepared: Between ${format(
            new Date(rest.startDate),
            "E MMM d yyyy k:mm:ss z"
          )} to ${format(new Date(rest.endDate), "E MMM d yyyy k:mm:ss z")}`
        : "";

    exData = exData?.customers?.map(({ Product, Cust_Rep_Customers }, i) => [
      i + 1,
      Cust_Rep_Customers.Cust_ID,
      Cust_Rep_Customers.LastName,
      Cust_Rep_Customers.TransType,
      Cust_Rep_Customers.Branch,
      Product,
    ]);

    exData = [
      [company],
      [employeeFullName(selectedEmployee) + " Rep.Sales/Customers Report"],
      [date],
      [""],
      [
        "S/N",
        "Cust ID",
        "Business Name",
        "Transaction Type",
        "Product / Manufacturer",
      ],
      ...exData,
    ];
    console.log(exData);
    setExcelData(exData);
  };

  return (
    <>
      <Modal
        show={true}
        onHide={() => {
          setShowSalesRepModal();
          setShowSalesRepModal(false);
        }}
        dialogClassName="customer-select-modal"
        backdropClassName={`global-backdrop`}
        centered={true}
        animation={false}
        enforceFocus={false}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h1>
              {`Customers for ${employeeFullName(selectedEmployee)}`}{" "}
              <Button variant="" onClick={() => refetch()}>
                <CachedIcon />
              </Button>{" "}
            </h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column justify-content-between">
          <div className="search-area d-flex flex-wrap gap-3 w-100 px-3 mb-4">
            <div className="global-search-area  flex-grow-1">
              <MagnifyIcon />
              <Form.Control
                id="queryParams-q"
                className=""
                name="LastName"
                value={queryParams.LastName}
                onChange={(e) => handleSearchQueryChange(e)}
                placeholder="Search..."
              />
            </div>

            <Form.Group className="mb-2-5 w-25 text-nowrap">
              <Form.Label className="d-none">Product / Manufacturer</Form.Label>
              <Select
                name="Product"
                classNamePrefix="form-select"
                menuPosition="fixed"
                menuPlacement="auto"
                placeholder="All Product / Manufacturer"
                isSearchable={true}
                key={data?.Product}
                onChange={(selected) => {
                  setQueryParams({
                    ...queryParams,
                    Product: selected.value,
                  });
                }}
                value={
                  data?.productName.find(
                    (el) => el.value === queryParams?.Product
                  ) || ""
                }
                options={data?.productName || []}
              />
            </Form.Group>
            {/*   <Form.Group>
              <Select
                classNamePrefix="form-select"
                menuPosition="fixed"
                menuPlacement="auto"
                placeholder="Customer Type"
                name="TransType"
                isSearchable={true}
                onChange={(selected) => {
                  setQueryParams({
                    ...queryParams,
                    TransType: selected?.value,
                  });
                }}
                value={data.customerTypes?.find(
                  (el) => el.value === queryParams.TransType
                )}
                options={data.customerTypes || []}
                isClearable
              />
            </Form.Group> */}
            <div className="actions">
              <CSVLink
                className="btn print d-none"
                filename={`Rep.Sales/Customers Report(${format(
                  new Date(),
                  "dd-MMM-yyyy hh:mm:ss a"
                )}).csv`}
                data={excelData}
                ref={CSVLinkRef}
              />

              {/* <button /> */}
              <Dropdown>
                <Dropdown.Toggle
                  variant=""
                  className="btn print"
                  disabled={isfetchingExcel}
                  bsPrefix=""
                >
                  Export
                  <ExportIcon color="#008000" />
                </Dropdown.Toggle>
                <Dropdown.Menu
                  popperConfig={{
                    strategy: "fixed",
                  }}
                  renderOnMount
                  className="text-center"
                >
                  <Dropdown.Item
                    as="div"
                    onClick={onDownloadExcelData}
                    className="p-cursor"
                  >
                    Excel <ExcelIcon color="#008000" />
                  </Dropdown.Item>
                  <Dropdown.Item as="div">
                    <a
                      href={`${backendUrl}/api/customers/pdf/customers-sales-rep?${queryString.stringify(
                        { ...rest, salesRep: selectedEmployee.EmployeeID }
                      )}`}
                      target="blank"
                    >
                      PDF
                      <PDFIcon color="#ff0000" />
                    </a>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <div className="content table-max  px-3 pb-4 pe-2 flex-grow-1">
            <Table
              borderless
              striped
              responsive
              className="product-table text-nowrap"
            >
              <thead className="position-sticky top-0">
                <tr>
                  <th />
                  <th>Cust ID</th>
                  <th>Business Name</th>
                  <th>Transaction Type</th>
                  <th>Product / Manufacturer</th>
                </tr>
              </thead>

              <tbody className="blue-hover">
                {data.customerReps.map(
                  ({ Product, Cust_Rep_Customers }, index) => (
                    <tr key={index}>
                      <td> </td>
                      <td>{Cust_Rep_Customers.Cust_ID}</td>
                      <td>{Cust_Rep_Customers.LastName}</td>
                      <td>{Cust_Rep_Customers.TransType}</td>
                      <td>{Product}</td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          </div>

          <div className="d-flex justify-content-between px-3 align-items-center pagination">
            <p className="m-0">
              Showing {data?.startIndex + 1} to{" "}
              {data?.endIndex <= data?.count ? data?.endIndex : data?.count} of{" "}
              {data.count} entries
            </p>
          </div>
          <div />
        </Modal.Body>
      </Modal>

      {showCreateNewCustomerModal && (
        <NewCustomerModal
          showCreateNewCustomerModal={showCreateNewCustomerModal}
          setShowCreateNewCustomerModal={setShowCreateNewCustomerModal}
        />
      )}
    </>
  );
}
