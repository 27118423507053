import { Table, Form, Dropdown } from "react-bootstrap";
// import { PageHeaderListIcon } from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useState, useRef } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import useDebounce, { useQueryParams } from "../../utils/hooks";
import { useQuery, useMutation } from "react-query";
import ReactPaginate from "react-paginate";
import queryString from "query-string";
import currency from "currency.js";
import { format, parse } from "date-fns";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import CubeOutline from "mdi-react/CubeOutlineIcon";
import Select from "react-select";
import { toast } from "react-toastify";

// import PageHeader from "../PageHeader";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { Outlet } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { fetchActionsUtil } from "../../utils/helpers";
import { paginationOptions } from "../../utils/helpers";
import ModalLoader from "../utils/ModalLoader";
import NoTableItem from "../utils/NoTableItem";
import BatchesModalPlant from "./BatchModalPlant";

export default function ItemsProgress() {
  const { backendUrl } = useAuth();
  const initialFilterParams = {
    q: "",
    startDate: "",
    endDate: "",
  };

  const refreshButton = useRef(null);

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });
  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });
  const [selectedItem, setSelectedItem] = useState(null);
  const [showBatchesModal, setShowBatchesModal] = useState(false);
  const [theWarehouse, setTheWarehouse] = useState("Default");

  const debouncedFilterParams = useDebounce(filterParams, 500);

  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  const plantLocal = JSON.parse(window.localStorage.getItem("plantDetails"));

  const getThings = async (qPr, Pl) => {
    const data = await fetchActionsUtil(
      `${backendUrl}/api/production/in-progress/${
        Pl?.PLID
      }?&${queryString.stringify(qPr)}`,
      "GET"
    );
    const { warehouses } = await fetchActionsUtil(
      `${backendUrl}/api/warehouse`,
      "GET"
    );

    const { plant } = await fetchActionsUtil(
      `${backendUrl}/api/production/plant/settings/${Pl?.PLID}`,
      "GET"
    );

    data.warehouses = [
      { label: "Default", value: "Default" },
      ...warehouses.map((el) => ({
        label: el.W_name,
        value: el.W_ID,
      })),
    ];

    setTheWarehouse(plant?.Warehouse);

    return data;
  };

  const { data, refetch, isLoading } = useQuery(
    ["PLANT_IN_PROGRESS_ITEMS", { queryParams, plantLocal }],
    () => getThings(queryParams, plantLocal),
    {
      keepPreviousData: false,
      staleTime: 0,
    }
  );

  const handleSearchQueryChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const handleShowBatches = (item) => {
    setSelectedItem(item);
    setShowBatchesModal(true);
  };

  const sendChangeWarehouse = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/production/plant/settings`,
        "POST",
        "",
        payload
      ),
    {
      onSuccess: ({ message, data }) => {
        toast.success(message);
        refetch();
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const handleChangeProgress = (warehouse) => {
    setTheWarehouse(warehouse);

    const payload = {
      Plant: plantLocal.PLID,
      Warehouse: warehouse,
      contents: "Active",
    };

    sendChangeWarehouse.mutate(payload);
  };

  return (
    <main className="inner-page-outlet">
      {/* <PageHeader
        name="Inventory On Transit"
        description="Receive Your Inventory"
        icon={<PageHeaderListIcon />}
      /> */}

      <main className="cash-book">
        <div className="content">
          <div className="content-main">
            <div className="content-body">
              <header className="flex-wrap gap-3">
                <div className="d-flex flex-grow-1">
                  <div className="global-search-area col col-md-6">
                    <MagnifyIcon />
                    <Form.Control
                      id="queryParams-q"
                      className=""
                      name="q"
                      value={filterParams.q}
                      onChange={(e) => handleSearchQueryChange(e)}
                      placeholder="Search by Item Code and Description..."
                    />
                  </div>
                  <button
                    onClick={() => refetch()}
                    className="btn text-primary mx-3"
                    ref={refreshButton}
                  >
                    <CachedIcon />
                  </button>
                </div>
                <div className="actions mr-5">
                  <div style={{ zIndex: "999" }}>
                    <span>Choose Warehouse</span>
                    <Select
                      classNamePrefix={"form-select"}
                      isSearchable={true}
                      value={data?.warehouses?.find(
                        (el) => el.value === theWarehouse
                      )}
                      onChange={({ value }) => {
                        handleChangeProgress(value);
                      }}
                      options={data?.warehouses}
                      isDisabled
                      menuPosition="fixed"
                    />
                  </div>
                  {/* <CSVLink
              className="btn print d-none"
              filename={`General Ledger Report(${format(
                new Date(),
                "dd-MMM-yyyy hh:mm:ss a"
              )}).csv`}
              data={excelData}
              ref={CSVLinkRef}
            /> */}

                  <div className="position-relative">
                    <RsDateRangePicker
                      placement="topEnd"
                      value={
                        filterParams.startDate && filterParams.endDate
                          ? [
                              parse(
                                filterParams.startDate,
                                "yyyy-MM-dd",
                                new Date()
                              ),
                              parse(
                                filterParams.endDate,
                                "yyyy-MM-dd",
                                new Date()
                              ),
                            ]
                          : []
                      }
                      onClean={() => clearDateRange()}
                      onOk={(date) => filterByDateRange(date)}
                    />
                  </div>

                  {/* <a
              target="_blank"
              rel="noreferrer"
              href={`${backendUrl}/api/reports/pdf/general-ledger?${queryString.stringify(
                queryParams
              )}`}
              className="btn print mx-4"
            >
              PDF <PDFIcon color="#ff0000" />
            </a>
            <button className="btn print" onClick={onDownloadExcelData}>
              Excel <ExcelIcon color="#008000" />
            </button> */}
                </div>
              </header>

              <div className="px-md-4">
                <Table
                  borderless
                  responsive
                  hover
                  striped
                  className="product-table"
                >
                  <thead>
                    <tr>
                      <th />
                      <th>S/N</th>
                      <th className="ml-3">Item Code</th>
                      <th>Description</th>
                      <th>Quantity</th>
                      <th>UnitCost</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.inProgress?.map((el, ind) => (
                      <tr key={ind}>
                        <td>
                          {" "}
                          <Dropdown>
                            <Dropdown.Toggle
                              variant=""
                              className="bg-white border-0"
                              bsPrefix="print more"
                            >
                              <DotsVerticalIcon />
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                              popperConfig={{
                                strategy: "fixed",
                              }}
                              renderOnMount
                              className="dropdown-with-icons"
                            >
                              <Dropdown.Item
                                as="button"
                                onClick={() => handleShowBatches(el)}
                              >
                                <CubeOutline className="text-light" />
                                Batches
                              </Dropdown.Item>

                              {/* <Dropdown.Item
                                as="button"
                                onClick={() => handleDeleteItem(el)}
                              >
                                <DeleteIcon />
                                Delete
                              </Dropdown.Item> */}
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                        <td>{ind + 1}</td>
                        <td>{el?.Bar_Code}</td>
                        <td>{el?.Item_Name}</td>
                        <td>{el?.Quantity}</td>
                        <td>
                          {currency(el?.UnitCost, {
                            symbol: "",
                          }).format()}
                        </td>
                        <td>
                          {currency(
                            parseFloat(el?.UnitCost) * parseInt(el?.Quantity),
                            {
                              symbol: "",
                            }
                          ).format()}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                {data?.inProgress?.length < 1 && (
                  <NoTableItem queryParams={queryParams} />
                )}

                {data?.inProgress?.length > 0 && (
                  <div className="d-flex justify-content-start mt-4">
                    <div className="gridChild gridChildBorderLeftBlue">
                      <div className="gridChildLeft">
                        <p className="gridChld1">
                          {currency(
                            data?.inProgress?.reduce(
                              (a, b) => a + b?.UnitCost * b.Quantity,
                              0
                            ),
                            {
                              symbol: "₦",
                            }
                          ).format()}
                        </p>
                        <p className="gridChld2">Total</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {data?.inProgress?.length > 0 && (
                <div className="d-flex justify-content-between px-3 align-items-center pagination">
                  <div className="pagination_left">
                    <p className="m-0 p-0">Show</p>
                    <select
                      value={queryParams.limit}
                      name="limit"
                      className="form-select "
                      onChange={(e) => handleSearchQueryChange(e)}
                    >
                      <option value="10">10 rows</option>
                      <option value="20">20 rows</option>
                      <option value="30">30 rows</option>
                      <option value="40">40 rows</option>
                      <option value="50">50 rows</option>
                      <option value="100">100 rows</option>
                    </select>
                  </div>

                  <ReactPaginate
                    {...paginationOptions}
                    pageCount={Math.ceil(data?.count / queryParams?.limit)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={0}
                    onPageChange={({ selected }) => {
                      document.body.scrollTop = document.documentElement.scrollTop = 0;
                      setQueryParams({
                        ...queryParams,
                        page: selected + 1,
                      });
                    }}
                    forcePage={parseInt(queryParams.page) - 1}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </main>

      <Outlet />
      <ModalLoader show={isLoading || sendChangeWarehouse.isLoading} />
      <BatchesModalPlant
        selectedItem={selectedItem}
        show={showBatchesModal}
        onHide={() => setShowBatchesModal(false)}
        plantLocal={plantLocal}
        theWarehouse={theWarehouse}
      />
    </main>
  );
}
