import Modal from "react-bootstrap/Modal";
import { useState, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import Select from "react-select";
import { useFormik } from "formik";
import * as yup from "yup";
import CreatableSelect from "react-select/creatable";

import { useAuth } from "../../hooks/useAuth";
import { fetchActionsUtil } from "../../utils/helpers";
import ConfirmDialog from "../ConfirmDialogue";
import ModalLoader from "../utils/ModalLoader";
import AddAccounts from "./AddAccounts";
import CloseIcon from "mdi-react/CloseIcon";

const wareCategory = ["", "Stock", "Assets", "Comsumables"];
const productLine = ["", "Solid", "Liquid", "Gas"];

const components = {
  DropdownIndicator: null,
};

const CreatePlantModal = (props) => {
  const { backendUrl } = useAuth();

  const [openAccountModal, setOpenAccountModal] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState([]);

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    // eslint-disable-next-line default-case
    switch (event.key) {
      case "Enter":
      case "Tab":
        setValue((prev) => [...prev, { label: inputValue, value: inputValue }]);
        setInputValue("");
        event.preventDefault();
    }
  };

  const getThings = async () => {
    const res = await fetchActionsUtil(
      `${backendUrl}/api/accounts/accountLists/CURRENT ASSET`,
      "GET"
    );

    const accounts = res.accountDes.map((el) => ({
      value: el.AccountID,
      label: el.Description,
    }));

    return accounts;
  };

  const { data = [], isFetching, refetch } = useQuery(
    ["FETCH_PLANTS_ACCOUNTS"],
    () => getThings(),
    {
      keepPreviousData: true,
    }
  );

  // console.log(data);
  const saveWarehouse = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/production/plants`,
        "POST",
        "",
        payload
      ),
    {
      onSuccess: () => {
        toast.success("Plant saved Successfully");
        formik.resetForm({
          storage_bins: [],
          Plant_Name: "",
          Manu_Type: "",
          Prod_Line: "",
          account: "",
        });
        setValue([]);
        props.refetch();
        props.onHide();
      },
      onError: () => {
        toast.error("Unable to save plant");
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      storage_bins: [],
      Plant_Name: "",
      Manu_Type: "",
      Prod_Line: "",
      account: "",
    },
    validationSchema: yup.object().shape({
      storage_bins: yup.array().required("Input storage bins"),
      Plant_Name: yup.string().required("Plant Name is required"),
      Manu_Type: yup.string().required("Manufacture Type is required"),
      Prod_Line: yup.string().required("Product Line is required"),
      account: yup.string().required("Account is required"),
    }),
    onSubmit: async (values) => {
      if (
        await ConfirmDialog({
          title: "Create Plant",
          description: `Are you sure you want to Create this Plant`,
        })
      ) {
        values.storage_bins = value.map((d) => d.label);
        console.log(values);
        saveWarehouse.mutate(values);
      }
    },
  });

  const openAccount = () => {
    if (formik.values.Plant_Name === "") {
      return formik.setFieldError("Plant_Name", "Warehouse Name is required");
    } else {
      setOpenAccountModal(true);
    }
  };

  const setAccountReturn_WH = (id) => {
    console.log(id.toString(), "ids");
    formik.setFieldValue("account", id.toString());
  };

  return (
    <Modal
      onHide={props.onHide}
      show={props.show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div>
          <div>
            <div className="d-flex justify-content-between">
              <h6>Create Plant</h6>
              <div role="button" className="text-lg" onClick={props.onHide}>
                <CloseIcon />
              </div>
            </div>
            <p className="text-muted">
              Create a new plant and assign storage bins to it
            </p>
          </div>
          <div className="mt-3">
            {/*  */}
            <div className="mb-3">
              <label className="col-form-label fw-bold">Plant Name</label>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  value={formik.values.Plant_Name}
                  name="Plant_Name"
                  onChange={formik.handleChange}
                />
                <span className="form-control-feedback text-danger">
                  {formik.errors.Plant_Name}
                </span>
              </div>
            </div>
            {/*  */}
            <div className="mb-3">
              <label className="col-form-label fw-bold">
                Manufacturing Type
              </label>
              <div className="form-group">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={formik.values.Manu_Type}
                  name="Manu_Type"
                  onChange={formik.handleChange}
                >
                  {wareCategory?.map((a, i) => (
                    <option key={i} value={a}>
                      {a}
                    </option>
                  ))}
                </select>
                <span className="form-control-feedback text-danger">
                  {formik.errors.Manu_Type}
                </span>
              </div>
            </div>
            {/*  */}

            <div className="mb-3 row">
              <label className="col-form-label fw-bold">
                Map to GL account
              </label>
              <div className="form-group col-10">
                <Select
                  closeMenuOnSelect={true}
                  value={data?.find((el) => el.value === formik.values.account)}
                  onChange={(selected) => {
                    formik.setFieldValue("account", selected.value);
                  }}
                  options={data}
                />
                <span className="form-control-feedback text-danger">
                  {formik.errors.account}
                </span>
              </div>
              <div className="col-2">
                <button
                  size="sm"
                  className="btn btn-primary"
                  onClick={openAccount}
                >
                  + Add
                </button>
              </div>
            </div>

            {/*  */}
            <div className="mb-3">
              <label className="col-form-label fw-bold">Product Line</label>
              <div className="form-group">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={formik.values.Prod_Line}
                  name="Prod_Line"
                  onChange={formik.handleChange}
                >
                  {productLine.map((a, i) => (
                    <option key={i} value={a}>
                      {a}
                    </option>
                  ))}
                </select>
                <span className="form-control-feedback text-danger">
                  {formik.errors.Prod_Line}
                </span>
              </div>
            </div>
            {/*  */}
            <div className="mb-3">
              <label className="col-form-label fw-bold">Storage Bins</label>
              <div className="form-group">
                <CreatableSelect
                  components={components}
                  inputValue={inputValue}
                  isClearable
                  isMulti
                  menuIsOpen={false}
                  onChange={(newValue) => setValue(newValue)}
                  onInputChange={(newValue) => setInputValue(newValue)}
                  onKeyDown={handleKeyDown}
                  placeholder="Type something and hit enter..."
                  value={value}
                />
                <span className="form-control-feedback text-danger">
                  {formik.errors.storage_bins}
                </span>
              </div>
            </div>
            {/*  */}
          </div>
        </div>
      </Modal.Body>
      <div className="d-inline-flex justify-content-end m-4">
        <button className="btn bg print mx-4" onClick={props.onHide}>
          Cancel
        </button>
        <button
          type="submit"
          className="btn btn-primary"
          onClick={formik.handleSubmit}
        >
          Save Plant
        </button>
      </div>
      <ModalLoader show={isFetching || saveWarehouse.isLoading} />
      <AddAccounts
        onHide={() => setOpenAccountModal(false)}
        show={openAccountModal}
        warehouse={formik.values.W_name}
        setAccountReturn_WH={setAccountReturn_WH}
        refetch={refetch}
      />
    </Modal>
  );
};

export default CreatePlantModal;
