import { Modal, Form, Button } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import "./../../assets/scss/new-entity-modal.scss";
import Select from "react-select";
import DatePickerCustomInput from "../utils/DatePickerCustomInput";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import { useFormik, getIn } from "formik";
import * as yup from "yup";
import Datetime from "react-datetime";
import { useMutation, useQuery } from "react-query";
import { services } from "../../config";
import { toast } from "react-toastify";
import LoginDialog from "../LoginDialog";
import { useState } from "react";
import moment from "moment";
import { useAuth } from "../../hooks/useAuth";
import { LockIcon } from "../Icons";
import { queryActions } from "../../utils/reactQueryActions";

export default function NewEmployeeModal({
  showCreateNewEmployeeModal,
  setShowCreateNewEmployeeModal,
  setSelectedEmployee,
  refetch,
}) {
  const { backendUrl } = useAuth();
  const formik = useFormik({
    initialValues: {
      EmployeeID: String(Date.now()),
      Title: "",
      LastName: "",
      FirstName: "",
      MiddleName: "",
      ContactAddress: "",
      Email: "",
      ModifiedDate: moment(),
      HireDate: moment(),
      Department: "Sales",
      Gender: "male",
      PhoneNumber: "",
    },
    validationSchema: yup.object().shape({
      EmployeeID: yup.string().required("required"),
      FirstName: yup.string().required("required"),
      Department: yup.string().required("required"),
    }),
    onSubmit: (values) => {
      const { ModifiedDate, HireDate } = values;
      if (typeof ModifiedDate === "string") {
        formik.setFieldError("ModifiedDate", "Invalid date");
      }

      if (typeof HireDate === "string") {
        formik.setFieldError("HireDate", "Invalid date");
      }

      submit({
        ...values,
        ModifiedDate: ModifiedDate,
        HireDate: HireDate,
      });
    },
  });

  const createEmployee = async (payload) => {
    let response = await fetch(`${backendUrl}/api/employees/create`, {
      method: "POST",
      // credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const createEmployeeMutation = useMutation(
    (payload) => createEmployee(payload),
    {
      onSuccess: ({ message, data }) => {
        toast.success(message);

        if (refetch) refetch();
        formik.resetForm();
        setShowCreateNewEmployeeModal(false);
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const submit = (payload) => {
    createEmployeeMutation.mutate(payload);
  };

  const getDepartments = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/users/departments`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };
  const { data: { departments } = { departments: null } } = useQuery(
    [queryActions.GET_DEPARTMENTS],
    () => getDepartments(),
    {}
  );

  return (
    <Modal
      show={showCreateNewEmployeeModal}
      onHide={() => setShowCreateNewEmployeeModal(false)}
      dialogClassName="item-select-modal edit"
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1>Add Employee</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          noValidate
          onSubmit={formik.handleSubmit}
          className=" pb-2"
          autoComplete="off"
        >
          <div className="">
            <Form.Group className="mb-3 pb-2">
              <Form.Label className="mb-2">Employee ID</Form.Label>
              <Form.Control
                type="text"
                placeholder="xxxxxx"
                name="EmployeeID"
                value={formik.values.EmployeeID}
                style={{ pointerEvents: "none" }}
              />
            </Form.Group>

            <Form.Group className="mb-3 pb-2">
              <Form.Label className="mb-1">Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Title"
                name="Title"
                value={formik.values.Title}
                onChange={formik.handleChange}
                isInvalid={formik.touched.Title && !!formik.errors.Title}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.Title}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3 pb-2">
              <Form.Label className="mb-1">FirstName</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter FirstName"
                name="FirstName"
                value={formik.values.FirstName}
                onChange={formik.handleChange}
                isInvalid={
                  formik.touched.FirstName && !!formik.errors.FirstName
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.FirstName}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3 pb-2">
              <Form.Label className="mb-1">MiddleName</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Contact Name"
                name="MiddleName"
                value={formik.values.MiddleName}
                onChange={formik.handleChange}
                isInvalid={
                  formik.touched.MiddleName && !!formik.errors.MiddleName
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.MiddleName}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3 pb-2">
              <Form.Label className="mb-1">Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Last Name"
                name="LastName"
                value={formik.values.LastName}
                onChange={formik.handleChange}
                isInvalid={formik.touchedLastName && !!formik.errors.LastName}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.LastName}
              </Form.Control.Feedback>
            </Form.Group>

            <div className="row">
              <></>
              <Form.Group className="col-md-6 mb-4">
                <Form.Label className="login-label">Gender</Form.Label>
                <br />
                <Form.Check
                  type="radio"
                  label="Male"
                  name="Gender"
                  value="male"
                  checked={formik.values.Gender === "male"}
                  inline
                  onChange={formik.handleChange}
                />
                <Form.Check
                  type="radio"
                  label="Female"
                  value="female"
                  name="Gender"
                  checked={formik.values.Gender === "female"}
                  inline
                  onChange={formik.handleChange}
                />
              </Form.Group>
              {/*  */}
            </div>

            <div className="row">
              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Email Address</Form.Label>
                <Form.Control
                  className=""
                  type="email"
                  placeholder="Enter email"
                  name="Email"
                  value={formik.values.Email}
                  onChange={formik.handleChange}
                  isInvalid={formik.touched.Email && !!formik.errors.Email}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.Email}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Phone Number</Form.Label>
                <Form.Control
                  className=""
                  type="tel"
                  placeholder="(555) xxxx xxxx"
                  name="PhoneNumber"
                  value={formik.values.PhoneNumber}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.PhoneNumber && !!formik.errors.PhoneNumber
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.PhoneNumber}
                </Form.Control.Feedback>
              </Form.Group>
            </div>

            <div className="row">
              <Form.Group className="col-12 mb-3">
                <Form.Label>Department</Form.Label>
                <Form.Select
                  name="Department"
                  value={formik.values.Department}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.Department && !!formik.errors.Department
                  }
                >
                  <option value="">Select Recipient's Department</option>
                  {departments &&
                    departments.map((el, index) => (
                      <option key={index} value={el.Department}>
                        {el.Department}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </div>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Contact Address</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter Contact Address"
                name="ContactAddress"
                rows={5}
                value={formik.values.ContactAddress}
                onChange={formik.handleChange}
                isInvalid={
                  formik.touched.ContactAddress &&
                  !!formik.errors.ContactAddress
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.ContactAddress}
              </Form.Control.Feedback>
            </Form.Group>

            <div className="row">
              <Form.Group className="col-6 mb-3">
                <Form.Label htmlFor="HireDate">Hire Date</Form.Label>
                <Datetime
                  dateFormat="MMM DD, YYYY"
                  timeFormat={false}
                  closeOnSelect={true}
                  closeOnClickOutside={true}
                  name="HireDate"
                  inputProps={{
                    className: `date-input form-control ${
                      formik.touched.HireDate && !!formik.errors.HireDate
                        ? "is-invalid"
                        : ""
                    }`,
                    placeholder: "Select date",
                    readOnly: true,
                  }}
                  value={formik.values.HireDate}
                  onChange={(date) => {
                    formik.setFieldValue("HireDate", date, true);
                  }}
                  onBlur={() => formik.setFieldTouched("BOB", true)}
                />
                {formik.touched.HireDate && !!formik.errors.HireDate ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.HireDate}
                  </span>
                ) : null}
              </Form.Group>

              <Form.Group className="col-6 mb-3">
                <Form.Label htmlFor="ModifiedDate">Date</Form.Label>
                <Datetime
                  dateFormat="MMM DD, YYYY"
                  timeFormat={false}
                  closeOnSelect={true}
                  closeOnClickOutside={true}
                  name="ModifiedDate"
                  inputProps={{
                    className: `date-input form-control ${
                      formik.touched.ModifiedDate &&
                      !!formik.errors.ModifiedDate
                        ? "is-invalid"
                        : ""
                    }`,
                    placeholder: "Select date",
                    readOnly: true,
                  }}
                  value={formik.values.ModifiedDate}
                  onChange={(date) => {
                    formik.setFieldValue("ModifiedDate", date, true);
                  }}
                  onBlur={() => formik.setFieldTouched("BOB", true)}
                />
                {formik.touched.ModifiedDate && !!formik.errors.ModifiedDate ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.ModifiedDate}
                  </span>
                ) : null}
              </Form.Group>
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          type="submit"
          className="w-100 submit-btn mt-3 py-2"
          disabled={createEmployeeMutation.isLoading}
          onClick={() => formik.submitForm()}
        >
          {createEmployeeMutation.isLoading
            ? "Please wait..."
            : "Create Employee"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
