import { isEmpty } from "lodash";
import { Modal, Table, Button, Form, FormCheck } from "react-bootstrap";
import { useBackendUrl } from "../../utils/hooks";
import { FieldArray, FormikProvider, useFormik } from "formik";
import NoTableItem from "../utils/NoTableItem";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";

export default function AssignGroupModal({
  showAssignGroupModal = true,
  setShowAssignGroupModal,
  selectedStaffToAssign,
}) {
  const backendUrl = useBackendUrl();

  const createGroups = async (payload) => {
    let response = await fetch(
      `${backendUrl}/api/customers/create-group-for-staff`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const createGroupsMutation = useMutation((payload) => createGroups(payload), {
    onSuccess: ({ message }) => {
      toast.success("Success");
      formik.resetForm();
      refetch();
      setShowAssignGroupModal(false);
    },
    onError: ({ message = "" }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });

  const formik = useFormik({
    initialValues: {
      Staff_ID: selectedStaffToAssign.Staff_ID,
      groups: [],
    },
    onSubmit: async (values) => {
      // console.log(values);
      createGroupsMutation.mutate({
        ...values,
        groups: values.groups
          .filter((el) => el?.selected)
          .map((el) => ({
            Group: el.Group,
            Staff_ID: selectedStaffToAssign.Staff_ID,
          })),
      });
    },
  });

  const getGroups = async () => {
    let response = await fetch(
      `${backendUrl}/api/customers/group?Staff_ID=${
        selectedStaffToAssign.Staff_ID
      }`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    if (data.groups) {
      formik.setFieldValue("groups", data.groups);
    }
    return data;
  };

  const { refetch, isFetching, isSuccess } = useQuery(
    ["GET_BUISINESS_GroupS"],
    () => getGroups(),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  return (
    <Modal
      show={showAssignGroupModal}
      onHide={() => setShowAssignGroupModal(false)}
      centered={true}
      animation={false}
      enforceFocus={false}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 className="h6">
            Assign Customer Group to {selectedStaffToAssign?.Name}
          </h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormikProvider value={formik}>
          <Form noValidate onSubmit={formik.handleSubmit}>
            <FieldArray
              name="groups"
              render={(arrayHelpers) => (
                <Table responsive borderless striped className="product-table">
                  <thead>
                    <tr>
                      <th>Select</th>
                      <th>Group</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formik.values.groups.map((el, index) => (
                      <tr key={index}>
                        <td>
                          <FormCheck
                            name={`groups[${index}.selected]`}
                            checked={formik.values.groups[index].selected}
                            onChange={(e) => {
                              formik.setFieldValue(
                                `groups[${index}].selected`,
                                e.target.checked
                              );
                            }}
                          />
                        </td>
                        <td>{el.Group}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            />

            {!isFetching && isSuccess && isEmpty(formik.values.groups) ? (
              <div>...</div>
            ) : null}

            <div className="d-flex justify-content-between">
              <div />
              <Button
                disabled={isFetching || createGroupsMutation.isLoading}
                variant="primary"
                className="w-100 p-2"
                type="submit"
              >
                {isFetching || createGroupsMutation.isLoading
                  ? "Please wait…"
                  : "Assign"}
              </Button>
            </div>
          </Form>
        </FormikProvider>
      </Modal.Body>
    </Modal>
  );
}
