import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useAuth } from "../../hooks/useAuth";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useEffect, useMemo, useState } from "react";
import useDebounce from "../../utils/hooks";
import { useQuery } from "react-query";
import queryString from "query-string";
import { truncate } from "lodash";
import Select from "react-select";
import { formatDate } from "../../utils/helpers";
import { isEmpty } from "lodash";
import { format, parse } from "date-fns";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import { Form } from "react-bootstrap";
import ChartBarIcon from "mdi-react/ChartBarIcon";
import currency from "currency.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const selectOptions = [
  {
    label: "Quantity",
    value: "QTY",
  },
  {
    label: "Profitability",
    value: "Profit",
  },
  {
    label: "Revenue",
    value: "SubTotal",
  },
  {
    label: "State",
    value: "State",
  },
  {
    label: "City",
    value: "LGA",
  },
];

export function SalesAnalysisChart({
  showSalesAnalysisChart,
  setShowSalesAnalysisChart,
  dateRange,
}) {
  const initialFilterParams = {
    sortBy: "QTY",
    ...dateRange,
  };
  const { backendUrl } = useAuth();
  const [show, setShow] = useState(false);
  const [queryParams, setQueryParams] = useState({
    ...initialFilterParams,
  });
  const debouncedqueryParams = useDebounce(queryParams, 800);

  const getRevenue = async (debouncedqueryParams) => {
    let response = await fetch(
      `${backendUrl}/api/invexcloud/sold-product-by-qty?${queryString.stringify(
        debouncedqueryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const { error, data, isSuccess, refetch, isFetching } = useQuery(
    ["GET_REVENUE", debouncedqueryParams, backendUrl],
    () => getRevenue(debouncedqueryParams),
    {
      // enabled: false,
      // cacheTime: 0,
      onSuccess: (data) => {
        // queryClient.removeQueries("LOGIN");
      },
    }
  );

  useEffect(() => {
    console.log(error);
  }, [error]);

  const filterByDateRange = (date) => {
    setQueryParams({
      ...queryParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setQueryParams({
      ...queryParams,
      startDate: "",
      endDate: "",
    });
  };

  return (
    <Modal
      show={showSalesAnalysisChart}
      onHide={() => setShowSalesAnalysisChart(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="h5">
          <ChartBarIcon /> Sales Analysis
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-between gap-2">
          <div>
            {" "}
            <Form.Group className="mb-2-5 d-flex align-items-center gap-2">
              <div className="position-relative">
                <RsDateRangePicker
                  placement="auto"
                  value={
                    queryParams.startDate && queryParams.endDate
                      ? [
                          parse(
                            queryParams.startDate,
                            "yyyy-MM-dd",
                            new Date()
                          ),
                          parse(queryParams.endDate, "yyyy-MM-dd", new Date()),
                        ]
                      : []
                  }
                  onClean={() => clearDateRange()}
                  onOk={(date) => filterByDateRange(date)}
                />
              </div>
            </Form.Group>
          </div>
          <div className="d-flex align-items-center gap-2">
            Sort By
            <Select
              classNamePrefix={`form-select-analytics`}
              isSearchable={false}
              options={selectOptions}
              value={selectOptions.find(
                (el) => el.value === queryParams.sortBy
              )}
              onChange={(selected) => {
                setQueryParams({
                  ...queryParams,
                  sortBy: selected?.value,
                });
              }}
            />
          </div>
        </div>
        <Bar
          options={{
            //  responsive: true,
            plugins: {
              legend: {
                position: "bottom",
                labels: {
                  fontColor: "#94A3B8",
                  fontSize: 12,
                  boxHeight: 8,
                  boxWidth: 8,
                  pointStyle: "circle",
                  usePointStyle: true,
                },
              },
              title: {
                display: false,
              },
              tooltip: {
                backgroundColor: "#00000080",
                boxHeight: 8,
                boxWidth: 8,
                pointStyle: "circle",
                usePointStyle: true,
                boxPadding: 8,
              },
            },

            scales: {
              y: {
                grid: {
                  color: "#F4F7FA",
                },
                ticks: {
                  color: "#94A3B8",
                  stepSize: 100000000,
                  fontSize: 12,
                  callback: function(value, index, values) {
                    if (["Profit", "SubTotal"].includes(queryParams.sortBy)) {
                      return currency(value, {
                        symbol: "₦",
                      }).format();
                    }
                  },
                },
              },
              x: {
                grid: {
                  color: "#fff",
                },
                ticks: {
                  color: "#94A3B8",
                  fontSize: 12,
                },
              },
            },
          }}
          data={
            queryParams.sortBy === "State" || queryParams.sortBy === "LGA"
              ? {
                  labels: [
                    ...(isSuccess
                      ? data?.items.map((el) =>
                          el?.State ? el?.State : el.LGA
                        )
                      : []),
                  ],
                  datasets: [
                    {
                      label: "Invoices",
                      data: [
                        ...(isSuccess ? data?.items.map((el) => el.count) : []),
                      ],
                      backgroundColor: "#2463AE",
                      borderRadius: 100,
                      barThickness: 16,
                    },
                  ],
                }
              : {
                  labels: [
                    ...(isSuccess
                      ? data?.items.map((el) => truncate(el.Item_Name))
                      : []),
                  ],
                  datasets: [
                    {
                      label: "Sold Items",
                      data: [
                        ...(isSuccess
                          ? data?.items.map((el) => el[queryParams.sortBy])
                          : []),
                      ],
                      backgroundColor: "#2463AE",
                      borderRadius: 100,
                      barThickness: 16,
                    },
                  ],
                }
          }
        />
      </Modal.Body>
    </Modal>
  );
}
