import { Button, Table } from "react-bootstrap";
import PaymentAccountDialog from "../PaymentAccountDialog";
import { useIsCashier } from "../../utils/hooks";
import { useAuth } from "../../hooks/useAuth";
import { isEmpty, uniq } from "lodash";
import { useMutation, useQuery } from "react-query";
import { queryActions } from "../../utils/reactQueryActions";
import { toast } from "react-toastify";
import currency from "currency.js";
import { useState } from "react";
import { useMemo } from "react";
import { last } from "lodash";
import { useNavigate } from "react-router-dom";
import { uniqBy } from "lodash";
import { Fragment } from "react";
import ApprovalBadge from "../utils/ApprovalBadge";
import { formatDate } from "../../utils/helpers";

export default function AllowanceRequest({ requisition }) {
  const isCashier = useIsCashier();
  const navigate = useNavigate();
  const { backendUrl, token, user: authUser } = useAuth();

  const postAllowance = async (payload) => {
    let response = await fetch(
      `${backendUrl}/api/requisition/pay-allowance/${requisition.requestid}`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const postAllowanceMutation = useMutation(
    (payload) => postAllowance(payload),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  const fetchSetUpData = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/invoice/set-up`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    data.vendors = [
      {
        value: "",
        label: "One-time vendor",
      },
      ...data?.vendors.map((el) => ({
        label: el.CompanyName,
        value: el.Vendor_ID,
      })),
    ];

    data.banks = data?.banks
      .filter((el) => el?.BankName && el.currency !== "USD")
      .map((el) => ({
        label: el.BankName,
        value: el.BankName,
      }));

    data.branch = [
      {
        value: "General",
        label: "General",
      },
    ];

    data.imprest = data?.imprest
      .filter((el) => el?.Description)
      .map((el) => ({
        ...el,
        label: el?.Description,
        value: el?.Description,
      }));

    return data;
  };

  const {
    data = { banks: [], vendors: [], branch: [], imprest: [] },
    isLoading,
  } = useQuery([queryActions.INVOICE_SETUP], () => fetchSetUpData(), {
    keepPreviousData: true,
  });

  const postPayment = async () => {
    const formValues = await PaymentAccountDialog({
      data,
      previouslySelectedAccount: {
        By: "",
        From: "",
      },
      payFromLabel: "From",
      payByLabel: "By",
      amount: 0,
      hasRemark: true,
    });

    if (formValues && !isEmpty(formValues)) {
      postAllowanceMutation.mutate({
        ...formValues,
        By: formValues.reimburseBy,
        From: formValues.reimburseFrom,
      });
    }

    // console.log(formValues);
  };

  const postExpense = ({ job, allowances }) => {
    navigate("/expenses-entry", {
      state: {
        expenses: allowances.map((el) => ({
          ...el,
          Particulers: el.description,
          Amount: currency(el?.amount).value,
        })),
        job,
        Vendor_ID: "",
        requisitionApprovedBy: last(requisition?.requisitiontracks)
          .receivedbyUser.Name,
        requisitionTitle: requisition?.title,
        currency: "NGN",
        requestid: requisition.requestid,
      },
    });
  };

  const allowanceData = useMemo(() => {
    const requestData = requisition?.loanDetails
      ? JSON.parse(requisition?.loanDetails)
      : {};

    requestData.allowancesByJob = uniqBy(requestData.details, "job").map(
      (el) => ({
        jobNumber: el.job,
        title: el.tittle,
        paymentStatus: el.paymentStatus,
        allowances: requestData.details.filter(
          (detail) => detail.job === el.job
        ),
      })
    );

    return { ...requestData };
  }, [requisition]);

  return (
    <div className="rounded mb-3">
      {/* 
      {JSON.stringify(allowanceData?.allowancesByJob)} */}
      {allowanceData?.allowancesByJob &&
        allowanceData?.allowancesByJob.map((el, index) => (
          <Fragment key={index}>
            <div className="d-flex justify-content-between">
              <div>
                <p className="mb-3">
                  <span>Job Number:</span> {el.job}
                </p>
                <p className="mb-3 fw-6">
                  <span>Job Title:</span> {el.title}
                </p>
              </div>
              {requisition?.status.includes("Approved & Closed") && (
                <div>
                  {el?.paymentStatus === "Paid" ? (
                    <ApprovalBadge
                      text={"Paid"}
                      className="approval"
                      bg="success"
                    />
                  ) : isCashier ? (
                    <Button
                      variant="light-blue"
                      onClick={() =>
                        postExpense({
                          job: {
                            title: el.title,
                            jobNumber: el.jobNumber,
                          },
                          allowances: el.allowances,
                        })
                      }
                    >
                      Post Payment
                    </Button>
                  ) : null}
                </div>
              )}
            </div>
            <Table key={index} className="product-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Allowance</th>
                  <th>Job Done</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {el.allowances.map((detail, index) => (
                  <tr key={index}>
                    <td>
                      {detail?.overtimeDate
                        ? formatDate(detail?.overtimeDate)
                        : "..."}{" "}
                      -{" "}
                      {detail?.overtimeDateTo
                        ? formatDate(detail?.overtimeDateTo)
                        : "..."}
                    </td>
                    <td>{detail.name}</td>
                    <td>{detail.description}</td>
                    <td>
                      {currency(detail.amount, {
                        symbol: "",
                      }).format()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Fragment>
        ))}
    </div>
  );
}
