import { Dropdown, Form, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { Link } from "react-router-dom";
import "./../../assets/scss/scoped/invoicelist.scoped.scss";
import useDebounce, { useQueryParams, useScrollTop } from "../../utils/hooks";
import Select from "react-select";
import CachedIcon from "mdi-react/CachedIcon";
import SalesByInvoice from "../Reports/SalesByInvoice";
import Invoices from "../Reports/Invoices";
import { useStoreState } from "easy-peasy";
import { IsPrivileged } from "../DisplayChildElement";

export function InvoiceList() {
  const generalSettings = useStoreState((state) => state.generalSettings);

  // isprivileged
  return (
    <IsPrivileged
      roleName={["Sale by Invoice", "Invoice List", "Sales and Invoicing"]}
    >
      <section className="cashbook-wrapped">
        {generalSettings.Item_Type !== "Service" ? (
          <SalesByInvoice />
        ) : (
          <Invoices />
        )}
      </section>
    </IsPrivileged>
  );
}
