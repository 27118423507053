import { isEmpty, uniqBy } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import Card from "react-bootstrap/Card";
import { Table, Button } from "react-bootstrap";

import { useQueries, useQuery } from "react-query";
import ReactPaginate from "react-paginate";
import { useLocalStorage, useQueryParams } from "../../utils/hooks";
import { paginationOptions } from "../../utils/helpers";
import ViewGridIcon from "mdi-react/ViewGridIcon";
import FormatListBulletedIcon from "mdi-react/FormatListBulletedIcon";
const distributors = [
  {
    name: "TRACEVISION",
    warehouse: 1,
    shop: 1,
    logo: `https://tracevision-api.invexone.com/images/company-logo.png`,
    usersBackendApi: [
      `http://localhost:5002/api/users`,
      `http://localhost:5002/api/users`,
    ],
    addressBackendApi: [
      `http://localhost:5002/api/users/company`,
      `http://localhost:5002/api/users/company`,
      `http://localhost:5002/api/users/company`,
    ],
  },
  {
    name: "CECALLIS",
    warehouse: 1,
    shop: 1,
    logo: `https://cecallis-api.invexone.com/images/company-logo.png`,
    usersBackendApi: [
      `http://localhost:5002/api/users`,
      `http://localhost:5002/api/users`,
      `http://localhost:5002/api/users`,
    ],
    addressBackendApi: [
      `http://localhost:5002/api/users/company`,
      `http://localhost:5002/api/users/company`,
    ],
  },
  {
    name: "MEDBURY",
    warehouse: 1,
    shop: 1,
    logo: `https://hq-api.medpharma.io/images/company-logo.png`,
    usersBackendApi: [`http://localhost:5002/api/users`],
    addressBackendApi: [`http://localhost:5002/api/users/company`],
  },
];

function DistributorListRows({
  warehouse,
  shop,
  logo,
  name,
  usersBackendApi,
  addressBackendApi,
  index,
}) {
  const fetchData = async (endpoint) => {
    try {
      const res = await fetch(endpoint);
      return await res.json();
    } catch (error) {
      throw new Error(`Failed to fetch ${endpoint}`);
    }
  };

  const addresses = useQueries(
    !isEmpty(addressBackendApi)
      ? addressBackendApi.flatMap((url) => {
          return {
            queryKey: ["url", url],
            queryFn: async () => await fetchData(url),
          };
        })
      : []
  );

  const users = useQueries(
    usersBackendApi
      ? usersBackendApi.flatMap((url) => {
          return {
            queryKey: ["url", url],
            queryFn: async () => await fetchData(url),
          };
        })
      : []
  );

  const data = useMemo(() => {
    const address = addresses
      ?.map((address) => {
        const companyAddress = address.data;
        const companyName = companyAddress?.data?.company;
        return companyName?.map((arr) => {
          return {
            AddressLine1: arr.AddressLine1,
            companyName: arr.CompName,
          };
        });
      })
      .flat();

    const user = users?.flatMap((user) => {
      const companyUsers = user?.data;
      return companyUsers?.staff;
    });

    const uniqStaff = uniqBy(user, "username")?.length;

    return {
      address,
      uniqStaff,
    };
  }, [users, addresses]);

  return (
    <>
      <tr className="p-cursor">
        <td />
        <td>{index}</td>
        <td>{name}</td>
        <td>{warehouse}</td>
        <td>{shop}</td>
        <td>{data?.uniqStaff}</td>
        <td>
          {" "}
          {data?.address?.map((address) => (
            <div className=" my-3">
              <div className=" row">
                {/*   <p className=" col-lg-3 fw-bold">Address: </p> */}
                <p className="">{address?.AddressLine1}</p>
              </div>
            </div>
          ))}
        </td>
        <td>
          {" "}
          {data?.address?.map((address) => (
            <div className=" my-3">
              <div className="  row">
                <p className=" ">{address?.companyName}</p>
              </div>
            </div>
          ))}
        </td>
      </tr>
    </>
  );
}
function DistributorCards({
  warehouse,
  shop,
  logo,
  name,
  usersBackendApi,
  addressBackendApi,
}) {
  const fetchData = async (endpoint) => {
    try {
      const res = await fetch(endpoint);
      return await res.json();
    } catch (error) {
      throw new Error(`Failed to fetch ${endpoint}`);
    }
  };

  const addresses = useQueries(
    !isEmpty(addressBackendApi)
      ? addressBackendApi.flatMap((url) => {
          return {
            queryKey: ["url", url],
            queryFn: async () => await fetchData(url),
          };
        })
      : []
  );

  const users = useQueries(
    usersBackendApi
      ? usersBackendApi.flatMap((url) => {
          return {
            queryKey: ["url", url],
            queryFn: async () => await fetchData(url),
          };
        })
      : []
  );

  const data = useMemo(() => {
    const address = addresses
      ?.map((address) => {
        const companyAddress = address.data;
        const companyName = companyAddress?.data?.company;
        return companyName?.map((arr) => {
          return {
            AddressLine1: arr.AddressLine1,
            companyName: arr.CompName,
          };
        });
      })
      .flat();

    const user = users?.flatMap((user) => {
      const companyUsers = user?.data;
      return companyUsers?.staff;
    });

    const uniqStaff = uniqBy(user, "Name")?.length;

    return {
      address,
      uniqStaff,
    };
  }, [users, addresses]);

  return (
    <div className="  col-sm-12  col-md-6 col-lg-4 col-xl-4   ">
      <Card className="company-distributor-card border-0 h-100  ">
        <Card.Body className=" px-5">
          <div className=" d-flex   align-items-center py-4 ">
            <div
              style={{
                maxWidth: "10rem",
                maxHeight: "10rem",
              }}
              className=" position-relative w-100 h-100 "
            >
              <img
                src={logo}
                alt={name}
                className="  p-2"
                style={{
                  backgroundColor: "#F7F7F7",
                  minHeight: "10rem",
                  minWidth: "10rem",
                  maxHeight: "10rem",
                  maxWidth: "10rem",
                  objectFit: "contain",
                  borderRadius: "5px",
                }}
              />
              <span
                className="company-icon position-absolute"
                style={{
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%",
                  backgroundColor: "green",
                  right: "0px",
                }}
              />
            </div>

            <div className=" mx-4">
              <p className=" fw-bold">
                Warehouse <span>{warehouse}</span>
              </p>
              <p className=" fw-bold">
                Shop: <span>{shop}</span>
              </p>
              <p className=" fw-bold">
                Users: <span>{data?.uniqStaff}</span>
              </p>
            </div>
          </div>

          {data?.address?.map((address) => (
            <div className=" my-3">
              <div className="  row">
                <p className=" col-lg-9 fw-bold">{address?.companyName}</p>
              </div>
              <div className=" row">
                {/*   <p className=" col-lg-3 fw-bold">Address: </p> */}
                <p className=" col-lg-9">{address?.AddressLine1}</p>
              </div>
            </div>
          ))}
        </Card.Body>
      </Card>
    </div>
  );
}

export default function Distribtors() {
  const [companyDistributors, setCompanyDistributors] = useState(distributors);
  const [viewType, setViewType] = useLocalStorage("grid1");

  useEffect(() => {
    if (!Boolean(viewType)) {
      setViewType("grid1");
    }
  }, []);

  return (
    <main>
      <div>
        <div className=" row mx-auto w-100  py-5" style={{ maxWidth: "98%" }}>
          <header className=" d-flex justify-content-between align-items-center ">
            <h5>Company Distributors </h5>

            <div className="d-flex gap-3">
              <Button
                variant=""
                className={`border ${
                  viewType === "grid1" || !viewType ? "bg-light-blue" : ""
                }`}
                onClick={() => setViewType("grid1")}
              >
                <ViewGridIcon />
              </Button>{" "}
              <Button
                variant=""
                className={`border ${
                  viewType === "list1" ? "bg-light-blue" : ""
                }`}
                onClick={() => setViewType("list1")}
              >
                <FormatListBulletedIcon />
              </Button>
            </div>
          </header>
        </div>
        <div style={{ backgroundColor: "#F4F7FA" }}>
          <div
            style={{
              paddingTop: "3rem",
              paddingBottom: "3rem",
              maxWidth: "98%",
            }}
            className={` row mx-auto w-100  mx-auto ${
              viewType !== "list1" ? " d-none" : ""
            }`}
          >
            <Table
              borderless
              responsive
              hover
              striped
              className="product-table"
            >
              <thead>
                <tr>
                  <th />
                  <th>S/N</th> <th>Company</th>
                  <th>Warehouse</th> <th>Shop</th> <th>Users</th>{" "}
                  <th>Address</th>
                  <th>Branch</th>
                </tr>
              </thead>
              <tbody>
                {!isEmpty(companyDistributors) &&
                  companyDistributors.map((distributor, index) => (
                    <DistributorListRows
                      key={distributor.name}
                      index={index + 1}
                      name={distributor.name}
                      warehouse={distributor.warehouse}
                      shop={distributor.shop}
                      logo={distributor.logo}
                      usersBackendApi={distributor.usersBackendApi}
                      addressBackendApi={distributor.addressBackendApi}
                    />
                  ))}
              </tbody>
            </Table>
          </div>
        </div>

        <div
          style={{
            backgroundColor: "#F4F7FA",
            paddingTop: "3rem",
            paddingBottom: "3rem",
          }}
          className={viewType !== "grid1" ? " d-none" : ""}
        >
          <div
            className="   d-flex flex-column justify-content-center  align-items-center  mx-auto"
            style={{ maxWidth: "98%" }}
          >
            <div className=" row mx-auto w-100 ">
              {!isEmpty(companyDistributors) &&
                companyDistributors.map((distributor) => (
                  <DistributorCards
                    key={distributor.name}
                    name={distributor.name}
                    warehouse={distributor.warehouse}
                    shop={distributor.shop}
                    logo={distributor.logo}
                    usersBackendApi={distributor.usersBackendApi}
                    addressBackendApi={distributor.addressBackendApi}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
