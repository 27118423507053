import { Modal, Form, Button } from "react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import { useState } from "react";
import { confirmable, createConfirmation } from "react-confirm";
import { useBackendUrl, useEffectOnce } from "../../utils/hooks";
import NumberCustomInput from "./../utils/NumberCustomInput";
import CurrencyCustomInput from "./../utils/CurrencyCustomInput";
import { qtyFormat, Units } from "../../utils/helpers";
import { appSettings } from "../../config";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import { useStoreActions, useStoreState } from "easy-peasy";
import Select from "react-select";
import ItemSizeDialog from "./../ItemSizeDialog";
import { useEffect } from "react";
import currency from "currency.js";
import { allAcountDetailIDs, fetchActionsUtil } from "../../utils/helpers";

export default function UpdateWarehouseItemPriceModal({
  showUpdatePriceModal,
  selectedItem,
  setSelectedItem,
  setShowUpdatePriceModal,
  refetch,
}) {
  const backendUrl = useBackendUrl();

  const [accountsDetails, setaccountsDetails] = useState([]);
  const [accountses, setAccountses] = useState([]);
  const [accountDescribtion, setAccountDescribtion] = useState(null);
  const [prevType, setPrevType] = useState("");

  const itemMeasurements = useStoreState((state) =>
    state.itemMeasurements.map((el) => ({ label: el.Size, value: el.Size }))
  );

  const setItemMeasurements = useStoreActions(
    (actions) => actions.setItemMeasurements
  );

  const wareshouseLocal = JSON.parse(
    window.localStorage.getItem("warehouseName")
  );

  //   console.log(selectedItem);

  const initialValues = {
    UnitPrice: 0, // walk / retail customer
    Vat_5: 0, // distributor
    Tax: 0, // unit price in pieces in gigc, wholesale in normal invex
    Unit: "Each",
    Size: "",

    //--------------
    Margin: 0,
    Incentive: 0,
    UnitCost: 0,
    qty: 0,

    //-------------
    pcsPriceDistributor: 0,

    accountSelect: "",
    accountName: "",
    savingType: "",
    DetailType: "",
    Item_Name: "",
  };
  const schema = yup.object().shape({
    UnitPrice: yup.string().required("required"),
    Tax: yup.string().required("required"),
    Vat_5: yup.string().required("required"),
    qty: yup.string().required("required"),
    Item_Name: yup.string().required("required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values) => {
      if (wareshouseLocal?.cater === "Assets") {
        if (!values.accountSelect) {
          return formik.setFieldError("accountSelect", `Select Account`);
        }
        if (!values.DetailType) {
          return formik.setFieldError(
            "DetailType",
            `Select Account Detail Type`
          );
        }
        if (!values.accountName) {
          return formik.setFieldError("accountName", `Map to account`);
        }
      }

      updatePriceMutation.mutate({
        ...values,
        Warehouse: selectedItem.Warehouse,
        Bar_Code: selectedItem.Bar_Code,
      });
    },
  });

  const getAccountDetailType = async (desc) => {
    const acc = await fetchActionsUtil(
      `${backendUrl}/api/accounts/accountDetailType/${desc}`,
      "GET"
    );

    const account = acc.accountDes.map((el) => ({
      value: el.AccountID,
      label: el.Description,
    }));

    setAccountses(account);
  };

  const setUp = async () => {
    if (wareshouseLocal?.cater === "Assets") {
      const detailsAccount = await fetchActionsUtil(
        `${backendUrl}/api/accounts/accountDesc/FIXED ASSET`,
        "GET"
      );
      const details = [
        ...new Set(detailsAccount?.accountDes.map((d) => d.AccountDesc)),
      ].map((el) => ({
        value: el,
        label: el,
      }));
      setaccountsDetails(details);
      setAccountDescribtion(selectedItem?.accountListAssets?.Description);
      selectedItem?.DetailType &&
        getAccountDetailType(selectedItem?.DetailType);
      setPrevType(selectedItem?.accountSelect);
    }

    formik.setValues({
      UnitPrice: selectedItem?.UnitPrice,
      Tax: selectedItem?.Tax,
      Vat_5: selectedItem?.Vat_5,
      Unit: selectedItem?.Product_Model,
      Size: selectedItem?.Item_Desc,
      qty: selectedItem?.Quantity,

      //----------------
      Margin: selectedItem?.Margin,
      Incentive: selectedItem?.Incentive,
      UnitCost: selectedItem?.UnitCost,
      //-----------------
      pcsPriceDistributor: selectedItem?.pcsPriceDistributor || 0,

      accountSelect: selectedItem?.accountSelect,
      accountName: selectedItem?.accountName,
      savingType: selectedItem?.savingType,
      DetailType: selectedItem?.DetailType,
      Item_Name: selectedItem?.Item_Name,
    });
  };

  useEffectOnce(() => {
    setUp();
  });

  const updatePrice = async (payload) => {
    let response = await fetch(`${backendUrl}/api/warehouse/update-price`, {
      method: "POST",
      // credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const updatePriceMutation = useMutation((payload) => updatePrice(payload), {
    onSuccess: ({ message, data }) => {
      refetch();
      toast.success(message);
      setSelectedItem(null);
      setShowUpdatePriceModal(false);
    },
    onError: () => {
      toast.error(`Unable to perform action`);
    },
  });

  const addItemMeasurement = async () => {
    const newSize = await ItemSizeDialog();
    if (newSize) {
      formik.setFieldValue("Size", newSize.Size);
      setItemMeasurements([...itemMeasurements, newSize]);
    }
  };

  const getUniqueAccountId = async (name) => {
    let rangers = allAcountDetailIDs.filter((item) => {
      if (item.data.some((d) => d === name)) {
        return [item.num1, item.num2];
      }
    });
    let id = "";
    const res = await fetchActionsUtil(
      `${backendUrl}/api/accounts/generate-account-id`,
      "POST",
      "",
      { num1: rangers[0].num1, num2: rangers[0].num2 }
    );
    id = res.id;

    if (id === "") {
      getUniqueAccountId(name);
    } else {
      return id;
    }
  };

  const chooseCreateAccount = async () => {
    formik.setFieldValue("accountSelect", "CreateAccount");
    let id = await getUniqueAccountId("FIXED ASSET");
    formik.setFieldValue("accountName", id);
  };

  const chooseMapToAccount = async () => {
    formik.setFieldValue("accountSelect", "MapToAccount");
    formik.setFieldValue("accountName", "");
  };

  //Incentive & Margin
  //   useEffect(() => {
  //     if (appSettings.hasIncentive) {
  //       const UnitPrice = currency(formik.values.UnitCost)
  //         .add(formik.values.Incentive)
  //         .add(formik.values.Margin).value;

  //       formik.setFieldValue("UnitPrice", UnitPrice);
  //       formik.setFieldValue("Tax", UnitPrice);
  //       formik.setFieldValue("Vat_5", UnitPrice);
  //     }
  //   }, [formik.values.UnitCost, formik.values.Margin, formik.values.Incentive]);

  const showAccountSections = () => {
    let show = null;
    if (accountDescribtion) {
      if (prevType === "MapToAccount") {
        show = (
          <Form.Check
            inline
            label="Create Account"
            value="CreateAccount"
            name="accountSelect"
            type="radio"
            onChange={chooseCreateAccount}
            checked={formik.values?.accountSelect === "CreateAccount"}
            isInvalid={
              formik.touched.accountSelect && !!formik.errors.accountSelect
            }
          />
        );
      } else if (prevType === "CreateAccount") {
        show = (
          <Form.Check
            inline
            label={
              accountDescribtion ? "Map To Different Account" : "Map To Account"
            }
            value="MapToAccount"
            name="accountSelect"
            type="radio"
            checked={formik.values?.accountSelect === "MapToAccount"}
            onChange={chooseMapToAccount}
            isInvalid={
              formik.touched.accountSelect && !!formik.errors.accountSelect
            }
          />
        );
      }
    } else {
      show = (
        <>
          <Form.Check
            inline
            label={
              accountDescribtion ? "Map To Different Account" : "Map To Account"
            }
            value="MapToAccount"
            name="accountSelect"
            type="radio"
            checked={formik.values?.accountSelect === "MapToAccount"}
            onChange={chooseMapToAccount}
            isInvalid={
              formik.touched.accountSelect && !!formik.errors.accountSelect
            }
          />
          <Form.Check
            inline
            label="Create Account"
            value="CreateAccount"
            name="accountSelect"
            type="radio"
            onChange={chooseCreateAccount}
            checked={formik.values?.accountSelect === "CreateAccount"}
            isInvalid={
              formik.touched.accountSelect && !!formik.errors.accountSelect
            }
          />
        </>
      );
    }

    return show;
  };

  return (
    <Modal
      show={showUpdatePriceModal}
      onHide={() => {
        setSelectedItem(null);
        setShowUpdatePriceModal(false);
      }}
      centered={true}
      animation={false}
      enforceFocus={false}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 className="h6">Edit {selectedItem?.Item_Name}</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate onSubmit={formik.handleSubmit}>
          {wareshouseLocal?.cater === "Assets" ? (
            <>
              <Form.Group className="mb-3 pb-2">
                <Form.Label className="mb-3">Account</Form.Label>
                <div className="d-flex gap-3 justify-content-between w-100">
                  {accountDescribtion ? (
                    <div>
                      <span>
                        {" "}
                        <b>Asset Account :</b>{" "}
                      </span>{" "}
                      <span>{accountDescribtion}</span>
                    </div>
                  ) : null}

                  {showAccountSections()}
                </div>
              </Form.Group>
              {/*  */}
              <div className="row">
                <Form.Group className="col-6 mb-3">
                  <Form.Label className="mb-1">Account Detail Type</Form.Label>
                  <Select
                    classNamePrefix={"form-select"}
                    value={accountsDetails?.find(
                      (el) => el.value === formik.values.DetailType
                    )}
                    onChange={({ value }) => {
                      formik.setFieldValue("DetailType", value);
                      getAccountDetailType(value);
                    }}
                    options={accountsDetails}
                  />
                  {formik.touched.DetailType && !!formik.errors.DetailType ? (
                    <p className="custom-invalid-feedback">
                      {formik.errors.DetailType}
                    </p>
                  ) : null}
                </Form.Group>

                {formik.values.accountSelect === "CreateAccount" ? null : (
                  <div className="mb-3 col-6">
                    <Form.Label className="mb-1">Map to GL account</Form.Label>
                    <div className="form-group">
                      <Select
                        closeMenuOnSelect={true}
                        value={accountses?.find(
                          (el) => el.value === formik.values.accountName
                        )}
                        onChange={(selected) => {
                          formik.setFieldValue("accountName", selected.value);
                        }}
                        options={accountses}
                      />
                      <span className="form-control-feedback text-danger">
                        {formik.errors.accountName}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : null}
          {/*  */}

          <Form.Group className="mb-3 pb-2">
            <Form.Label className="mb-1">Item Name</Form.Label>
            <Form.Control
              className=""
              type="text"
              placeholder="Enter Item Name"
              name="Item_Name"
              value={formik.values.Item_Name}
              onChange={formik.handleChange}
              isInvalid={formik.touched.Item_Name && !!formik.errors.Item_Name}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.Item_Name}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>UnitCost</Form.Label>
            <CurrencyCustomInput
              type="text"
              placeholder="Enter Unit Cost"
              name="UnitCost"
              value={formik.values.UnitCost}
              onValueChange={(value, name) => {
                formik.setFieldValue(name, value);
              }}
              isInvalid={formik.touched.UnitCost && !!formik.errors.UnitCost}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.UnitCost}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>
              {selectedItem?.Product_Model === "Tons"
                ? "Tonage Price"
                : `UnitPrice (Retail / Walk In)`}
            </Form.Label>

            <CurrencyCustomInput
              type="text"
              name="UnitPrice"
              value={formik.values.UnitPrice}
              onValueChange={(value, name) => {
                formik.setFieldValue(name, value);
              }}
              isInvalid={formik.touched.UnitPrice && !!formik.errors.UnitPrice}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.UnitPrice}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>
              {selectedItem?.Product_Model !== "Tons"
                ? `UnitPrice (Wholesale)`
                : `Pieces Price`}
            </Form.Label>
            <CurrencyCustomInput
              type="text"
              name="Tax"
              value={formik.values.Tax}
              onValueChange={(value, name) => {
                formik.setFieldValue(name, value);
              }}
              isInvalid={formik.touched.Tax && !!formik.errors.Tax}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.UnitPrice}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>
              {selectedItem?.Product_Model !== "Tons"
                ? `UnitPrice (Distributor)`
                : `Tonage Price (Distributor)`}
            </Form.Label>
            <CurrencyCustomInput
              type="text"
              name="Vat_5"
              value={formik.values.Vat_5}
              onValueChange={(value, name) => {
                formik.setFieldValue(name, value);
              }}
              isInvalid={formik.touched.Vat_5 && !!formik.errors.Vat_5}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.Vat_5}
            </Form.Control.Feedback>
          </Form.Group>

          {selectedItem?.Product_Model === "Tons" && (
            <Form.Group className="mb-3">
              <Form.Label>Pieces Price (Distributor) </Form.Label>
              <CurrencyCustomInput
                type="text"
                name="pcsPriceDistributor"
                value={formik.values.pcsPriceDistributor}
                onValueChange={(value, name) => {
                  formik.setFieldValue(name, value);
                }}
                isInvalid={
                  formik.touched.pcsPriceDistributor &&
                  !!formik.errors.pcsPriceDistributor
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.pcsPriceDistributor}
              </Form.Control.Feedback>
            </Form.Group>
          )}

          <div className="row text-nowrap align-items-center">
            <Form.Group className="col-5 mb-3 pb-2">
              <Form.Label className="mb-1">Unit</Form.Label>
              <Select
                classNamePrefix={`form-select`}
                options={Units}
                value={Units.find((el) => el.value === formik.values.Unit)}
                onChange={({ value }) => {
                  formik.setFieldValue("Unit", value);
                }}
              />
              <Form.Control.Feedback type="invalid" />
            </Form.Group>

            {["Tons", "Pieces"].includes(formik.values.Unit) && (
              <>
                <Form.Group className="col-6 mb-3 pb-2">
                  <Form.Label className="mb-1">Size</Form.Label>
                  <Select
                    classNamePrefix={`form-select`}
                    options={itemMeasurements}
                    value={itemMeasurements.find(
                      (el) => el.value === formik.values.Size
                    )}
                    onChange={({ value }) => {
                      formik.setFieldValue("Size", value);
                    }}
                  />
                </Form.Group>
                <Form.Group className="col-2 pb-2 px-0">
                  <Button size="sm" onClick={() => addItemMeasurement()}>
                    + Add
                  </Button>
                </Form.Group>
              </>
            )}

            {wareshouseLocal?.cater === "Assets" ? (
              // <Form.Group className="mb-3">
              //   <Form.Label>Quantity</Form.Label>

              //   <Form.Control
              //     className=""
              //     name="qty"
              //     value={formik.values.qty}
              //     onChange={(e) => formik.setFieldValue("qty", e.target.value)}
              //     placeholder="Quantity"
              //     isInvalid={formik.touched.qty && !!formik.errors.qty}
              //   />
              //   <Form.Control.Feedback type="invalid">
              //     {formik.errors.qty}
              //   </Form.Control.Feedback>
              // </Form.Group>

              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label>Quantity</Form.Label>
                <NumberCustomInput
                  placeholder="0"
                  name="qty"
                  value={formik.values.qty}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                  isInvalid={formik.touched.qty && !!formik.errors.qty}
                  onBlur={() => formik.setFieldTouched("qty", true)}
                />
                {formik.touched.qty && !!formik.errors.qty ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.qty}
                  </span>
                ) : null}
              </Form.Group>
            ) : null}
          </div>

          <Button
            disabled={updatePriceMutation.isLoading}
            variant="primary"
            className="w-100 p-2"
            type="submit"
          >
            {updatePriceMutation.isLoading ? "Please wait…" : "Save Changes"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

/* export default function UpdateItemPriceModal(props) {
  return createConfirmation(confirmable(UpdatePrice))({ ...props });
}
 */
