import { Dropdown, Table, Form, Button, InputGroup } from "react-bootstrap";
import { CSVLink } from "react-csv";
import DetailedIcon, {
  ExportIcon,
  FilterIcon,
  FilterTwoIcon,
  BusinessBillIcon,
  PrintIcon,
  SummaryChartIconUp,
  SummaryChartIconDown,
  SummaryCloseIcon,
  FilterCollapseIcon,
  BookIcon,
  RecieptIcon,
  PageHeaderListIcon,
  ExcelIcon,
  PDFIcon,
  LinkVariantIcon,
  DeleteIcon,
} from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useMemo, useRef, useState } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import Select from "react-select";
import useDebounce, {
  useIsAdmin,
  useIsCashier,
  useIsHMB,
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
import { appSettings, services } from "../../config";
import { queryActions, reportActions } from "../../utils/reactQueryActions";
import { useQuery } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  copyText,
  customerFullName,
  defaultSelectValue,
  formatDate,
  paginationOptions,
  reactSelectTheme,
  removeTimeZone,
} from "../../utils/helpers";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import queryString from "query-string";
import { useAuth } from "../../hooks/useAuth";
import { Popover } from "react-tiny-popover";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import CustomerSelector from "../utils/CustomerSelector";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { isEmpty, lowerCase } from "lodash";
import printJS from "print-js";
import ModalLoader from "../utils/ModalLoader";
import { toast } from "react-toastify";
import NoTableItem from "../utils/NoTableItem";
import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import TableComponent from "../TableComponent";
import { Link, useNavigate } from "react-router-dom";
import { currenciesOptions } from "../../utils/currencies";
import { IsPrivileged } from "../DisplayChildElement";

const drumDocTypes = [
  {
    label: "All",
    value: "",
  },
  {
    label: "Drum Return",
    value: "Drum Return",
  },
  {
    label: "Drum Payment",
    value: "Drum Payment",
  },
];

export default function CustomerLedger({ usage = "" }) {
  useScrollTop();
  const navigate = useNavigate();
  const isAdmin = useIsAdmin();
  const isCashier = useIsCashier();
  const isHMB = useIsHMB();

  const initialFilterParams = {
    customerName: "",
    customerId: "",
    /*  startDate: format(new Date(), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"), */
    enableDateRange: false,
    requireCustomer: true,
    usage,
    DocType: "",
    currency: "NGN",
    balanceExternal: isHMB,
  };

  const { backendUrl } = useAuth();
  const [activeRow, setActiveRow] = useState(null);
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  const [btnSummryActive, setBtnSummaryActive] = useState(false);
  const [showMoreSummary, setShowMoreSummary] = useState(true);
  const [loadingPrint, setLoadingPrint] = useState(false);
  const [excelData, setExcelData] = useState([]);

  const CSVLinkRef = useRef(null);

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  const close = () => {
    setShowMoreSummary(true);
    setBtnSummaryActive(false);
  };

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 100,
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({
      ...queryParams,
      ...debouncedFilterParams,
    });
  }, [debouncedFilterParams]);

  const fetchCustomerLedgers = async (queryParams) => {
    // await waitFor(5000);

    let response = await fetch(
      `${backendUrl}/api/reports/customer-ledger?&${queryString.stringify(
        queryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    data.invoiceCat = data.invoiceCat.map((el) => ({
      value: el,
      label: el,
    }));
    return data;
  };

  const {
    data = {
      count: 0,
      customerLedgers: [],
    },
    refetch,
    isFetching,
    isSuccess,
  } = useQuery(
    [`${reportActions.CUSTOMER_LEDGER}_${usage}`, queryParams],
    () => fetchCustomerLedgers(queryParams),
    {
      keepPreviousData: true,
      enabled: Boolean(queryParams?.customerId) || usage === "drums",
    }
  );

  // download excel function
  const onDownloadExcelData = async () => {
    const { limit, page, ...rest } = queryParams;
    let exData = await fetchExcelData(
      `${backendUrl}/api/reports/customer-ledger?${queryString.stringify(
        rest
      )}`,
      "GET"
    );

    const company = exData.data?.company?.CompName;
    exData = exData.data.customerLedgers.map((row) => ({
      ...row,
      Post_Time: format(new Date(row.Post_Time), "dd-MMM-yyyy hh:mm:ss a"),
    }));

    exData = exData.map((d) => [
      d.Post_Time,
      d.DocType,
      d.Trans_ID,
      d.PaymentType,
      d.Remark,
      d.Debit,
      d.Credit,
      d.Balance,
      d.Username,
    ]);
    const date =
      rest.startDate && rest.endDate
        ? `Date Prepared: Between ${format(
            new Date(rest.startDate),
            "E MMM d yyyy k:mm:ss z"
          )} to ${format(new Date(rest.endDate), "E MMM d yyyy k:mm:ss z")}`
        : "";
    exData = [
      [company],
      ["Customer Ledger Report"],
      [date],
      [""],
      [
        "Trans Date",
        "Doc Type",
        "Trans ID",
        "Payment Type",
        "Transaction Remark",
        "Debit Amount",
        "Credit Amount",
        "Balance (LCY)",
        "Username",
      ],
      ...exData,
      [""],
      ["Summary"],
      [
        "Total Invoice Amount",
        currency(data?.totalInvoiceAmt, {
          symbol: "",
        }).format(),
      ],
      [
        "Total Payment Amount",
        currency(data?.totalPaymentAmt, {
          symbol: "",
        }).format(),
      ],
      [
        "Balance (LCY)",
        currency(data?.balance, {
          symbol: "",
        }).format(),
      ],
      [
        "Reversed Invoice Amount",
        currency(data?.reversedInvoiceAmount, {
          symbol: "",
        }).format(),
      ],
      [
        "Reversed Payment Amount",
        currency(data?.reversedPaymentAmount, {
          symbol: "",
        }).format(),
      ],
      ...(usage === "drums"
        ? [
            [
              "Total Drums Returned",
              currency(data?.totalDrums, {
                symbol: "",
              }).format(),
            ],
          ]
        : []),
    ];
    //  console.log(exData);
    setExcelData(exData);
  };

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
      enableDateRange: true,
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams(initialFilterParams);
    setQueryParams({
      ...queryParams,
      ...initialFilterParams,
    });
  };

  const handleSelectedCustomer = (customer) => {
    setFilterParams({
      ...filterParams,
      customerId: customer.Cust_ID,
      customerName: customerFullName(customer),
    });
    setShowItemsPopover(false);
  };

  const tableHead = () => {
    return (
      <thead className="position-sticky top-0">
        <tr>
          <th />
          {usage === "drums" ? <th>Business Name</th> : null}
          <th>Trans Date</th>
          <th>Doc Type</th>
          <th>Trans ID</th>
          <th>Payment Type</th>
          <th>Tran. Remark</th>
          <th>Debit Amount</th>
          <th>Credit Amount</th>
          <th>Balance (LCY)</th>
          {isHMB && <th>Bank</th>}
          <th>Username</th>
        </tr>
      </thead>
    );
  };

  const tableData = (el, index) => {
    return (
      <>
        <td>
          {["invoice", "official receipt", "drum payment"].includes(
            lowerCase(el.DocType)
          ) && (
            <Dropdown>
              <Dropdown.Toggle
                variant=""
                className="bg-white border-0"
                bsPrefix="print more"
              >
                <DotsVerticalIcon />
              </Dropdown.Toggle>
              <Dropdown.Menu
                popperConfig={{
                  strategy: "fixed",
                }}
                renderOnMount
                className="dropdown-with-icons"
              >
                {lowerCase(el.DocType) === "invoice" && (
                  <>
                    <Dropdown.Item as="div">
                      <a
                        href={`${backendUrl}/api/invoice/pdf/invoice/${
                          el.Trans_ID
                        }`}
                        target="blank"
                      >
                        <BookIcon />
                        Open Invoice - A4
                      </a>
                    </Dropdown.Item>
                    <Dropdown.Item as="div">
                      <a
                        href={`${backendUrl}/api/invoice/pdf/invoice/${
                          el.Trans_ID
                        }?thermalPrinter=true`}
                        target="blank"
                      >
                        <BookIcon />
                        Open Invoice - Thermal Printer
                      </a>
                    </Dropdown.Item>
                    <Dropdown.Item as="button">
                      <a
                        href={`${backendUrl}/api/invoice/pdf/waybill/${
                          el.Trans_ID
                        }`}
                        target="blank"
                      >
                        <PageHeaderListIcon />
                        Open Waybill
                      </a>
                    </Dropdown.Item>
                    {!isHMB && (
                      <Dropdown.Item
                        as="button"
                        onClick={() =>
                          copyText(
                            `${
                              services.frontendUrl
                            }/sales-and-invoicing/delete-invoice?TransactionID=${
                              el.Trans_ID
                            }`
                          )
                        }
                      >
                        <LinkVariantIcon /> Copy Delete Invoice Link
                      </Dropdown.Item>
                    )}
                    {isAdmin || (appSettings.isGigc && isCashier) ? (
                      <Dropdown.Item as="div">
                        <Link
                          to={`/sales-and-invoicing/delete-invoice?TransactionID=${
                            el.Trans_ID
                          }`}
                          // target="blank"
                        >
                          <DeleteIcon /> Delete Invoice
                        </Link>
                      </Dropdown.Item>
                    ) : null}
                  </>
                )}
                {lowerCase(el.DocType) === "official receipt" && (
                  <>
                    <Dropdown.Item as="div">
                      <a
                        href={`${backendUrl}/api/invoice/pdf/receipt/${
                          el.Trans_ID
                        }`}
                        target="blank"
                      >
                        <RecieptIcon />
                        Open Reciept - A4
                      </a>
                    </Dropdown.Item>

                    <Dropdown.Item as="div">
                      <a
                        href={`${backendUrl}/api/invoice/pdf/receipt/${
                          el.Trans_ID
                        }?thermalPrinter=true`}
                        target="blank"
                      >
                        <RecieptIcon />
                        Open Reciept - Thermal Printer
                      </a>
                    </Dropdown.Item>
                    <Dropdown.Item
                      as="button"
                      onClick={() =>
                        copyText(
                          `${
                            services.frontendUrl
                          }/sales-and-invoicing/delete-payment?TransactionID=${
                            el.Trans_ID
                          }&PaymentType=${el.PaymentType}&Balance=${el.Balance}`
                        )
                      }
                    >
                      <LinkVariantIcon /> Copy Delete Payment Link
                    </Dropdown.Item>
                    {isAdmin || (appSettings.isGigc && isCashier) ? (
                      <Dropdown.Item onClick={() => navigate()} as="div">
                        <Link
                          to={`/sales-and-invoicing/delete-payment?TransactionID=${
                            el.Trans_ID
                          }&PaymentType=${el.PaymentType}&Balance=${
                            el.Balance
                          }`}
                        >
                          <DeleteIcon /> Delete Payment
                        </Link>
                      </Dropdown.Item>
                    ) : null}
                  </>
                )}
                {lowerCase(el.DocType) === "drum payment" && (
                  <>
                    <Dropdown.Item as="div">
                      <a
                        href={`${backendUrl}/api/invoice/pdf/receipt/${
                          el.Trans_ID
                        }`}
                        target="blank"
                      >
                        <RecieptIcon />
                        Open Reciept - A4
                      </a>
                    </Dropdown.Item>

                    <Dropdown.Item as="div">
                      <a
                        href={`${backendUrl}/api/invoice/pdf/receipt/${
                          el.Trans_ID
                        }?thermalPrinter=true`}
                        target="blank"
                      >
                        <RecieptIcon />
                        Open Reciept - Thermal Printer
                      </a>
                    </Dropdown.Item>
                    <Dropdown.Item
                      as="button"
                      onClick={() =>
                        copyText(
                          `${
                            services.frontendUrl
                          }/sales-and-invoicing/delete-payment?TransactionID=${
                            el.Trans_ID
                          }&PaymentType=${el.PaymentType}&Balance=${el.Balance}`
                        )
                      }
                    >
                      <LinkVariantIcon /> Copy Delete Payment Link
                    </Dropdown.Item>
                  </>
                )}
              </Dropdown.Menu>{" "}
            </Dropdown>
          )}
        </td>
        {usage === "drums" ? <td>{customerFullName(el?.customer)}</td> : null}
        <td>{el.Post_Time ? formatDate(el?.Post_Time) : ""}</td>
        <td>{el.DocType}</td>
        <td>{el.Trans_ID}</td>
        <td>{el.PaymentType}</td>
        <td>{el.Remark}</td>
        <td>
          {el.currency && el.currency !== "NGN"
            ? currency(el.Debit, {
                symbol: "",
              })
                .divide(el.conversionAmount)
                .format()
            : currency(el.Debit, {
                symbol: "",
              }).format()}
        </td>
        <td>
          {el.currency && el.currency !== "NGN"
            ? currency(el.Credit, {
                symbol: "",
              })
                .divide(el.conversionAmount)
                .format()
            : currency(el.Credit, {
                symbol: "",
              }).format()}
        </td>
        <td>
          {el.currency && el.currency !== "NGN"
            ? currency(el.Balance, {
                symbol: "",
              })
                .divide(el.conversionAmount)
                .format()
            : currency(el.Balance, {
                symbol: "",
              }).format()}
        </td>
        {isHMB && <td>{el?.BankName}</td>}
        <td>{el.Username}</td>
      </>
    );
  };

  const manageTransaction = (event, el) => {
    console.log(el);

    if (event.detail === 2 && el.Trans_ID) {
      if (isHMB) {
        return navigate(`/hmb-rsmoh/manage-transaction`, {
          state: {
            TransactionID: el.Trans_ID,
          },
        });
      }
      navigate(`/inventory-for-sales/manage-transaction`, {
        state: {
          TransactionID: el.Trans_ID,
        },
      });
    }
  };

  const currencySymbol = useMemo(() => {
    const foundCurrency = currenciesOptions.find(
      (el) => el.cc === filterParams?.currency
    );
    return foundCurrency ? foundCurrency.symbol : "₦";
  }, [filterParams?.currency]);

  // isprivileged
  return (
    <IsPrivileged roleName="Customer Ledger">
      <main className="cash-book">
        <div className="content">
          <div className="content-main">
            <div className={`filter-area ${showFilter ? "open" : "close"}`}>
              <div className="filter-header">
                <h2>
                  <FilterTwoIcon /> Filters
                </h2>

                <Button
                  variant="white"
                  onClick={() => setShowFilter(!showFilter)}
                >
                  <FilterCollapseIcon />
                </Button>
              </div>
              <div className="filter-body">
                <Form>
                  <div className="body">
                    <Form.Group className="mb-2-5">
                      <Form.Label>Customer ID</Form.Label>
                      <InputGroup>
                        <Form.Control
                          name="customerId"
                          placeholder="Enter Customer ID"
                          value={filterParams?.customerId}
                          onChange={(e) => handleFilterParamsChange(e)}
                        />
                        <Popover
                          isOpen={showItemsPopover}
                          onClickOutside={() => setShowItemsPopover(false)}
                          content={() => (
                            <CustomerSelector
                              handleSelectedCustomer={handleSelectedCustomer}
                              setShowCustomerSelector={setShowItemsPopover}
                            />
                          )}
                          position="bottom"
                        >
                          <InputGroup.Text
                            onClick={() =>
                              setShowItemsPopover(!showItemsPopover)
                            }
                          >
                            <MagnifyIcon />
                          </InputGroup.Text>
                        </Popover>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-2-5">
                      <Form.Label>Business Name</Form.Label>
                      <Form.Control
                        name="customerName"
                        value={filterParams?.customerName}
                        placeholder="Enter Business Name"
                        onChange={(e) => handleFilterParamsChange(e)}
                      />
                    </Form.Group>

                    {usage === "drums" ? (
                      <Form.Group className="mb-2-5">
                        <Form.Label>Doc Type</Form.Label>
                        <Select
                          classNamePrefix="form-select"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          placeholder="All"
                          theme={reactSelectTheme}
                          isSearchable={false}
                          value={drumDocTypes.find(
                            (el) => el.value === filterParams?.DocType
                          )}
                          options={drumDocTypes}
                          onChange={(selected) => {
                            setFilterParams({
                              ...filterParams,
                              DocType: selected.value,
                            });
                          }}
                        />
                      </Form.Group>
                    ) : (
                      <Form.Group className="mb-2-5">
                        <Form.Label>Invoice Cat</Form.Label>
                        <Select
                          classNamePrefix="form-select"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          placeholder="All"
                          theme={reactSelectTheme}
                          isSearchable={false}
                          key={data?.invoiceCat}
                          defaultValue={defaultSelectValue(
                            queryParams?.invoiceCat,
                            data?.invoiceCat,
                            {
                              value: "",
                              label: "All",
                            }
                          )}
                          options={data?.invoiceCat || []}
                          onChange={(selected) => {
                            setFilterParams({
                              ...filterParams,
                              invoiceCat: selected.value,
                            });
                          }}
                        />
                      </Form.Group>
                    )}

                    <hr className="mt-3 mb-4" />

                    <div className="mb-2-5">
                      <Form.Check
                        inline
                        label="Enable Date Range"
                        name="enableDateRange"
                        type={"checkbox"}
                        id={`enableDateRange`}
                        checked={filterParams?.enableDateRange}
                        onChange={(e) => handleFilterParamsChange(e)}
                      />
                    </div>

                    <Form.Group className="">
                      <Form.Label>Date Range</Form.Label>
                      <div className="position-relative">
                        <RsDateRangePicker
                          placement="topStart"
                          value={
                            filterParams.startDate && filterParams.endDate
                              ? [
                                  parse(
                                    filterParams.startDate,
                                    "yyyy-MM-dd",
                                    new Date()
                                  ),
                                  parse(
                                    filterParams.endDate,
                                    "yyyy-MM-dd",
                                    new Date()
                                  ),
                                ]
                              : []
                          }
                          onClean={() => clearDateRange()}
                          onOk={(date) => filterByDateRange(date)}
                        />
                      </div>
                    </Form.Group>
                  </div>

                  <div className="buttons rounded">
                    <Button onClick={() => reset()} variant="white">
                      Reset
                    </Button>
                    <Button onClick={() => search()} variant="primary">
                      Search
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
            <div className="content-body">
              <header>
                <h1>
                  {!showFilter && (
                    <button
                      onClick={() => setShowFilter(!showFilter)}
                      className="btn filter"
                    >
                      <FilterTwoIcon />
                    </button>
                  )}
                  Customer Ledger
                  <button
                    title="Refresh"
                    onClick={() =>
                      isEmpty(queryParams.customerId) && isEmpty(usage)
                        ? [
                            toast.error("Please select a customer"),
                            setShowItemsPopover(true),
                          ]
                        : refetch()
                    }
                    className="btn text-primary"
                  >
                    <CachedIcon />
                  </button>
                </h1>

                {queryParams?.customerName && (
                  <p>
                    Business Name: <b>{queryParams?.customerName}</b>
                  </p>
                )}
                <div className="actions" style={{ zIndex: 9 }}>
                  {/* <button className="btn print">
              Filters <FilterIcon />
            </button> */}
                  {/*  <button className="btn print">
                  Print <PrintIcon />
                </button>
                <button className="btn print">
                  Export <ExportIcon />
                </button>

                <button className="btn print">
                  Open Receipt <BusinessBillIcon className="pageListIcon" />
                </button> */}

                  {/*  <a
                  href={`${backendUrl}/api/customers/pdf/ledger/${
                    filterParams?.customerId
                  }?${queryString.stringify(queryParams)}`}
                  target="_blank"
                  rel="noreferrer"
                  className="btn print"
                >
                  Print <PrintIcon />
                </a> */}

                  <Form.Group>
                    <Select
                      classNamePrefix="form-select"
                      placeholder="Select Currency"
                      isSearchable={false}
                      options={currenciesOptions}
                      value={currenciesOptions.find(
                        (el) => el.value === filterParams.currency
                      )}
                      onChange={({ value }) =>
                        setFilterParams({
                          ...filterParams,
                          page: 1,
                          currency: value,
                        })
                      }
                    />
                  </Form.Group>

                  <CSVLink
                    className="btn print d-none"
                    filename={`Customer Ledger(${format(
                      new Date(),
                      "dd-MMM-yyyy hh:mm:ss a"
                    )}).csv`}
                    data={excelData}
                    ref={CSVLinkRef}
                  />

                  <Dropdown>
                    <Dropdown.Toggle
                      variant=""
                      className="btn print"
                      disabled={isfetchingExcel}
                      bsPrefix=""
                    >
                      Export
                      <ExportIcon color="#008000" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      popperConfig={{
                        strategy: "fixed",
                      }}
                      renderOnMount
                      className="text-center"
                    >
                      <Dropdown.Item
                        as="div"
                        onClick={onDownloadExcelData}
                        className="p-cursor"
                      >
                        Excel <ExcelIcon color="#008000" />
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="div"
                        onClick={() =>
                          !filterParams?.customerId
                            ? toast.error("Please select a customer")
                            : printJS({
                                printable: `${backendUrl}/api/customers/pdf/ledger/${
                                  filterParams?.customerId
                                }?${queryString.stringify(queryParams)}`,
                                onLoadingStart: () => setLoadingPrint(true),
                                onLoadingEnd: () => setLoadingPrint(false),
                              })
                        }
                        className="p-cursor"
                      >
                        PDF <PDFIcon color="#ff0000" />
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  <button
                    onClick={() => setBtnSummaryActive(true)}
                    className="btn summaryBtn "
                  >
                    Show Summary
                  </button>
                </div>
              </header>

              <div className="px-md-4">
                <TableComponent
                  responsive
                  borderless
                  striped
                  tableHeadsFunction={tableHead}
                  mainDataArray={data.customerLedgers}
                  tableDataRowFunction={tableData}
                  className="product-table text-nowrap"
                  tableRowClick={(e, el) => manageTransaction(e, el)}
                />
                {!isFetching && isSuccess && isEmpty(data?.customerLedgers) ? (
                  <div>
                    <NoTableItem queryParams={queryParams} />
                    <p className="text-center">
                      {queryParams?.customerName}{" "}
                      <span>{`(${queryParams.currency})`}</span>
                    </p>
                  </div>
                ) : isEmpty(queryParams.customerId) && isEmpty(usage) ? (
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <NoTableItem queryParams={{}} />
                    <Button
                      onClick={() => {
                        setTimeout(() => {
                          setShowItemsPopover(true);
                        }, 50);
                      }}
                      className="text-center"
                      variant="outline-primary"
                    >
                      Select a Customer
                    </Button>
                  </div>
                ) : null}
              </div>

              <div className="d-flex justify-content-between px-3 align-items-center pagination">
                {/*<p className="m-0">
                Showing {data?.startIndex + 1} to{" "}
                {data?.endIndex <= data?.count ? data?.endIndex : data?.count}{" "}
                of {data.count} entries
                  </p> */}
                <div className="pagination_left">
                  <p className="m-0 p-0">Show</p>
                  <select
                    value={queryParams.limit}
                    name="limit"
                    className="form-select "
                    onChange={(e) => handleSearchQueryChange(e)}
                  >
                    <option value="10">10 rows</option>
                    <option value="20">20 rows</option>
                    <option value="30">30 rows</option>
                    <option value="40">40 rows</option>
                    <option value="40">100 rows</option>
                  </select>
                </div>

                <ReactPaginate
                  {...paginationOptions}
                  pageCount={Math.ceil(data.count / queryParams.limit)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={0}
                  onPageChange={({ selected }) => {
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                    setQueryParams({
                      ...queryParams,
                      page: selected + 1,
                    });
                  }}
                  forcePage={queryParams.page - 1}
                />
              </div>

              {/* <div className="p-4 border-top">
          <h2>Summary</h2>
          <div className="summary">
            <table className="table table-borderless balance">
              <tbody>
                <tr>
                  <td>Total Sales</td>
                  <td>218,134,177.92</td>
                </tr>

                <tr>
                  <td>Total Profit</td>
                  <td>9,276,961.63</td>
                </tr>

                <tr>
                  <td>Total Losses</td>
                  <td>N/A</td>
                </tr>

                <tr>
                  <td>Qty Sold</td>
                  <td>19884</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> */}

              <div
                className={
                  btnSummryActive
                    ? `summaryNew border-top open`
                    : `summaryNew border-top`
                }
              >
                <div className="summaryHeader">
                  <h2>Summary</h2>
                  {btnSummryActive && (
                    <button onClick={() => close()} className="btn p-0">
                      <SummaryCloseIcon />
                    </button>
                  )}
                </div>

                <div className="gridCont">
                  <div className="gridChild gridChildBorderLeftBlue">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(data?.totalInvoiceAmt, {
                          symbol: currencySymbol,
                        }).format()}
                      </p>
                      <p className="gridChld2">Total Invoice Amt</p>
                    </div>

                    <div className="gridChildRight">
                      <SummaryChartIconUp />
                      <p>5%</p>
                    </div>
                  </div>

                  <div className="gridChild gridChildBorderLeftOrange">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(data?.totalPaymentAmt, {
                          symbol: currencySymbol,
                        }).format()}
                      </p>
                      <p className="gridChld2">Total Payment Amt</p>
                    </div>

                    <div className="gridChildRight">
                      <SummaryChartIconUp />
                      <p>5%</p>
                    </div>
                  </div>

                  <div className="gridChild gridChildBorderLeftBrown">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(data?.balance, {
                          symbol: currencySymbol,
                        }).format()}
                      </p>
                      <p className="gridChld2">Balance (LCY)</p>
                    </div>

                    <div className="gridChildRight">
                      <SummaryChartIconDown />
                      <p>5%</p>
                    </div>
                  </div>
                  {isEmpty(usage) && (
                    <>
                      <div className="gridChild gridChildBorderLeftDarkerGreen">
                        <div className="gridChildLeft">
                          <p className="gridChld1">
                            {currency(data?.reversedInvoiceAmount, {
                              symbol: currencySymbol,
                            }).format()}
                          </p>
                          <p className="gridChld2">Reversed Invoice Amt</p>
                        </div>

                        <div className="gridChildRight">
                          <SummaryChartIconDown />
                          <p>5%</p>
                        </div>
                      </div>

                      <div className="gridChild gridChildBorderLeftDarkGreen">
                        <div className="gridChildLeft">
                          <p className="gridChld1">
                            {currency(data?.reversedPaymentAmount, {
                              symbol: currencySymbol,
                            }).format()}
                          </p>
                          <p className="gridChld2"> Reversed Payment Amt</p>
                        </div>

                        <div className="gridChildRight">
                          <SummaryChartIconUp />
                          <p>5%</p>
                        </div>
                      </div>
                    </>
                  )}

                  {usage === "drums" && (
                    <>
                      <div className="gridChild gridChildBorderLeftDarkerGreen">
                        <div className="gridChildLeft">
                          <p className="gridChld1">
                            {currency(data?.totalDrums, {
                              symbol: currencySymbol,
                              precision: 1,
                            }).format()}
                          </p>
                          <p className="gridChld2">Total Drums Returned </p>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <ModalLoader show={loadingPrint || isfetchingExcel} />
      </main>
    </IsPrivileged>
  );
}
