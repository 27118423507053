import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  BookIcon,
  CirclesFourIcon,
  ClockIcon,
  CoinIcon,
  CurrencyCircleDollarIcon,
  MoneyDollarIcon,
  RecieptIcon,
  ShoppingCartIcon,
  TagSimpleIcon,
  UserIcon,
} from "../Icons";
import NewCustomerModal from "../NewCustomerModal";
import {
  useIsGovernmentInvexERP,
  useResolveRootRoles,
} from "../../utils/hooks";
import { useAuth } from "../../hooks/useAuth";
import { useIsFetching } from "react-query";

export default function Nav() {
  const isFetching = useIsFetching();
  const { user: authUser } = useAuth();

  const isGovernmentInvexERP = useIsGovernmentInvexERP();
  const [showCreateNewCustomerModal, setShowCreateNewCustomerModal] = useState(
    false
  );
  const { getRootRoles } = useResolveRootRoles();
  const [activeIndex, setActiveIndex] = useState(null);
  const items = useMemo(() => {
    const navItems = [
      ...(isGovernmentInvexERP
        ? [
            {
              name: "Receive Funds",
              icon: <MoneyDollarIcon />,
              to: "/post-payments",
            },
            {
              name: "Imprest Account",
              icon: <RecieptIcon />,
              to: "/reports/imprest-account",
            },
            {
              name: "Expenses",
              icon: <BookIcon />,
              to: "/reports/expenses",
            },
          ]
        : [
            {
              name: "New Customer",
              icon: <UserIcon />,
              // onClick: () => setShowCreateNewCustomerModal(true),
              to: "/sales-and-invoicing/customer-list?action=create",
            },
            {
              name: "Make Invoice",
              icon: <ShoppingCartIcon />,
              to: "/sales-and-invoicing/create-invoice",
            },
            {
              name: "Sales History",
              icon: <ClockIcon />,
              to: "/reports/sales-analysis",
            },
          ]),
      {
        name: "Other Incomes",
        icon: <CoinIcon />,
        to: "/reports/cash-bank-balances",
      },
      {
        name: "Receive Payments",
        icon: <TagSimpleIcon />,
        to: "/post-payments",
      },
      {
        name: "Vendors Ledger",
        icon: <BookIcon />,
        to: "/vendor/vendors-ledger",
      },
      {
        name: "Purchase History",
        icon: <CurrencyCircleDollarIcon />,
        to: "/reports/purchase-history",
      },
      {
        name: "Cash Book",
        icon: <RecieptIcon />,
        to: "/reports/payment-report",
      },
      {
        name: "All Menus",
        icon: <CirclesFourIcon />,
      },
    ];

    return getRootRoles({
      navItems,
    });
  }, [isGovernmentInvexERP, isFetching, authUser?.company]);

  return (
    <div className="dashboard-nav  ">
      <ul>
        {items.map((item, index) => (
          <li key={index}>
            <Link
              onClick={() => {
                setActiveIndex(index);
                item.onClick && item.onClick();
              }}
              className={`btn ${activeIndex === index && "active"}`}
              to={item?.to || ""}
            >
              <span className="icon">{item.icon}</span>
              <span> {item.name}</span>
            </Link>
          </li>
        ))}
      </ul>

      {showCreateNewCustomerModal && (
        <NewCustomerModal
          showCreateNewCustomerModal={showCreateNewCustomerModal}
          setShowCreateNewCustomerModal={setShowCreateNewCustomerModal}
        />
      )}
    </div>
  );
}
