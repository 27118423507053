import { format } from "date-fns";
import { Modal, Form, Button } from "react-bootstrap";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { services } from "../../config";
import { cacheTimes, queryActions } from "../../utils/reactQueryActions";
import { useFormik } from "formik";
import * as yup from "yup";
import { Fragment, useEffect, useRef, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import eventBus from "../../utils/EventBus";
import CurrencyInput from "react-currency-input-field";
import { useMutation } from "react-query";
import { isEmpty } from "lodash";
import ModalLoader from "../utils/ModalLoader";
import { useEffectOnce } from "../../utils/hooks";
import { toast } from "react-toastify";
import { formDataForFormBuider } from "../../utils/helpers";
import ConfirmDialog from "../ConfirmDialogue";
import { useStoreState } from "easy-peasy";

export default function NewFillableFormModal({
  location,
  setShowNewFillableFormModal,
  template,
  setTemplate,
}) {
  const { user: authUser, backendUrl } = useAuth();
  const currentLoggedInCompany = useStoreState(
    (state) => state.currentLoggedInCompany
  );
  const iframeRef = useRef();

  const [isLoadingIframe, setIsLoadingIframe] = useState(true);
  const [reciever, setReceiver] = useState();

  const createForm = async (payload) => {
    const formData = formDataForFormBuider(payload);
    let response = await fetch(`${backendUrl}/api/forms/create-form`, {
      method: "POST",
      credentials: "include",
      body: formData,
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const createFormMutation = useMutation((payload) => createForm(payload), {
    onSuccess: ({ message, data }) => {
      toast.success(message);
      setShowNewFillableFormModal(false);
    },
    onError: () => {
      toast.error(`Unable to perform action`);
    },
  });

  const formik = useFormik({
    initialValues: {
      Title: "",
      Date_Log: new Date(),
      FormType: "",
      Description: "",
      Template: "",
    },
    validationSchema: yup.object().shape({
      Title: yup.string().required(),
    }),
    onSubmit: (values) => {
      createFormMutation.mutate(
        { ...values },
        {
          onSuccess: (data) => {},
          onError: ({ errors }) => {
            if (errors) formik.setErrors(errors);
          },
        }
      );
    },
  });

  /* useEffectOnce(() => {
    try {
      const page = JSON.parse(template)[0];
      const signatories = page.children
        .filter((el) => el.type === "signatoryElement")
        .map((el) => el.props.fields);

      if (signatories) {
        const permissions = signatories.flat().map((el) => el.permission);

        if (permissions) {
          setReceiver(permissions[0]);

          formik.setFieldValue("department", permissions[0].department);
          formik.setFieldValue("recipient", permissions[0].staff);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }); */

  const setformData = async (data) => {
    formik.setFieldValue("Template", data);

    if (
      await ConfirmDialog({
        title: "Submit",
        description: "Are you sure, you want to submit",
      })
    ) {
      formik.submitForm();
    }
  };

  const handleMessage = (message) => {
    if (message.data.type === "CLOSE") {
      setShowNewFillableFormModal(false);
    } else if (message.data.type === "SAVE") {
      console.log(message.data.value, "react");
      setformData(message.data.value);
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleMessage);

    // cleanup this component
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  //----------------------------------------------------------------------
  // fetch data for form
  const getDepartments = async () => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/users/departments-with-users`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const {
    data = { departments: [], staffs: [], jobGrades: [] },
    isFetching,
  } = useQuery(
    [queryActions.GET_USERS_WITH_DEPARTMENTS],
    () => getDepartments(),
    {
      enabled: true,
      keepPreviousData: true,
      cacheTime: cacheTimes.GET_USERS_WITH_DEPARTMENTS,
    }
  );

  const sendSetupData = () => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        {
          data: {
            ...data,
            template: JSON.parse(template),
            backendUrl,
            companyData: currentLoggedInCompany,
          },
        },
        process.env.REACT_APP_FORM_BUILDER_URL
      );
    }
  };

  const getUsersInDepartments = async (department) => {
    let response = await fetch(
      `${backendUrl}/api/users/by-department/${department}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };
  const {
    data: { users } = { users: null },
    refetch: getUsers,
    isFetching: isLoadingUsers,
  } = useQuery(
    [queryActions.GET_USERS_IN_DEPARTMENTS, formik.values.department],
    () => getUsersInDepartments(formik.values.department),
    {
      enabled: false,
    }
  );

  const fetchVendors = async (department) => {
    let response = await fetch(`${backendUrl}/api/vendors`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };
  const {
    data: { vendors } = { vendors: [] },
    isFetching: isLoadingVendors,
  } = useQuery([queryActions.GET_VENDORS], () => fetchVendors(), {
    enabled: true,
  });

  useEffect(() => {
    if (formik.values.department) {
      getUsers();
    }
  }, [formik.values.department]);

  const save = () => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        {
          type: "SUBMIT",
        },
        process.env.REACT_APP_FORM_BUILDER_URL
      );
    }
  };

  eventBus.useCustomEventListener(
    "SEND_FILE_TO_IFRAME",
    ({ files, elementId, elementType }) => {
      const iframe = iframeRef.current;
      if (iframe && iframe.contentWindow) {
        iframe.contentWindow.postMessage(
          {
            data: {
              files,
              elementId,
              elementType,
            },
            type: "FILES_FROM_PARENT",
          },
          process.env.REACT_APP_FORM_BUILDER_URL
        );
      }
    }
  );

  return (
    <>
      <Modal
        show={true}
        onHide={() => {
          setTemplate(null);
          setShowNewFillableFormModal(false);
        }}
        //   dialogClassName="requisition-details-modal"
        backdropClassName={`global-backdrop`}
        centered={true}
        animation={false}
        enforceFocus={false}
        size="lg"
        // fullscreen={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h1 className="h5"> Form</h1>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="approve-action p-0">
          <Form noValidate onSubmit={formik.handleSubmit} className="p-3 px-4">
            <Form.Group className="mb-3">
              <Form.Label>Title :</Form.Label>
              <Form.Control
                name="Title"
                placeholder="Enter a title"
                value={formik.values.Title}
                onChange={formik.handleChange}
                isInvalid={formik.touched.Title && !!formik.errors.Title}
              />

              <Form.Control.Feedback type="invalid">
                {formik.errors.Title}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Description :</Form.Label>

              <Form.Control
                name="Description"
                value={formik.values.Description}
                onChange={formik.handleChange}
                as="textarea"
                placeholder="Enter your detail description"
                rows={3}
                isInvalid={
                  formik.touched.Description && !!formik.errors.Description
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.Description}
              </Form.Control.Feedback>
            </Form.Group>

            <div className="row gap-3">
              <Form.Group className="col-md-5 mb-3">
                <Form.Label>Type :</Form.Label>
                <Form.Select
                  name="FormType"
                  value={formik.values.FormType}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.FormType && !!formik.errors.FormType
                  }
                  disabled={reciever?.FormType}
                >
                  <option value="">Select Type</option>
                  <option value="Organisation">Organisation</option>
                  <option value="Public">Public</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.FormType}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </Form>

          <div className="form-area border">
            {!isFetching ? (
              <iframe
                ref={iframeRef}
                id="Frame"
                onLoad={(e) => {
                  setIsLoadingIframe(false);
                  sendSetupData();
                }}
                className="w-100"
                style={{ backgroundColor: "#E5E7EB", minHeight: "100vh" }}
                title="Form Builder"
                src={`${process.env.REACT_APP_FORM_BUILDER_URL}/form/fill`}
              />
            ) : null}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={createFormMutation.isLoading}
            onClick={() => setShowNewFillableFormModal(false)}
            type="button"
            variant="white"
            className="border bg-white px-3"
          >
            Cancel
          </Button>
          <Button
            disabled={createFormMutation.isLoading}
            type="button"
            variant="primary"
            className="px-3"
            onClick={() => save()}
          >
            {createFormMutation.isLoading ? "Please wait..." : "Post"}
          </Button>
        </Modal.Footer>
      </Modal>
      <ModalLoader
        show={isFetching || isLoadingIframe || createFormMutation.isLoading}
      />
    </>
  );
}
