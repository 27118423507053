import { useState } from "react";
import { NavLink } from "react-router-dom";

export default function Nav(props) {
  const [items] = useState([
    {
      name: "Sales",
      to: "sales",
    },
    {
      name: "Customers",
      to: "customers",
    },
    {
      name: "Payment",
      to: "payment",
    },
    {
      name: "Debtors",
      to: "debtors",
    },
    {
      name: "Sales Reps",
      to: "/sales-rep/employees",
    },
  ]);

  return (
    <div className="dashboard-tabs innerpage-tabs tier-1 px-4">
      <ul className="nav nav-tabs">
        {items.map((item, index) => (
          <li key={index} className="nav-item">
            <NavLink className={`nav-link ga-nav-tab`} to={item.to}>
              {item.name}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
}
