import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { services } from "../config";
import { useAuth } from "../hooks/useAuth";
import { formDataForFormBuider } from "../utils/helpers";

export default function RequisitionService() {
  const { backendUrl } = useAuth();
  try {
    const createRequisition = async (payload) => {
      // await waitFor(5000);
      let formDataForCustom;
      const formData = new FormData();
      for (const [key, value] of Object.entries(payload)) {
        if (key === "initialFiles") {
          for (const file of value) {
            if (!file?.fileId) {
              formData.append("initialFiles", file);
            }
          }
          continue;
        }
        formData.append(key, value);
      }
      // formData.append("signature", file, `${authUser.Name}_signature`);

      payload.requiredSignatoriesStaff_ID = JSON.stringify(
        payload?.requiredSignatoriesStaff_ID
          ? payload?.requiredSignatoriesStaff_ID
          : []
      );

      if (payload?.filledFormData) {
        formDataForCustom = formDataForFormBuider(
          {
            Template: payload?.filledFormData,
          },
          formData
        );
      }

      let response = await fetch(`${backendUrl}/api/requisition/create`, {
        method: "POST",
        credentials: "include",
        body: payload?.filledFormData ? formDataForCustom : formData,
      });

      if (!response.ok) {
        response = await response.json();
        throw new Error(response.message);
      }

      const { data } = await response.json();
      return data;
    };

    const createRequisitionMutation = useMutation(
      (payload) => createRequisition(payload),
      {
        onSuccess: (data) => {
          toast.success("Requisition created successfully");
        },
        onError: (err) => {
          toast.error("Unable to create Requisition");
        },
      }
    );

    return {
      createRequisitionMutation,
    };
  } catch (err) {
    console.log(err);
  }
}
