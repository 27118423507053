import { useState } from "react";
import { NavLink } from "react-router-dom";

export function InventoryProcessingNav(props) {
  const [items] = useState([
    /*  {
      name: "Return Item From Branch",
      to: "return-item-from-branch",
    }, */
    {
      name: "Processing",
      to: "processing",
    },
    {
      name: "Processed",
      to: "processed",
    },
    {
      name: "Unprocessed",
      to: "unprocessed",
    },
  ]);

  return (
    <div className="dashboard-tabs innerpage-tabs px-4 pt-5 mb-3" {...props}>
      <ul className="nav nav-tabs">
        {items.map((item, index) => (
          <li key={index} className="nav-item">
            <NavLink className={`nav-link ga-nav-tab`} to={item.to}>
              {item.name}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
}
