import { Fragment, useState, useEffect } from "react";
import NavDropdown from "react-bootstrap/NavDropdown";
import "../assets/scss/NavBar.scss";
import { HamburgerIcon, HomeIcon, Logo } from "./Icons";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import { Dropdown, Image, Modal, Spinner } from "react-bootstrap";
import photoUrl from "./../assets/images/profile.png";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import {
  appSettings,
  backendApis,
  useReportsNavItems,
  salesUserReportNavItems,
  storeUserReportNavItems,
  wareHouseChildren,
  vendorManagement,
} from "../config";
import Avatar from "./utils/Avatar";
import {
  useIsAdmin,
  useIsCashier,
  useIsGovernmentInvexERP,
  useIsMaintenance,
  useIsOperations,
  useIsSalesManager,
  useOnClickOutside,
  useResolveRootRoles,
  useToggle,
} from "../utils/hooks";
import { useRef } from "react";
import { useMemo } from "react";
import { toast } from "react-toastify";
import UpdateProfileModal from "./UpdateProfileModal";
import { isEmpty } from "lodash";
import { lowerCase } from "lodash";
import AccountTransferModal from "./SalesAndInvoicing/AccountTransferModal";
import GeneralSettingsModal from "./GeneralSettingsModal";
import RolesPriviledgesModal, {
  RootRolesPriviledgesModal,
} from "./RolesPriviledgesModal";
import ModalLoader from "./utils/ModalLoader";
import { services } from "../config";
import CementModals from "./Cement/CementModals";
import eventBus from "../utils/EventBus";
import { useStoreActions, useStoreState } from "easy-peasy";
import { HRFrontEndUrl, IS_HR, HRBackEndUrl, setCookies } from "../config";
import { maintanceChildren } from "../config";
import QuickLinkComponent from "./QuickLinkComponent";
import { toLower } from "lodash";
import ServiceSettingsModal from "./ServiceSettingsModal";
import { Notification } from "./Notification";
import { useCacheBuster } from "react-cache-buster";

export default function NavBar() {
  const { checkCacheStatus } = useCacheBuster();
  const location = useLocation();
  const {
    login,
    logout,
    setUser,
    backendUrl,
    user: authUser,
    isCement,
    isTransportAndLogistics,
    isIronRod,
    isExpired,
  } = useAuth();
  const [smShow, setSmShow] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [navOpen, setNavOpen, toggleNavOpen] = useToggle(false);
  const navHolder = useRef();
  const isAdmin = useIsAdmin();
  const isCashier = useIsCashier();
  const isSalesManager = useIsSalesManager();
  const isOperations = useIsOperations();
  const isMaintenance = useIsMaintenance();
  const isGovernmentInvexERP = useIsGovernmentInvexERP();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isAccountTransferOpen, setIsAccountTransferOpen] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [showGeneralSettingsModal, setShowGeneralSettingsModal] = useState(
    false
  );
  const [showRolesPriviledgesModal, setShowRolesPriviledgesModal] = useState(
    false
  );
  const [
    showRootRolesPriviledgesModal,
    setShowRootRolesPriviledgesModal,
  ] = useState(false);
  const [showServiceSettingsModal, setShowServiceSettingsModal] = useState(
    false
  );
  const generalSettings = useStoreState((state) => state.generalSettings);
  const rootRoles = useStoreState((state) => state.rootRoles);

  const savedStaffRoles = useStoreState((state) => state.savedStaffRoles);
  const isAccountExpired = useStoreState((state) => state.isAccountExpired);

  const reportsNavItems = useReportsNavItems();
  // console.log("authUser => ", authUser);
  // whether to switch company

  const {
    // backendUrl,
    user: { Staff_ID, sound },
  } = useAuth();

  const switchCompanyQueryParam = searchParams.get("switchCompany");
  useEffect(() => {
    if (
      switchCompanyQueryParam &&
      authUser.company !== switchCompanyQueryParam
    ) {
      const foundCompany = backendApis.find(
        (el) => el.name === switchCompanyQueryParam
      );
      console.log("foundCompany", foundCompany);
      if (foundCompany) switchCompany(foundCompany);
    }
  }, [switchCompanyQueryParam]);

  const logoutUser = async (options) => {
    try {
      setSmShow(true);
      const res = await fetch(`${backendUrl}/api/auth/logout`, {
        method: "POST",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        body: JSON.stringify({ Staff_ID: authUser.Staff_ID, ...options }),
        credentials: "include",
      });

      if (res.ok) {
        logout();
      } else {
        toast.error("Unable to perform action: Please try again");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setSmShow(false);
    }
  };

  const handleKeyDown = (event) => {
    // event.preventDefault();
    let charCode = String.fromCharCode(event.which).toLowerCase();
    /* if ((event.ctrlKey || event.metaKey) && charCode === "l") {
      alert("CTRL+S Pressed");
    } else if ((event.ctrlKey || event.metaKey) && charCode === "c") {
      alert("CTRL+C Pressed");
    } else if ((event.ctrlKey || event.metaKey) && charCode === "v") {
      alert("CTRL+V Pressed");
    } */

    if (event.ctrlKey || event.metaKey) {
      switch (charCode) {
        case "l":
          logoutUser();
          break;
        case "m":
          setShowServiceSettingsModal(true);
          break;
        case "q":
          setShowRootRolesPriviledgesModal(true);
          break;
        default:
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown, true);
    return () => window.removeEventListener("keydown", handleKeyDown, true);
  }, []);

  useEffect(() => {
    setNavOpen(false);
  }, [location]);

  useOnClickOutside(navHolder, () => setNavOpen(false));

  const openAccountTransfer = () => {
    setIsAccountTransferOpen(true);
  };

  const storeUserNav = useMemo(
    () => [
      {
        name: "Tools",
        childRoutes: [
          ...(appSettings.isBarrelsAndCrates
            ? [
                {
                  name: "Post Transaction",
                  to: "sales-and-invoicing/create-invoice",
                },
                {
                  name: "Customers",
                  to: "sales-and-invoicing/customer-list",
                },
                {
                  name: "Inquirers",
                  to: "sales-and-invoicing/enquire-list",
                },
              ]
            : []),
          {
            name: "Item Issuer",
            to: "inventory-for-sales/item-issuer",
          },
          {
            name: "Receive Inventory",
            to: "receive-inventory",
          },
          {
            name: `Create ${generalSettings.pendingInvoiceType}`,
            to: "sales-and-invoicing/create-proforma-invoice",
          },
          ...(appSettings?.isMedbury
            ? [
                {
                  name: `Create ${
                    generalSettings.pendingInvoiceType
                  } Without Approval`,
                  to:
                    "sales-and-invoicing/create-proforma-invoice-without-approval",
                },
              ]
            : []),
          {
            name: "Item Conversion",
            to: "inventory-for-sales/item-conversion",
          },
          {
            name: "Stock Count",
            to: "inventory-for-sales/stock-counts",
          },
        ],
      },
      {
        name: "Reports",
        to: "reports",
        childRoutes: [...storeUserReportNavItems],
      },

      {
        name: "Warehouse",
        to: "warehouse",
        childRoutes: [...wareHouseChildren],
      },
      {
        name: "Supply Center",
        to: "supply-center",
      },

      {
        name: "Purchase Order",
        to: "PO",
      },
      {
        name: "Forms and Approvals",
        to: "requisition",
        childRoutes: [
          {
            name: "Request and Approvals",
            to: "request",
          },
          {
            name: "Fill a Form",
            to: "fill-a-form",
          },
        ],
      },
      {
        name: "Human Resources",
        HR: true,
      },
    ],
    [generalSettings.pendingInvoiceType]
  );

  const salesUserNav = useMemo(
    () => [
      {
        name: "Tools",
        childRoutes: [
          {
            name: "Post Transaction",
            to: "sales-and-invoicing/create-invoice",
          },
          {
            name: "Customers",
            to: "sales-and-invoicing/customer-list",
          },
          {
            name: `Create ${generalSettings.pendingInvoiceType}`,
            to: "sales-and-invoicing/create-proforma-invoice",
          },
          {
            name: `Pre-order`,
            to: "sales-and-invoicing/preorder-invoice",
          },
          ...(appSettings?.isMedbury
            ? [
                {
                  name: `Create ${
                    generalSettings.pendingInvoiceType
                  } Without Approval`,
                  to:
                    "sales-and-invoicing/create-proforma-invoice-without-approval",
                },
              ]
            : []),
          {
            name: "Supply Center",
            to: "supply-center",
          },
          ...(!appSettings.isBatchStandard
            ? [
                {
                  name: "Receive Payments",
                  to: "post-payments",
                },
              ]
            : []),
          ...(isSalesManager
            ? [
                {
                  name: "Approve Pending Invoice",
                  to: "approval",
                },
                {
                  name: "Pending Online Orders",
                  to: "approval",
                },
              ]
            : []),
          {
            name: "Item Conversion",
            to: "inventory-for-sales/item-conversion",
          },
          {
            name: "Delete Invoice",
            to: "sales-and-invoicing/delete-invoice",
          },
          {
            name: "Stock Count",
            to: "inventory-for-sales/stock-counts",
          },
        ],
      },
      {
        name: "Reports",
        to: "reports",
        childRoutes: [
          ...salesUserReportNavItems.filter(
            (el) =>
              (isCement ? el.isCementNav : false) ||
              typeof el.isCementNav === "undefined"
          ),
        ],
      },
      {
        name: "Forms and Approvals",
        to: "requisition",
        childRoutes: [
          {
            name: "Request and Approvals",
            to: "request",
          },
          {
            name: "Fill a Form",
            to: "fill-a-form",
          },
        ],
      },
      {
        name: "Human Resources",
        HR: true,
      },
    ],
    [generalSettings.pendingInvoiceType, isCement, isSalesManager]
  );

  // Admin
  const items = useMemo(
    () => [
      /*{
        name: "Files",
        childRoutes: [
          {
            name: "Logout",
            shortcut: "Ctrl+L",
            onClick: () => logout(),
          },
        ],
      },*/

      {
        name: "Quick Links",
        childRoutes: [
          {
            name: "Customers",
            to: "sales-and-invoicing/customer-list",
          },
          {
            name: "Sales and Invoicing",
            to: "sales-and-invoicing/invoice-list",
          },
          {
            name: "Make Invoice",
            to: "sales-and-invoicing/create-invoice",
          },
          {
            name: `Pre-order`,
            to: "sales-and-invoicing/preorder-invoice",
          },
          {
            name: "Sales Inventory",
            to: "inventory-for-sales/inventory-entry",
          },
          {
            name: "Item Issuer",
            to: "inventory-for-sales/item-issuer",
          },
          {
            name: "Delivery",
            to: "delivery",
          },

          // {
          //   name: "Business Development/CRM",
          //   to: "business-development-crm",
          // },

          /*  {
            name: "Warehouse Inventory",
            to: "warehouse-inventory/inventory-list",
          }, */

          {
            name: "Supply Center",
            to: "supply-center",
          },
          {
            name: "GL Opening Balance",
            to: "journal-modification",
          },
          {
            name: "Sales Rep",
            to: "sales-rep/employees",
          },
          ...(isIronRod
            ? [
                ...(isAdmin || isCashier
                  ? [
                      {
                        name: "Approve Pending Invoice",
                        to: "approval",
                      },
                      ...(generalSettings.store
                        ? [
                            {
                              name: "Pending Online Orders",
                              to: "approval-store",
                            },
                            ...(generalSettings?.customerCanEditPriceInCart
                              ? [
                                  {
                                    name: "Pending Online Quotes",
                                    to: "approval-store-quotation",
                                  },
                                ]
                              : []),
                            {
                              name: "Pending Payments from Online Store",
                              to: "approval-store-pending-payment",
                            },
                          ]
                        : []),
                      {
                        name:
                          "Approvals (Vendor Payment, Expenses, Journal etc)",
                        to: "approval-action",
                      },
                    ]
                  : []),
              ]
            : []),
          ...(isCement
            ? [
                {
                  name: "Raise LPO (Setup ATC)",
                  onClick: () => eventBus.dispatch("showSetupAtcModal", true),
                },
                {
                  name: "Load ATC",
                  to: "load-atc",
                },
                ...(isAdmin
                  ? [
                      {
                        name: "Price Settings",
                        to: "price-settings",
                      },
                      {
                        name: "Create Region",
                        onClick: () =>
                          eventBus.dispatch("showCreateRegionModal", true),
                      },
                      {
                        name: "Create Plant",
                        onClick: () =>
                          eventBus.dispatch("showCreatePlantModal", true),
                      },
                      {
                        name: "Create Depot",
                        onClick: () =>
                          eventBus.dispatch("showCreateDepotModal", true),
                      },
                      {
                        name: "Create Product or Service Category",
                        onClick: () =>
                          eventBus.dispatch("showCreateCategoryModal", true),
                      },
                      {
                        name: "Create Sub Category",
                        onClick: () =>
                          eventBus.dispatch("showCreateSubCategoryModal", true),
                      },
                    ]
                  : []),
              ]
            : []),
          ...(isTransportAndLogistics
            ? [
                {
                  name: "Driver Salary Center",
                  to: "driver-salary",
                },
              ]
            : []),
          {
            name: "Receive Inventory",
            to: "receive-inventory",
          },
          {
            name: "Expenses Entry",
            to: "expenses-entry",
          },

          isAdmin || !(appSettings.isGigc && isCashier)
            ? {
                name: "Receive Payments",
                to: "post-payments",
              }
            : {},
          {
            name: "Reconcilation",
            to: "reconcilation/customer-ledger/Cash",
          },
          {
            name: "Due Invoices",
            to: "sales-and-invoicing/due-invoices",
          },
          {
            name: "Business Development/CRM",
            to: "business-development-crm/customer-list",
          },
          {
            name: "Document Numbering",
            to: "document-number/system",
          },
          ...(generalSettings.Item_Type === "Service"
            ? [
                {
                  name: "Operations",
                  to: "operations/jobs/",
                },
                {
                  name: "QHSE",
                  to: "qhse/jobs/",
                },
              ]
            : []),

          generalSettings.enableDrumAccount
            ? {
                name: "Drums Account",
                grandchildRoutes: [
                  {
                    name: "Update Drum Account",
                    to: "drums/update-drum-account",
                  },
                  {
                    name: "Drum Account Sheet",
                    to: "drums/drum-account-sheet",
                  },
                ],
              }
            : {},
          /*  {
            name: "Business Development/CRM",
            to: "business-development-crm",
          }, */
        ],
      },
      {
        name: "Tools",
        childRoutes: [
          ...(isAdmin
            ? [
                {
                  name: "Return Invoice Item",
                  to: "sales-and-invoicing/return-invoice-item",
                },
                {
                  name: "Replace Invoice Item",
                  to: "sales-and-invoicing/replace-invoice-item",
                },
                {
                  name: "Edit Invoice Payment",
                  to: "sales-and-invoicing/replace-payment",
                } /*  {
                  name: "Edit Invoice",
                  to: "sales-and-invoicing/edit-invoice",
                }, */,
                {
                  name: "Delete Invoice",
                  to: "sales-and-invoicing/delete-invoice",
                },
                {
                  name: "Delete Payment",
                  to: "sales-and-invoicing/delete-payment",
                },
                {
                  name: `Create ${generalSettings.pendingInvoiceType}`,
                  to: "sales-and-invoicing/create-proforma-invoice",
                },
                ...(appSettings?.isMedbury
                  ? [
                      {
                        name: `Create ${
                          generalSettings.pendingInvoiceType
                        } Without Approval`,
                        to:
                          "sales-and-invoicing/create-proforma-invoice-without-approval",
                      },
                    ]
                  : []),
                {
                  name: "Credit/Debit Memo",
                  to: "credit-debit-memo",
                },
                {
                  name: "Item Conversion",
                  to: "inventory-for-sales/item-conversion",
                },
                {
                  name: "Refund Payment",
                  to: "refund-payment",
                },
                {
                  name: "Customer Reimbursement",
                  to: "reimbursement",
                },
                {
                  name: "Analytics",
                  to: "analytics",
                },
                {
                  name: "Document Number",
                  to: "document-number/system",
                },
              ]
            : [
                {
                  name: "Credit/Debit Memo",
                  to: "credit-debit-memo",
                },
                {
                  name: "Return Invoice Item",
                  to: "sales-and-invoicing/return-invoice-item",
                },
                {
                  name: `Create ${generalSettings.pendingInvoiceType}`,
                  to: "sales-and-invoicing/create-proforma-invoice",
                },
                ...(appSettings?.isMedbury
                  ? [
                      {
                        name: `Create ${
                          generalSettings.pendingInvoiceType
                        } Without Approval`,
                        to:
                          "sales-and-invoicing/create-proforma-invoice-without-approval",
                      },
                    ]
                  : []),
              ]),
        ],
      },
      {
        name: "Warehouse",
        to: "warehouse",
        childRoutes: [...wareHouseChildren],
      },
      {
        name: "Procurement",
        childRoutes: [
          ...vendorManagement.filter(
            (el) =>
              (isCement ? el.isCementNav : false) ||
              typeof el.isCementNav === "undefined"
          ),
        ],
      },
      {
        name: "Reports",
        to: "reports",
        childRoutes: [...reportsNavItems],
      },
      {
        name: "Production",
        childRoutes: [
          {
            name: "Plants",
            to: "Production/plants",
          },

          {
            name: "Bill of Materials",
            to: "production/temlates",
          },
        ],
      },
      ...(generalSettings.Item_Type === "Service"
        ? [
            {
              name: "Maintenance",
              childRoutes: [...maintanceChildren],
            },
          ]
        : []),
      {
        name: "Account Setup",
        childRoutes: [
          isAdmin
            ? {
                name: "Chart of Account",
                to: "account-setup/chart-of-account",
              }
            : {},
          {
            name: "Journal",
            to: "journal-entry",
          },
        ],
      },
      {
        name: "Banking",
        childRoutes: [
          {
            name: "Banks",
            to: "banks",
          },
          {
            name: "Bank Account Analysis",
            to: "bank-account-analysis",
          },
          {
            name: "Cash Transfer and Lodgement",
            onClick: () => openAccountTransfer(),
          },
        ],
      },
      {
        name: "Forms and Approvals",
        to: "requisition",
        childRoutes: [
          {
            name: "Forms",
            to: "forms/company",
          },
          {
            name: "Request and Approvals",
            to: "request",
          },
          ...(isAdmin
            ? [
                {
                  name: "Fund Request Approval",
                  to:
                    "fund-request-approval?page=1&limit=40&q=&status=&type=Fund+Request&sortByDate=dateModified&FormID=&showAll=true",
                },
                {
                  name: "System Forms",
                  to: "system-form",
                },
              ]
            : []),
          {
            name: "Fill a Form",
            to: "request",
          },
        ],
      },
      ...(!isAdmin && !isEmpty(savedStaffRoles)
        ? [
            {
              name: "Human Resources",
              HR: true,
            },
          ]
        : []),
      /* {
        name: <DotsHorizontalIcon style={{ color: "#94A3B8" }} />,
      }, */
    ],
    [
      //  authUser?.company,
      generalSettings.pendingInvoiceType,
      generalSettings.enableDrumAccount,
      isCement,
      isIronRod,
      isCashier,
      isAdmin,
      isTransportAndLogistics,
      generalSettings.Item_Type,
      reportsNavItems,
    ]
  );

  const operationsNavItems = useMemo(
    () => [
      ...(authUser.AccessLavel === "High"
        ? [
            {
              name: "Quick Links",
              childRoutes: [
                {
                  name: "Operations",
                  to: "operations/jobs/",
                },
              ],
            },
            {
              name: "Maintenance",
              childRoutes: [...maintanceChildren],
            },
            {
              name: "Production",
              childRoutes: [
                {
                  name: "Plants",
                  to: "Production/plants",
                },

                {
                  name: "Bill of Materials",
                  to: "production/temlates",
                },
              ],
            },
            {
              name: "Warehouse",
              to: "warehouse",
              childRoutes: [...wareHouseChildren],
            },
          ]
        : []),
      {
        name: "Forms and Approvals",
        to: "requisition",
        childRoutes: [
          {
            name: "Request and Approvals",
            to: "request",
          },
          {
            name: "Fill a Form",
            to: "fill-a-form",
          },
        ],
      },
      {
        name: "Human Resources",
        HR: true,
      },
    ],
    [authUser]
  );

  const qhseNavItems = useMemo(
    () => [
      {
        name: "Quick Links",
        childRoutes: [
          {
            name: "QHSE",
            to: "qhse/jobs/",
          },
          {
            name: "Approve Inbound PO",
            to: "qhse/warehouse-receive-assurance",
          },
          {
            name: "C of A",
            to: "qhse/approval-action?Type=C of A",
          },
        ],
      },
      {
        name: "Forms and Approvals",
        to: "requisition",
        childRoutes: [
          {
            name: "Forms",
            to: "forms/company",
          },
          {
            name: "Request and Approvals",
            to: "request",
          },
          {
            name: "Fill a Form",
            to: "fill-a-form",
          },
        ],
      },
      {
        name: "Human Resources",
        HR: true,
      },
    ],
    []
  );

  const procurementNavItems = useMemo(
    () => [
      {
        name: "Sales Inventory",
        to: "inventory-for-sales/inventory-entry",
      },
      {
        name: "Warehouse",
        to: "warehouse",
        childRoutes: [...wareHouseChildren],
      },
      {
        name: "Vendor Management",
        childRoutes: [
          ...vendorManagement.filter(
            (el) =>
              (isCement ? el.isCementNav : false) ||
              typeof el.isCementNav === "undefined"
          ),
        ],
      },
      {
        name: "Forms and Approvals",
        to: "requisition",
        childRoutes: [
          {
            name: "Request and Approvals",
            to: "request",
          },
          {
            name: "Fill a Form",
            to: "fill-a-form",
          },
        ],
      },
      {
        name: "Human Resources",
        HR: true,
      },
    ],
    []
  );

  const buisinessDevelopmentNavItems = useMemo(
    () => [
      {
        name: "Business Development/CRM",
        to: "business-development-crm",
      },
      {
        name: "Forms and Approvals",
        to: "requisition",
        childRoutes: [
          {
            name: "Request and Approvals",
            to: "request",
          },
          {
            name: "Fill a Form",
            to: "fill-a-form",
          },
        ],
      },
      {
        name: "Human Resources",
        HR: true,
      },
    ],
    []
  );

  const documentControlNavItems = useMemo(
    () => [
      {
        name: "Document Numbering",
        to: "document-number/system",
      },

      {
        name: "Forms and Approvals",
        to: "requisition",
        childRoutes: [
          {
            name: "Forms",
            to: "forms/company",
          },
          {
            name: "Request and Approvals",
            to: "request",
          },
          {
            name: "Fill a Form",
            to: "request",
          },
        ],
      },
      {
        name: "Human Resources",
        HR: true,
      },
    ],
    []
  );

  // Cashier
  const cashierNavItems = useMemo(
    () => [
      {
        name: "Tools",
        childRoutes: [
          ...(isAdmin
            ? [
                {
                  name: "Return Invoice Item",
                  to: "sales-and-invoicing/return-invoice-item",
                },
                {
                  name: "Replace Invoice Item",
                  to: "sales-and-invoicing/replace-invoice-item",
                },
                {
                  name: "Edit Invoice Payment",
                  to: "sales-and-invoicing/replace-payment",
                },
                {
                  name: "Delete Invoice",
                  to: "sales-and-invoicing/delete-invoice",
                },
                {
                  name: "Delete Payment",
                  to: "sales-and-invoicing/delete-payment",
                },
                {
                  name: `Create ${generalSettings.pendingInvoiceType}`,
                  to: "sales-and-invoicing/create-proforma-invoice",
                },
                {
                  name: "Credit/Debit Memo",
                  to: "credit-debit-memo",
                },
                {
                  name: "Item Conversion",
                  to: "inventory-for-sales/item-conversion",
                },
                {
                  name: "Refund Payment",
                  to: "refund-payment",
                },
                {
                  name: "Analytics",
                  to: "analytics",
                },
              ]
            : [
                {
                  name: "Credit/Debit Memo",
                  to: "credit-debit-memo",
                },
                {
                  name: "Edit Invoice Payment",
                  to: "sales-and-invoicing/replace-payment",
                },
                {
                  name: "Return Invoice Item",
                  to: "sales-and-invoicing/return-invoice-item",
                },
                {
                  name: `Create ${generalSettings.pendingInvoiceType}`,
                  to: "sales-and-invoicing/create-proforma-invoice",
                },
              ]),
        ],
      },
      {
        name: "Quick Links",
        childRoutes: [
          {
            name: "Customers",
            to: "sales-and-invoicing/customer-list",
          },
          {
            name: "Sales and Invoicing",
            to: "sales-and-invoicing/invoice-list",
          },
          {
            name: "Make Invoice",
            to: "sales-and-invoicing/create-invoice",
          },
          {
            name: "Sales Inventory",
            to: "inventory-for-sales/inventory-entry",
          },
          {
            name: "Item Issuer",
            to: "inventory-for-sales/item-issuer",
          },

          {
            name: "GL Opening Balance",
            to: "journal-modification",
          },

          {
            name: "Supply Center",
            to: "supply-center",
          },
          ...(isIronRod
            ? [
                ...(isAdmin || isCashier
                  ? [
                      {
                        name: "Approve Pending Invoice",
                        to: "approval",
                      },
                      {
                        name:
                          "Approvals (Vendor Payment, Expenses, Journal etc)",
                        to: "approval-action",
                      },
                    ]
                  : []),
              ]
            : []),
          ...(isCement
            ? [
                {
                  name: "Raise LPO (Setup ATC)",
                  onClick: () => eventBus.dispatch("showSetupAtcModal", true),
                },
                {
                  name: "Load ATC",
                  to: "load-atc",
                },
                ...(isAdmin
                  ? [
                      {
                        name: "Price Settings",
                        to: "price-settings",
                      },
                      {
                        name: "Create Region",
                        onClick: () =>
                          eventBus.dispatch("showCreateRegionModal", true),
                      },
                      {
                        name: "Create Plant",
                        onClick: () =>
                          eventBus.dispatch("showCreatePlantModal", true),
                      },
                      {
                        name: "Create Depot",
                        onClick: () =>
                          eventBus.dispatch("showCreateDepotModal", true),
                      },
                      {
                        name: "Create Product or Service Category",
                        onClick: () =>
                          eventBus.dispatch("showCreateCategoryModal", true),
                      },
                      {
                        name: "Create Sub Category",
                        onClick: () =>
                          eventBus.dispatch("showCreateSubCategoryModal", true),
                      },
                    ]
                  : []),
              ]
            : []),
          ...(isTransportAndLogistics
            ? [
                {
                  name: "Driver Salary Center",
                  to: "driver-salary",
                },
              ]
            : []),
          {
            name: "Receive Inventory",
            to: "receive-inventory",
          },
          {
            name: "Expenses Entry",
            to: "expenses-entry",
          },

          isAdmin || !(appSettings.isGigc && isCashier)
            ? {
                name: "Receive Payments",
                to: "post-payments",
              }
            : {},
          {
            name: "Reconcilation",
            to: "reconcilation/customer-ledger/Cash",
          },
          {
            name: "Due Invoices",
            to: "sales-and-invoicing/due-invoices",
          },
          {
            name: "Delivery",
            to: "delivery",
          },
          {
            name: "Document Numbering",
            to: "document-number/system",
          },
          ...(generalSettings.Item_Type === "Service"
            ? [
                {
                  name: "Operations",
                  to: "operations/jobs/",
                },
                {
                  name: "QHSE",
                  to: "qhse/jobs/",
                },
              ]
            : []),

          generalSettings.enableDrumAccount
            ? {
                name: "Drums Account",
                grandchildRoutes: [
                  {
                    name: "Update Drum Account",
                    to: "drums/update-drum-account",
                  },
                  {
                    name: "Drum Account Sheet",
                    to: "drums/drum-account-sheet",
                  },
                ],
              }
            : {},

          /*  {
            name: "Store",
            onClick: () => window.open("/store", "_blank"),
          }, */
        ],
      },
      {
        name: "Account Setup",
        childRoutes: [
          {
            name: "Chart of Account",
            to: "account-setup/chart-of-account",
          },
          {
            name: "Journal",
            to: "journal-entry",
            /* grandchildRoutes: [
              {
                name: "Journal Entry",
                to: "journal-entry",
              },
              {
                name: "Expenses Entry",
                to: "expenses-entry",
              },
            ], */
          },
        ],
      },
      {
        name: "Production",
        childRoutes: [
          {
            name: "Plants",
            to: "Production/plants",
          },

          {
            name: "Bill of Materials",
            to: "production/temlates",
          },
        ],
      },
      {
        name: "Banking",
        childRoutes: [
          {
            name: "Banks",
            to: "banks",
          },
          {
            name: "Bank Account Analysis",
            to: "bank-account-analysis",
          },
          {
            name: "Cash Transfer and Lodgement",
            onClick: () => openAccountTransfer(),
          },
        ],
      },
      {
        name: "Reports",
        to: "reports",
        childRoutes: [...reportsNavItems],
      },
      {
        name: "Warehouse",
        to: "warehouse",
        childRoutes: [...wareHouseChildren],
      },
      {
        ...(generalSettings.Item_Type === "Service"
          ? { name: "Procurement" }
          : { name: "Vendor Management" }),
        childRoutes: [
          ...vendorManagement.filter(
            (el) =>
              (isCement ? el.isCementNav : false) ||
              typeof el.isCementNav === "undefined"
          ),
        ],
      },
      ...(generalSettings.Item_Type === "Service"
        ? [
            {
              name: "Maintenance",
              childRoutes: [...maintanceChildren],
            },
          ]
        : []),
      {
        name: "Forms and Approvals",
        to: "requisition",
        childRoutes:
          generalSettings.Item_Type === "Service"
            ? [
                {
                  name: "Request and Approvals",
                  to: "request",
                },
                {
                  name: "Fill a Form",
                  to: "fill-a-form",
                },
              ]
            : [
                {
                  name: "Request and Approvals",
                  to: "request",
                },
              ],
      },
    ],
    [
      //  authUser?.company,
      generalSettings.pendingInvoiceType,
      generalSettings.enableDrumAccount,
      isCement,
      isIronRod,
      isCashier,
      isAdmin,
      isTransportAndLogistics,
      generalSettings.Item_Type,
      reportsNavItems,
    ]
  );

  // Maintenance
  const maintenanceNavItems = useMemo(
    () => [
      {
        name: "Warehouse",
        to: "warehouse",
        childRoutes: [...wareHouseChildren],
      },
      {
        name: "Production",
        childRoutes: [
          {
            name: "Plants",
            to: "Production/plants",
          },

          {
            name: "Bill of Materials",
            to: "production/temlates",
          },
        ],
      },
      {
        name: "Maintenance",
        childRoutes: [...maintanceChildren],
      },
      {
        name: "Forms and Approvals",
        to: "requisition",
        childRoutes: [
          {
            name: "Request and Approvals",
            to: "request",
          },
          {
            name: "Fill a Form",
            to: "request",
          },
        ],
      },
      {
        name: "Human Resources",
        HR: true,
      },
    ],
    [isMaintenance, authUser]
  );

  // Default
  const defaultNavItems = useMemo(
    () => [
      {
        name: "Forms and Approvals",
        to: "requisition",
        childRoutes: [
          {
            name: "Request and Approvals",
            to: "request",
          },
          {
            name: "Fill a Form",
            to: "request",
          },
        ],
      },
      {
        name: "Human Resources",
        HR: true,
      },
    ],
    [isOperations, authUser]
  );

  // Government
  const governmentNavItems = useMemo(
    () => [
      {
        name: "Tools",
        childRoutes: [
          {
            name: "Return Invoice Item",
            to: "sales-and-invoicing/return-invoice-item",
          },
          {
            name: "Replace Invoice Item",
            to: "sales-and-invoicing/replace-invoice-item",
          },
          {
            name: "Edit Invoice Payment",
            to: "sales-and-invoicing/replace-payment",
          } /*  {
            name: "Edit Invoice",
            to: "sales-and-invoicing/edit-invoice",
          }, */,
          {
            name: "Delete Invoice",
            to: "sales-and-invoicing/delete-invoice",
          },
          {
            name: "Delete Payment",
            to: "sales-and-invoicing/delete-payment",
          },
          {
            name: `Create ${generalSettings.pendingInvoiceType}`,
            to: "sales-and-invoicing/create-proforma-invoice",
          },
          {
            name: "Credit/Debit Memo",
            to: "credit-debit-memo",
          },
          {
            name: "Item Conversion",
            to: "inventory-for-sales/item-conversion",
          },
          {
            name: "Refund Payment",
            to: "refund-payment",
          },
          {
            name: "Reimbursement",
            to: "reimbursement",
          },
          {
            name: "Analytics",
            to: "analytics",
          },
        ],
      },
      {
        name: "Quick Links",
        childRoutes: [
          {
            name: "Customers",
            to: "sales-and-invoicing/customer-list",
          },
          {
            name: "Sales and Invoicing",
            to: "sales-and-invoicing/invoice-list",
          },
          {
            name: "Make Invoice",
            to: "sales-and-invoicing/create-invoice",
          },
          {
            name: "Inventory For Sales",
            to: "inventory-for-sales/inventory-entry",
          },
          {
            name: "Item Issuer",
            to: "inventory-for-sales/item-issuer",
          },
          {
            name: "Supply Center",
            to: "supply-center",
          },
          {
            name: "Approve Pending Invoice",
            to: "approval",
          },
          {
            name: "Approvals (Vendor Payment, Expenses, Journal etc)",
            to: "approval-action",
          },
          {
            name: "Receive Inventory",
            to: "receive-inventory",
          },
          {
            name: "Expenses Entry",
            to: "expenses-entry",
          },
          {
            name: "Receive Payments",
            to: "post-payments",
          },
          {
            name: "Reconcilation",
            to: "reconcilation/customer-ledger/Cash",
          },
          {
            name: "Due Invoices",
            to: "sales-and-invoicing/due-invoices",
          },

          {
            name: "Document Numbering",
            to: "document-number/system",
          },
          ...(generalSettings.Item_Type === "Service"
            ? [
                {
                  name: "Operations",
                  to: "operations/jobs/",
                },
                {
                  name: "QHSE",
                  to: "qhse/jobs/",
                },
              ]
            : []),
        ],
      },
      {
        name: "Account Setup",
        childRoutes: [
          isAdmin
            ? {
                name: "Chart of Account",
                to: "account-setup/chart-of-account",
              }
            : {},
          {
            name: "Journal",
            to: "journal-entry",
          },
        ],
      },
      {
        name: "Banking",
        childRoutes: [
          {
            name: "Banks",
            to: "banks",
          },
          {
            name: "Bank Account Analysis",
            to: "bank-account-analysis",
          },
          {
            name: "Cash Transfer and Lodgement",
            onClick: () => openAccountTransfer(),
          },
        ],
      },
      {
        name: "Reports",
        to: "reports",
        childRoutes: [...reportsNavItems],
      },
      {
        name: "Warehouse",
        to: "warehouse",
        childRoutes: [...wareHouseChildren],
      },
      {
        ...(generalSettings.Item_Type === "Service"
          ? { name: "Procurement" }
          : { name: "Vendor Management" }),
        childRoutes: [
          ...vendorManagement.filter(
            (el) =>
              (isCement ? el.isCementNav : false) ||
              typeof el.isCementNav === "undefined"
          ),
        ],
      },
      {
        name: "Production",
        childRoutes: [
          {
            name: "Plants",
            to: "Production/plants",
          },

          {
            name: "Bill Of Materials",
            to: "production/temlates",
          },
        ],
      },
      ...(generalSettings.Item_Type === "Service"
        ? [
            {
              name: "Maintenance",
              childRoutes: [...maintanceChildren],
            },
          ]
        : []),
      {
        name: "Forms and Approvals",
        to: "requisition",
        childRoutes:
          generalSettings.Item_Type === "Service"
            ? [
                {
                  name: "Forms",
                  to: "forms/company",
                },
                {
                  name: "Request and Approvals",
                  to: "request",
                },
                {
                  name: "Fund Request Approval",
                  to:
                    "fund-request-approval?page=1&limit=40&q=&status=&type=Fund+Request&sortByDate=dateModified&FormID=&showAll=true",
                },
                {
                  name: "Fill a Form",
                  to: "request",
                },
              ]
            : [
                {
                  name: "Request and Approvals",
                  to: "request",
                },
              ],
      },
    ],
    [
      //  authUser?.company,
      generalSettings.pendingInvoiceType,
      generalSettings.enableDrumAccount,
      isCement,
      isIronRod,
      isCashier,
      isAdmin,
      isTransportAndLogistics,
      generalSettings.Item_Type,
      reportsNavItems,
    ]
  );

  const { getRootRoles, resolveName } = useResolveRootRoles();
  const resolveNav = (department) => {
    department = toLower(department);
    const navItems =
      department === "admin" || !isEmpty(savedStaffRoles)
        ? items
        : ["store", "warehouse"].includes(department)
        ? storeUserNav
        : ["sales", "sales manager", "hr"].includes(department)
        ? salesUserNav
        : department === "qhse"
        ? qhseNavItems
        : department === "operations"
        ? operationsNavItems
        : department === "procurement"
        ? procurementNavItems
        : department === "business development"
        ? buisinessDevelopmentNavItems
        : department === "document control"
        ? documentControlNavItems
        : ["cashier", "accountant"].includes(department)
        ? cashierNavItems
        : department === "maintenance"
        ? maintenanceNavItems
        : department === "government invexerp"
        ? governmentNavItems
        : department === "hospital management board" ||
          department === "primary healthcare center" ||
          department === "rivers state ministry of health"
        ? []
        : department === "supply and logistics"
        ? [
            {
              name: "Supply Center",
              to: "supply-center",
            },
            { name: "Delivery", to: "delivery" },
          ]
        : defaultNavItems;

    return getRootRoles({
      parent: "",
      navItems,
    });
  };

  const switchCompany = async (company) => {
    //  console.log(company);
    try {
      setSmShow(true);
      // logout old company
      // ---------------------------------------------------------------------
      const res = await fetch(`${backendUrl}/api/auth/logout`, {
        method: "POST",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        body: JSON.stringify({ Staff_ID: authUser.Staff_ID }),
        credentials: "include",
      });

      if (!res.ok) throw new Error();

      // HR Service
      let HRres = null;
      if (IS_HR) {
        HRres = await fetch(`${HRBackEndUrl}/users/switch-company`, {
          method: "POST",
          headers: {
            Accept: "Application/json",
            "Content-Type": "Application/json",
          },
          body: JSON.stringify({
            Staff_ID: authUser.Staff_ID,
            backendApi: company,
          }),
          credentials: "include",
        });

        if (!HRres.ok) throw new Error();
      }

      // login new company
      //-------------------------------------------------------------------------
      const loginResponse = await fetch(`${company.url}/api/auth/authorize`, {
        method: "POST",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        body: JSON.stringify({
          username: authUser.username,
          oldToken: authUser.token,
        }),
        credentials: "include",
      });
      if (!loginResponse.ok) {
        logout();
      }

      const { user } = await loginResponse.json();
      if (isEmpty(user)) {
        toast.error("User not found in company: Try again");
        logout();
      }

      // HR Service
      if (IS_HR) {
        const HRData = await HRres.json();
        setCookies(HRData);
      }

      setUser({
        ...user,
        timestamp: String(Date.now()),
        company: company.name,
      });
    } catch (err) {
      console.log(err);
      toast.error("Unable switch company, Please try again");
    } finally {
      setSmShow(false);
    }
  };

  eventBus.useCustomEventListener("LOGOUT", (options) => {
    logoutUser(options);
  });

  return (
    <nav className="NavBar">
      <div className="d-flex align-items-center">
        <NavLink
          to={
            isAdmin && !isAccountExpired ? "/dashboard" : "/payment-and-billing"
          }
          className="d-flex align-items-center"
        >
          <div className="home-icon d-none">
            <HomeIcon />
          </div>
          <Logo className="logo" />
        </NavLink>
        <ul ref={navHolder} className={navOpen ? "open" : "closed"}>
          {!isAccountExpired &&
            resolveNav(authUser.Department).map((item, index) => (
              <li key={index}>
                {!item.childRoutes ? (
                  item.HR ? (
                    IS_HR ? (
                      <a
                        href={HRFrontEndUrl}
                        className={` ${
                          location.pathname.startsWith(`/${item.to}`)
                            ? "active fw-6"
                            : ""
                        }`}
                      >
                        {resolveName(item.name)}
                      </a>
                    ) : (
                      <Link
                        to="/no-hr"
                        className={`btn fw-normal ${
                          location.pathname.startsWith(`/${item.to}`)
                            ? "fw-6"
                            : ""
                        }`}
                      >
                        {resolveName(item.name)}
                      </Link>
                    )
                  ) : (
                    <Link
                      to={item?.to ? item.to : ""}
                      className={`btn fw-normal ${
                        location.pathname.startsWith(`/${item.to}`)
                          ? "fw-6"
                          : ""
                      }`}
                    >
                      {resolveName(item.name)}
                    </Link>
                  )
                ) : item.name === "Quick Links" ? (
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="white"
                      bsPrefix={`toggle ${
                        location.pathname.startsWith(`/${item.to}`)
                          ? "active fw-6"
                          : ""
                      }`}
                    >
                      {resolveName(item.name)}
                    </Dropdown.Toggle>
                    {/* "Large Quick Links" */}
                    {isAdmin && generalSettings?.largeQuickLink ? (
                      <QuickLinkComponent
                        items={item.childRoutes}
                        isIronRod={isIronRod}
                        isAdmin={isAdmin}
                        isCashier={isCashier}
                        isCement={isCement}
                        isTransportAndLogistics={isTransportAndLogistics}
                        isGigc={appSettings.isGigc}
                        Item_Type={generalSettings.Item_Type}
                        enableDrumAccount={generalSettings.enableDrumAccount}
                      />
                    ) : (
                      <Dropdown.Menu
                        popperConfig={{
                          strategy: "fixed",
                        }}
                        renderOnMount
                        className={
                          item.name !== "Reports" ? "overflow-y-auto" : ""
                        }
                      >
                        {item.childRoutes.map((el, index) => (
                          <Fragment key={index}>
                            {el.to ? (
                              <Dropdown.Item as="div">
                                <Link
                                  to={`${item.to ? `/${item.to}` : ""}/${
                                    el.to
                                  }`}
                                >
                                  {resolveName(el.name)}
                                </Link>
                              </Dropdown.Item>
                            ) : el.onClick ? (
                              <Dropdown.Item
                                onClick={() => el.onClick()}
                                as="button"
                              >
                                {resolveName(el.name)}{" "}
                                <span className="shortcut">{el?.shortcut}</span>
                              </Dropdown.Item>
                            ) : el.grandchildRoutes ? (
                              <div className="position-relative submenu">
                                <button
                                  className="dropdown-item justify-content-between submenu-toggle px-3"
                                  href="#"
                                >
                                  {resolveName(el.name)} <span>&raquo;</span>
                                </button>
                                {el.grandchildRoutes && (
                                  <ul className="dropdown-menu dropdown-submenu text-left">
                                    {el.grandchildRoutes.map((grandEl) => (
                                      <li className="w-100" key={grandEl.name}>
                                        <Dropdown.Item as="div">
                                          <Link to={`/${grandEl.to}`}>
                                            {resolveName(grandEl.name)}
                                          </Link>
                                        </Dropdown.Item>
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </div>
                            ) : null}
                          </Fragment>
                        ))}
                      </Dropdown.Menu>
                    )}
                  </Dropdown>
                ) : (
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="white"
                      bsPrefix={`toggle ${
                        location.pathname.startsWith(`/${item.to}`)
                          ? "active fw-6"
                          : ""
                      }`}
                    >
                      {resolveName(item.name)}
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      popperConfig={{
                        strategy: "fixed",
                      }}
                      renderOnMount
                      className={
                        item.name !== "Reports" ? "overflow-y-auto" : ""
                      }
                    >
                      {item.childRoutes.map((el, index) => (
                        <Fragment key={index}>
                          {el.to ? (
                            <Dropdown.Item as="div">
                              <Link
                                to={`${item.to ? `/${item.to}` : ""}/${el.to}`}
                              >
                                {resolveName(el.name)}
                              </Link>
                            </Dropdown.Item>
                          ) : el.onClick ? (
                            <Dropdown.Item
                              onClick={() => el.onClick()}
                              as="button"
                            >
                              {resolveName(el.name)}{" "}
                              <span className="shortcut">{el?.shortcut}</span>
                            </Dropdown.Item>
                          ) : el.grandchildRoutes ? (
                            <div className="position-relative submenu">
                              <button
                                className="dropdown-item justify-content-between submenu-toggle px-3"
                                href="#"
                              >
                                {resolveName(el.name)} <span>&raquo;</span>
                              </button>
                              {el.grandchildRoutes && (
                                <ul className="dropdown-menu dropdown-submenu text-left">
                                  {el.grandchildRoutes.map((grandEl) => (
                                    <li className="w-100" key={grandEl.name}>
                                      <Dropdown.Item as="div">
                                        <Link to={`/${grandEl.to}`}>
                                          {resolveName(grandEl.name)}
                                        </Link>
                                      </Dropdown.Item>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </div>
                          ) : null}
                        </Fragment>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </li>
            ))}

          {isAdmin ? (
            <li>
              <Dropdown>
                <Dropdown.Toggle variant="white" bsPrefix={`toggle`}>
                  Switch Branch
                </Dropdown.Toggle>
                <Dropdown.Menu
                  popperConfig={{
                    strategy: "fixed",
                  }}
                  renderOnMount
                >
                  {backendApis
                    .filter((el) => !el.viewOnly)
                    .map((el) => (
                      <Dropdown.Item
                        key={el.name}
                        as="button"
                        className={`${
                          authUser?.company === el.name ? "active" : ""
                        }`}
                        onClick={() => switchCompany(el)}
                      >
                        {el.name}
                      </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
              </Dropdown>
            </li>
          ) : null}
        </ul>
      </div>

      <div className="d-flex align-items-center">
        {/*   <ChevronDownIcon style={{ color: "#0A4A95" }} /> */}
        <div className="mx-3">
          <Notification
            backendUrl={backendUrl}
            Staff_ID={authUser?.Staff_ID}
            sound={authUser?.sound}
          />
        </div>
        <Dropdown>
          <Dropdown.Toggle
            variant="white"
            className="btn d-flex border-0 align-items-center p-1"
          >
            <Avatar
              className="avatar"
              src={photoUrl}
              name={authUser?.Name}
              company={authUser?.company}
              department={authUser?.Department}
              userId={authUser?.Staff_ID}
            />
          </Dropdown.Toggle>
          <Dropdown.Menu
            popperConfig={{
              strategy: "fixed",
            }}
            renderOnMount
          >
            {!isAccountExpired && (
              <Dropdown.Item as="button" onClick={() => setShowProfile(true)}>
                Edit Profile
              </Dropdown.Item>
            )}

            {isAdmin && !isAccountExpired && (
              <>
                <Dropdown.Item as="button">
                  <Link to="/dashboard/user-managemnt">User Management</Link>
                </Dropdown.Item>
                <Dropdown.Item as="button">
                  <Link to="/dashboard/company-information-settings">
                    Company Information Settings
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  onClick={() => setShowGeneralSettingsModal(true)}
                >
                  Settings
                </Dropdown.Item>
              </>
            )}

            <Dropdown.Item as="button">
              <Link to="/payment-and-billing">Payments & Billing</Link>
            </Dropdown.Item>

            {/*  {isAdmin && (
              <>
                <Dropdown.Item
                  as="button"
                  onClick={() => setShowRolesPriviledgesModal(true)}
                >
                  Roles & Priviledge
                </Dropdown.Item>
              </>
            )} */}
            <Dropdown.Item as="button">
              {services.frontendUrl === `http://localhost:3000` ? (
                <Link
                  to={`/user-feedBack?UserName=${authUser.Name}&Useremail=${
                    authUser.email
                  }`}
                >
                  Feedback
                </Link>
              ) : (
                <a
                  href={`https://feedback.invexonline.com/user-feedBack?UserName=${
                    authUser.Name
                  }&Useremail=${authUser.email}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Feedback
                </a>
              )}
            </Dropdown.Item>
            {/*  <Dropdown.Item
              as="button"
              onClick={() => setShowServiceSettingsModal(true)}
            >
              Service Settings
            </Dropdown.Item> */}
            <Dropdown.Item as="button" onClick={() => logoutUser()}>
              Logout
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item as="button" onClick={checkCacheStatus}>
              Version {process.env?.REACT_APP_VERSION}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <button onClick={() => toggleNavOpen()} className="btn p-1 d-md-none">
          <HamburgerIcon />
        </button>
      </div>
      {showProfile && (
        <UpdateProfileModal
          show={showProfile}
          onHide={() => setShowProfile(false)}
        />
      )}
      {isAccountTransferOpen && (
        <AccountTransferModal
          setShowAccountTransferModal={setIsAccountTransferOpen}
        />
      )}
      {showGeneralSettingsModal && (
        <GeneralSettingsModal
          showGeneralSettingsModal={showGeneralSettingsModal}
          setShowGeneralSettingsModal={setShowGeneralSettingsModal}
        />
      )}
      {showRolesPriviledgesModal && (
        <RolesPriviledgesModal
          showRolesPriviledgesModal={showRolesPriviledgesModal}
          setShowRolesPriviledgesModal={setShowRolesPriviledgesModal}
        />
      )}

      {showRootRolesPriviledgesModal && (
        <RootRolesPriviledgesModal
          showRolesPriviledgesModal={showRootRolesPriviledgesModal}
          setShowRolesPriviledgesModal={setShowRootRolesPriviledgesModal}
        />
      )}

      {showServiceSettingsModal && (
        <ServiceSettingsModal
          showServiceSettingsModal={showServiceSettingsModal}
          setShowServiceSettingsModal={setShowServiceSettingsModal}
        />
      )}
      <ModalLoader show={smShow} />
      {/*  Regions, plants cat, sub cats  */}
      <CementModals />
    </nav>
  );
}
