import { useMemo, useState } from "react";

import MagnifyIcon from "mdi-react/MagnifyIcon";
import { Modal, Table, Form, Button, InputGroup } from "react-bootstrap";
import { services, durationOptions, appSettings } from "../../config";
import useDebounce, { useEffectOnce, useUpdateEffect } from "../../utils/hooks";
import { queryActions } from "../../utils/reactQueryActions";
import "./../../assets/scss/item-select-modal.scss";
import currency from "currency.js";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import NumberCustomInput from "../utils/NumberCustomInput";
import { useFormik } from "formik";
import * as yup from "yup";
import Select from "react-select";
import { useEffect } from "react";
import {
  pcsToTons,
  qtyFormat,
  qtyFormatToString,
  tonsToPcs,
} from "../../utils/helpers";
import ConfirmDialog from "../ConfirmDialogue";
import { useAuth } from "../../hooks/useAuth";
import { cloneDeep, isEmpty, lowerCase } from "lodash";
import { useStoreActions, useStoreState } from "easy-peasy";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import ModalLoader from "../utils/ModalLoader";
import Datetime from "react-datetime";
import {
  CashSelectIcon,
  ChequeSelectIcon,
  CreditMemoSelectIcon,
  CreditSelectIcon,
  DirectTransferSelectIcon,
} from "../Icons";
import { format } from "date-fns";
import moment from "moment";
import { currenciesOptions } from "../../utils/currencies";

const transferFrom = [
  {
    label: "Cash",
    value: "Cash",
  },
  {
    label: "Bank Account",
    value: "Bank Account",
  },
];

export default function AccountTransferModal({
  setShowAccountTransferModal,
  refetch,
}) {
  const paymentMethod = [
    {
      icon: <CashSelectIcon />,
      label: "Cash",
      value: "Cash",
    },

    {
      icon: <ChequeSelectIcon />,
      label: "Cheque",
      value: "Cheque",
    },
    {
      icon: <CreditSelectIcon />,
      label: "Credit/Debit Card (POS)",
      value: "Credit/Debit Card",
    },

    {
      icon: <CreditMemoSelectIcon />,
      label: "Credit Memo",
      value: "Credit Memo",
    },
    {
      icon: <DirectTransferSelectIcon />,
      label: "Direct Bank Transfer",
      value: "Direct Bank Transfer",
    },
    /* {
      icon: <CashSelectIcon />,
      label: "Split Payment",
      value: "Split Payment",
    }, */
  ];

  const { backendUrl } = useAuth();

  const generalSettings = useStoreState((state) => state.generalSettings);
  const fetchSetUpData = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/journal/set-up`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    data.banks = data?.banks
      .filter((el) => el?.Description)
      .map((el) => ({
        ...el,
        label: `${el.Description} ${el?.currency ? `(${el?.currency})` : ``}`,
        value: el?.Description,
      }));

    data.imprest = data?.imprest
      .filter((el) => el?.Description)
      .map((el) => ({
        ...el,
        label: el?.Description,
        value: el?.Description,
      }));

    data.cashAtHand = data?.cashAtHand
      .filter((el) => el?.Description)
      .map((el) => ({
        ...el,
        label: el?.Description,
        value: el?.Description,
      }));

    return data;
  };

  const { data = { banks: [], imprest: [], cashAtHand: [] } } = useQuery(
    [queryActions.JOURNAL_SETUP],
    () => fetchSetUpData(),
    {
      keepPreviousData: true,
    }
  );

  const accountTransfer = async (payload) => {
    let response = await fetch(
      payload.hasForeignCurrency
        ? `${backendUrl}/api/journal/transfer-to-dom`
        : `${backendUrl}/api/journal/transfer`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const accountTransferMutation = useMutation(
    (payload) => accountTransfer(payload),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
        formik.resetForm();
        if (refetch) refetch();
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      transferFrom: "Cash",
      transferTo: "Bank Account",
      bank: "",
      newInstallment: "",
      transferFromAccount: "Cash on hand",
      transferToAccount: "",
      //---
      salesDate: moment(),
      name: "",
      tellerNumber: "",
      remark: "",
      PaymentType: "Cash",
      conversionAmount: generalSettings.dollarInBaseCurrency,
    },
    validationSchema: yup.object().shape({
      newInstallment: yup.string().required("Required"),
      // accountName: yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      values = cloneDeep(values);
      if (isEmpty(values.transferFromAccount)) {
        return toast.error("Please select 'Tranfer from' Account Name");
      }

      if (isEmpty(values.transferToAccount)) {
        return toast.error("Please select 'Transfer to' Account Name");
      }

      if (values.transferTo === values.transferFrom) {
        if (values.transferFromAccount === values.transferToAccount) {
          return toast.error("You cannot transfer to same Account");
        }
      }

      if (
        await ConfirmDialog({
          title: "Post Transaction",
          description: "Are you sure, you want to make this transaction",
          summaryTitle: "Summary",
          summary: {
            "Transfer From": `${values.transferFrom} - ${
              values.transferFromAccount
            }`,
            "Transfer Amount": currency(values.newInstallment, {
              symbol: transferFromCurrency.symbol,
            }).format(),
            PaymentType: values.PaymentType,
            "Transfer To": `${values.transferTo} - ${values.transferToAccount}`,
            Date: format(new Date(values.salesDate), "dd MMM, yyyy"),
          },
        })
      ) {
        // Cleanup for backend
        let amountFrom = 0,
          ammountTo = 0;

        const baseCurrency = "NGN";

        if (
          transferFromCurrency.currency !== transferToCurrency.currency &&
          exchangeCurrency !== "NGN"
        ) {
          if (
            transferFromCurrency.currency !== baseCurrency &&
            transferToCurrency.currency === baseCurrency
          ) {
            amountFrom = values.newInstallment;
            ammountTo = currency(values.newInstallment).multiply(
              values.conversionAmount
            ).value;
          } else if (
            transferFromCurrency.currency === baseCurrency &&
            transferToCurrency.currency !== baseCurrency
          ) {
            amountFrom = values.newInstallment;
            ammountTo = currency(values.newInstallment).divide(
              values.conversionAmount
            ).value;
          } else {
            amountFrom = values.newInstallment;
            ammountTo = values.newInstallment;
          }
          accountTransferMutation.mutate({
            ...values,
            amountFrom,
            ammountTo,
            transferFromCurrency: transferFromCurrency.currency,
            transferToCurrency: transferToCurrency.currency,
            hasForeignCurrency: true,
          });
        } else {
          accountTransferMutation.mutate({
            ...values,
            hasForeignCurrency: false,
          });
        }
      }
    },
  });

  const handleTransferFrom = (value) => {
    formik.setFieldValue("transferFrom", value);
    if (value === "Cash") {
      formik.setFieldValue("transferFromAccount", data.cashAtHand[0].value);
    } else {
      formik.setFieldValue("transferFromAccount", data.banks[0].value);
    }
  };

  const handleTransferTo = (value) => {
    formik.setFieldValue("transferTo", value);
    if (value === "Cash") {
      formik.setFieldValue("transferToAccount", data.cashAtHand[0].value);
    } else {
      formik.setFieldValue("transferToAccount", data.banks[0].value);
    }
  };

  const transferFromCurrency = useMemo(() => {
    const accountCurrency = formik.values?.transferFromAccount
      ? data.banks.find((el) => el.value === formik.values.transferFromAccount)
          ?.currency
      : "NGN";

    const foundCurrency = currenciesOptions.find(
      (el) => el.cc === (accountCurrency ? accountCurrency : "NGN")
    );
    return foundCurrency;
  }, [formik.values.transferFromAccount, formik.values.transferFrom]);

  const transferToCurrency = useMemo(() => {
    const accountCurrency = formik.values?.transferToAccount
      ? data.banks.find((el) => el.value === formik.values.transferToAccount)
          ?.currency
      : "NGN";

    const foundCurrency = currenciesOptions.find(
      (el) => el.cc === (accountCurrency ? accountCurrency : "NGN")
    );
    return foundCurrency;
  }, [formik.values.transferToAccount, formik.values.transferTo]);

  const exchangeCurrency = useMemo(() => {
    return [transferFromCurrency?.currency, transferToCurrency?.currency].every(
      (val, i, arr) => val === arr[0]
    )
      ? transferFromCurrency?.currency
      : "USD";
  }, [transferFromCurrency, transferToCurrency]);

  const convertedAmount = useMemo(() => {
    const baseCurrency = "NGN";
    return transferFromCurrency.currency !== baseCurrency &&
      transferToCurrency.currency === baseCurrency
      ? currency(formik.values.newInstallment).multiply(
          formik.values.conversionAmount
        ).value
      : currency(formik.values.newInstallment).divide(
          formik.values.conversionAmount
        ).value;
  }, [
    transferFromCurrency,
    transferToCurrency,
    formik.values.newInstallment,
    formik.values.conversionAmount,
  ]);

  return (
    <>
      <Modal
        show={true}
        onHide={() => setShowAccountTransferModal(false)}
        dialogClassName="item-select-modal edit"
        backdropClassName={`global-backdrop`}
        centered={true}
        animation={false}
        enforceFocus={false}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h1>Account Transfer</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="body-content">
            <div className="postion-relative">
              <Form noValidate onSubmit={formik.handleSubmit}>
                <Form.Group className="mb-3 pb-1">
                  <Form.Label>Transfer From</Form.Label>
                  <Select
                    classNamePrefix={"form-select"}
                    options={transferFrom}
                    value={transferFrom.find(
                      (el) => el.value === formik.values.transferFrom
                    )}
                    onChange={({ value }) => {
                      handleTransferFrom(value);
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3 pb-1">
                  <Form.Label>Account Name</Form.Label>
                  {formik.values.transferFrom === "Cash" ? (
                    <Select
                      classNamePrefix={"form-select"}
                      options={data.cashAtHand}
                      value={data.cashAtHand.find(
                        (el) => el.value === formik.values.transferFromAccount
                      )}
                      onChange={({ value }) =>
                        formik.setFieldValue("transferFromAccount", value)
                      }
                    />
                  ) : (
                    <Select
                      classNamePrefix={"form-select"}
                      options={data.banks}
                      value={data.banks.find(
                        (el) => el.value === formik.values.transferFromAccount
                      )}
                      onChange={({ value }) =>
                        formik.setFieldValue("transferFromAccount", value)
                      }
                    />
                  )}
                </Form.Group>

                <Form.Group className="mb-3 pb-1">
                  <Form.Label>Trans Type</Form.Label>
                  <Form.Control readOnly defaultValue={"Receipt"} />
                </Form.Group>

                <Form.Group className="mb-3 pb-1">
                  <Form.Label>Balance</Form.Label>
                  <CurrencyCustomInput
                    currencySymbol={transferFromCurrency?.symbol}
                    name="PriceSold"
                    placeholder="0.00"
                    value={
                      formik.values.transferFrom === "Cash"
                        ? data.cashAtHand.find(
                            (el) =>
                              el.value === formik.values.transferFromAccount
                          )?.RunningBalanceCalculated
                        : data.banks.find(
                            (el) =>
                              el.value === formik.values.transferFromAccount
                          )?.RunningBalanceCalculated
                    }
                    isDisabled={true}
                  />
                </Form.Group>

                <hr className="my-4" />

                <Form.Group className="mb-3 pb-1">
                  <Form.Label>Date</Form.Label>
                  <Datetime
                    timeFormat={false}
                    closeOnSelect={true}
                    closeOnClickOutside={true}
                    dateFormat="MMM DD, YYYY"
                    name="salesDate"
                    inputProps={{
                      className: `date-input form-control ${
                        formik.touched.salesDate && !!formik.errors.salesDate
                          ? "is-invalid"
                          : ""
                      }`,
                      placeholder: "Select date",
                      readOnly: true,
                    }}
                    value={formik.values.salesDate}
                    onChange={(date) => {
                      formik.setFieldValue("salesDate", date, true);
                    }}
                    onBlur={() => formik.setFieldTouched("salesDate", true)}
                  />
                </Form.Group>

                <Form.Group className="mb-3 pb-1">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3 pb-1">
                  <Form.Label>Teller Number</Form.Label>
                  <Form.Control
                    name="tellerNumber"
                    value={formik.values.tellerNumber}
                    onChange={formik.handleChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3 pb-1">
                  <Form.Label>Transfer Amount</Form.Label>
                  <CurrencyCustomInput
                    currencySymbol={transferFromCurrency?.symbol}
                    name="newInstallment"
                    placeholder="0.00"
                    decimalsLimit={2}
                    value={formik.values.newInstallment}
                    onValueChange={(value, name) =>
                      formik.setFieldValue(name, value)
                    }
                    isInvalid={
                      formik.touched.newInstallment &&
                      !!formik.errors.newInstallment
                    }
                    onBlur={() =>
                      formik.setFieldTouched("newInstallment", true)
                    }
                  />
                  {formik.touched.newInstallment &&
                  !!formik.errors.newInstallment ? (
                    <span className="custom-invalid-feedback">
                      {formik.errors.newInstallment}
                    </span>
                  ) : null}
                </Form.Group>

                {/*  <Form.Group className="mb-3 pb-1">
                  <Form.Label>Balance on Imprest Account</Form.Label>
                  <CurrencyCustomInput
                    name="PriceSold"
                    placeholder="0.00"
                    value={
                      data.imprest.find(
                        (el) => el.value === formik.values.accountName
                      )?.RunningBalanceCalculated
                    }
                    isDisabled={true}
                  />
                </Form.Group> */}

                {/* <Form.Group className="mb-3 pb-1">
                  <Form.Label>Select Bank</Form.Label>
                  <Select
                    classNamePrefix={"form-select"}
                    className={
                      formik.touched.bank && !!formik.errors.bank
                        ? "is-invalid"
                        : ""
                    }
                    options={data.banks}
                    value={data.banks.find(
                      (el) => el.value === formik.values.bank
                    )}
                    isDisabled={formik.values.transferFrom !== "Bank Account"}
                    onChange={({ value }) =>
                      formik.setFieldValue("bank", value)
                    }
                  />
                  {formik.touched.bank && formik.errors.bank ? (
                    <span className="text-danger mt-2">
                      {formik.errors.bank}
                    </span>
                  ) : null}
                </Form.Group> */}

                <hr className="my-4" />

                <Form.Group className="mb-3 pb-1">
                  <Form.Label>Transfer To</Form.Label>
                  <Select
                    classNamePrefix={"form-select"}
                    options={transferFrom}
                    value={transferFrom.find(
                      (el) => el.value === formik.values.transferTo
                    )}
                    onChange={({ value }) => {
                      handleTransferTo(value);
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3 pb-1">
                  <Form.Label>Account Name</Form.Label>
                  {formik.values.transferTo === "Cash" ? (
                    <Select
                      classNamePrefix={"form-select"}
                      options={data.cashAtHand.filter(
                        (el) => el.value !== formik.values.transferFromAccount
                      )}
                      value={data.cashAtHand.find(
                        (el) => el.value === formik.values.transferToAccount
                      )}
                      onChange={({ value }) =>
                        formik.setFieldValue("transferToAccount", value)
                      }
                    />
                  ) : (
                    <Select
                      classNamePrefix={"form-select"}
                      options={data.banks.filter(
                        (el) => el.value !== formik.values.transferFromAccount
                      )}
                      value={data.banks.find(
                        (el) => el.value === formik.values.transferToAccount
                      )}
                      onChange={({ value }) =>
                        formik.setFieldValue("transferToAccount", value)
                      }
                    />
                  )}
                </Form.Group>

                {/*  <div className="d-flex gap-3">
                  <Form.Group>
                    <Select
                      classNamePrefix="form-select"
                      placeholder="Select Currency"
                      isSearchable={false}
                      options={currenciesOptions}
                      value={currenciesOptions.find(
                        (el) => el.value === transferToCurrency?.currency
                      )}
                      isDisabled
                    />
                  </Form.Group>

                  {transferToCurrency?.currency &&
                  transferToCurrency?.currency !== "NGN" ? (
                    <Form.Group>
                      <CurrencyCustomInput
                        currencySymbol={"Ex. Rate"}
                        name="transferToConversionAmount"
                        value={formik.values.transferToConversionAmount}
                        onValueChange={(value, name) => {
                          formik.setFieldValue(name, value);
                        }}
                        placeholder="0.00"
                      />
                    </Form.Group>
                  ) : null}
                </div> */}

                <hr className="my-4" />

                {exchangeCurrency && exchangeCurrency !== "NGN" ? (
                  <>
                    <Form.Group className="mb-3 pb-1">
                      <Form.Label>Exchange Rate</Form.Label>
                      <div className="d-flex gap-3">
                        <Form.Group>
                          <Select
                            classNamePrefix="form-select"
                            placeholder="Select Currency"
                            isSearchable={false}
                            options={currenciesOptions}
                            value={currenciesOptions.find(
                              (el) => el.value === exchangeCurrency
                            )}
                            isDisabled={true}
                          />
                        </Form.Group>

                        <Form.Group>
                          <CurrencyCustomInput
                            currencySymbol={"Ex. Rate"}
                            name="conversionAmount"
                            value={formik.values.conversionAmount}
                            onValueChange={(value, name) => {
                              formik.setFieldValue(name, value);
                            }}
                            placeholder="0.00"
                          />
                        </Form.Group>
                      </div>{" "}
                    </Form.Group>{" "}
                    {transferToCurrency?.symbol !==
                      transferFromCurrency?.symbol && (
                      <Form.Group className="mb-3 pb-1">
                        <Form.Label>Converted Transfer Amount</Form.Label>
                        <CurrencyCustomInput
                          currencySymbol={transferToCurrency?.symbol}
                          name="PriceSold"
                          placeholder="0.00"
                          value={convertedAmount}
                          isDisabled={true}
                        />
                      </Form.Group>
                    )}
                  </>
                ) : null}

                <Form.Group className="mb-3 pb-1">
                  <Form.Label>Payment Type</Form.Label>
                  <Select
                    classNamePrefix="form-select"
                    menuPlacement="bottom"
                    menuPosition="fixed"
                    placeholder="Choose method"
                    isSearchable={false}
                    value={paymentMethod.find(
                      (el) => el.value === formik.values.PaymentType
                    )}
                    options={paymentMethod}
                    onChange={({ value }) =>
                      formik.setFieldValue("PaymentType", value)
                    }
                    getOptionLabel={(el) => (
                      <div className="label-with-icon d-flex gap-2 align-items-center">
                        <span>{el.icon}</span>{" "}
                        <span className="fw-5">{el.label}</span>
                      </div>
                    )}
                  />
                </Form.Group>

                <Form.Group className="mb-3 pb-1">
                  <Form.Label>Remark</Form.Label>
                  <Form.Control
                    name="remark"
                    value={formik.values.remark}
                    onChange={formik.handleChange}
                    maxLength={50}
                  />
                </Form.Group>
              </Form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => formik.submitForm()}
            type="submit"
            className="w-100 submit-btn py-3"
            disabled={accountTransferMutation.isLoading}
          >
            Transfer Now
          </Button>
        </Modal.Footer>
      </Modal>

      <ModalLoader show={accountTransferMutation.isLoading} />
    </>
  );
}
