import { first, isEmpty } from "lodash";
import { Fragment, useState, useMemo } from "react";
import { Button, Table } from "react-bootstrap";
import { customerFullName, scrollToElement } from "../utils/helpers";
import CustomerSelectModal from "./CustomerSelectModal";
import { AddCircleIcon, MoneyDollarIcon, NoSelectedItemIcon } from "./Icons";
import PageHeader from "./PageHeader";
import Datetime from "react-datetime";
import CurrencyCustomInput from "./utils/CurrencyCustomInput";
import { Popover } from "react-tiny-popover";
import { useFormik } from "formik";
import moment from "moment";
import DatePickerCustomInput from "./utils/DatePickerCustomInput";
import useDebounce, { useEffectOnce } from "../utils/hooks";
import currency from "currency.js";
import { useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useAuth } from "../hooks/useAuth";
import ModalLoader from "./utils/ModalLoader";
import { queryActions } from "../utils/reactQueryActions";
import { last } from "lodash";
import ConfirmDialog from "./ConfirmDialogue";
import CreditDebitTableRow from "./CreditDebitTableRow";
import { fetchActionsUtil, returnRowError } from "../utils/helpers";
import { appSettings } from "../config";
import { IsPrivileged } from "./DisplayChildElement";

export default function CreateCreditDebitMemo() {
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const { backendUrl, token } = useAuth();
  const [showCustomerSelectorModal, setShowCustomerSelectorModal] = useState(
    false
  );

  const [tableData, setTableData] = useState([
    {
      Cust_ID: "",
      TransactionID: `STN${Date.now()}1`,
    },
  ]);
  const setSelectedCustomer = (customer) => {
    const lastitem = last(tableData);
    customer.TransactionID = `STN${Date.now()}`;
    console.log("customer => ", customer);
    if (lastitem.Cust_ID === "") {
      setTableData([
        ...tableData.filter((el, index) => index !== tableData.length - 1),
        customer,
        { Cust_ID: "", TransactionID: `STN${Date.now()}2` },
      ]);
    } else {
      setTableData([
        ...tableData,
        customer,
        { Cust_ID: "", TransactionID: `STN${Date.now()}1` },
      ]);
    }
  };

  const editTable = ({ index, formValues }) => {
    tableData[index] = {
      ...tableData[index],
      ...formValues,
    };
    setTableData([...tableData]);
  };

  const addNewRow = (index) => {
    if (index === tableData.length - 1) {
      setTableData([
        ...tableData,
        { Cust_ID: "", TransactionID: `STN${Date.now()}1` },
      ]);
    }
  };

  const remove = (index) => {
    const newTableData = tableData.filter((el, i) => index !== i);
    setTableData([...newTableData]);
  };

  const updateCustomersLedger = async (payload) => {
    const formData = new FormData();
    formData.append("payload", JSON.stringify(payload));

    let response = await fetch(`${backendUrl}/api/customers/post-memo`, {
      method: "POST",
      headers: {
        /*   Accept: "Application/json",
        "Content-Type": "Application/json", */
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
      body: formData,
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const updateCustomersLedgerMutation = useMutation(
    (payload) => updateCustomersLedger(payload),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
        setTableData([{ Cust_ID: "", TransactionID: `STN${Date.now()}1` }]);
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  // const accountTypeCheck = useMemo(() => {
  //   return tableData.some((d) => d?.PaymentType === "Refound");
  // }, [tableData]);

  const post = async () => {
    const table =
      tableData.length > 1
        ? tableData.filter((el, index) => index !== tableData.length - 1)
        : tableData;

    const foundEmptymemoType = table.findIndex((el) => el.PaymentType === "");
    if (foundEmptymemoType !== -1) {
      return toast.error(
        returnRowError("Select a Memo Type", foundEmptymemoType)
      );
    }

    const foundEmptyCustomerIndex = table.findIndex((el) => el.Cust_ID === "");
    if (foundEmptyCustomerIndex !== -1) {
      scrollToElement(`#newInstallment-customer-${foundEmptyCustomerIndex}`);
      return toast.error(
        returnRowError("Select a Customer", foundEmptyCustomerIndex)
      );
    }

    const foundEmptyInstallment = table.find(
      (el) => Number(el.newInstallment) <= 0
    );

    const foundIndexInstallment = table.findIndex(
      (el) => Number(el.newInstallment) <= 0
    );

    if (foundEmptyInstallment) {
      scrollToElement(`#newInstallment-${foundIndexInstallment}`);
      return toast.error(
        returnRowError("Empty or Invalid Amount", foundIndexInstallment)
      );
    }

    if (
      await ConfirmDialog({
        title: "Post Transaction",
        description: "Are you sure, you want to make this transaction",
      })
    ) {
      // Ensure only valid data are sent
      const customersData = tableData.filter(
        (el) => el.Cust_ID && el.newInstallment
      );

      updateCustomersLedgerMutation.mutate(
        {
          customers: customersData,
        },
        {
          onSuccess: () => {
            if (customersData && customersData.length === 1) {
              const customer = first(customersData);
              window.open(
                `${backendUrl}/api/invoice/pdf/memo/${
                  customer.TransactionID
                }?thermalPrinter=${appSettings.requireSalesRep}`,
                "_blank",
                "noopener,noreferrer"
              );
            }
          },
        }
      );

      console.log({
        customers: tableData.filter((el) => el.Cust_ID && el.newInstallment),
      });
    }
  };

  const getCustomer = async () => {
    let response = await fetch(
      // `${backendUrl}/api/customers?withCredit=${true}&withPaymentDue=${true}`,
      `${backendUrl}/api/customers?page=1&limit=50`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    data.customers = data.customers.map((el) => ({
      ...el,
      label: customerFullName(el),
      value: el.Cust_ID,
    }));
    return data;
  };

  const { data = { customers: [] }, isFetching } = useQuery(
    [queryActions.CUSTOMERS],
    () => getCustomer(),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  const addToParentCustomerList = (newCustomerFromSearch) => {
    queryClient.setQueryData([queryActions.CUSTOMERS], (data) => {
      if (
        !data.customers.find(
          (el) => el.Cust_ID === newCustomerFromSearch.Cust_ID
        )
      ) {
        data.customers = [...data.customers, newCustomerFromSearch];
        return data;
      } else {
        return data;
      }
    });
  };

  const getCustomerBalanceAndCredit = async (Cust_ID) => {
    // return new Promise(async (resolve, reject) => {
    try {
      setIsLoading(true);
      let response = await fetch(
        `${backendUrl}/api/customers/balance-and-credit/${Cust_ID}`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
            "Content-Type": "Application/json",
          },
          credentials: "include",
        }
      );

      if (!response.ok) {
        response = await response.json();
        throw new Error();
      } else {
        const {
          data: { balance, duePayments, invoiceCats },
        } = await response.json();
        // resolve({ balance, duePayments });
        queryClient.setQueryData([queryActions.CUSTOMERS], (data) => {
          data.customers = data.customers.map((el) =>
            el.Cust_ID === Cust_ID
              ? { ...el, balance, duePayments, invoiceCats }
              : { ...el }
          );
          return data;
        });

        setTableData((tableData) =>
          tableData.map((el) =>
            el.Cust_ID === Cust_ID
              ? { ...el, balance, duePayments, invoiceCats }
              : { ...el }
          )
        );
      }
    } catch (err) {
      console.log(err);

      // reject();
    } finally {
      setIsLoading(false);
    }
    //  });
  };

  return (
    <IsPrivileged roleName="Create Memo">
      <main className="create-invoice">
        <PageHeader
          name="Create Memo"
          description="Create Your Payment Memo"
          icon={<MoneyDollarIcon />}
        />

        <div className="p-3 content">
          <div className="d-flex content-holder rounded">
            <section className="item-details customers">
              <div>
                <header>
                  <h1>Post Credit/Debit Memo</h1>
                </header>

                <div className="selected-data-area mt-3">
                  {/*  */}
                  <div className="table-holder">
                    {!isEmpty(tableData) ? (
                      <Table
                        responsive
                        borderless
                        hover
                        striped
                        className="product-table text-nowrap post-payment"
                      >
                        <thead>
                          <tr>
                            <th />
                            {/* <th>Customer ID</th> */}
                            <th>Memo Type</th>
                            {/* <th>Account</th> */}
                            <th>Business Name</th>
                            <th>Remark</th>
                            <th>Amount</th>
                            <th>Link Payment to Invoice</th>
                            <th>Balance on Ledger</th>
                            <th>Transaction Date</th>
                            <th>Credit No</th>
                            <th>Invoice Type</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableData.map((el, index) => (
                            <Fragment key={el.TransactionID}>
                              <CreditDebitTableRow
                                customers={data?.customers}
                                index={index}
                                customer={el}
                                editTable={editTable}
                                remove={remove}
                                addNewRow={addNewRow}
                                backendUrl={backendUrl}
                                getCustomerBalanceAndCredit={
                                  getCustomerBalanceAndCredit
                                }
                                addToParentCustomerList={
                                  addToParentCustomerList
                                }
                              />
                            </Fragment>
                          ))}
                        </tbody>
                      </Table>
                    ) : null}
                  </div>

                  <button
                    onClick={() => setShowCustomerSelectorModal(true)}
                    type="button"
                    className="btn text-primary my-3 d-flex align-items-center gap-2"
                  >
                    <AddCircleIcon />
                    Add Customer
                  </button>

                  {/*  No item  */}
                  {isEmpty(tableData) ? (
                    <div className="no-item my-5">
                      <div className="info">
                        <NoSelectedItemIcon />
                        <h2 className="mb-2">
                          Haven't selected a Customer yet
                        </h2>
                        <p>
                          You can click +Add Customer Button to add a Customer
                          to the table.
                        </p>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              {!isEmpty(tableData) && (
                <div className="d-flex justify-content-between total-info">
                  <div />
                  <div>
                    <Button
                      onClick={() => post()}
                      disabled={updateCustomersLedgerMutation.isLoading}
                      type="button"
                      className="py-3 px-5"
                    >
                      Post Memo
                    </Button>
                  </div>
                </div>
              )}
            </section>
          </div>
        </div>

        {/*   Modals */}
        {showCustomerSelectorModal && (
          <CustomerSelectModal
            setShowCustomerSelectorModal={setShowCustomerSelectorModal}
            setSelectedCustomer={setSelectedCustomer}
            withCredit={true}
            withPaymentDue={true}
            isMulti={false}
            alreadySelectedCustomers={tableData}
            withInvoiceCat={true}
          />
        )}

        <ModalLoader
          show={updateCustomersLedgerMutation.isLoading || isFetching}
        />

        <ModalLoader
          show={isLoading}
          title="Please wait, getting customer balance and due payments..."
        />
      </main>
    </IsPrivileged>
  );
}
