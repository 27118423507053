import React, { Suspense, lazy, useMemo } from "react";
import "./assets/scss/index.scss";
import "react-datepicker/dist/react-datepicker.css";

import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import DashboardLayout from "./components/DashboardLayout";
// import { Test } from "./components/Test";
import InnerPageLayout from "./components/InnerPageLayout";
import { Nav as SalesAndInvoicingNav } from "./components/SalesAndInvoicing/Nav";
import { BusinessDevelopmentCRMNav } from "./components/BusinessDevelopmentCRM/Nav";
import { InvoiceList } from "./components/SalesAndInvoicing/InvoiceList";
import Login from "./components/Login";
import HomeLayout from "./components/HomeLayout";
import MasterLayout from "./components/MasterLayout";
// import Hello from "./components/utils/Hello";
import DetailedIcon, {
  BucketIcon,
  PageHeaderListIcon,
  RecieptIcon,
  WareHouseIcon,
} from "./components/Icons";
import Accounts from "./components/Dashboard/Accounts";
// import System from "./components/Dashboard/System";
import { Wrapper as SalesAndInvoicingWrapper } from "./components/SalesAndInvoicing/Wrapper";
import { BusinessDevelopmentCRMWrapper } from "./components/BusinessDevelopmentCRM/Wrapper";
import CreateInvoice from "./components/SalesAndInvoicing/CreateInvoice";
import ReportsNav from "./components/Reports/ReportsNav";
// import CashBook from "./components/Reports/CashBook";
import SalesAnalysis from "./components/Reports/SalesAnalysis";
import SalesRepReports from "./components/Reports/SalesRepReports/SalesRepReports";
import Sales from "./components/Reports/SalesRepReports/Sales";
import Nav from "./components/Reports/SalesRepReports/Nav";
import Customers from "./components/Reports/SalesRepReports/Customers";
import SalesByInvoice from "./components/Reports/SalesByInvoice";
import PaymentReport from "./components/Reports/PaymentReport";
import Debtor from "./components/Reports/SalesRepReports/Debtor";
import Payment from "./components/Reports/SalesRepReports/Payment";
import TransactionAnalysis from "./components/Reports/TransactionAnalysis";
import CustomerTransactionAnalysis from "./components/Reports/CustomerTransactionAnalysis";
import CustomerLedger from "./components/Reports/CustomerLedger";
// import LoyaltyCustomers from "./components/Reports/LoyaltyCustomers";
import AccountsPayables from "./components/Reports/AccountsPayables";
import AccountsReceivables from "./components/Reports/AccountsReceivables";
import PurchaseHistory from "./components/Reports/PurchaseHistory";
// import StockPositionByDate from "./components/Reports/StockPositionByDate";
import InventoryAnalysis from "./components/Reports/InventoryAnalysis";
import InventoryAnalysisWarehouse from "./components/Reports/InventoryAnalysisWarehouse";
// import PrintInventoryByProduct from "./components/Reports/PrintInventoryByProduct";
import PostVendorsPayment from "./components/Vendor/PostVendorsPayment";
import VendorsLedger from "./components/Vendor/VendorsLedger";
import Expenses from "./components/Reports/Expenses";
import GeneralLedger from "./components/Reports/GeneralLedger";
import TrialBalance from "./components/Reports/TrialBalance";
import IncomeStatement from "./components/Reports/IncomeStatement";
import BalanceSheet from "./components/Reports/BalanceSheet";
import Send from "./components/Requisition/Send";
import Request from "./components/Requisition/Request";
import RequisitionNav from "./components/Requisition/RequisitionNav";
import RequisitionWrapper from "./components/Requisition/RequisitionWrapper";
import DetailsModal from "./components/Requisition/DetailsModal";
import PreviewModal from "./components/Requisition/PreviewModal";
import ApproveAndSendModal from "./components/Requisition/ApproveAndSendModal";
import ConversationsModal from "./components/Requisition/Conversation/ConversationsModal";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { NoMatch } from "./components/utils/NoMatch";
import SalesInventory from "./components/SalesInventory";
import ApprovalIndex from "./components/Approval/ApprovalIndex";
// import ApproveInvoiceModal from "./components/Approval/ApproveInvoiceModal";
import { CustomerList } from "./components/SalesAndInvoicing/CustomerList";
import CreateCustomersInvoice from "./components/SalesAndInvoicing/CreateCustomersInvoice";
import CreateIronRodInvoice from "./components/SalesAndInvoicing/CreateIronRodInvoice";
import { useEffectOnce, useIsAdmin } from "./utils/hooks";
import TransportReport from "./components/Reports/TransportReport";
import TransportRevenueEstimates from "./components/Reports/TransportRevenueEstimates";
import { useAuth } from "./hooks/useAuth";
import DriverSalary from "./components/Salary/DriverSalary";
import Incentive from "./components/Incentives/Incentive";
// import { InventoryList } from "./components/Inventory/InventoryList";
import { InventoryNav } from "./components/Inventory/InventoryNav";
import InventoryListEntry from "./components/Inventory/InventoryListEntry";
import SupplyCenter from "./components/SupplyCenter";
import ReturnItem from "./components/SalesAndInvoicing/ReturnItem";
import PostPayment from "./components/PostPayment";
import CreateProformaInvoice from "./components/SalesAndInvoicing/CreateProformaInvoice";
import { ItemIssuer } from "./components/Inventory/ItemIssuer";
import ReceiveInventory from "./components/Receive/ReceiveInventory";
import DeleteInvoice from "./components/SalesAndInvoicing/DeleteInvoice";
// import ReturnItemFromBranch from "./components/Inventory/ReturnItemFromBranch";
import { InventoryManagerNav } from "./components/Inventory/InventoryManagerNav";
import DamagesReturnRecorder from "./components/Inventory/DamagesReturnRecorder";
import ExpensesEntry from "./components/ExpensesEntry";
import Cookies from "universal-cookie";
import CreditDebitMemo from "./components/creditDebitMemo";
import CreateCreditDebitMemo from "./components/CreateCreditDebitMemo";
import Authorize from "./components/Authorize";
import Auth from "./components/Auth";
import VerifyEmail from "./components/Verify-Email";
import ChartOfAccount from "./components/Maintenance/ChartOfAccount";
import Account from "./components/Maintenance/Account";
import ChartOfAccountImprest from "./components/Maintenance/ChartOfAccountImprest";
import JournalEntry from "./components/JournalEntry";
import ManageTransaction from "./components/SalesAndInvoicing/ManageTransaction";
import Cashes from "./components/Reports/Cashes";
import EditProformaInvoice from "./components/SalesAndInvoicing/EditProformaInvoice";
import StockRecords from "./components/Reports/StockRecords";
import UserManagemnt from "./components/Dashboard/UserManagement";
import CompanyInformationSettings from "./components/Dashboard/CompanyInformationSettings";
import SubNavs from "./components/SubNavs";
import { wareHouseChildren } from "./config";
import WareHouse from "./components/warehouse/warehouse";
import WarehouseInventoryListEntry from "./components/warehouse/stock/WarehouseInventoryListEntry";
import DocumentsCertification from "./components/BusinessDevelopmentCRM/DocumentsCertification";
import ProspectiveCustomers from "./components/BusinessDevelopmentCRM/ProspectiveCustomers";
import { WarehouseInventoryNav } from "./components/warehouse/WarehouseInventoryNav";
import StorageLocation from "./components/warehouse/StorageLocation";
import WarehouseChangeSide from "./components/warehouse/WarehouseChangeSide";
import { WarehouseIssuesItem } from "./components/warehouse/WarehouseIssueItem";
import ReceiveItemWarehouse from "./components/warehouse/ReceiveItemWarehouse";
import { VendorsInventoryNav } from "./components/Vendor/VendorInventoryNav";
import Vendors from "./components/Vendor/Vendors";
import VendorCreditDebitMemo from "./components/Vendor/VendorCreditDebitMemo";
import CreateVendorCreditDebitMemo from "./components/Vendor/CreateVendorCreditDebitMemo";
import { VendorWrapper } from "./components/Vendor/VendorWapper";
import ItemConversion from "./components/Inventory/ItemConversion";
import DeletePayment from "./components/SalesAndInvoicing/DeletePayment";
import CreateRefund from "./components/Refund";
import Plants from "./components/Production/Plants";
import { FeedBack } from "./components/FeedBack";
import { ThankYouFeedBack } from "./components/ThankYouFeedBAck";
import PlantChangeSide from "./components/Production/PlantChangeSide";
import { PlantInventoryNav } from "./components/Production/PlantInventryNav";
import ReceiveItemPlant from "./components/Production/ReceiveItemPlant";
import PlantProductLog from "./components/Production/PlantProductLog";
import ItemsProgress from "./components/Production/ItemInProgress";
import PlantTemplateItems from "./components/Production/PlantItemsTemplate";
import PlantItemUsedLog from "./components/Production/PlantItemUsedLog";
import PlantHistoryLog from "./components/Production/PlantHistoryLog";
import ModalLoader from "./components/utils/ModalLoader";

import VendorUpcomingPayments from "./components/Vendor/VendorUpcomingPayments";
import AtcPaymentReport from "./components/Vendor/AtcPaymentReport";
import { ProductWrapper } from "./components/Production/ProductWapper";
import ItemsOutOfStock from "./components/Inventory/ItemsOutOfStock";
import DamagesReturnRecorderWarehouse from "./components/warehouse/DamagesReturnRecorder";
import PlantDamages from "./components/Production/PlantDamages";
import PlantWarehouseDamages from "./components/warehouse/PlantWarehouseDamages";
import SalesIncentive from "./components/Reports/SalesIncentive";
import { EnquireList } from "./components/SalesAndInvoicing/EnquireList";
import ReplaceItem from "./components/SalesAndInvoicing/ReplaceItem";
import Quotation from "./components/SalesAndInvoicing/Quotation";
import Proforma from "./components/SalesAndInvoicing/Proforma";
import { NOHR } from "./components/utils/NOHR";
import VendorLogin from "./components/vendorLogin";
import VendorMasterLayout from "./components/VendorMasterLayout";
import ReconcilationNav from "./components/Reconcilation/ReconcilatioNav";
import { DrumsNav } from "./components/Inventory/DrumsNav";
import { lowerCase } from "lodash";
import AllJobs from "./components/Jobs/AllJobs";
import CreateJob from "./components/Jobs/CreateJob";
import OperationsNav from "./components/Jobs/OperationsNav";
import { WarehouseWrapper } from "./components/SalesAndInvoicing/WarehouseWrapper";

import FileHolder from "./components/FileHolder";
import FormsLayout from "./components/FormsLayout";
import Forms from "./components/Requisition/Forms";
import FillForm from "./components/Requisition/FillForm";
import PostDrumPayment from "./components/PostDrumPayment";
import EditJob from "./components/Jobs/EditJob";
import EditPendingInvoice from "./components/SalesAndInvoicing/EditPendingInvoice";
import PrepareJob from "./components/Jobs/PrepareJob";
import EditPendingServiceInvoice from "./components/SalesAndInvoicing/EditPendingServiceInvoice";
import JobIncomeStatement from "./components/Maintenance/JobIncomeStatement";
import JobDetails from "./components/Jobs/JobDetails";
import Invoices from "./components/Reports/Invoices";
import AllQHSEJobs from "./components/Jobs/AllQHSEJobs";
import QHSENav from "./components/Jobs/QHSENav";
import BusinessDevelopmentCRM from "./components/Business-Development-CRM";
import StagesByJob from "./components/Jobs/StagesByJob";
import ApproveAndCloseWithRecieverModal from "./components/Requisition/ApproveAndCloseWithReciever";
import FillAForm from "./components/Requisition/FillAForm";
import { MaintanceNav } from "./components/maintance/maintanceNav";
import { MaintanceWrapper } from "./components/maintance/MaintanceWrapper";
import AllJobsBDM from "./components/Jobs/AllJobsBDM";
import EditForm from "./components/Requisition/EditForm";
import ItemsExpiryReport from "./components/Inventory/ItemsExpiryReport";
import AgingReport from "./components/Reports/AgingReport";
import EditPendingDeleteJournalEntry from "./components/EditPendingDeleteJournalEntry";
import SupplyRate from "./components/Vendor/SupplyRate";
import PlantPerformance from "./components/Production/PlantPerformance";
import ItemsExpectedReport from "./components/Inventory/ItemsExpectedReport";
import StoreRoutes from "./components/Store/StoreRoutes";
import IsServiceAvailable from "./components/IsServiceAvailable";
import { InventoryProcessingNav } from "./components/Inventory/InventryProcessing";
import CheckWrapper from "./components/CheckWrapper";
import EditInvoiceWrapper from "./components/SalesAndInvoicing/EditInvoiceWrapper";
import { NotSubscribed } from "./components/utils/NotSubscribed";
import Preorder from "./components/SalesAndInvoicing/Preorder";
import EditPreorderInvoiceWrapper from "./components/SalesAndInvoicing/EditPreorderInvoiceWrapper";
import ViewEmployees from "./components/Employees/ViewEmployees";
import { HMBCustomerList } from "./components/SalesAndInvoicing/HMBCustomerList";
import CustomerGroups from "./components/CustomerGroup";
import PendingOnlineStorePayments from "./components/Approval/PendingOnlineStorePayments";
import Delivery from "./components/SalesAndInvoicing/Delivery";
import HMBProforma from "./components/SalesAndInvoicing/HMBProforma";
import ChangePassword from "./components/ChangePassword";
import Verify from "./components/Verified";
import QuotesAndOrder from "./components/SalesAndInvoicing/QuotesAndOrder";
import StockCounts from "./components/Inventory/StockCounts";
import CreateVendorInvoice from "./components/Vendor/CreateVendorInvoice";
import { useStoreState } from "easy-peasy";
import SerialNumberInAndOutReport from "./components/Inventory/SerialNumberInAndOutReport";
import Distribtors from "./components/Distributors/Distribtors";
import SerialNumbers from "./components/Inventory/SerialNumbers";
import SendCustomersEmail from "./components/CustomersEmail/SendCustomersEmail";
import ApproveVendorInvoice from "./components/Approval/ApproveVendorInvoice";
import { HMBNav } from "./components/SalesAndInvoicing/HMBNav";
import { HMBWrapper } from "./components/SalesAndInvoicing/HMBWrapper";
import CacheCheck from "./CacheCheck";
import SystemForms from "./components/Requisition/SystemForms";
import HMBInventory from "./components/Inventory/HMBInventory";
import PaymentAndBilling from "./components/PaymentAndBilling/PaymentAndBilling";
import RenewBusinessPlan from "./components/PaymentAndBilling/RenewBusinessPlan";
import RenewExpiredAccount from "./components/PaymentAndBilling/RenewExpiredAccount";
import ProcessingBillingAndPayment from "./components/PaymentAndBilling/ProcessingBillingAndPayment";
import ConfirmPaymentAndBilling from "./components/PaymentAndBilling/ConfirmPaymentAndBilling";

// Lazy imports ------------------------------------------------------
const WarehouseItemsSerialDetails = lazy(() =>
  import("./components/warehouse/WarehouseItemSerialDetail")
);
const ItemsSerialDetails = lazy(() =>
  import("./components/Inventory/ItemsSerialDetails")
);

const WareHouseItemReorder = lazy(() =>
  import("./components/warehouse/WarehoueItemReorder")
);

const WarehouseExpiryReportPage = lazy(() =>
  import("./components/warehouse/WarehouseExpirePage")
);

const VendorRegistration = lazy(() =>
  import("./components/VendorRegistration")
);

const WarehouseMainInventory = lazy(() =>
  import("./components/warehouse/WarehouseMainInventory")
);

const RFQCompany = lazy(() => import("./components/RFQCompany"));

const AssetsRegister = lazy(() =>
  import("./components/warehouse/stock/AssetRegister")
);

const UpcomingMaintance = lazy(() =>
  import("./components/maintance/UpcomingMaintenance")
);

const WarehouseReturnHistory = lazy(() =>
  import("./components/warehouse/ReturnHistory")
);

const WarehouseIssuesHistory = lazy(() =>
  import("./components/warehouse/IssueHistory")
);

const AllAssetItems = lazy(() =>
  import("./components/maintance/AllAssetItems")
);

const SiteVisit = lazy(() => import("./components/Jobs/SiteVisit"));

const WarehouseMaintance = lazy(() =>
  import("./components/maintance/WarehouseMaintance")
);

const HistoryMaintance = lazy(() =>
  import("./components/maintance/HistoryMaintance")
);

const CreateMeterInvoice = lazy(() =>
  import("./components/SalesAndInvoicing/CreateMeterInvoice")
);

const CategoryPage = lazy(() => import("./components/Inventory/CategoryPage"));

const ManufactuerPage = lazy(() =>
  import("./components/Inventory/ManufactuerPage")
);

const InventoryStorageLocation = lazy(() =>
  import("./components/Inventory/InventoryStorageLocation")
);

const AuditPage = lazy(() => import("./components/Inventory/AuditPage"));

const MultipleItemConversion = lazy(() =>
  import("./components/Inventory/MultipleItemConvertion")
);

const ForcastRecorder = lazy(() =>
  import("./components/Inventory/ForcastRecorder")
);

const CustomerProfile = lazy(() =>
  import("./components/CustomerDetails/CustomerAccount")
);

const ShoppingCost = lazy(() => import("./components/Inventory/ShippingCost"));

const CreateReimbursement = lazy(() => import("./components/Reimbursement"));

const Banks = lazy(() => import("./components/Banks"));
const CementPriceSettings = lazy(() =>
  import("./components/CementPriceSettings")
);

const VendorRFQ = lazy(() => import("./components/VendorRFQ"));

const VendorQuotes = lazy(() => import("./components/VendorQuotes"));

const VendorAdvance = lazy(() => import("./components/Vendor/VendorAdvance"));

const VendorAcceptForPO = lazy(() => import("./components/VendorAcceptForPO"));

const CompanyPO = lazy(() => import("./components/CompanyPO"));

const VendorPO = lazy(() => import("./components/VendorPO"));

const VendorReturnedQuotes = lazy(() =>
  import("./components/VendorReturnedQuotes")
);

const BankAccountAnalysis = lazy(() =>
  import("./components/Reports/BankAccountAnalysis")
);
const LoadAtc = lazy(() => import("./components/LoadAtc"));
const Analytics = lazy(() => import("./components/Analytics"));
const CustomerLedgerReconcilation = lazy(() =>
  import("./components/Reconcilation/CustomerLedgerReconcilation")
);
const DueInvoices = lazy(() =>
  import("./components/SalesAndInvoicing/DueInvoices")
);
const EditInvoice = lazy(() =>
  import("./components/SalesAndInvoicing/EditInvoice")
);
const ApprovalAction = lazy(() =>
  import("./components/Approval/ApproveAction")
);
const EditPendingExpense = lazy(() =>
  import("./components/EditPendingExpense")
);
const EditJournalEntry = lazy(() => import("./components/EditJournalEntry"));
const ReplacePayment = lazy(() =>
  import("./components/SalesAndInvoicing/ReplacePayment")
);

const PurchaseHistoryWarehouse = lazy(() =>
  import("./components/warehouse/PurchaseHistoryWarehouse")
);

const ShowFilledFormPage = lazy(() =>
  import("./components/ShowFilledFormPage")
);

const DocumentNumberSystem = lazy(() =>
  import("./components/DocumentNumberSystem")
);
const NumberSystemNav = lazy(() =>
  import("./components/NumberSystem/NumberSystemNav")
);
const FormNumberSystem = lazy(() =>
  import("./components/NumberSystem/FormNumberSystem")
);
const ReplaceVendorPayment = lazy(() =>
  import("./components/SalesAndInvoicing/ReplaceVendorPayment")
);
const DeleteVendorPayment = lazy(() =>
  import("./components/SalesAndInvoicing/DeleteVendorPayment")
);
const EditPendingReplaceVendorPayment = lazy(() =>
  import("./components/SalesAndInvoicing/EditPendingReplaceVendorPayment")
);
const EditPendingDeleteVendorPayment = lazy(() =>
  import("./components/SalesAndInvoicing/EditPendingDeleteVendorPayment")
);
const EditPendingEditJournalEntry = lazy(() =>
  import("./components/EditPendingEditJournalEntry")
);
const DeleteJournalEntry = lazy(() =>
  import("./components/DeleteJournalEntry")
);

const ReceiveWarehouseAssurance = lazy(() =>
  import("./components/maintance/ReceiveWarehouseAssurance")
);
const ProductPlan = lazy(() => import("./components/Production/ProductPlan"));

const RoutingPO = lazy(() => import("./components/RoutingPO"));

const CustomerTransType = lazy(() => import("./components/CustomerTransType"));

// --------------------------------------------------------------------

function App() {
  let location = useLocation();
  const generalSettings = useStoreState((state) => state.generalSettings);
  const {
    isTransportAndLogistics,
    isIronRod,
    isCement,
    isOilAndGas,
    user: authUser,
  } = useAuth();

  const isAdmin = useMemo(() => lowerCase(authUser?.Department) === "admin", [
    authUser,
  ]);

  let state = location?.state;
  // let url = new URL(document.location.href);

  // Get company from url first, next get from state
  // then set company here before we render our content

  /*  useEffect(() => {
    document.documentElement.style.scrollBehavior = "auto";
    setTimeout(() => window.scrollTo(0, 0), 5);
    setTimeout(
      () => (document.documentElement.style.scrollBehavior = "smooth"),
      5
    );
  }, [location]); */

  /*   useEffectOnce(() => {
    let url = new URL(document.location.href);
    url = searchParamsToObject(url.searchParams.entries());

    // fot gigc
    if (url.authorize && !appSettings.isBatchStandard) {
      if (isEmpty(authUser)) {
      }
    }
  }); */

  useEffectOnce(() => {
    try {
      const cookies = new Cookies();
      const token = cookies.get("x-access-token");
      // const token2 = cookies.get("token");
      if (token && authUser?.token) {
        cookies.set("x-access-token", authUser.token, {
          path: "/",
          // domain: ".invexonline.com",
        });
      }
      /*  if (token2 && authUser?.token) {
        cookies.set("token", authUser.token, { path: "/" });
      } */
    } catch (err) {
      console.log(err);
    }
  });

  /*   useEffect(() => {
    console.log(location);
  }, [location]); */

  return (
    <CacheCheck>
      <Suspense fallback={<ModalLoader show={true} />}>
        <div
          className="app"
          key={`${authUser?.company}_${authUser?.timestamp}`}
        >
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
          <FileHolder />
          <Routes location={state?.backgroundLocation || location}>
            {!process.env.REACT_APP_IS_STORE && (
              <Route path="">
                <Route index element={<Navigate to="dashboard" />} />
                <Route element={<HomeLayout />}>
                  <Route path="login" element={<Login />} />
                </Route>
                <Route path="authorize" element={<Authorize />} />
                <Route path="user-feedBack" element={<FeedBack />} />
                <Route
                  path="feedback-thankyou"
                  element={<ThankYouFeedBack />}
                />
                <Route path="customer-profile" element={<CustomerProfile />} />
                <Route path="change-password" element={<ChangePassword />} />
                <Route path="verify" element={<Verify />} />
                <Route path="vendor-login" element={<VendorLogin />} />
                <Route path="auth" element={<Auth />} />
                <Route
                  path="vendor-registration"
                  element={<VendorRegistration />}
                />
                <Route path="verify-email" element={<VerifyEmail />} />
              </Route>
            )}

            {/* VENDOR USER SIDE */}
            <Route path="vendor-user" element={<VendorMasterLayout />}>
              <Route path="Request-For-Quote" element={<VendorRFQ />} />
              <Route path="Quotes" element={<VendorQuotes />} />
              <Route path="PO" element={<VendorPO />} />
            </Route>
            {/* END OF VENDOR USER SIDE */}

            <Route
              path="public-form/:id/:company/:slug"
              element={<FillForm />}
            />

            <Route
              path="show-filled-form/:formId/:childId"
              element={<ShowFilledFormPage />}
            />

            <Route element={<MasterLayout />}>
              <Route path="dashboard" element={<DashboardLayout />}>
                <Route index element={<Dashboard />} />
                <Route path="accounts-dashboard" element={<Accounts />} />
                <Route path="user-managemnt" element={<UserManagemnt />} />
                <Route
                  path="company-information-settings"
                  element={<CompanyInformationSettings />}
                />
                <Route path="employees" element={<ViewEmployees />} />
              </Route>
              <Route path="sales-inventory" element={<SalesInventory />} />
              <Route path="banks" element={<Banks />} />
              <Route path="analytics" element={<Analytics />} />
              <Route
                path="bank-account-analysis"
                element={<BankAccountAnalysis />}
              />
              <Route
                path="business-development-crm"
                // element={<BusinessDevelopmentCRM />}
              >
                <Route
                  element={
                    <InnerPageLayout
                      name="Business Development / CRM"
                      description="Business Development / Customer Relationship Management"
                      icon={<PageHeaderListIcon />}
                    />
                  }
                >
                  <Route
                    element={
                      <BusinessDevelopmentCRMWrapper
                        Nav={<BusinessDevelopmentCRMNav />}
                      />
                    }
                  >
                    <Route index element={<Navigate to="customer-list" />} />
                    <Route path="customer-list" element={<CustomerList />} />

                    <Route path="job-list">
                      <Route index element={<AllJobsBDM />} />
                      <Route path="create" element={<CreateJob />} />
                      <Route path="edit" element={<EditJob />} />
                      <Route path="prepare" element={<PrepareJob />} />
                      <Route path="stages" element={<StagesByJob />} />{" "}
                      <Route
                        path="job-income-statement"
                        element={<JobIncomeStatement />}
                      />
                      <Route
                        path="details/:jobNumber"
                        element={<JobDetails />}
                      />
                    </Route>
                    <Route path="crm" element={<BusinessDevelopmentCRM />} />
                    <Route
                      path="documents-certifications"
                      element={<DocumentsCertification />}
                    />
                    <Route
                      path="prospective-customers"
                      element={<ProspectiveCustomers />}
                    />
                  </Route>
                </Route>
              </Route>
              <Route path="credit-debit-memo" element={<CreditDebitMemo />} />
              <Route
                path="create-credit-debit-memo"
                element={<CreateCreditDebitMemo />}
              />
              <Route path="reimbursement" element={<CreateReimbursement />} />
              <Route path="refund-payment" element={<CreateRefund />} />
              <Route path="expenses-entry" element={<ExpensesEntry />} />
              <Route path="journal-entry" element={<JournalEntry />} />
              <Route
                path="journal-modification"
                element={<JournalEntry type="journal-modification" />}
              />
              <Route
                path="journal-entry/edit/:TransID"
                element={<EditJournalEntry />}
              />
              <Route
                path="journal-entry/delete/:TransID"
                element={<DeleteJournalEntry />}
              />
              {isCement && (
                <>
                  <Route path="load-atc" element={<LoadAtc />} />
                  {isAdmin && (
                    <Route
                      path="price-settings"
                      element={<CementPriceSettings />}
                    />
                  )}
                </>
              )}
              <Route path="incentives" element={<Incentive />} />
              <Route path="driver-salary" element={<DriverSalary />} />
              <Route path="supply-center" element={<SupplyCenter />} />
              <Route path="delivery" element={<Delivery />} />
              <Route path="post-payments" element={<PostPayment />} />
              <Route path="expenses-entry" element={<ExpensesEntry />} />
              <Route basename path="reports">
                <Route
                  element={
                    <InnerPageLayout
                      Nav={<ReportsNav />}
                      name="Report Center"
                      description="Your current sales summary and activity"
                      icon={<PageHeaderListIcon />}
                    />
                  }
                >
                  <Route index element={<Navigate to="payment-report" />} />
                  {/*  <Route path="cash-book" element={<CashBook />} /> */}
                  <Route path="payment-report" element={<PaymentReport />} />
                  <Route path="sale-by-invoice" element={<SalesByInvoice />} />
                  <Route path="invoices" element={<Invoices />} />
                  <Route
                    path="sales-analysis"
                    element={
                      isTransportAndLogistics ? (
                        <TransportReport />
                      ) : (
                        <SalesAnalysis />
                      )
                    }
                  />
                  <Route path="sales-incentive" element={<SalesIncentive />} />

                  <Route
                    path="sales-rep-reports"
                    element={<SalesRepReports Nav={<Nav />} />}
                  >
                    <Route index element={<Navigate to="" />} />
                    <Route path="" element={<Sales />} />
                    <Route path="sales" element={<Sales />} />
                    <Route path="customers" element={<Customers />} />
                    <Route path="payment" element={<Payment />} />
                    <Route path="debtors" element={<Debtor />} />
                  </Route>

                  <Route
                    path="transaction-analysis"
                    element={<TransactionAnalysis />}
                  />
                  <Route
                    path="customer-transactions-analysis"
                    element={<CustomerTransactionAnalysis />}
                  />
                  <Route
                    path="customer-ledger"
                    element={<CustomerLedger key={location.pathname} />}
                  />
                  {/*  <Route
                  path="loyalty-customers"
                  element={<LoyaltyCustomers />}
                /> */}
                  <Route
                    path="accounts-recievables"
                    element={<AccountsReceivables />}
                  />
                  <Route
                    path="accounts-payables"
                    element={<AccountsPayables />}
                  />
                  <Route
                    path="purchase-history"
                    element={<PurchaseHistory />}
                  />
                  {/* <Route
                  path="stock-position-by-date"
                  element={<StockPositionByDate />}
                /> */}
                  <Route
                    path="inventory-analysis"
                    element={<InventoryAnalysis />}
                  />
                  <Route path="stock-records" element={<StockRecords />} />
                  <Route
                    path="inventory-analysis-warehouse"
                    element={<InventoryAnalysisWarehouse />}
                  />
                  {/*  <Route
                  path="print-inventory-by-product"
                  element={<PrintInventoryByProduct />}
                /> */}
                  <Route path="expenses" element={<Expenses />} />
                  <Route
                    path="imprest-account"
                    element={<ChartOfAccountImprest />}
                  />
                  {/* <Route
                  path="bank-account-analysis"
                  element={<BankAccountAnalysis />}
                /> */}
                  <Route path="general-ledger" element={<GeneralLedger />} />
                  <Route path="trial-balance" element={<TrialBalance />} />
                  <Route
                    path="income-statement"
                    element={<IncomeStatement />}
                  />
                  <Route path="balance-sheet" element={<BalanceSheet />} />

                  <Route path="transport" element={<TransportReport />} />
                  <Route
                    path="transport-revenue-estimates"
                    element={<TransportRevenueEstimates />}
                  />

                  <Route path="cash-bank-balances" element={<Cashes />} />
                  <Route
                    path="job-income-statement"
                    element={<JobIncomeStatement />}
                  />
                  <Route
                    path="accounts-recievables-aging-report"
                    element={<AgingReport />}
                  />
                </Route>
              </Route>
              {/*  */}
              <Route
                basename
                path="warehouse"
                element={<IsServiceAvailable serviceType="warehouse" />}
              >
                <Route
                  element={
                    <InnerPageLayout
                      Nav={<SubNavs childrenArrayNav={wareHouseChildren} />}
                      name="Warehouse"
                      description="Your warehouse deatials and activities"
                      icon={<WareHouseIcon />}
                    />
                  }
                >
                  {/* <Route index element={<Navigate to="/" />} /> */}
                  <Route index path="warehouse" element={<WareHouse />} />
                  <Route
                    index
                    path="storage-location"
                    element={<StorageLocation />}
                  />
                  <Route
                    index
                    path="main-asset-register"
                    element={<WarehouseMainInventory />}
                  />
                </Route>
              </Route>
              {/*  */}
              <Route
                basename
                path="warehouse-inventory"
                element={<IsServiceAvailable serviceType="warehouse" />}
              >
                <Route
                  element={
                    <InnerPageLayout
                      name="Warehouse Inventory"
                      description="Your warehouse details and activities"
                      icon={<WareHouseIcon />}
                      sideComponent={<WarehouseChangeSide />}
                    />
                  }
                >
                  <Route
                    element={
                      <WarehouseWrapper Nav={<WarehouseInventoryNav />} />
                    }
                  >
                    {/* <Route index element={<Navigate to="/inventry-entry" />} /> */}
                    <Route
                      path="inventry-entry"
                      element={<WarehouseInventoryListEntry />}
                    />
                    <Route
                      path="item-issuer"
                      element={<WarehouseIssuesItem />}
                    />
                    <Route
                      path="receive-item"
                      element={<ReceiveItemWarehouse />}
                    />
                    {/* <Route path="maintance" element={<WarehouseMaintance />} /> */}
                    <Route
                      path="plant-damages"
                      element={<PlantWarehouseDamages />}
                    />

                    <Route
                      path="damages-return-recorder"
                      element={<DamagesReturnRecorderWarehouse />}
                    />
                    <Route
                      path="purchase-history"
                      element={<PurchaseHistoryWarehouse />}
                    />
                    <Route
                      path="issue-history"
                      element={<WarehouseIssuesHistory />}
                    />
                    <Route
                      path="return-history"
                      element={<WarehouseReturnHistory />}
                    />
                    <Route
                      path="assets-register"
                      element={<AssetsRegister />}
                    />
                    <Route
                      path="out-of-stock"
                      element={<WareHouseItemReorder />}
                    />
                    <Route
                      path="expiry-report"
                      element={<WarehouseExpiryReportPage />}
                    />
                    <Route
                      path="items-serial-details"
                      element={<WarehouseItemsSerialDetails />}
                    />
                  </Route>
                </Route>
              </Route>
              {/*  */}
              <Route basename path="maintenance">
                <Route
                  element={
                    <InnerPageLayout
                      name="Maintenance List"
                      description="Your maintenance details and activities"
                      icon={<PageHeaderListIcon />}
                    />
                  }
                >
                  <Route element={<MaintanceWrapper Nav={<MaintanceNav />} />}>
                    <Route path="Upcoming" element={<UpcomingMaintance />} />
                    <Route path="Ongoing" element={<WarehouseMaintance />} />
                    <Route path="history" element={<HistoryMaintance />} />
                    <Route path="all-asset" element={<AllAssetItems />} />
                  </Route>
                </Route>
              </Route>
              {/*  */}
              <Route basename path="vendor">
                <Route
                  element={
                    <InnerPageLayout
                      name="Procurement"
                      description="Your procurement details and activities"
                      icon={<PageHeaderListIcon />}
                    />
                  }
                >
                  <Route path="vendor-advance" element={<VendorAdvance />} />
                  <Route
                    element={<VendorWrapper Nav={<VendorsInventoryNav />} />}
                  >
                    <Route path="vendors" element={<Vendors />} />
                    <Route path="vendors-ledger" element={<VendorsLedger />} />
                    <Route
                      path="post-vendors-payment"
                      element={<PostVendorsPayment />}
                    />
                    <Route
                      path="creadit-debit-memo"
                      element={<VendorCreditDebitMemo />}
                    />
                    <Route
                      path="create-vendors-memo"
                      element={<CreateVendorCreditDebitMemo />}
                    />
                    <Route
                      path="create-vendors-invoice"
                      element={<CreateVendorInvoice />}
                    />
                    <Route
                      path="upcoming-payments"
                      element={<VendorUpcomingPayments />}
                    />
                    <Route path="company-rfq" element={<RFQCompany />} />
                    <Route
                      path="atc-payment-report"
                      element={<AtcPaymentReport />}
                    />
                    <Route
                      path="pending-quotes"
                      element={<VendorReturnedQuotes />}
                    />
                    <Route
                      path="approved-quotes"
                      element={<VendorAcceptForPO />}
                    />
                    <Route path="PO" element={<CompanyPO />} />
                    <Route path="routing-PO" element={<RoutingPO />} />
                    {/* <Route path="Verify-Vendors" element={<VerifyVendors />} /> */}

                    <Route path="supply-rate" element={<SupplyRate />} />
                  </Route>
                </Route>{" "}
                <Route
                  path="replace-vendor-payment"
                  element={<ReplaceVendorPayment />}
                />
                <Route
                  path="delete-vendor-payment"
                  element={<DeleteVendorPayment />}
                />
              </Route>
              {/*  */}
              <Route
                basename
                path="Production"
                element={<IsServiceAvailable serviceType="production" />}
              >
                <Route path="plants" element={<Plants />} />
                <Route path="temlates" element={<PlantTemplateItems />} />
              </Route>
              {/*  */}
              <Route
                basename
                path="plant-inventory"
                element={<IsServiceAvailable serviceType="production" />}
              >
                <Route
                  element={
                    <InnerPageLayout
                      name="Plant Inventory"
                      description="Your plant details and activities"
                      icon={<WareHouseIcon />}
                      sideComponent={<PlantChangeSide />}
                    />
                  }
                >
                  <Route
                    element={<ProductWrapper Nav={<PlantInventoryNav />} />}
                  >
                    <Route
                      path="inventry-entry"
                      element={<PlantProductLog />}
                    />
                    <Route path="item-progress" element={<ItemsProgress />} />
                    <Route path="receive-item" element={<ReceiveItemPlant />} />
                    <Route path="item-used" element={<PlantItemUsedLog />} />
                    <Route path="plant-history" element={<PlantHistoryLog />} />
                    <Route
                      path="bill-of-quantity"
                      element={<PlantTemplateItems hideInsideHeader={true} />}
                    />
                    <Route path="plant-damages" element={<PlantDamages />} />
                    <Route path="product-plan" element={<ProductPlan />} />
                    <Route
                      path="plant-performance"
                      element={<PlantPerformance />}
                    />
                  </Route>
                </Route>
              </Route>
              {/*  */}
              <Route path="sales-and-invoicing">
                <Route
                  element={
                    <InnerPageLayout
                      name="Sales Center"
                      description="Manage invoice and create a new invoice."
                      icon={<PageHeaderListIcon />}
                    />
                  }
                >
                  <Route
                    element={
                      <SalesAndInvoicingWrapper
                        Nav={<SalesAndInvoicingNav />}
                      />
                    }
                  >
                    <Route index element={<Navigate to="invoice-list" />} />
                    <Route path="invoice-list" element={<InvoiceList />} />
                    <Route path="customer-list" element={<CustomerList />} />
                    <Route
                      path="online-store-customer-list"
                      element={
                        <CustomerList
                          key={location.pathname}
                          Dept="Online Store"
                        />
                      }
                    />
                    <Route
                      path="customer-type"
                      element={<CustomerTransType />}
                    />
                    <Route path="customer-group" element={<CustomerGroups />} />
                    <Route path="inventory" element={<InventoryListEntry />} />
                    <Route
                      path="manage-transaction"
                      element={<ManageTransaction />}
                    />
                    <Route path="enquire-list" element={<EnquireList />} />
                    <Route path="quotation" element={<Quotation />} />
                    <Route path="proforma" element={<Proforma />} />
                    <Route
                      path="online-store-quotes-orders"
                      element={<QuotesAndOrder />}
                    />
                    <Route path="pre-order" element={<Preorder />} />
                    <Route path="due-invoices" element={<DueInvoices />} />
                  </Route>
                </Route>
                {/*  */}

                <Route
                  path="create-invoice"
                  element={
                    isIronRod ? (
                      <CreateIronRodInvoice />
                    ) : isCement ? (
                      <CreateCustomersInvoice />
                    ) : isOilAndGas ? (
                      <CreateMeterInvoice />
                    ) : (
                      <CreateInvoice />
                    )
                  }
                />

                <Route
                  path="preorder-invoice"
                  element={
                    <CreateIronRodInvoice
                      mode="preorder"
                      key={location.pathname}
                    />
                  }
                />

                <Route
                  path="create-proforma-invoice"
                  element={<CreateProformaInvoice />}
                />
                <Route
                  path="create-proforma-invoice-without-approval"
                  element={<CreateProformaInvoice withoutApproval={true} />}
                />
                <Route
                  path="edit-proforma-invoice/:TransactionID"
                  element={<EditProformaInvoice />}
                />
                <Route
                  path="edit-pending-invoice/:TransactionID"
                  element={<EditPendingInvoice />}
                />
                <Route
                  path="edit-pending-service-invoice/:TransactionID"
                  element={<EditPendingServiceInvoice />}
                />

                {/*  */}

                {/* Create invoices  */}
                <Route
                  path="create-customers-invoice"
                  element={<CreateCustomersInvoice />}
                />
                <Route
                  path="create-meter-invoice"
                  element={<CreateMeterInvoice />}
                />
                <Route
                  path="create-iron-rod-invoice"
                  element={<CreateIronRodInvoice />}
                />
                {/*  */}
                <Route path="return-invoice-item" element={<ReturnItem />} />
                <Route path="replace-invoice-item" element={<ReplaceItem />} />
                <Route path="delete-invoice" element={<DeleteInvoice />} />
                <Route path="delete-payment" element={<DeletePayment />} />
                {/*  <Route path="edit-invoice" element={<EditInvoice />} /> */}
                <Route path="replace-payment" element={<ReplacePayment />} />
                <Route
                  path="edit-invoice/:Trans_ID"
                  element={<EditInvoiceWrapper key={location.pathname} />}
                />
                <Route
                  path="edit-preorder-invoice/:Trans_ID"
                  element={
                    <EditPreorderInvoiceWrapper key={location.pathname} />
                  }
                />
              </Route>
              {/*  */}
              <Route
                path="requisition"
                element={
                  <InnerPageLayout
                    name="Approval Workflow"
                    description="Build your form and create approval workflow"
                    icon={<PageHeaderListIcon />}
                  />
                }
              >
                <Route
                  element={<RequisitionWrapper Nav={<RequisitionNav />} />}
                >
                  <Route index element={<Navigate to="request" />} />
                  <Route exact path="forms" element={<FormsLayout />}>
                    <Route index element={<Navigate to="templates" />} />
                    <Route path="templates" element={<Send />} />
                    <Route
                      path="company"
                      element={<Forms formType="Organisation" />}
                    />
                    <Route
                      path="public"
                      element={<Forms formType="Public" />}
                    />
                    <Route
                      path="system"
                      element={<SystemForms withNav={true} />}
                    />
                    {/* <Route path="draft" element={<Send formType="draft" />} /> */}
                  </Route>{" "}
                  <Route exact path="fill-a-form" element={<FormsLayout />}>
                    <Route index element={<Navigate to="company" />} />
                    <Route
                      path="company"
                      element={<FillAForm formType="Organisation" />}
                    />
                    <Route
                      path="public"
                      element={<FillAForm formType="Public" />}
                    />
                    <Route path="system" element={<SystemForms />} />
                  </Route>
                  <Route path="send" element={<Send />} />
                  <Route
                    path="system-form"
                    element={<SystemForms withNav={false} />}
                  />
                  {["request", "fund-request-approval"].map((path) => (
                    <Route exact path={path} element={<Request key={path} />}>
                      <Route path="details/:id" element={<DetailsModal />} />
                      <Route path="preview/:id" element={<PreviewModal />} />
                      <Route
                        path="approve-and-send/:id"
                        element={<ApproveAndSendModal />}
                      />
                      <Route
                        path="approve-and-close/:id"
                        element={<ApproveAndCloseWithRecieverModal />}
                      />

                      {/* Rerender WHEN id CHANGES */}
                      <Route
                        path="conversations/:id"
                        render={(props) => (
                          <ConversationsModal
                            key={props.match.params.id}
                            {...props}
                          />
                        )}
                      />
                    </Route>
                  ))}
                </Route>
              </Route>
              <Route
                exact
                path="approval"
                element={<ApprovalIndex key={location.pathname} />}
              />
              <Route
                exact
                path="approval-store"
                element={
                  <ApprovalIndex sentBy="Store" key={location.pathname} />
                }
              />
              <Route
                exact
                path="approval-store-quotation"
                element={
                  <ApprovalIndex
                    sentBy="Store"
                    status="Quotation"
                    key={location.pathname}
                  />
                }
              />
              <Route
                exact
                path="approval-store-pending-payment"
                element={
                  <PendingOnlineStorePayments
                    sentBy="Store"
                    key={location.pathname}
                  />
                }
              />
              <Route
                exact
                path="approval-action"
                element={<ApprovalAction />}
              />
              <Route path="approval/expense" element={<EditPendingExpense />} />
              <Route
                path="approval/replace-vendor-payment"
                element={<EditPendingReplaceVendorPayment />}
              />
              <Route
                path="approval/delete-vendor-payment"
                element={<EditPendingDeleteVendorPayment />}
              />
              <Route
                exact
                path="approval/vendor-invoice"
                element={<ApproveVendorInvoice />}
              />
              <Route
                path="approval/journal-entry/edit/:TransID"
                element={<EditPendingEditJournalEntry />}
              />
              <Route
                path="approval/journal-entry/delete/:TransID"
                element={<EditPendingDeleteJournalEntry />}
              />
              <Route
                path="approval/details/:TransactionID"
                element={<EditProformaInvoice />}
              />
              <Route basename path="inventory-for-sales">
                <Route
                  element={
                    <InnerPageLayout
                      name={generalSettings?.salesInventoryName}
                      description="Manage and organize your inventory"
                      icon={<PageHeaderListIcon />}
                    />
                  }
                >
                  <Route
                    element={
                      <SalesAndInvoicingWrapper Nav={<InventoryNav />} />
                    }
                  >
                    <Route index element={<Navigate to="inventory-entry" />} />
                    <Route
                      path="inventory-entry"
                      element={<InventoryListEntry />}
                    />
                    <Route
                      path="item-conversion"
                      element={<ItemConversion />}
                    />

                    <Route path="shopping-cost" element={<ShoppingCost />} />
                    <Route path="vendor" element={<Vendors />} />
                    <Route path="item-issuer" element={<ItemIssuer />} />
                    <Route
                      path="storage-location"
                      element={<InventoryStorageLocation />}
                    />
                    <Route path="stock-counts" element={<StockCounts />} />
                    <Route path="category" element={<CategoryPage />} />
                    <Route path="manufactuer" element={<ManufactuerPage />} />
                    <Route
                      path="item-serial-details"
                      element={<ItemsSerialDetails />}
                    />
                    <Route path="serial-numbers" element={<SerialNumbers />} />
                    {/* */}
                    <Route
                      exact
                      path="inventory-manager"
                      element={
                        <InnerPageLayout Nav={<InventoryManagerNav />} />
                      }
                    >
                      <Route
                        index
                        element={<Navigate to="damages-return-recorder" />}
                      />

                      <Route
                        path="damages-return-recorder"
                        element={<DamagesReturnRecorder />}
                      />
                    </Route>
                    {/* */}

                    <Route
                      exact
                      path="processing-manager"
                      element={
                        <InnerPageLayout Nav={<InventoryProcessingNav />} />
                      }
                    >
                      <Route index element={<Navigate to="processing" />} />
                      <Route
                        path="processing"
                        element={<MultipleItemConversion />}
                      />
                      <Route
                        path="unprocessed"
                        element={
                          <InventoryListEntry ItemStatus="Unprocessed" />
                        }
                      />
                      <Route
                        path="processed"
                        element={<InventoryListEntry ItemStatus="Processed" />}
                      />
                    </Route>

                    <Route
                      path="manage-transaction"
                      element={<ManageTransaction />}
                    />
                    <Route
                      path="items-out-of-stock"
                      element={<ItemsOutOfStock />}
                    />
                    <Route
                      path="items-expiry-report"
                      element={<ItemsExpiryReport />}
                    />
                    <Route
                      path="items-expected-report"
                      element={<ItemsExpectedReport />}
                    />
                    <Route
                      path="items-forcast-recorder"
                      element={<ForcastRecorder />}
                    />
                    <Route
                      path="inbound-outbound-inventory"
                      element={<SerialNumberInAndOutReport />}
                    />
                  </Route>
                </Route>
              </Route>
              {/* <Route basename path="receive-inventory">
            <Route
              element={
                <InnerPageLayout
                  name="Recieve Inventory"
                  description="Receive all your inventory"
                  icon={<PageHeaderListIcon />}
                />
              }
            >
              <Route
                element={<SalesAndInvoicingWrapper Nav={<InventoryNav />} />}
              >
                <Route index element={<Navigate to="receiving-inventory" />} />
                <Route
                  path="inventory-entry"
                  element={<InventoryListEntry />}
                /> <Route path="item-issuer" element={<ItemIssuer />} />
              </Route>
            </Route>
          </Route> */}
              <Route
                exact
                path="receive-inventory"
                element={<ReceiveInventory />}
              />
              <Route exact path="audit" element={<AuditPage />} />
              {/*  <Route path="warehouse-inventory">
            <Route
              element={
                <InnerPageLayout
                  name="Warehouse Inventory"
                  description="Manage and organize your Warehouse inventory"
                  icon={<BucketIcon />}
                />
              }
            >
              <Route
                element={
                  <SalesAndInvoicingWrapper Nav={<WarehouseInventoryNav />} />
                }
              >
                <Route index element={<Navigate to="inventory-list" />} />
                <Route path="inventory-list" element={<InventoryList />} />
                <Route path="item-issuer" element={<Test />} />
                <Route path="branches-inventory" element={<Test />} />
                <Route path="inventory-manager" element={<Test />} />
                <Route path="vendor" element={<Test />} />
              </Route>
            </Route>
          </Route> */}
              <Route path="account-setup">
                <Route index element={<ChartOfAccount />} />
                <Route path="chart-of-account" element={<ChartOfAccount />} />
                <Route path="account-list" element={<Account />} />
              </Route>
              {/*  */}
              <Route basename path="reconcilation">
                <Route
                  element={
                    <InnerPageLayout
                      Nav={<ReconcilationNav />}
                      name="Reconcilation"
                      description=""
                      icon={<PageHeaderListIcon />}
                    />
                  }
                >
                  {/* Rerender WHEN id CHANGES */}
                  <Route
                    path="customer-ledger/:PaymentType"
                    element={
                      <CustomerLedgerReconcilation key={location.pathname} />
                    }
                  />
                  <Route
                    path="bank"
                    element={<BankAccountAnalysis hasReconcilation={true} />}
                  />
                  <Route
                    path="expenses"
                    element={<Expenses hasReconcilation={true} />}
                  />
                </Route>
              </Route>
              {/*  */}
              <Route path="operations">
                <Route
                  element={
                    <InnerPageLayout
                      name="Operations"
                      description=""
                      icon={<RecieptIcon />}
                      Nav={<OperationsNav />}
                    />
                  }
                >
                  <Route index element={<AllJobs />} />
                  <Route path="jobs">
                    <Route index element={<AllJobs />} />
                    <Route path="create" element={<CreateJob />} />
                    <Route path="edit" element={<EditJob />} />
                    <Route path="prepare" element={<PrepareJob />} />
                    <Route path="stages" element={<StagesByJob />} />
                    {/*  <Route path="maintenance" element={<WarehouseMaintance />} /> */}
                    <Route path="details/:jobNumber" element={<JobDetails />} />
                  </Route>
                </Route>
              </Route>
              <Route path="qhse">
                <Route
                  element={
                    <InnerPageLayout
                      name="Quality, Health, Safety, & Environment (QHSE)"
                      description=""
                      icon={<RecieptIcon />}
                      Nav={<QHSENav />}
                    />
                  }
                >
                  <Route index element={<AllQHSEJobs />} />
                  <Route path="jobs">
                    <Route index element={<AllQHSEJobs />} />
                    <Route path="stages" element={<StagesByJob />} />
                    <Route
                      path="maintenance"
                      element={<WarehouseMaintance />}
                    />
                    <Route path="details/:jobNumber" element={<JobDetails />} />
                    <Route path="site-visit" element={<SiteVisit />} />
                  </Route>{" "}
                </Route>
                <Route
                  path="warehouse-receive-assurance"
                  element={<CompanyPO usage={"qhse"} />}
                />
                <Route
                  path="approval-action"
                  element={<ApprovalAction usage={"qhse"} />}
                />
              </Route>
              {/*  */}
              {/* */}
              <Route basename path="drums">
                <Route
                  element={
                    <InnerPageLayout
                      name="Drums Account"
                      description=""
                      icon={<PageHeaderListIcon />}
                      Nav={<DrumsNav />}
                    />
                  }
                >
                  <Route
                    index
                    element={<Navigate to="update-drum-account" />}
                  />
                  <Route
                    path="update-drum-account"
                    element={<PostDrumPayment />}
                  />
                  <Route
                    path="drum-account-sheet"
                    element={
                      <CustomerLedger usage="drums" key={location.pathname} />
                    }
                  />
                </Route>
              </Route>
              {/* */}
              {/*  */}
              <Route path="hmb-rsmoh">
                <Route
                  element={
                    <InnerPageLayout
                      // name="Hospital Management Board"
                      name={authUser?.Department}
                      description={""}
                      icon={<PageHeaderListIcon />}
                    />
                  }
                >
                  <Route element={<HMBWrapper Nav={<HMBNav />} />}>
                    <Route index element={<Navigate to="customer-list" />} />
                    <Route path="customer-list" element={<HMBCustomerList />} />
                    <Route
                      path="sales-analysis"
                      element={<SalesAnalysis key={location.pathname} />}
                    />
                    <Route
                      path="customer-ledger"
                      element={<CustomerLedger key={location.pathname} />}
                    />
                    <Route
                      path="manage-transaction"
                      element={<ManageTransaction />}
                    />
                    <Route
                      path="pending-proforma"
                      element={
                        <HMBProforma Status="Pending" key={location.pathname} />
                      }
                    />
                    <Route
                      path="approved-proforma"
                      element={
                        <HMBProforma
                          Status="Approved"
                          key={location.pathname}
                        />
                      }
                    />
                    <Route
                      path="accounts-recievables-aging-report"
                      element={<AgingReport key={location.pathname} />}
                    />
                    <Route
                      path="sales-incentive"
                      element={<SalesIncentive />}
                    />
                    <Route path="inventory" element={<HMBInventory />} />
                  </Route>
                </Route>
              </Route>{" "}
              <Route basename path="sales-rep">
                <Route
                  element={
                    <InnerPageLayout
                      name="Sales Rep"
                      description=""
                      icon={<PageHeaderListIcon />}
                    />
                  }
                >
                  <Route index element={<Navigate to="employees" />} />
                  <Route
                    path="employees"
                    element={<ViewEmployees usage="sales-rep" />}
                  />
                </Route>
              </Route>
              <Route basename path="document-number">
                <Route
                  element={
                    <InnerPageLayout
                      name="Document Numbering"
                      description=""
                      icon={<RecieptIcon />}
                      Nav={<NumberSystemNav />}
                    />
                  }
                >
                  <Route index element={<Navigate to="system" />} />
                  <Route path="system" element={<DocumentNumberSystem />} />
                  <Route path="forms" element={<FormNumberSystem />} />
                </Route>
              </Route>
              <Route path="distributors" element={<Distribtors />} />
              <Route
                path="send-customers-email"
                element={<SendCustomersEmail />}
              />
              <Route
                path="payment-and-billing"
                element={<PaymentAndBilling />}
              />
              <Route
                path="process-billing-payment-transaction"
                element={<ProcessingBillingAndPayment />}
              />
              <Route
                path="confirm-billing-payment-transaction"
                element={<ConfirmPaymentAndBilling />}
              />
              <Route
                path="renew-expired-account"
                element={<RenewExpiredAccount />}
              />
              <Route
                path="renew-business-plan"
                element={<RenewBusinessPlan />}
              />
              <Route path="PO" element={<CompanyPO />} />
            </Route>
            {/*  */}

            <Route
              path="company-form/:id/:company/:slug"
              element={<FillForm />}
            />

            <Route
              path="edit-company-form/:id/:company/:slug"
              element={<EditForm />}
            />

            {/*  */}

            {/*  */}

            <Route path="/no-hr" element={<NOHR />} />
            <Route path="/not-subscribed" element={<NotSubscribed />} />

            {StoreRoutes}

            {/*  */}

            {/*  */}
            <Route path="*" element={<NoMatch />} />
          </Routes>
        </div>
      </Suspense>
    </CacheCheck>
  );
}

export default App;
