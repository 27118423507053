import Modal from "react-bootstrap/Modal";
// import { useState, useEffect } from "react";
import { useMemo } from "react";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import Select from "react-select";
import { useFormik } from "formik";
import * as yup from "yup";
import { Table } from "react-bootstrap";

import { useAuth } from "../../hooks/useAuth";
import { fetchActionsUtil } from "../../utils/helpers";
import ConfirmDialog from "../ConfirmDialogue";
import ModalLoader from "../utils/ModalLoader";
import { DeleteIcon } from "../Icons";
import CloseIcon from "mdi-react/CloseIcon";

const CreateWarehouseUserModal = (props) => {
  const { backendUrl } = useAuth();

  // const [openAccountModal, setOpenAccountModal] = useState(false);

  const getThings = async () => {
    const staffs = await fetchActionsUtil(`${backendUrl}/api/users`, "GET");
    staffs.staff = staffs.staff.map((el) => ({
      value: el.Staff_ID,
      label: el.Name,
    }));

    return staffs;
  };

  const { data, isFetching } = useQuery(
    ["FETCH_WAREHOUSE_STAFFS", useQuery],
    () => getThings(),
    {
      keepPreviousData: true,
    }
  );

  const filteredStaffs = useMemo(() => {
    const wareName = props?.singleWarehouse?.warehouseUsers?.map(
      (e) => e.Staff_ID
    );
    return data?.staff?.filter(function(e) {
      return this.indexOf(e.value) < 0;
    }, wareName);
  }, [data?.staff, props?.singleWarehouse?.warehouseUsers]);

  // console.log(props?.singleWarehouse?.warehouseUsers);

  const addMoreUsers = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/warehouse/users`,
        "POST",
        "",
        payload
      ),
    {
      onSuccess: () => {
        toast.success("Users added Successfully");
        formik.resetForm({
          users: [],
        });
        props.refetch();
        props.onHide();
      },
      onError: () => {
        toast.error("Unable to add user");
      },
    }
  );

  const mutateDeleteUsers = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/warehouse/users`,
        "DELETE",
        "",
        payload
      ),
    {
      onSuccess: () => {
        toast.success("User deteled Successfully");
        formik.resetForm({
          users: [],
        });
        props.refetch();
        props.onHide();
      },
      onError: () => {
        toast.error("Unable to delete user");
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      users: [],
    },
    validationSchema: yup.object().shape({
      users: yup.array().required("Choose Users"),
    }),
    onSubmit: async (values) => {
      if (
        await ConfirmDialog({
          title: "Add Users",
          description: `Are you sure you want to add users to this warehouse `,
        })
      ) {
        values.users = values.users.map((d) => d.value);
        values.W_ID = props?.singleWarehouse?.warehouseId;
        console.log(values);
        addMoreUsers.mutate(values);
      }
    },
  });

  const deleteUser = async (Staff_ID, W_ID) => {
    if (
      await ConfirmDialog({
        title: "Delete Users",
        description: `Are you sure you want to delete this user to this warehouse `,
      })
    ) {
      console.log(Staff_ID, W_ID);
      mutateDeleteUsers.mutate({ Staff_ID, W_ID });
    }
  };

  return (
    <Modal
      onHide={props.onHide}
      show={props.show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div>
          <div>
            <div className="d-flex justify-content-between">
              <h6>{props?.singleWarehouse?.warehouseName.toUpperCase()}</h6>
              <div role="button" className="text-lg" onClick={props.onHide}>
                <CloseIcon />
              </div>
            </div>
            <p className="text-muted">You can add more users here</p>
          </div>
          {/*  */}
          <div className="container mt-3">
            <label className="col-form-label fw-bold">Users</label>
            <div className="row">
              <div className="col-8">
                <Select
                  closeMenuOnSelect={true}
                  isLoading={isFetching}
                  isMulti
                  isSearchable={true}
                  onChange={(selected) => {
                    formik.setFieldValue("users", selected);
                  }}
                  options={filteredStaffs}
                />
                <span className="form-control-feedback text-danger">
                  {formik.errors.users}
                </span>
              </div>
              {/*  */}
              <div className="col-4 text-center">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={formik.handleSubmit}
                >
                  Add User
                </button>
              </div>
              {/*  */}
            </div>
          </div>
          {/*  */}
          <div className="my-4">
            <Table
              // responsive
              borderless
              striped
              className="product-table container"
            >
              <thead>
                <tr>
                  <th>Full Name</th>
                  <th>Branch</th>
                  <th>Access Level</th>
                  {/* <th>Warehouse</th> */}
                  <th />
                </tr>
              </thead>
              <tbody>
                {props?.singleWarehouse?.warehouseUsers?.map((el, i) => (
                  <tr key={i}>
                    <td>{el.Name}</td>
                    <td>{el.Branch}</td>
                    <td>{el.AccessLavel}</td>
                    {/* <td>
                      {el.Warehouse.split(",").map((e, id) => (
                        <span
                          key={id}
                          className="badge mx-1 mb-2 bg-light p-2 text-nowarp"
                        >
                          {e}
                        </span>
                      ))}
                    </td> */}
                    <td>
                      <span
                        onClick={() =>
                          deleteUser(
                            el.Staff_ID,
                            props?.singleWarehouse?.warehouseId
                          )
                        }
                        className="p-cursor"
                      >
                        <DeleteIcon />
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            {/*  */}
          </div>
        </div>
      </Modal.Body>
      <div className="d-inline-flex justify-content-end m-4">
        <button className="btn bg print mx-4" onClick={props.onHide}>
          Cancel
        </button>
      </div>
      <ModalLoader show={isFetching || addMoreUsers.isLoading} />
    </Modal>
  );
};

export default CreateWarehouseUserModal;
