import { Outlet, useNavigate } from "react-router-dom";
import { useEffect } from "react";

import "../../assets/scss/customer.scss";
import { CustomerSideBar } from "./SideBar";
import { useAuth } from "../../hooks/useAuth";
import { BreadcrumbLinks } from "../Store/Items";

export const CustomerAccount = () => {
  const { customer } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!customer) {
      navigate("/store", { replace: true });
    }
  }, [customer, navigate]);

  return (
    <div className="customer-profile-bg">
      <div className="main-container">
        <BreadcrumbLinks />
        <div className="bg-white rounded mt-4">
          <div className="row overide-row">
            <div className="col-md-2">
              <div className="pt-4">
                <CustomerSideBar />
              </div>
            </div>
            <div className="col-md-10 border-start py-4">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
