import {
  Breadcrumb,
  Accordion,
  Form,
  Button,
  Table,
  FormCheck,
} from "react-bootstrap";
import "../../assets/scss/store/mainContent.scss";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import Select from "react-select";
import useDebounce, {
  useLocalStorage,
  useQueryParams,
  useScrollTop,
  useUnits,
} from "../../utils/hooks";
import { useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import queryString from "query-string";
import { useQuery } from "react-query";
import { queryActions } from "../../utils/reactQueryActions";
import { startCase, truncate } from "lodash";
import currency from "currency.js";
import ReactPaginate from "react-paginate";
import {
  paginationOptions,
  scrollToTop,
  sortOptions,
  unitsResolver,
} from "../../utils/helpers";
import NumberCustomInput from "../utils/NumberCustomInput";
import { GridIcon, ListIcon } from "./StoreIcons";
import { useEffect } from "react";
import { useMemo } from "react";
import { upperFirst } from "lodash";
import { useStoreActions, useStoreState } from "easy-peasy";
import eventBus, { useCustomEventListener } from "../../utils/EventBus";
import noImageUrl from "./../../assets/images/no-image.png";
import { isEmpty } from "lodash";
import NoTableItem from "../utils/NoTableItem";
import { toast } from "react-toastify";
import { nanoid } from "nanoid";
import { appSettings } from "../../config";

export function BreadcrumbLinks() {
  const location = useLocation();
  const crumbs = useMemo(() => location.pathname.split("/"), [location]);

  const cleanUpLink = (link) => {
    return link ? startCase(decodeURIComponent(link.replace("-", " "))) : "";
  };

  return (
    <Breadcrumb className="fw-5">
      {crumbs
        .filter((el) => el)
        .map((el, i, arr) => (
          <Breadcrumb.Item
            key={i}
            as="div"
            active={arr.length - 1 === i}
            className={arr.length - 1 === i ? "text-primary" : ""}
          >
            {arr.length - 1 === i ? (
              cleanUpLink(el)
            ) : (
              <Link
                to={`${appSettings.storeBaseUrl}/${
                  el && el !== "store" ? el : ""
                }`}
              >
                {cleanUpLink(el)}
              </Link>
            )}
          </Breadcrumb.Item>
        ))}
    </Breadcrumb>
  );
}

function FilterArea({ filterParams, setFilterParams, refetch }) {
  return (
    <Form>
      <Accordion defaultActiveKey="0" flush>
        <Accordion.Item eventKey="0">
          <Accordion.Header variant="" className="bg-white">
            <b>Price</b>
          </Accordion.Header>
          <Accordion.Body className="p-0">
            <div className="p-3 border-bottom">
              <Form.Group className="mb-2-5">
                <Form.Label>Minimum</Form.Label>
                <CurrencyCustomInput
                  name={`minPrice`}
                  value={filterParams.minPrice}
                  onValueChange={(value, name) => {
                    setFilterParams({
                      ...filterParams,
                      page: 1,
                      minPrice: value ? value : "",
                    });
                  }}
                />
              </Form.Group>

              <Form.Group className="mb-0">
                <Form.Label>Maximum</Form.Label>
                <CurrencyCustomInput
                  name={`maxPrice`}
                  value={filterParams.maxPrice}
                  onValueChange={(value, name) => {
                    setFilterParams({
                      ...filterParams,
                      page: 1,
                      maxPrice: value ? value : "",
                    });
                  }}
                />
              </Form.Group>
            </div>

            <div className="buttons row w-100 m-0 gap-2 mt-0 p-3">
              <Button
                onClick={() =>
                  setFilterParams({
                    ...filterParams,
                    minPrice: "",
                    maxPrice: "",
                  })
                }
                variant="white"
                className="col border"
              >
                Reset
              </Button>
              <Button
                onClick={() => refetch()}
                variant="primary"
                className="col"
              >
                Search
              </Button>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>{" "}
    </Form>
  );
}

function ItemData({ layout, item, hideUnitPriceInStore, qtyInStock }) {
  const { customerBackendUrl: backendUrl } = useAuth();
  const addToCartStore = useStoreActions((actions) => actions.addToCart);
  const removeFromCart = useStoreActions((actions) => actions.removeFromCart);
  const addQuantityToCart = useStoreActions(
    (actions) => actions.addQuantityToCart
  );
  const addSaleType = useStoreActions((actions) => actions.addSaleType);

  const cartData = useStoreState((state) =>
    state.cart.find((el) => el.Bar_Code === item.Bar_Code)
  );
  const cartDataIndex = useStoreState((state) =>
    state.cart.findIndex((el) => el.Bar_Code === item.Bar_Code)
  );

  const { Units, getUnitLabelByValue } = useUnits();
  useEffect(() => {
    console.log(hideUnitPriceInStore);
  }, [hideUnitPriceInStore]);
  const addToCart = (item) => {
    if (
      item?.Reorder_Level &&
      Number(item?.Reorder_Level) > Number(item?.quantityInStock)
    ) {
      return toast.info("Out of Stock");
    }
    item.saleType = item?.Product_Model;

    if (hideUnitPriceInStore) {
      item.UnitPrice = 0;
    }

    item.PriceSold = item.UnitPrice;
    addToCartStore(item);

    // Focus
    setTimeout(() => {
      const el = document.querySelector(
        `input[name="${item.Bar_Code}Quantity"]`
      );
      if (el) {
        el.focus();
        el.select();
      }
    }, 50);
  };

  const getItemImageUrl = (item) => {
    const images = item.images ? JSON.parse(item.images) : [];
    return !isEmpty(images)
      ? `${backendUrl}/api/items/view-file/${images[0]?.systemFileName}`
      : noImageUrl;
  };

  return !layout || layout === "grid" ? (
    <div key={item.Bar_Code} className="item-holder">
      <div>
        <div
          className="image-area p-cursor"
          onClick={() =>
            !cartData &&
            addToCart({ ...item, Quantity: 1, quantityInStock: item.Quantity })
          }
        >
          <img src={getItemImageUrl(item)} />
        </div>
        <h3 className="item-name">{item.Item_Name || "..."}</h3>
        {!hideUnitPriceInStore ? (
          <p className="price">
            {currency(item?.UnitPrice, {
              symbol: "₦",
            }).format()}
          </p>
        ) : null}

        <div className="d-flex gap-1 flex-wrap">
          {item?.addDiscount && (
            <small>
              <b>Discount:</b>{" "}
              {item?.onlineDiscountType === "Fixed"
                ? currency(item?.onlineDiscountTotalAmount, {
                    symbol: "₦",
                  }).format()
                : `${currency(item?.onlineDiscountTypeInput, {
                    symbol: "",
                    precision: 0,
                  }).format()}%`}
              {"  "}
            </small>
          )}
          <small className="mt-0 text-xs">
            <b>UOM:</b> {item?.Product_Model}
          </small>

          {item?.Reorder_Level &&
          Number(item?.Reorder_Level) > Number(item?.Quantity) ? (
            <small className="mt-0 text-xs text-danger w-100">
              Out of Stock
            </small>
          ) : null}
          {item?.MinimumOrder && Number(item?.MinimumOrder) > 0 ? (
            <small className="mt-0 text-xs w-100">
              <b>Min Order Qty:</b>{" "}
              {currency(item?.MinimumOrder, {
                symbol: "",
                precision: 0,
              }).format()}
            </small>
          ) : null}
        </div>

        <p className="desc mb-3">{truncate(item?.Item_Desc) || "..."}</p>
      </div>
      {cartData ? (
        <NumberCustomInput
          name={`${item.Bar_Code}Quantity`}
          value={cartData.Quantity}
          onValueChange={(value, name) => {
            addQuantityToCart({
              index: cartDataIndex,
              Quantity: value,
              Bar_Code: item.Bar_Code,
            });
          }}
        />
      ) : (
        <Button
          onClick={() =>
            addToCart({ ...item, Quantity: 1, quantityInStock: item.Quantity })
          }
          className="w-100 action"
        >
          Add To Cart
        </Button>
      )}
    </div>
  ) : (
    <tr key={item.Bar_Code}>
      <td>
        <FormCheck
          checked={Boolean(cartData)}
          onChange={(e) =>
            e.target.checked
              ? addToCart({
                  ...item,
                  Quantity: 1,
                  quantityInStock: item.Quantity,
                })
              : removeFromCart({ ...item })
          }
        />
      </td>
      <td>
        <div className="image-area">
          <img src={getItemImageUrl(item)} />
        </div>
      </td>
      <td>
        <p className="fw-5">{item.Item_Name || "..."}</p>
        <span>{item?.Item_Desc}</span>
      </td>{" "}
      {!hideUnitPriceInStore ? (
        <td>
          {currency(item?.UnitPrice, {
            symbol: "₦",
          }).format()}
        </td>
      ) : null}
      <td>
        {cartData ? (
          <NumberCustomInput
            name={`${item.Bar_Code}Quantity`}
            style={{ width: "3rem" }}
            value={cartData.Quantity}
            onValueChange={(value, name) => {
              addQuantityToCart({
                index: cartDataIndex,
                Quantity: value,
              });
            }}
          />
        ) : (
          "..."
        )}
      </td>
      <td>
        {" "}
        {cartData ? (
          <Select
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                width: "7rem",
              }),
            }}
            classNamePrefix={"form-select"}
            placeholder={"Select"}
            isSearchable={false}
            value={Units.find((el) => el.value === cartData.saleType)}
            options={unitsResolver(Units, cartData.saleType)}
            onChange={({ value }) => {
              addSaleType({
                index: cartDataIndex,
                saleType: value,
              });
            }}
            isDisabled={item.Product_Model !== "Tons"}
          />
        ) : (
          getUnitLabelByValue(item?.Product_Model)
        )}
      </td>
      <td>{item.Product_Name || "..."}</td>
      <td>{item.Cat_Name || "..."}</td>
      {/*  <td>{item?.Product_Model}</td> */}
      {qtyInStock && (
        <td>
          {currency(item?.Quantity, {
            symbol: "",
            precision: 0,
          }).format()}
        </td>
      )}
      {/*    <td>{truncate(item?.Item_Desc)}</td> */}
    </tr>
  );
}

export default function Items({ usage = "" }) {
  useScrollTop();
  const location = useLocation();
  const navigate = useNavigate();
  const totalInCart = useStoreState((state) =>
    state.cart
      .map((el) => el.Quantity)
      .reduce((a, b) => currency(a).add(b).value, 0)
  );
  const [layout, setLayout] = useLocalStorage("grid"); /* table */
  const { customerBackendUrl: backendUrl, customer } = useAuth();
  const { categoryName = "", productName = "" } = useParams();
  const generalSettings = useStoreState((state) => state.generalSettings);

  const initialFilterParams = {
    page: 1,
    limit: 42,
    barcode: "",
    itemName: "",
    q: "",
    sort: "name-asc",
    minPrice: "",
    maxPrice: "",
  };

  const [queryParams, setQueryParams] = useQueryParams({
    ...initialFilterParams,
  });
  const [filterParams, setFilterParams] = useState({
    ...queryParams,
    ...initialFilterParams,
  });
  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({
      ...queryParams,
      ...debouncedFilterParams,
    });
  }, [debouncedFilterParams]);
  //-------------------------------------------------------------------------------
  const fetchItems = async (queryParams, categoryName, productName, usage) => {
    // await waitFor(5000);
    // console.log(usage);
    let response = await fetch(
      `${backendUrl}/api/${
        usage === "customer-products" ? "store/customer-products" : "items"
      }?&${queryString.stringify({
        ...queryParams,
        product: productName,
        category: categoryName,
        Cust_ID: customer?.Cust_ID,
        showInStore: true,
      })}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const {
    data = { count: 0, items: [] },
    refetch,
    isFetching,
    isSuccess,
  } = useQuery(
    [
      `STORE_ITEMS_${usage}_${categoryName}_${productName}`,
      queryParams,
      categoryName,
      productName,
      usage,
    ],
    () => fetchItems(queryParams, categoryName, productName, usage),
    {
      keepPreviousData: true,
    }
  );

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  eventBus.useCustomEventListener("STORE_SEARCH_STRING", (searchText) => {
    setQueryParams({
      ...queryParams,
      q: searchText,
      page: 1,
    });
  });

  if (isEmpty(customer) && usage === "customer-products") {
    //  toast.info("Not Logged In");
    return <Navigate to={appSettings.storeBaseUrl} key={nanoid()} />;
  }

  return (
    <main className="store-main">
      <div className="d-flex justify-content-between align-items-center head-row pb-4">
        <BreadcrumbLinks />

        <div className="d-flex gap-3 align-items-center">
          <h2>
            <b>Results</b> <span>({data.count})</span>{" "}
            {productName || categoryName ? (
              <b> - "{productName || categoryName}"</b>
            ) : null}
          </h2>

          <div className="d-flex grid-filters text-nowrap">
            <Select
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  width: "12rem",
                }),
              }}
              classNamePrefix={`form-select-store`}
              isSearchable={false}
              placeholder="Sort By"
              options={sortOptions}
              onChange={({ value }) =>
                setQueryParams({
                  ...queryParams,
                  page: 1,
                  sort: value,
                })
              }
            />{" "}
            <Button
              variant=""
              className={`ms-4 ${
                !layout || layout === "grid" ? "text-primary" : "text-light"
              }`}
              onClick={() => setLayout("grid")}
            >
              <GridIcon />
            </Button>
            <Button
              variant=""
              className={`ms-1 ${
                layout === "table" ? "text-primary" : "text-light"
              }`}
              onClick={() => setLayout("table")}
            >
              <ListIcon />
            </Button>
          </div>
        </div>
      </div>
      <div className="content">
        <aside className="filter">
          <header>Filters</header>
          <FilterArea
            filterParams={filterParams}
            setFilterParams={setFilterParams}
            refetch={refetch}
          />
        </aside>

        <section className="flex-md-grow-1 ">
          {!layout || layout === "grid" ? (
            <div className="items-grid m-0 gap-4">
              {data?.items.map((el) => (
                <ItemData
                  key={el?.Bar_Code}
                  item={el}
                  layout={layout}
                  hideUnitPriceInStore={
                    generalSettings?.hideUnitPriceInStore &&
                    usage !== "customer-products"
                  }
                />
              ))}
            </div>
          ) : (
            <div className="items-table">
              <Table
                hover
                striped
                borderless
                responsive
                className="product-table store-items-table"
              >
                <thead>
                  <tr>
                    <td />
                    <td>Image</td>
                    <td>Product Name</td>
                    {!generalSettings?.hideUnitPriceInStore ||
                    usage === "customer-products" ? (
                      <td>Price </td>
                    ) : null}
                    <td>Qty In Cart</td>
                    <td>UOM</td>
                    <td>Manufacturer</td>
                    <td>Category</td>
                    {!generalSettings.customerCanEditPriceInCart && (
                      <td>Qty In Stock </td>
                    )}
                    {/*    <td>Desc</td> */}
                  </tr>
                </thead>

                <tbody>
                  {data?.items.map((el, i) => (
                    <ItemData
                      key={el?.Bar_Code}
                      item={el}
                      layout={layout}
                      hideUnitPriceInStore={
                        generalSettings?.hideUnitPriceInStore &&
                        usage !== "customer-products"
                      }
                      qtyInStock={!generalSettings.customerCanEditPriceInCart}
                    />
                  ))}
                </tbody>
              </Table>
            </div>
          )}

          {!isFetching && isSuccess && isEmpty(data?.items) ? (
            <div className="py-5 bg-white">
              <NoTableItem queryParams={queryParams} />{" "}
            </div>
          ) : null}

          <div className="d-flex justify-content-between px-3 align-items-center pagination mt-4">
            <div className="pagination_left d-flex gap-3 align-items-center">
              <p className="m-0 p-0 fw-6">Show</p>
              <select
                value={queryParams.limit}
                name="limit"
                className="form-select "
                onChange={(e) => handleSearchQueryChange(e)}
              >
                <option value="10">10 rows</option>
                <option value="20">20 rows</option>
                <option value="30">30 rows</option>
                <option value="42">42 rows</option>
                <option value="50">50 rows</option>
                <option value="100">100 rows</option>
              </select>
            </div>

            <ReactPaginate
              {...paginationOptions}
              pageCount={Math.ceil(data.count / queryParams.limit)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={0}
              onPageChange={({ selected }) => {
                scrollToTop();
                setQueryParams({
                  ...queryParams,
                  page: selected + 1,
                });
              }}
              forcePage={queryParams.page - 1}
            />
          </div>

          <div className="d-flex justify-content-between p-3 mt-3">
            <div />

            {totalInCart ? (
              <Button
                onClick={() =>
                  navigate(`${appSettings.storeBaseUrl}/cart`, {
                    state: { checkout: false },
                  })
                }
                className="p-2 px-5"
              >
                {generalSettings?.checkoutButtonText}
              </Button>
            ) : null}
          </div>
        </section>
      </div>
    </main>
  );
}
