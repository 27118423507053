import React, { useEffect, useMemo, useState } from "react";
import PageHeader from "../PageHeader";
import { PaymentAndBillingIcon } from "../Icons";
import { Button, Card, Form, Modal, Table } from "react-bootstrap";
import PaystackPop from "@paystack/inline-js";
import Select from "react-select";

import * as yup from "yup";

import {
  Form as FormikForm,
  ErrorMessage,
  useFormik,
  FieldArray,
  FormikProvider,
} from "formik";
import { waitFor } from "../../utils/helpers";
import currency from "currency.js";
import { ConfirmRemoveUserBillingDialog } from "../ConfirmDialogue";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/useAuth";
import { useQueries, useQuery } from "react-query";
import NumberCustomInput from "../utils/NumberCustomInput";
import { useNavigate } from "react-router-dom";
import { uniqBy } from "lodash";
import { backendApis } from "../../config";

const apis = [backendApis];
const allDepartment = [
  "",
  "Admin",
  "Accountant",
  // "Cashier",
  "Content Management",
  "Contracts",
  "HR",
  "Sales",
  // "Shout Out",
  "Store",
  "Warehouse",
  "Production",
  "Sales Manager",
  "Operations",
  "Business Development",
  "Procurement",
  "QHSE",
  "Maintenance",
  "Document Control",
  "Government InvexERP",
  "Supply and Logistics",
  "Hospital Management Board",
];

const RemoveUserModal = ({
  show,
  handleHide,
  userStatus,
  handleSubmit: submit,
  companyStaff,
  totalUsers,
  currentUsers,
}) => {
  const [removedUser, setRemovedUsers] = useState([]);
  const formik = useFormik({
    initialValues: {
      user: [{ Name: "", Department: "", existingUsers: "", Staff_ID: "" }],
    },

    onSubmit: (values) => {
      if (values.user.length > totalUsers) {
        formik.setErrors({
          user: `user limit exceeded`,
        });
        throw new Error(`user limit exceeded`);
      } else {
        const addedUsers = 0;
        const removedUsers = currentUsers - totalUsers;
        const userActionType = "expiredRemovedUsers";

        const userDetails = removedUser.map((user) => {
          return {
            Name: user.Name,
            Staff_ID: user.Staff_ID,
            email: user?.email ? user?.email : "",
            branchUrl: user?.url ? user?.url : [],
            branch: user.Branch ? user.Branch : "",
            department: user.Department,
          };
        });

        submit(
          userDetails,
          addedUsers,
          removedUsers,
          userActionType,
          currentUsers
        );
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue("user", companyStaff);

    formik.setFieldValue("existingUsers", companyStaff);

    const staffOptions = allDepartment?.map((department) => ({
      label: department,
      value: department,
    }));

    formik.setFieldValue("staff", staffOptions);
    setRemovedUsers([]);
  }, [companyStaff]);

  return (
    <Modal
      show={show}
      centered
      onHide={handleHide}
      enforceFocus={false}
      backdrop="true"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>{userStatus}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <div className="content px-4 pb-4">
              <FieldArray
                name="user"
                render={(arrayHelpers) => (
                  <div className="content px-4 pb-4">
                    <Table responsive className="product-table " striped>
                      <thead>
                        <tr>
                          <td className=" fw-bold">Staff ID</td>
                          <td className=" fw-bold">Name</td>
                          <td className=" fw-bold">Department</td>
                          <td className=" fw-bold">Branch</td>
                          <td className=" fw-bold" />
                        </tr>
                      </thead>
                      <tbody>
                        {formik.values.user.map((el, index) => (
                          <tr>
                            <td className=" ">
                              {formik.values.user[index].Staff_ID}
                            </td>

                            <td className="">
                              {formik.values.user[index].Name}
                            </td>

                            <td className="">
                              {formik.values.user[index].Department}
                            </td>
                            <td className="">
                              {formik.values.user[index].Branch}
                            </td>

                            <td>
                              <Button
                                title="Remove"
                                variant=""
                                type="button"
                                size="xs"
                                onClick={() => {
                                  setRemovedUsers([...removedUser, el]);

                                  arrayHelpers.remove(index);
                                }}
                              >
                                ✖
                              </Button>
                            </td>
                          </tr>
                        ))}{" "}
                        <tr>
                          <td colspan="5">
                            <ErrorMessage
                              name="user"
                              component="span"
                              className="text-danger mb-2"
                            />
                          </td>
                        </tr>
                      </tbody>

                      <tfoot>
                        <tr>
                          <td colspan="5">
                            <div className="w-100  d-flex  justify-content-between align-items-center">
                              <div>
                                <p>
                                  Users: {formik.values.user.length} /{" "}
                                  {totalUsers}
                                </p>
                              </div>
                              <Button variant="primary" type="submit">
                                Continue{" "}
                              </Button>
                            </div>
                          </td>
                        </tr>
                      </tfoot>
                    </Table>
                  </div>
                )}
              />
            </div>
          </Form>
        </FormikProvider>
      </Modal.Body>
    </Modal>
  );
};

const initialValues = {
  addUsers: 1,
  totalUsers: 0,
  totalAmount: 0,
  currentBalance: 0,
  billing: 0,
  currentPlan: "",
  currentUsers: null,
  planDurationType: "",
  pricePerUser: 0,
};

export default function RenewExpiredAccount() {
  const { backendUrl, token } = useAuth();
  const [companyStaff, setComapanyStaff] = useState([]);
  const [totalUsers, setTotalUsers] = useState(null);
  const [show, setShow] = useState(false);
  const [removeUserModal, setRemoveUserModal] = useState(false);
  const [userStatus, setUserStatus] = useState("");
  const [currentUser, setCurrentUser] = useState(null);
  const [activeBtn, setActiveBtn] = useState("");
  const navigate = useNavigate();
  const [companyUrl, setCompanyUrl] = useState("");

  const handleShow = () => {
    setShow(true);
  };
  const handleHideRemoveUserModal = () => {
    setRemoveUserModal(false);
  };

  const handleHide = () => {
    setShow(false);
  };
  const paystackConfig = {
    //  reference: new Date().getTime().toString(),
    //  email: "user@example.com",
    // amount: 20000, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    key: process.env.REACT_APP_BILLING_AND_PAYMENT_PAYSTACK_PUBLIC_KEY,
  };

  const pay = async ({
    amount,
    reference,
    email,
    users,
    userDetails = "",
    addedUsers,
    removedUsers,
    userActionType,
    currentUsers,
    planDurationType,
    invexversion,
    pricePerUser,
  }) => {
    try {
      await waitFor(500);
      const paystack = new PaystackPop();
      paystack.newTransaction({
        ...paystackConfig,
        reference,
        email,
        amount: currency(amount).multiply(100).value,
        metadata: {
          totalUsers: users,
          type: "billing",
          usersDetails: userDetails,
          addedUsers: addedUsers,
          removedUsers: removedUsers,
          userActionType: userActionType,
          currentUsers: currentUsers,
          email: email,
          planDurationType,
          invexversion,
          pricePerUser,
          totalAmount: amount,
          reference,
          companyUrl: companyUrl,
        },
        // other params

        onSuccess: async (transaction) => {
          if (process.env.REACT_APP_ENV !== "production") {
            await fetch(`${backendUrl}/api/company/billing-hook`, {
              method: "POST",
              credentials: "include",
              body: JSON.stringify({
                metadata: {
                  totalUsers: users,
                  type: "billing",
                  usersDetails: userDetails,
                  addedUsers: addedUsers,
                  removedUsers: removedUsers,
                  userActionType: userActionType,
                  currentUsers: currentUsers,
                  email: email,
                  planDurationType,
                  invexversion,
                  pricePerUser,
                  totalAmount: amount,
                  reference,
                  companyUrl: companyUrl,
                },
              }),
              headers: {
                Accept: "Application/json",
                "Content-Type": "Application/json",
                ...(token ? { Authorization: `Bearer ${token}` } : {}),
              },
            });
          }
          navigate(
            `/process-billing-payment-transaction?reference=${
              transaction.reference
            }&q=${formik.values.addUsers}`
          );
        },
        onCancel: () => {
          // user closed popup
          console.log("closed");
        },
      });
    } catch (err) {
      console.log(err);
      toast.error(JSON.stringify(err));
    }
  };
  const payForRemainingUsers = async ({
    amount,
    reference,
    email,
    users,
    userDetails = "",
    addedUsers,
    removedUsers,
    userActionType,
    currentUsers,
    planDurationType,
    invexversion,
    pricePerUser,
  }) => {
    try {
      await waitFor(500);
      const paystack = new PaystackPop();
      paystack.newTransaction({
        ...paystackConfig,

        reference,
        email,
        amount: currency(amount).multiply(100).value,
        metadata: {
          totalUsers: users,
          type: "billing",
          usersDetails: userDetails,
          addedUsers: addedUsers,
          removedUsers: removedUsers,
          userActionType: userActionType,
          currentUsers: currentUsers,
          email: email,
          planDurationType,
          invexversion,
          pricePerUser,
          totalAmount: amount,
          reference,
          companyUrl: companyUrl,
        },
        // other params

        onSuccess: async (transaction) => {
          if (process.env.REACT_APP_ENV !== "production") {
            await fetch(`${backendUrl}/api/company/billing-hook`, {
              method: "POST",
              credentials: "include",
              body: JSON.stringify({
                metadata: {
                  totalUsers: users,
                  type: "billing",
                  usersDetails: userDetails,
                  addedUsers: addedUsers,
                  removedUsers: removedUsers,
                  userActionType: userActionType,
                  currentUsers: currentUsers,
                  email: email,
                  planDurationType,
                  invexversion,
                  pricePerUser,
                  totalAmount: amount,
                  reference,
                  companyUrl: companyUrl,
                },
              }),
              headers: {
                Accept: "Application/json",
                "Content-Type": "Application/json",
                ...(token ? { Authorization: `Bearer ${token}` } : {}),
              },
            });
          }

          navigate(
            `/process-billing-payment-transaction?reference=${
              transaction.reference
            }&q=${formik.values.addUsers}`
          );
        },
        onCancel: () => {
          // user closed popup
          console.log("closed");
        },
      });
    } catch (err) {
      console.log(err);
      toast.error(JSON.stringify(err));
    }
  };

  const handleSubmit = async (
    values,
    addedUsers,
    removedUsers,
    userActionType,
    currentUsers
  ) => {
    await payForRemainingUsers({
      email: formik.values.email,
      amount: formik.values.billing,
      reference: new Date().getTime().toString(),
      users: formik.values.addUsers,
      userDetails: JSON.stringify(values),
      addedUsers,
      removedUsers,
      userActionType,
      currentUsers,
      planDurationType: formik.values.planDurationType,
      invexversion: process.env.REACT_APP_SITE_TITLE,
      pricePerUser: formik.values.pricePerUser,
    });
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async () => {
      if (formik.values.addUsers === formik.values.currentUsers) {
        await payForRemainingUsers({
          email: formik.values.email,
          amount: formik.values.billing,
          reference: new Date().getTime().toString(),
          users: formik.values.addUsers,
          addedUsers: 0,
          removedUsers: 0,
          userActionType: "expiredRenew",
          currentUsers: formik.values.currentUsers,
          planDurationType: formik.values.planDurationType,
          invexversion: process.env.REACT_APP_SITE_TITLE,
          pricePerUser: formik.values.pricePerUser,
        });
      } else if (formik.values.addUsers < formik.values.currentUsers) {
        const confirm = await ConfirmRemoveUserBillingDialog({
          title: `Remove users`,
          description: `Are you sure you want proceed?`,
        });

        if (confirm) {
          setUserStatus("Remove users");
          setRemoveUserModal(true);
          setTotalUsers(formik.values.addUsers);
        }
      } else {
        await pay({
          email: formik.values.email,
          amount: formik.values.billing,
          reference: new Date().getTime().toString(),
          users: formik.values.addUsers,
          addedUsers: formik.values.addUsers - formik.values.currentUsers,
          removedUsers: 0,
          userActionType: "expiredAddedUsers",
          currentUsers: formik.values.currentUsers,
          planDurationType: formik.values.planDurationType,
          invexversion: process.env.REACT_APP_SITE_TITLE,
          pricePerUser: formik.values.pricePerUser,
        });
      }
    },
  });

  const fetchcompanyDetails = async (url) => {
    const res = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
    });

    const data = await res.json();

    setComapanyStaff(data?.staff);

    const companyData = data?.company;
    return companyData;
  };

  const { data } = useQuery(
    ["GET_COMPANY_DETAILS"],
    () => fetchcompanyDetails(`${backendUrl}/api/company/details`),
    {
      onSuccess: (data) => {
        formik.setFieldValue("email", data?.Email);
        formik.setFieldValue("currentPlan", "Business plan");
        formik.setFieldValue("currentUsers", Number(data?.Num_Users));
        formik.setFieldValue("addUsers", Number(data?.Num_Users));

        formik.setFieldValue(
          "planDurationType",
          data?.planDurationType || "yearly"
        );

        formik.setFieldValue(
          "billing",
          process.env?.REACT_APP_SITE_TITLE === "InvexERP" &&
            data?.planDurationType === "yearly"
            ? Number(data?.Num_Users) * 250000
            : process.env?.REACT_APP_SITE_TITLE !== "InvexERP" &&
              data?.planDurationType === "yearly"
            ? Number(data?.Num_Users) * 125000
            : process.env?.REACT_APP_SITE_TITLE === "InvexERP" &&
              data?.planDurationType === "monthly"
            ? Number(data?.Num_Users) * 25000
            : process.env?.REACT_APP_SITE_TITLE !== "InvexERP" &&
              data?.planDurationType === "monthly"
            ? Number(data?.Num_Users) * 12500
            : 0
        );

        if (!Boolean(data?.planDurationType)) {
          formik.setFieldValue(
            "billing",
            process.env.REACT_APP_SITE_TITLE === "InvexERP"
              ? 250000 * Number(data?.Num_Users)
              : 125000 * Number(data?.Num_Users)
          );
        }

        const companyHqUrl = backendApis
          .map((url) => {
            if (url.isHq) {
              return url.url;
            }
          })

          .join("");

        setCompanyUrl(companyHqUrl);

        setCurrentUser(Number(data?.Num_Users));
        formik.setFieldValue("pricePerUser", data?.pricePerUser);
        setActiveBtn(data?.planDurationType || "yearly");
      },

      enabled: true,
    }
  );

  const fetchUsers = async (endpoint) => {
    try {
      const res = await fetch(endpoint);

      const data = await res.json();

      return { ...data, url: endpoint };
    } catch (error) {
      throw new Error(`Failed to fetch ${endpoint}`);
    }
  };

  const queries = useQueries(
    apis
      ?.map((company) =>
        company.map((api) => ({
          queryKey: ["user", api.name],
          queryFn: () => fetchUsers(`${api.url}/api/users`),
        }))
      )
      .flat()
  );

  const userData = useMemo(() => {
    if (queries && queries.length > 0 && data) {
      const companies = queries?.map((query) => query.data);

      const staff = companies.filter(
        (company) => company?.company?.CompName === data?.CompName
      );

      const companyStaff = staff
        ?.map((user) => {
          return user.staff.map((staf) => {
            return { ...staf, url: user?.url };
          });
        })
        .flat();

      const uniqStaff = uniqBy(companyStaff, "username");

      function findUserBranches(username) {
        return companyStaff.filter((url) => url.username === username);
      }

      const userBranches = uniqStaff.map((branch) => {
        const found = findUserBranches(branch.username);

        const urls = found.map((url) => url.url);
        return { ...branch, url: urls };
      });

      return userBranches;
    } else {
      return [];
    }
  }, [queries, data]);
  const handleYearlyBtn = () => {
    formik.setFieldValue("planDurationType", "yearly");

    formik.setFieldValue(
      "billing",
      process.env?.REACT_APP_SITE_TITLE === "InvexERP"
        ? 250000 * Number(formik.values.addUsers)
        : 125000 * Number(formik.values.addUsers)
    );

    formik.setFieldValue(
      "pricePerUser",
      process.env?.REACT_APP_SITE_TITLE === "InvexERP" ? 250000 : 125000
    );

    setActiveBtn("yearly");
  };

  const handleMonthlyBtn = () => {
    formik.setFieldValue("planDurationType", "monthly");

    formik.setFieldValue(
      "billing",
      process.env?.REACT_APP_SITE_TITLE === "InvexERP"
        ? 25000 * Number(formik.values.addUsers)
        : 12500 * Number(formik.values.addUsers)
    );

    formik.setFieldValue(
      "pricePerUser",
      process.env?.REACT_APP_SITE_TITLE === "InvexERP" ? 25000 : 12500
    );

    setActiveBtn("monthly");
  };

  return (
    <>
      <RemoveUserModal
        handleShow={handleShow}
        show={removeUserModal}
        setShow={setRemoveUserModal}
        handleHide={handleHideRemoveUserModal}
        userStatus={userStatus}
        handleSubmit={handleSubmit}
        totalUsers={totalUsers}
        companyStaff={userData}
        currentUsers={currentUser}
      />

      {/* <SkipModal handleShow={handleShow} show={show} /> */}
      <main className="inner-page-outlet">
        <PageHeader
          name="Payment & Billing"
          description="Renew your business plan"
          icon={<PaymentAndBillingIcon />}
        />

        <Form noValidate onSubmit={formik.handleSubmit}>
          <main className=" container mx-auto" style={{ maxWidth: "600px" }}>
            <div>
              <div className=" w-100 p-5 px-3">
                <Card>
                  <Card.Body className="p-4 ">
                    <h6 className="card-header-title  my-3">Billing</h6>
                    <div className=" mb-3">
                      <b>
                        Current Plan:
                        <span className=" fw-normal mx-1"> Business plan</span>
                      </b>
                    </div>

                    <p className=" fw-bold border-bottom my-3 pb-2">
                      Billing Details:
                    </p>

                    <div className="col-md-6 d-flex mb-4 ">
                      <Button
                        style={{
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                          border: 0,
                          backgroundColor:
                            activeBtn === "monthly" ? "#5a9ace" : "#e4edf2",
                          color: activeBtn === "monthly" ? "white" : "black",
                        }}
                        onClick={handleMonthlyBtn}
                        className={`w-100  py-2  `}
                      >
                        Monthly
                      </Button>
                      <Button
                        style={{
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                          border: 0,
                          backgroundColor:
                            activeBtn === "yearly" ? "#5a9ace" : "#e4edf2",
                          color: activeBtn === "yearly" ? "white" : "black",
                        }}
                        className={`w-100  py-2`}
                        onClick={handleYearlyBtn}
                      >
                        Yearly
                      </Button>
                    </div>

                    {formik.values.planDurationType === "yearly" && (
                      <div className=" d-flex align-items-center gap-2">
                        <Form.Group className="mb-3">
                          <Form.Check
                            type="radio"
                            label={`${currency(
                              process.env?.REACT_APP_SITE_TITLE === "InvexERP"
                                ? 250000
                                : 125000
                            ).format({
                              symbol: "₦",
                              precision: 0,
                            })} / user / year ${
                              formik.values.planDurationType === "yearly"
                                ? "( your current plan is billed at yearly)"
                                : ""
                            }`}
                            name="planDurationType"
                            value={"yearly"}
                            checked={
                              formik.values.planDurationType === "yearly"
                            }
                            onChange={() => {
                              formik.setFieldValue(
                                "planDurationType",
                                "yearly"
                              );

                              formik.setFieldValue(
                                "billing",
                                process.env?.REACT_APP_SITE_TITLE === "InvexERP"
                                  ? 250000 * Number(formik.values.addUsers)
                                  : 125000 * Number(formik.values.addUsers)
                              );

                              formik.setFieldValue(
                                "pricePerUser",
                                process.env?.REACT_APP_SITE_TITLE === "InvexERP"
                                  ? 250000
                                  : 125000
                              );
                            }}
                          />
                        </Form.Group>
                      </div>
                    )}

                    {formik.values.planDurationType === "monthly" && (
                      <div className=" d-flex align-items-center gap-2">
                        <Form.Group className="mb-3">
                          <Form.Check
                            type="radio"
                            label={`${currency(
                              process.env?.REACT_APP_SITE_TITLE === "InvexERP"
                                ? 25000
                                : 12500
                            ).format({
                              symbol: "₦",
                              precision: 0,
                            })} / user / Month ${
                              formik.values.planDurationType === "monthly"
                                ? "( your current plan is billed at monthly)"
                                : ""
                            }`}
                            name="planDurationType"
                            checked={
                              formik.values.planDurationType === "monthly"
                            }
                            onChange={() => {
                              formik.setFieldValue(
                                "planDurationType",
                                "monthly"
                              );

                              formik.setFieldValue(
                                "billing",
                                process.env?.REACT_APP_SITE_TITLE === "InvexERP"
                                  ? 25000 * Number(formik.values.addUsers)
                                  : 12500 * Number(formik.values.addUsers)
                              );

                              formik.setFieldValue(
                                "pricePerUser",
                                process.env?.REACT_APP_SITE_TITLE === "InvexERP"
                                  ? 25000
                                  : 12500
                              );
                            }}
                          />
                        </Form.Group>
                      </div>
                    )}

                    <div className="mb-3   col-md-6">
                      <div>Add users:</div>
                      <NumberCustomInput
                        initialValues={1}
                        placeholder="0"
                        name="addUsers"
                        value={formik.values.addUsers}
                        onValueChange={(value, name) => {
                          formik.setFieldValue(name, value);

                          formik.setFieldValue(
                            "billing",
                            Boolean(value) &&
                              process.env?.REACT_APP_SITE_TITLE ===
                                "InvexERP" &&
                              formik.values.planDurationType === "yearly"
                              ? value * 250000
                              : process.env?.REACT_APP_SITE_TITLE !==
                                  "InvexERP" &&
                                formik.values.planDurationType === "yearly"
                              ? value * 125000
                              : process.env?.REACT_APP_SITE_TITLE ===
                                  "InvexERP" &&
                                formik.values.planDurationType === "monthly"
                              ? value * 25000
                              : process.env?.REACT_APP_SITE_TITLE !==
                                  "InvexERP" &&
                                formik.values.planDurationType === "monthly"
                              ? value * 12500
                              : 0
                          );
                        }}
                        onBlur={() => formik.setFieldTouched("addUsers", true)}
                      />
                    </div>

                    <div className=" mb-3">
                      Total Users:
                      <span span className=" mx-2">
                        {formik.values.addUsers}
                      </span>
                    </div>
                    <div className="mb-2">
                      Total Amount:{" "}
                      <span className=" mx-2">
                        {" "}
                        {currency(formik.values.billing).format({
                          symbol: "₦",
                        })}
                      </span>
                    </div>

                    <div className=" row">
                      <div className=" col-md-8 mb-3">
                        <Button
                          variant="primary"
                          className="  py-3 mt-2 col-md-5"
                          type="submit"
                        >
                          Make Payment
                        </Button>
                        <Button
                          variant=""
                          onClick={() => navigate("/payment-and-billing")}
                          className="btn-lg mt-2 col-md-5 mx-2 py-3    btn-outline-primary"
                        >
                          Cancel
                        </Button>
                      </div>
                      <div className=" col-md-12">
                        <div style={{ maxWidth: "8rem" }}>
                          <img src="/card.png" className=" img-fluid" />
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </main>
        </Form>
      </main>
    </>
  );
}
