import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useState } from "react";
import { Modal, Table, Form, Button } from "react-bootstrap";
// import "./../assets/scss/customer-select-modal.scss";
import useDebounce from "../../utils/hooks";
import { appSettings, services } from "../../config";
import queryString from "query-string";
import { useMutation, useQuery } from "react-query";
import { queryActions } from "../../utils/reactQueryActions";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import {
  customerFullName,
  employeeFullName,
  paginationOptions,
} from "../../utils/helpers";
import currency from "currency.js";
import { useAuth } from "../../hooks/useAuth";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import CachedIcon from "mdi-react/CachedIcon";
import NoTableItem from "../utils/NoTableItem";
import { isEmpty } from "lodash";
import { DeleteIcon } from "../Icons";
import ConfirmDialog from "../ConfirmDialogue";

const options = [
  { label: "Pending", value: "Pending" },
  { label: "Approved", value: "Approved" },
];

export default function SetSalesRepModal({
  setShowSaleRepModal,
  selectedCustomer,
  setSelectedCustomer,
}) {
  const { backendUrl } = useAuth();
  const [queryParams, setQueryParams] = useState({
    /*  page: 1,
    limit: 10, */
    Cust_ID: selectedCustomer.Cust_ID,
  });
  const debouncedqueryParams = useDebounce(queryParams, 500);

  const getSaleReps = async (debouncedqueryParams) => {
    let response = await fetch(
      `${backendUrl}/api/customers/sales-rep?${queryString.stringify(
        debouncedqueryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    data.products = data.products.map((el) => ({
      value: el.Product_Name,
      label: el.Product_Name,
    }));

    data.employees = data.employees.map((el) => ({
      value: el.EmployeeID,
      label: employeeFullName(el),
    }));

    return data;
  };

  const {
    data = { customerReps: [], products: [], employees: [] },
    refetch,
    isFetching,
  } = useQuery(
    ["SALES_REP", debouncedqueryParams],
    () => getSaleReps(debouncedqueryParams),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      page: 1,
      [e.target.name]: e.target.value,
    });
  };

  const createCustomerSaleRep = async (payload) => {
    let response = await fetch(`${backendUrl}/api/customers/create-sale-rep`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const createCustomerSaleRepMutation = useMutation(
    (payload) => createCustomerSaleRep(payload),
    {
      onSuccess: ({ message, data }) => {
        toast.success(message);
        formik.resetForm();
        refetch();
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const deleteCustomerSaleRep = async (payload) => {
    let response = await fetch(`${backendUrl}/api/customers/delete-sale-rep`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const daleteCustomerSaleRepMutation = useMutation(
    (payload) => deleteCustomerSaleRep(payload),
    {
      onSuccess: ({ message, data }) => {
        toast.success(message);
        refetch();
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      Product: "",
      Rep_ID: "",
    },
    onSubmit: (values) => {
      if (!values.Product || !values.Rep_ID) {
        return toast.error("Please select an option");
      }
      values.Cust_ID = selectedCustomer.Cust_ID;
      createCustomerSaleRepMutation.mutate(values);
    },
  });

  const deleteCustomerRep = async (rep) => {
    if (
      await ConfirmDialog({
        title: `Delete ${rep?.Product} for ${
          rep.employee ? employeeFullName(rep.employee) : "..."
        }`,
        description: `Are you sure, you want to Delete?`,
      })
    ) {
      daleteCustomerSaleRepMutation.mutate({
        Product: rep.Product,
        Rep_ID: rep.Rep_ID,
        Cust_ID: rep.Cust_ID,
      });
    }
  };

  return (
    <Modal
      show={true}
      onHide={() => setShowSaleRepModal(false)}
      dialogClassName=""
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title className="flex-nowrap">
          <h1 className="h5 m-0">
            Set Sales Rep {"   "}{" "}
            <Button
              variant="white"
              className="text-primary"
              onClick={() => refetch()}
            >
              <CachedIcon />
            </Button>
          </h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className=" w-100 px-3 mb-4">
          <p className="fs-6">
            <span className="fw-6">Customer ID:</span>
            {"  "}
            {selectedCustomer.Cust_ID}
          </p>
          <p className="mb-3 fs-6">
            <span className="fw-6"> Business Name:</span>
            {"  "}
            {customerFullName(selectedCustomer)}
          </p>
          <hr />

          <Form noValidate onSubmit={formik.handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label> Product / Manufacturer </Form.Label>
              <Select
                classNamePrefix="form-select"
                options={data.products}
                value={data?.products?.find(
                  (el) => el.value === formik.values?.Product
                )}
                onChange={({ value }) => formik.setFieldValue("Product", value)}
                isSearchable={false}
                key={formik.values?.Product}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Sales Rep Name</Form.Label>
              <Select
                classNamePrefix="form-select"
                options={data.employees}
                value={data?.employees?.find(
                  (el) => el.value === formik.values?.Rep_ID
                )}
                onChange={({ value }) => formik.setFieldValue("Rep_ID", value)}
                isSearchable={false}
                key={formik.values?.Rep_ID}
              />
            </Form.Group>

            <Form.Group className="mt-3">
              <Button type="submit" className="px-3">
                Save
              </Button>
            </Form.Group>
          </Form>
        </div>

        <div className="content px-3 pb-4 pe-2">
          <Table borderless striped responsive className="product-table">
            <thead className="sticky border-bottom">
              <tr>
                <th />
                <th>Manufacturer</th>
                <th>Sales Rep</th>
              </tr>
            </thead>

            <tbody className="blue-hover">
              {data.customerReps &&
                data.customerReps.map((el, index) => (
                  <tr
                    key={index}
                    // onClick={() => handleSelectedSaleRep(el)}
                    className={`p-cursor`}
                  >
                    <td>
                      <Button
                        variant=""
                        onClick={() => deleteCustomerRep(el)}
                        disabled={daleteCustomerSaleRepMutation.isLoading}
                      >
                        <DeleteIcon />
                      </Button>
                    </td>
                    <td>{el.Product}</td>
                    <td>
                      {el.employee ? employeeFullName(el.employee) : "..."}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>

          {isEmpty(data.customerReps) && !isFetching ? (
            <div className="d-flex justify-content-center text-center w-100 my-4">
              <NoTableItem queryParams={queryParams} />
            </div>
          ) : null}
        </div>

        {/* <div className="d-flex justify-content-between px-3 align-items-center pagination">
          <p className="m-0">
            Showing {data?.startIndex + 1} to{" "}
            {data?.endIndex <= data?.count ? data?.endIndex : data?.count} of{" "}
            {data.count} entries
          </p>

          <ReactPaginate
            {...paginationOptions}
            pageCount={Math.ceil(data.count / queryParams.limit)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={0}
            onPageChange={({ selected }) => {
              document.body.scrollTop = document.documentElement.scrollTop = 0;
              setQueryParams({
                ...queryParams,
                page: selected + 1,
              });
            }}
            forcePage={queryParams.page - 1}
          />
        </div> */}
      </Modal.Body>
    </Modal>
  );
}
