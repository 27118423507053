import currency from "currency.js";
import { useQuery } from "react-query";
import { Link, Outlet, useLocation, useSearchParams } from "react-router-dom";
import {
  FileListIcon,
  ArrowDownGrowthIcon,
  ArrowUpGrowthIcon,
  LineChartIcon,
  BucketIcon,
  PageHeaderListIcon,
  CurrencyFillIcon,
  RecieptIcon,
  UserIcon,
} from "../../components/Icons";
import { useAuth } from "../../hooks/useAuth";
import { useIsAdmin, useIsCashier, useIsSales } from "../../utils/hooks";
import "./../../assets/scss/invoicewrapper.scss";
import { useEffect, useMemo } from "react";
import queryString from "query-string";

export function HMBWrapper({ Nav }) {
  const { backendUrl, user: authUser } = useAuth();
  const isAdmin = useIsAdmin();
  const isCashier = useIsCashier();
  const isSales = useIsSales();

  const { search } = useLocation();
  const queryParams = useMemo(() => queryString.parse(search), [search]);

  const getStats = async (queryParams = {}) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/invexcloud/hmb-rsmoh-stats?${queryString.stringify(
        queryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    let { data } = await response.json();

    return data;
  };

  const { error, data, isSuccess, refetch, isFetching } = useQuery(
    ["HMB_STAT", queryParams],
    () => getStats(queryParams),
    {
      // enabled: false,
      // cacheTime: 0,
      onSuccess: (data) => {
        // queryClient.removeQueries("LOGIN");
      },
    }
  );

  return (
    <div className="invoice-wrapper">
      <section className={`stats mb-3`}>
        <div className="stat">
          <div className="icon bg-light-blue">
            <UserIcon />
          </div>
          <div className="details">
            <p>
              {currency(data?.totalCustomers, {
                symbol: "",
                precision: 0,
              }).format()}
            </p>
            <span>Customers</span>
          </div>
        </div>

        <div className="stat">
          <div className="icon bg-light-blue">
            <RecieptIcon />
          </div>
          <div className="details">
            <p>
              {currency(data?.totalInvoices, {
                symbol: "",
                precision: 0,
              }).format()}
            </p>
            <span>Total Invoices</span>
          </div>
        </div>

        <div className="stat">
          <div className="icon bg-light-blue">
            <PageHeaderListIcon />
          </div>
          <div className="details">
            <p>
              {currency(data?.totalSales, {
                symbol: "₦",
                precision: 2,
              }).format()}
            </p>
            <span>Total Sales</span>
          </div>
        </div>

        {authUser?.Department === "Rivers State Ministry of Health" && (
          <>
            {/* <div className="stat">
              <div className="icon bg-light-blue">
                <LineChartIcon />
              </div>
              <div className="details">
                <p>
                  {currency(data?.totalProfit, {
                    symbol: "₦",
                    precision: 2,
                  }).format()}
                </p>
                <span>Total Profit</span>
              </div>
            </div> */}

            <div className="stat">
              <div className="icon bg-light-blue">
                <ArrowUpGrowthIcon />
              </div>
              <div className="details">
                <p>
                  {currency(data?.totalIncentive, {
                    symbol: "₦",
                    precision: 2,
                  }).format()}
                </p>
                <span>Total Incentive</span>
              </div>
            </div>
          </>
        )}
      </section>

      {Nav && <div className="nav-bar">{Nav}</div>}
      <Outlet />
    </div>
  );
}
