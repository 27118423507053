import React from "react";
import CacheBuster from "react-cache-buster";
import packageData from "../package.json";
import ModalLoader from "./components/utils/ModalLoader";
import ConfirmDialog from "./components/ConfirmDialogue";

const CacheCheck = ({ children }) => {
  const isProduction = process.env.NODE_ENV === "production";
  return (
    <CacheBuster
      currentVersion={packageData.version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={true} //If true, the library writes verbose logs to console.
      loadingComponent={<ModalLoader title="Please wait..." />} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
      onCacheClear={async (refreshCacheAndReload) => {
        /*  await ConfirmDialog({
          title: `New Version Available`,
          description: `Refresh...`,
          choice: false,
        }); */
        refreshCacheAndReload();
      }}
    >
      {children}
    </CacheBuster>
  );
};

export default CacheCheck;
