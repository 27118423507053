import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useState } from "react";
import { Modal, Table, Form, Button } from "react-bootstrap";
import "./../assets/scss/customer-select-modal.scss";
import useDebounce, { useEffectOnce } from "../utils/hooks";
import { services } from "../config";
import queryString from "query-string";
import { useQuery } from "react-query";
import { queryActions } from "../utils/reactQueryActions";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import {
  customerTypeOptions,
  paginationOptions,
  scrollToTop,
} from "../utils/helpers";
import { useAuth } from "../hooks/useAuth";
import NewCustomerModal from "./NewCustomerModal";
import { uniqBy } from "lodash";

export default function CustomerSelectModal({
  setShowCustomerSelectorModal,
  selectedCustomer,
  setSelectedCustomer,
  title,
  withCredit = false,
  withPaymentDue = false,
  isMulti = false,
  alreadySelectedCustomers = [],
  withInvoiceCat = false,
}) {
  const [showCreateNewCustomerModal, setShowCreateNewCustomerModal] = useState(
    false
  );
  const [showSelected, setShowSelected] = useState(false);
  const [massSelectedCustomers, setMassSelectedCustomers] = useState([]);
  const { backendUrl, token } = useAuth();
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 10,
    LastName: "",
    PhoneNo1: "",
    withCredit,
    withPaymentDue,
    withInvoiceCat,
  });
  const debouncedqueryParams = useDebounce(queryParams, 500);

  useEffectOnce(() => {
    setMassSelectedCustomers(alreadySelectedCustomers);
  });

  const getCustomer = async (debouncedqueryParams) => {
    let response = await fetch(
      `${backendUrl}/api/customers?${queryString.stringify(
        debouncedqueryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
          Authorization: `Bearer ${token}`,
        },
        credentials: "include",
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    data.customerTypes = uniqBy(
      [
        ...customerTypeOptions,
        ...data.customerTypes
          .filter((el) => el.TransType)
          .map((el) => ({
            value: el.TransType,
            label: el.TransType,
          })),
      ],
      "value"
    );
    return data;
  };

  const { data = { customers: [], customerTypes: [] } } = useQuery(
    [queryActions.CUSTOMERS, debouncedqueryParams],
    () => getCustomer(debouncedqueryParams),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
      page: 1,
    });
  };

  const handleSelectedCustomer = (customer) => {
    if (isMulti) {
      const foundCustomer = massSelectedCustomers.find(
        (el) => el.Cust_ID === customer.Cust_ID
      );
      if (foundCustomer) {
        // Remove
        setMassSelectedCustomers([
          ...massSelectedCustomers.filter(
            (el) => el.Cust_ID !== customer.Cust_ID
          ),
        ]);
      } else {
        // Add
        setMassSelectedCustomers([...massSelectedCustomers, customer]);
      }

      return;
    }
    setSelectedCustomer(customer);
    setShowCustomerSelectorModal(false);
  };

  const sendMassSelectedCustomers = () => {
    setSelectedCustomer(massSelectedCustomers);
    setShowCustomerSelectorModal(false);
  };

  return (
    <>
      <Modal
        show={true}
        onHide={() => setShowCustomerSelectorModal(false)}
        dialogClassName="customer-select-modal"
        backdropClassName={`global-backdrop`}
        centered={true}
        animation={false}
        enforceFocus={false}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h1>{title ? title : "Select Customer"}</h1>
            <p>
              {!title &&
                `Choose a customer in the following table or create a new customer`}
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column justify-content-between">
          <div className="search-area d-flex flex-wrap gap-3 w-100 px-3 mb-4">
            <div className="global-search-area  flex-grow-1">
              <MagnifyIcon />
              <Form.Control
                id="queryParams-q"
                className=""
                name="LastName"
                value={queryParams.LastName}
                onChange={(e) => handleSearchQueryChange(e)}
                placeholder="Search..."
              />
            </div>

            <Form.Group>
              <Select
                classNamePrefix="form-select"
                menuPosition="fixed"
                menuPlacement="auto"
                placeholder="Customer Type"
                name="TransType"
                isSearchable={true}
                onChange={(selected) => {
                  setQueryParams({
                    ...queryParams,
                    TransType: selected?.value,
                  });
                }}
                value={data.customerTypes.find(
                  (el) => el.value === queryParams.TransType
                )}
                options={data.customerTypes || []}
                isClearable
              />
            </Form.Group>
            {/* 
            <Form.Group>
              <Select
                classNamePrefix="form-select"
                menuPosition="fixed"
                menuPlacement="auto"
                placeholder="Last Created"
                name="category"
                isSearchable={true}
                key={data?.category}
                 onChange={(selected) => {
                          setFilterParams({
                            ...filterParams,
                            category: selected.value,
                          });
                        }}
                        defaultValue={defaultSelectValue(
                          queryParams?.category,
                          data?.category,
                          { value: "", label: "Select" }
                        )} 
                options={data?.category || []}
              />
            </Form.Group>

            <Form.Group>
              <Select
                classNamePrefix="form-select"
                menuPosition="fixed"
                menuPlacement="auto"
                placeholder="All Type"
                name="category"
                isSearchable={true}
                key={data?.category}
                 onChange={(selected) => {
                          setFilterParams({
                            ...filterParams,
                            category: selected.value,
                          });
                        }}
                        defaultValue={defaultSelectValue(
                          queryParams?.category,
                          data?.category,
                          { value: "", label: "Select" }
                        )} 
                options={data?.category || []}
              />
            </Form.Group>

            <Form.Group>
              <Select
                classNamePrefix="form-select"
                menuPosition="fixed"
                menuPlacement="auto"
                placeholder="All Branch"
                name="category"
                isSearchable={true}
                key={data?.category}
                  onChange={(selected) => {
                          setFilterParams({
                            ...filterParams,
                            category: selected.value,
                          });
                        }}
                        defaultValue={defaultSelectValue(
                          queryParams?.category,
                          data?.category,
                          { value: "", label: "Select" }
                        )} 
                options={data?.category || []}
              />
            </Form.Group>

            <Form.Group>
              <Select
                classNamePrefix="form-select"
                menuPosition="fixed"
                menuPlacement="auto"
                placeholder="Trans Type"
                name="category"
                isSearchable={true}
                key={data?.category}
                  onChange={(selected) => {
                          setFilterParams({
                            ...filterParams,
                            category: selected.value,
                          });
                        }}
                        defaultValue={defaultSelectValue(
                          queryParams?.category,
                          data?.category,
                          { value: "", label: "Select" }
                        )} 
                options={data?.category || []}
              />
            </Form.Group> */}

            {/*  <Button onClick={() => setShowCreateNewCustomerModal(true)}>
              Create New
            </Button> */}
          </div>

          <div className="content px-3 pb-4 pe-2 flex-grow-1">
            <div className="d-flex justify-content-end">
              {isMulti ? (
                <Form.Label className="d-flex gap-3 fw-6">
                  <Form.Switch
                    checked={showSelected}
                    onChange={() => setShowSelected(!showSelected)}
                  />
                  Show Selected
                </Form.Label>
              ) : null}
            </div>
            <Table
              borderless
              striped
              responsive
              className="product-table text-nowrap"
            >
              <thead className="sticky border-bottom">
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Transaction Type</th>
                  <th>Branch</th>
                  <th>Department</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody className="blue-hover">
                {(showSelected ? massSelectedCustomers : data.customers).map(
                  (el, index) => (
                    <tr
                      key={index}
                      onClick={() => handleSelectedCustomer(el)}
                      className={`p-cursor ${
                        selectedCustomer?.Cust_ID === el.Cust_ID
                          ? "active-row"
                          : ""
                      } `}
                    >
                      <td>{el.Cust_ID}</td>
                      <td>{el.LastName}</td>
                      <td>{el.TransType}</td>
                      <td>{el.Branch}</td>
                      <td>{el.Dept}</td>
                      <td>
                        {isMulti ? (
                          <Form.Switch
                            checked={massSelectedCustomers.find(
                              (customer) => customer.Cust_ID === el.Cust_ID
                            )}
                            readOnly
                          />
                        ) : (
                          <Button variant="outline-primary">Select</Button>
                        )}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          </div>

          <div className="d-flex justify-content-between px-3 align-items-center pagination">
            <p className="m-0">
              Showing {data?.startIndex + 1} to{" "}
              {data?.endIndex <= data?.count ? data?.endIndex : data?.count} of{" "}
              {data.count} entries
            </p>

            <ReactPaginate
              {...paginationOptions}
              pageCount={Math.ceil(data.count / queryParams.limit)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={0}
              onPageChange={({ selected }) => {
                scrollToTop();
                setQueryParams({
                  ...queryParams,
                  page: selected + 1,
                });
              }}
              forcePage={queryParams.page - 1}
            />
          </div>
        </Modal.Body>
        {isMulti && (
          <Modal.Footer>
            <Button
              onClick={() => sendMassSelectedCustomers()}
              className="px-3"
            >
              Add Selected Customers
            </Button>{" "}
          </Modal.Footer>
        )}
      </Modal>

      {showCreateNewCustomerModal && (
        <NewCustomerModal
          showCreateNewCustomerModal={showCreateNewCustomerModal}
          setShowCreateNewCustomerModal={setShowCreateNewCustomerModal}
        />
      )}
    </>
  );
}
